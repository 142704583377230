import React, { RefObject, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import {
  UploadEndEventPayload,
  UploadErrorEventPayload,
  UploadsStartEventPayload,
} from '@atlaskit/media-picker';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import ImageIcon from '@atlaskit/icon/glyph/image';

import { logErrorMessage, logInfoMessage } from '../../utils/sentry';
import {
  AnalyticsEventActions,
  AnalyticsEventSource,
  useAnalytics,
} from '../AnalyticsContext';

import * as Styled from './ProfileHeader.styled';

import { ProfileHeaderProps } from './types';
import UserHeaderImage from './UserHeaderImage';
import UserHeaderImageBrowser from './UserHeaderImageBrowser';
import { useProfileHeaderUserQuery } from './hooks/useProfileHeaderUserQuery';
import { useUpdateUserProfileHeaderMutation } from './hooks/useUpdateUserProfileHeaderMutation';
import { isFedRamp } from '../../config/env';

const REMOVE_IMAGE_ACTION = 'removeHeaderImage';
const UPLOAD_IMAGE_ACTION = 'uploadHeaderImage';

export const ProfileHeader = ({
  addFlag,
  hasRoundCorners = false,
  height = 192,
  userId,
  isEditable = false,
}: ProfileHeaderProps) => {
  const analytics = useAnalytics();
  const { formatMessage } = useIntl();
  const { data, isLoading, isError } = useProfileHeaderUserQuery(userId);
  const imgSrc = isLoading || isError ? '' : data?.headerImageUrl;

  const [isHovered, setIsHovered] = useState(false);
  const [isMediaBrowserOpen, setIsMediaBrowserOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const backdropComponentRef = useRef<HTMLDivElement>(null);

  const { mutateAsync: updateUserProfile } = useUpdateUserProfileHeaderMutation(
    userId,
  );

  const triggerOperationalEvents = useCallback(
    (action: string, attrs = {}) => {
      analytics.pushOperationalEvent({
        action,
        source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
        actionSubject: 'headerImage',
        attributes: {
          ...attrs,
        },
      });
    },
    [analytics],
  );

  const handleDropdownOpenChange = useCallback(({ event, isOpen }): void => {
    setIsDropdownOpen(isOpen);
    if (
      backdropComponentRef.current &&
      event.target instanceof Node &&
      backdropComponentRef.current.contains(event.target)
    ) {
      setIsHovered(false);
    }
  }, []);

  const handleUploadStart = useCallback(
    (event: UploadsStartEventPayload): void => {
      analytics.pushUIEvent({
        source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
        action: AnalyticsEventActions.CLICKED,
        actionSubject: 'button',
        actionSubjectId: 'profileHeaderMediaPickerUpload',
      });
      triggerOperationalEvents(AnalyticsEventActions.STARTED, {
        actionType: UPLOAD_IMAGE_ACTION,
      });

      setIsHovered(false);
      setIsUploading(true);
    },
    [analytics, triggerOperationalEvents],
  );

  /**
   * Uploading header image has 3 steps:
   * 1. Upload an actual image to media services via Browser of "@atlaskit/media-picker"
   * 2. Browser component returns an image id for us via "handleUploadEnd"
   * 3. We store the image id into our service via "pf-directory" service.
   * This function "handleUploadError" will handle errors for step 1 and step 3.
   */
  const handleUploadError = useCallback(
    (event: UploadErrorEventPayload): void => {
      triggerOperationalEvents(AnalyticsEventActions.FAILED, {
        actionType: UPLOAD_IMAGE_ACTION,
      });
      // log info because we already tracked this error by above operational event.
      void logInfoMessage('ProfileHeaderUploadError', {
        error: event.error,
      });
      setIsUploading(false);
      addFlag({
        title: formatMessage({
          id: 'ptc-directory.profile-header.error.title',
          defaultMessage: 'Oops, something went wrong',
          description:
            'Title of an error notifcation. Notifies the user that saving their header image failed. Appears in the bottom/left corner.',
        }),
        description: formatMessage({
          id: 'ptc-directory.profile-header.error.message',
          defaultMessage: 'We couldn‘t update your header image',
          description:
            'Message of an error notifcation. Notifies the user that saving their header image failed. Appears in the bottom/left corner.',
        }),
        type: 'error',
        id: 'profile-header.upload.error',
      });
    },
    [addFlag, formatMessage, triggerOperationalEvents],
  );

  const handleUploadEnd = useCallback(
    async (event: UploadEndEventPayload) => {
      // Checking for both file.id and public.id to make it work
      // for all media-picker versions in our peer dependency range
      const eventFileId = event && event.file && event.file.id;
      // @ts-ignore
      const eventPublicId = event && event.public && event.public.id;

      const uploadedFileId: string | undefined = eventFileId || eventPublicId;

      if (!uploadedFileId) {
        void logErrorMessage('ProfileHeaderUploadMissingIDError', { event });
      } else {
        try {
          await updateUserProfile({ headerImageId: uploadedFileId });
          triggerOperationalEvents(AnalyticsEventActions.SUCCEEDED, {
            actionType: UPLOAD_IMAGE_ACTION,
          });
          setIsUploading(false);
        } catch (e) {
          handleUploadError({
            fileId: uploadedFileId,
            error: {
              name: 'upload_fail',
              description:
                'failed to upload header image in profile and visibility page',
            },
          });
        }
      }
    },
    [handleUploadError, triggerOperationalEvents, updateUserProfile],
  );

  const onRemoveImage = async () => {
    triggerOperationalEvents(AnalyticsEventActions.STARTED, {
      actionType: REMOVE_IMAGE_ACTION,
    });

    try {
      await updateUserProfile({ headerImageId: null });
      triggerOperationalEvents(AnalyticsEventActions.REMOVED, {
        actionType: REMOVE_IMAGE_ACTION,
      });
    } catch (e) {
      triggerOperationalEvents(AnalyticsEventActions.FAILED, {
        actionType: REMOVE_IMAGE_ACTION,
      });
    }

    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      action: AnalyticsEventActions.CLICKED,
      actionSubject: 'button',
      actionSubjectId: 'profileHeaderRemove',
    });

    setIsHovered(false);
  };

  const onMouseEnter = (): void => {
    setIsHovered(true);
  };

  const onMouseLeave = (event): void => {
    /**
     * For mouseleave events, event.relatedTarget points to the element you
     * moved your mouse to. Browsers trigger mouseleave on-click with a null
     * relatedTarget for some reason. Each browser does it in its own selection
     * of weird scenarios.
     */
    if (event.relatedTarget !== null) {
      setIsHovered(isDropdownOpen);
    }
  };

  const renderHoverOverlay = (): JSX.Element => {
    const showHoverOverlay = isHovered && !isUploading && !isFedRamp();

    return (
      <DropdownMenu
        onOpenChange={handleDropdownOpenChange}
        placement="bottom"
        trigger={({ triggerRef, ...triggerProps }) => (
          <Styled.HoverOverlay
            data-testid="hover-overlay"
            isVisible={showHoverOverlay}
            height={height}
            hasRoundCorners={hasRoundCorners}
            {...triggerProps}
          >
            <Styled.DropdownTrigger
              ref={triggerRef as RefObject<HTMLDivElement>}
              tabIndex={0}
            >
              <ImageIcon size="large" label="" />
            </Styled.DropdownTrigger>
            <FormattedMessage
              {...(imgSrc
                ? {
                    id: 'ptc-directory.profile-header.action.update-image',
                    defaultMessage: 'Update your header image',
                    description:
                      'Call-to-action text to change the header image to the user profile. Shown to the user when they already have a header image set. Appears in the hover state of the header image on a person profile page.',
                  }
                : {
                    id: 'ptc-directory.profile-header.action.add-image',
                    defaultMessage: 'Add your header image',
                    description:
                      'Call-to-action text to add an header image to the user profile. Shown to the user when they do no have a current header image set. Appears in the hover state of the header image on a person profile page.',
                  })}
              tagName="p"
            />
          </Styled.HoverOverlay>
        )}
      >
        <DropdownItem onClick={() => setIsMediaBrowserOpen(true)}>
          <FormattedMessage
            id="ptc-directory.profile-header.dropdown.add-image"
            defaultMessage="Upload an image"
            description="Dropmenu item text that when selected will let the user upload an image file to use as their new header image. Appears in the dropdown-menu that opens when the header image hover overlay is clicked."
          />
        </DropdownItem>
        <DropdownItem isDisabled={!imgSrc} onClick={onRemoveImage}>
          <FormattedMessage
            id="ptc-directory.profile-header.dropdown.remove-image"
            defaultMessage="Remove image"
            description="Dropmenu item text that when selected will remove the users current header image. Appears in the dropdown-menu that opens when the header image hover overlay is clicked."
          />
        </DropdownItem>
      </DropdownMenu>
    );
  };

  const getBackdropProps = () => {
    if (!isEditable) {
      return {};
    }

    return {
      onMouseEnter: onMouseEnter,
      onFocus: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onBlur: onMouseLeave,
    };
  };

  return (
    <Styled.Header height={height}>
      <Styled.Backdrop
        {...getBackdropProps()}
        height={height}
        ref={backdropComponentRef}
      >
        <UserHeaderImage
          hasRoundCorners={hasRoundCorners}
          height={height}
          imgSrc={imgSrc}
          isUploading={isUploading || isLoading}
        />
        {renderHoverOverlay()}
      </Styled.Backdrop>

      {isEditable && (
        <UserHeaderImageBrowser
          onError={handleUploadError}
          onStart={handleUploadStart}
          onEnd={handleUploadEnd}
          onClose={() => {
            setIsMediaBrowserOpen(false);
          }}
          isOpen={isMediaBrowserOpen}
        />
      )}
    </Styled.Header>
  );
};
