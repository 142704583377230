import AnalyticsWebClient, {
  envType,
  userType,
  apdexType,
  tenantType,
} from '@atlassiansox/analytics-web-client';

import { whenDefined } from '../utils/watch-store';
import { CommonEvent, ScreenEvent } from '../components/AnalyticsContext';
import { getConfig } from '../config/envConfig';
import { EnvType, getEnv } from '../config/env';

import referrerInfo from '../utils/referrerInfo';
import { getPageLoadMetric } from '../browser-metrics/pageLoadMetrics';
import { logException } from '../utils/sentry';

function getServerName() {
  return window.location.hostname;
}

const { LOCAL, DEV, STAGING, PROD } = envType;
const ENV_MAP: Record<EnvType, envType> = {
  local: LOCAL,
  test: LOCAL,
  ddev: DEV,
  fedex: STAGING,
  stg: STAGING,
  'stg-fedm': STAGING,
  prod: PROD,
  'prod-fedm': PROD,
};

const analyticsConfig = getConfig().analytics;

type Options = any; // tslint:disable-line no-any
type EventType = 'track' | 'screen' | 'operation' | 'ui';
export type CallBackFn = () => void;

interface State {
  analyticsscheme: string;
  analyticsserver: string;
  server: string;
  product: string;
  isReadyToSend: boolean;
  pendingEventsQueue: {
    eventType: EventType;
    eventData: CommonEvent | ScreenEvent;
    eventCallback?: CallBackFn;
  }[];
  storage_key: string;
}

export const DEFAULT_PRODUCT_NAME = 'identity';
export const DEFAULT_SUB_PRODUCT_NAME = 'manageProfile';

class AnalyticsClient {
  accountIdPromise: Promise<string | null>;
  isInitallLoad: boolean;
  state: State & Options;
  analyticsWebClient: AnalyticsWebClient;

  constructor(
    store,
    options: any = {}, // tslint:disable-line no-any
  ) {
    this.accountIdPromise = whenDefined(store, 'user.data.accountId', {
      acceptNull: false,
      timeout: options.accountIdWaitTimeout,
    });

    this.state = {
      analyticsscheme: analyticsConfig.scheme,
      analyticsserver: analyticsConfig.server,
      server: getServerName(),
      product: DEFAULT_PRODUCT_NAME,
      isReadyToSend: false,
      pendingEventsQueue: [],
      storage_key: 'manage.profile.analytics',
      ...options,
    };

    // New GAS pipeline client. Follow this docs https://bitbucket.org/atlassian/analytics-web-client/src/master/ for more detail
    this.analyticsWebClient = new AnalyticsWebClient({
      env: ENV_MAP[getEnv()],
      product: DEFAULT_PRODUCT_NAME,
      subproduct: DEFAULT_SUB_PRODUCT_NAME,
    });
    this.analyticsWebClient.setTenantInfo(tenantType.NONE, undefined);

    // sending apdex events needs to know whether this is the initial load of the app or a transition.
    // Following variable helps us identify that.
    this.isInitallLoad = true;
  }

  getAnalyticsWebClient() {
    return this.analyticsWebClient;
  }

  sendTrackEvent(eventData) {
    void this.analyticsWebClient.sendTrackEvent(eventData);
  }

  /**
   * Checks whether the library has been initialized and decides whether the event should be sent or cached
   * @param {*} eventType Values could be 'track', 'screen', 'operation, 'ui'
   * @param {*} eventData Data of the event
   * @param {Function} cb Callback is called when the event is sent successfully.
   */
  private canEventBeSent(
    eventType: EventType,
    eventData: CommonEvent | ScreenEvent,
    cb?: CallBackFn,
  ): boolean {
    if (this.state.isReadyToSend) {
      return true;
    }

    // add to `pendingEventsQueue` in order to send the event later
    this.state.pendingEventsQueue.push({
      eventType,
      eventData,
      // `eventCallback` is called when `pendingEventsQueue` is processed and eventData is sent
      eventCallback: cb,
    });

    return false;
  }

  pushTrackEvent(eventData: CommonEvent, cb?: CallBackFn): void {
    if (this.canEventBeSent('track', eventData, cb)) {
      const event = this.buildEventPayload(eventData);
      void this.analyticsWebClient.sendTrackEvent(event, cb);
    }
  }

  pushUIEvent(eventData: CommonEvent, cb?: CallBackFn): void {
    if (this.canEventBeSent('ui', eventData, cb)) {
      const event = this.buildEventPayload(eventData);
      void this.analyticsWebClient.sendUIEvent(event, cb);
    }
  }

  pushOperationalEvent(eventData: CommonEvent, cb?: CallBackFn): void {
    if (this.canEventBeSent('operation', eventData, cb)) {
      const event = this.buildEventPayload(eventData);
      void this.analyticsWebClient.sendOperationalEvent(event, cb);
    }
  }

  pushScreenEvent(eventData: ScreenEvent, cb?: CallBackFn): void {
    const additionalAttributes = referrerInfo.get();
    referrerInfo.markUsed();

    if (eventData && additionalAttributes) {
      eventData = {
        ...eventData,
        attributes: {
          ...additionalAttributes,
          ...eventData.attributes,
        },
      };
    }
    try {
      const pageMetric = getPageLoadMetric(eventData.name);

      if (pageMetric.getData().state !== 'finished') {
        pageMetric.stop();
      }
    } catch (error) {
      void logException(error, 'browser metrics error');
    }

    if (this.canEventBeSent('screen', eventData, cb)) {
      void this.analyticsWebClient.sendScreenEvent(eventData, cb);
    }
  }

  buildEventPayload(eventData) {
    return {
      containerType: null,
      containerId: null,
      objectType: null,
      objectId: null,
      source: null,

      actionSubject: null,
      action: null,
      actionSubjectId: null,
      attributes: null,
      tags: [],
      ...eventData,
    };
  }

  /**
   *  Start up E-MAU events in the new analytics pipeline (GAS v3)
   *  See: https://extranet.atlassian.com/display/BPO/DACI%3A+E-MAU+Definition
   */
  async startUIViewedEvent() {
    const accountId = await this.accountIdPromise;

    if (accountId) {
      this.analyticsWebClient.setUserInfo(
        userType.ATLASSIAN_ACCOUNT,
        accountId,
      );
    }

    this.analyticsWebClient.startUIViewedEvent();
    this.state.isReadyToSend = true;
    this.flushPendingEventsQueue();
  }

  /**
   * Flush all the pending events in the queue
   */
  flushPendingEventsQueue() {
    this.state.pendingEventsQueue.forEach(element => {
      const { eventType, eventData, eventCallback } = element;

      switch (eventType) {
        case 'track':
          this.pushTrackEvent(eventData, eventCallback);
          break;
        case 'ui':
          this.pushUIEvent(eventData, eventCallback);
          break;
        case 'screen':
          this.pushScreenEvent(eventData, eventCallback);
          break;
        case 'operation':
          this.pushOperationalEvent(eventData, eventCallback);
          break;
        default:
          // tslint:disable-next-line no-console
          console.error('Invalide Event :', element);
          break;
      }
    });
  }

  startApdexEvent = apdexEvent => {
    this.analyticsWebClient.startApdexEvent(apdexEvent);
  };

  stopApdexEvent = apdexEvent => {
    let taskType;
    if (this.isInitallLoad) {
      // TODO: setting taskType in here is not required since if type
      // is not defined, it should default to INITIAL_LOAD.
      // But a bug in the api of the analytics client prevents us
      // from passing empty values for type
      // TODO: remove type from apdexStopEventType
      taskType = apdexType.INITIAL_LOAD;

      this.isInitallLoad = false;
    } else {
      taskType = apdexType.TRANSITION;
    }
    try {
      this.analyticsWebClient.stopApdexEvent({ ...apdexEvent, type: taskType });
    } catch (e) {
      // TODO as part of PTC-1283 - devise correct way to handle errors here
      // tslint:disable-next-line no-console
      console.warn(apdexEvent, e);
    }
  };
}

export default AnalyticsClient;

/**
 * In order for the app to have few stared usages of the same analytics client instances we'll preserve them in a local const
 */
let preservedAnalyticsClients;

export const getAnalyticsClient = (store?: any) => {
  if (!preservedAnalyticsClients || !store) {
    preservedAnalyticsClients = new AnalyticsClient(store);
  }

  return preservedAnalyticsClients;
};
