export function errorToReason(error) {
  var _ref = error || {},
    _ref$name = _ref.name,
    name = _ref$name === void 0 ? 'Unknown' : _ref$name,
    _ref$status = _ref.status,
    status = _ref$status === void 0 ? undefined : _ref$status;
  return {
    name: name,
    status: status
  };
}