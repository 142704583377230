import RestClient from './rest-client';
import { getConfig } from '../config/envConfig';

export enum TokenStatus {
  Available = 'available',
  Expired = 'expired',
  RefreshNeeded = 'refreshNeeded',
  ServerError = 'serverError',
}

interface Account {
  id: string;
  displayName: string;
  status: TokenStatus;
  connectedAt: string | null;
  scopes: string[];
  contextAris?: string[];
}

export enum ProviderTypes {
  Google = 'google',
  Dropbox = 'dropbox',
  OAuth2 = 'oauth2',
}

interface ServiceWithAccounts {
  service: {
    serviceKey: string;
    displayName: string;
    providerType: ProviderTypes;
  };
  accounts: Account[];
}

interface ContainerWithServicesAndAccounts {
  container: {
    containerId: string;
    displayName: string;
  };
  services: ServiceWithAccounts[];
}

export interface OutboundAuthAccountsPayload {
  containers: ContainerWithServicesAndAccounts[];
}

class OutboundAuthClient extends RestClient {
  getAccounts(aaId: string): Promise<OutboundAuthAccountsPayload> {
    return this.getResource(`/aaId/${aaId}`);
  }

  revokeAccess(aaId: string, externalAccountId: string): Promise<void> {
    return this.postResource(`/aaId/${aaId}/removeExternalAccount`, {
      externalAccountId,
    });
  }
}

export const outboundAuthClient = new OutboundAuthClient({
  serviceUrl: `${getConfig().stargateRoot}/outbound-auth`,
});
