import { retryOnException } from '@atlaskit/frontend-utilities/retry-operation';

import { AbstractAccountClient } from './AbstractAccountClient';
import type {
  AssociatedSession,
  Config,
  RecentAccount,
  User,
  UserListener,
} from './types';
import {getConfig} from '../../../../../common';

const retryOptions = {
  minTimeout: 1_000,
  retries: 4,
  factor: 2,
};

/**
 * The retry npm package has become incompatible due to Parcel 2 scope-hoisting and retry not correctly exporting.
 *
 * Since the maintainer of the retry package seems to have abandoned the project, moving toward an internal
 * retry library.
 *
 * The following recreates the previous settings for retry; see for formula https://www.npmjs.com/package/retry
 */
const intervalsMS = new Array(retryOptions.retries)
  .fill(0)
  .map(
    (_, attempt) =>
      Math.max(1, retryOptions.minTimeout) *
      Math.pow(retryOptions.factor, attempt),
    Infinity,
  );

export class DefaultAccountClient extends AbstractAccountClient {
  private _listeners: UserListener[] = [];

  /** @override */
  currentUser: User | null | undefined;

  recentAccountStorage: WindowLocalStorage['localStorage'];

  constructor(config: Config) {
    super(config);
    this.recentAccountStorage =
      config.recentAccountStorage || window.localStorage;
    this._load();
  }

  /** @override */
  addUserListener(this: this, callback: UserListener): () => void {
    this._listeners.push(callback);

    return () => {
      this._listeners = this._listeners.filter(
        listener => listener !== callback,
      );
    };
  }

  /** @override */
  async switchAccount(accountId: User['account_id']) {
    const response = await fetch('/SetCST/switch', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accountId }),
    });
    if (response.status !== 200) {
      throw Object.assign(
        new Error(
          `Unexpected response from /SetCST/switch: ${response.status}`,
        ),
        { response },
      );
    }
  }

  /** @override */
  async getRecentAssociatedSessions(this: this) {
    return retryOnException(
      async () => {
        const response = await fetch('/SetCST/list');

        if (response.status === 200) {
          const sessions: {
            accountId: string;
            sessionId: string;
            associations: AssociatedSession[];
          } = await response.json();

          const recentAccounts = this._getRecentAccounts();
          const recentAccountIds = new Set(
            recentAccounts.map(({ userId }) => userId),
          );

          return sessions.associations.filter(session =>
            recentAccountIds.has(session.accountId),
          );
        } else {
          throw Object.assign(
            new Error(
              `Unexpected response from /SetCST/list: ${response.status}`,
            ),
            { response },
          );
        }
      },
      { intervalsMS },
    );
  }

  private _load(this: this): void {
    retryOnException(
      async () => {
        const response = await fetch(`${getConfig().stargateRoot}/me`, {
          mode: 'cors',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          const user: User = await response.json();
          this._saveRecentAccount(user);
          return user;
        } else if (response.status === 401) {
          return null;
        } else {
          throw Object.assign(
            new Error(
              `Unexpected response from /gateway/api/me: ${response.status}`,
            ),
            { response },
          );
        }
      },
      { intervalsMS },
    ).then(
      user => {
        this.currentUser = user;
        this._listeners.forEach(listener => listener(user));
      },
      error => {
        this.currentUser = null;
        this._listeners.forEach(listener => listener(null, error));
      },
    );
  }

  private _saveRecentAccount(this: this, user: User): void {
    try {
      const recentAccounts: Array<RecentAccount> = JSON.parse(
        this.recentAccountStorage.getItem('identity.recent.accounts') || '[]',
      );

      const recentAccountIds = new Set(
        recentAccounts.map(({ userId }) => userId),
      );
      if (recentAccountIds.has(user.account_id)) {
        return;
      }

      this.recentAccountStorage.setItem(
        'identity.recent.accounts',
        JSON.stringify([
          ...recentAccounts,
          {
            userId: user.account_id,
            lastUsed: Date.now(),
          },
        ]),
      );
    } catch (error) {}
  }

  private _getRecentAccounts(this: this): Array<RecentAccount> {
    try {
      const recentAccounts: Array<RecentAccount> = JSON.parse(
        this.recentAccountStorage.getItem('identity.recent.accounts') || '[]',
      );

      return recentAccounts;
    } catch (error) {
      return [];
    }
  }
}
