import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl-next';
import styled from '@emotion/styled';

import AtlassianSwitcher, {
  AtlassianSwitcherPrefetchTrigger,
} from '@atlassian/switcher';
import { AppSwitcher } from '@atlaskit/atlassian-navigation';
import Popup from '@atlaskit/popup';

export const AppSwitcherPopupStyledDiv = styled.div`
  padding: 0;
  max-height: 80vh;
`;

const AtlassianSwitcherPopup: React.ComponentType = () => {
  const { formatMessage } = useIntl();
  const [isAppSwitcherOpen, setIsAppSwitcherOpen] = useState(false);
  const toggleAppSwitcherOpen = useCallback(
    () => setIsAppSwitcherOpen(isOpen => !isOpen),
    [],
  );
  const closeAppSwitcher = useCallback(() => setIsAppSwitcherOpen(false), []);
  return (
    <Popup
      placement="bottom-start"
      rootBoundary="document"
      onClose={closeAppSwitcher}
      isOpen={isAppSwitcherOpen}
      content={() => (
        <AppSwitcherPopupStyledDiv>
          <AtlassianSwitcher appearance="standalone" product="manage-profile" />
        </AppSwitcherPopupStyledDiv>
      )}
      trigger={triggerProps => (
        <AtlassianSwitcherPrefetchTrigger>
          <AppSwitcher
            tooltip={formatMessage({
              id: 'ptc-directory.app-switcher-popup.tooltip',
              defaultMessage: 'Switch to...',
              description: 'Tooltip for the app switcher',
            })}
            onClick={toggleAppSwitcherOpen}
            {...triggerProps}
          />
        </AtlassianSwitcherPrefetchTrigger>
      )}
    />
  );
};

export default AtlassianSwitcherPopup;
