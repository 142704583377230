import { injectGlobal } from '@emotion/css';
import { colors, fontFamily } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  html {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    font-family: ${fontFamily()};
    font-size: 14px;
    line-height: 1.42857143;
    color: ${token('color.text', colors.N800)};
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  b, strong {
    font-weight: 600;
  }
`;
