import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl-next';

import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import {
  Anchor,
  DismissibleSectionMessage,
  AnalyticsEventActions,
  AnalyticsEventSource,
  AnalyticsEventSubjects,
  useAnalytics,
} from '../../../../common';

import { UserState } from './types';
import { startShowingBannerDate } from './constants';
import {
  useDismissAdminManagesAccountBannerMutation,
  useDismissedAdminManagesAccountBannerQuery,
} from './hooks';

const AdminManagesAccountBannerImpl = () => {
  const analytics = useAnalytics();
  const {
    mutate: dismissBanner,
  } = useDismissAdminManagesAccountBannerMutation();

  useEffect(() => {
    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      action: AnalyticsEventActions.SHOWN,
      actionSubject: AnalyticsEventSubjects.NOTIFICATION,
      actionSubjectId: 'domainClaimNotification',
    });
  }, [analytics]);

  const MessageContent = (
    <>
      <strong>
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.admin-manages-account-message.title"
          defaultMessage="Your admin now manages your account."
          description="Title text for the notice message informing the user that their account now manages by admin"
        />
      </strong>
      <span>&nbsp;</span>
      <FormattedMessage
        id="ptc-directory.settings.profile-and-visibility.admin-manages-account-message.body"
        defaultMessage="Contact your admin to change your account details."
        description="Body text for the notice message informing the user that their account now manages by admin"
      />
    </>
  );

  const onDismiss = () => {
    void dismissBanner();

    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      action: AnalyticsEventActions.CLICKED,
      actionSubject: AnalyticsEventSubjects.BUTTON,
      actionSubjectId: 'dismissDomainClaimNotification',
    });
  };

  const onAnchorClick = () => {
    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      action: AnalyticsEventActions.CLICKED,
      actionSubject: AnalyticsEventSubjects.LINK,
      actionSubjectId: 'learnMoreAboutManagedAccounts',
    });
  };

  return (
    <DismissibleSectionMessage
      testId="managedAccountBanner"
      message={MessageContent}
      actions={[
        <Anchor
          href="https://confluence.atlassian.com/x/kQkvOg"
          target="_blank"
          key="adminManageAccountBannerLinkText"
          onClick={onAnchorClick}
        >
          <FormattedMessage
            id="ptc-directory.settings.profile-and-visibility.admin-manages-account-message.link-text"
            defaultMessage="Learn more about managed accounts"
            description="Text of link to learn more about managed accounts"
          />
        </Anchor>,
      ]}
      icon={
        <InfoIcon
          size="medium"
          primaryColor={token('color.icon.accent.purple', colors.P500)}
          label="info block"
        />
      }
      onDismiss={onDismiss}
    />
  );
};

export const AdminManagesAccountBanner = ({
  isUserLoading,
  isManaged,
  timestamp = '',
}: UserState) => {
  const enabled = !isUserLoading && isManaged;
  const { data, isLoading } = useDismissedAdminManagesAccountBannerQuery(
    enabled,
  );
  const dismissed = data?.status;
  const lastSeenDate = data?.lastSeenDate;

  if (isUserLoading || !isManaged || isLoading) {
    return null;
  }

  const managedByDate = new Date(timestamp).getTime();

  if (new Date(startShowingBannerDate).getTime() > managedByDate) {
    return null;
  }

  if (
    dismissed &&
    lastSeenDate &&
    new Date(lastSeenDate).getTime() > managedByDate
  ) {
    return null;
  }

  return <AdminManagesAccountBannerImpl />;
};

export default AdminManagesAccountBanner;
