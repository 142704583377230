import React, { ErrorInfo, ReactNode } from 'react';

import {
  Analytics,
  AnalyticsEventActions,
  AnalyticsEventSource,
  GenericErrorPage,
  logException,
} from '../../common';

interface Props {
  analytics: Analytics;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { analytics } = this.props;

    void logException(error, 'Error caught in ErrorBoundary', {
      info: errorInfo,
      tags: {
        severity: 'high',
      },
    });

    analytics.pushOperationalEvent({
      source: AnalyticsEventSource.CLIENT_METRICS,
      actionSubject: 'errorBoundary',
      action: AnalyticsEventActions.TRIGGERED,
      attributes: {
        product: 'manage-profile-app',
        boundary: 'app',
        error: error.toString(),
      },
      tags: ['error', 'error-boundary'],
    });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return <GenericErrorPage hasSupportLink />;
  }
}
