import { FieldType } from '../../../../common';
import { UserDetails } from '../../../../utils/user';

interface Field {
  /**
   * Underlying value of the field. The value that is edited when a field
   * enters edit mode. Shown in read mode in the absence of a `read` property.
   */
  edit?: string;
  /**
   * Alternate value to display instead of the edit value when field is in read-mode
   */
  read?: string;
}

export type Fields = { [k in FieldType]?: Field };

interface TimeData {
  remoteWeekdayIndex?: string;
  remoteWeekdayString?: string;
  remoteTimeString?: string;
}

/**
 * Formats local time for the user being viewed.
 *
 * Exported for testing.
 */
export const formatRemoteTime = ({
  remoteWeekdayIndex,
  remoteWeekdayString,
  remoteTimeString,
}: TimeData): string | undefined => {
  if (!remoteWeekdayIndex || !remoteWeekdayString || !remoteTimeString) {
    return;
  }

  const localWeekdayIndex = new Date().getDay().toString();
  if (remoteWeekdayIndex === localWeekdayIndex) {
    return remoteTimeString;
  } else {
    return `${remoteWeekdayString} ${remoteTimeString}`;
  }
};

type SubmitHandler<A> = (fieldType: FieldType, value: string) => A;

export function submitDataTransformer<A>(
  onSubmit: SubmitHandler<A>,
): SubmitHandler<A> {
  return (fieldType, value) => {
    let transformedValue: string;

    switch (fieldType) {
      case FieldType.Timezone:
        // The server expects `America/Los_Angeles` etc., but we use spaces
        transformedValue = value.replace(/ /g, '_');
        break;
      default:
        transformedValue = value;
        break;
    }

    return onSubmit(fieldType, transformedValue);
  };
}

/**
 * Transform the data from the server into edit/read views.
 *
 * Exported for testing.
 */
export const transformUserData = (userData: UserDetails): Fields => ({
  [FieldType.Title]: {
    edit: userData[FieldType.Title],
  },
  [FieldType.FullName]: {
    edit: userData[FieldType.FullName],
  },
  [FieldType.Nickname]: {
    edit: userData[FieldType.Nickname],
  },
  [FieldType.Department]: {
    edit: userData[FieldType.Department],
  },
  [FieldType.CompanyName]: {
    edit: userData[FieldType.CompanyName],
  },
  [FieldType.Location]: {
    edit: userData[FieldType.Location],
  },
  [FieldType.Timezone]: {
    read: formatRemoteTime(userData),
    edit:
      // The server sends `America/Los_Angeles` etc., but we use spaces
      userData[FieldType.Timezone] &&
      userData[FieldType.Timezone].replace(/_/g, ' '),
  },
  [FieldType.LocalTime]: {
    edit: formatRemoteTime(userData) || '',
    read: formatRemoteTime(userData) || '',
  },
  [FieldType.Email]: {
    edit: userData[FieldType.Email],
  },
  [FieldType.PhoneNumber]: {
    edit: userData[FieldType.PhoneNumber],
  },
});
