import { useLayoutEffect, useState } from 'react';
import { User } from '../../../account-client/types';
import { useAccountClient } from '../../common/context';

export const useUser = (): User | null | undefined => {
  const client = useAccountClient();
  const [user, setUser] = useState(client.currentUser);
  useLayoutEffect(() => client.addUserListener(setUser), [client]);
  return user;
};
