/**
 * List of languages supported in Unified Profile according to the following page + comment
 * 2019-03-12
 * https://product-fabric.atlassian.net/wiki/spaces/PTD/pages/747538300?focusedCommentId=881656746#comment-881656746
 */
// TODO: PTC-2088 - simplify list of locales
// - key (left side) is used in code
// - value (right side) is used for API endpoints of Identity
export enum LanguageTag {
  ZH_CN = 'zh-CN',
  ZH_TW = 'zh-TW',
  CS = 'cs',
  DA = 'da',
  NL = 'nl',
  EN_US = 'en-US',
  EN_UK = 'en-GB',
  ET = 'et',
  FI = 'fi',
  FR = 'fr',
  DE = 'de',
  HU = 'hu',
  IS = 'is',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NB = 'nb',
  PL = 'pl',
  PT_BR = 'pt-BR',
  PT_PT = 'pt-PT',
  RO = 'ro',
  RU = 'ru',
  SK = 'sk',
  TH = 'th',
  TR = 'tr',
  ES = 'es',
  UK = 'uk',
  VI = 'vi',
  SV = 'sv',
}

// As per https://product-fabric.atlassian.net/wiki/spaces/PTD/pages/747538300/What+list+of+languages+should+we+show+to+our+customers
// we show languages in their native language. Country codes only included for English and languages where we have
// multiple variants that need to be disambiguated by country (e.g. Portuguese)
// - key (left side) is used in code
// - value (right side) is used to show in UI regardless of user's language
export const supportedLanguages: Record<LanguageTag, string> = {
  // this hardcoding is approved by Melanie Heighway
  // Approved list of languages for the dropdown are sourced from this spreadsheet;
  // https://drive.google.com/file/d/15IZhDfqX7MZFHL3sq7bSsCE0p6WO3Mdz/view
  [LanguageTag.ZH_CN]: '中文 (简体)',
  [LanguageTag.ZH_TW]: '中文 (繁體)',
  [LanguageTag.CS]: 'Čeština',
  [LanguageTag.DA]: 'Dansk',
  [LanguageTag.NL]: 'Nederlands',
  [LanguageTag.EN_US]: 'English (US)',
  [LanguageTag.EN_UK]: 'English (UK)',
  [LanguageTag.ET]: 'Eesti',
  [LanguageTag.FI]: 'Suomi',
  [LanguageTag.FR]: 'Français',
  [LanguageTag.DE]: 'Deutsch',
  [LanguageTag.HU]: 'Magyar',
  [LanguageTag.IS]: 'Íslenska',
  [LanguageTag.IT]: 'Italiano',
  [LanguageTag.JA]: '日本語',
  [LanguageTag.KO]: '한국어',
  // this hardcoding is approved by Melanie Heighway
  [LanguageTag.NB]: 'Norsk',
  [LanguageTag.PL]: 'Polski',
  [LanguageTag.PT_BR]: 'Português (Brasil)',
  [LanguageTag.PT_PT]: 'Português (Portugal)',
  [LanguageTag.RO]: 'Română',
  [LanguageTag.RU]: 'Русский',
  [LanguageTag.SK]: 'Slovenčina',
  // this hardcoding is approved by Melanie Heighway
  [LanguageTag.TH]: 'ภาษาไทย‎',
  [LanguageTag.TR]: 'Türkçe',
  [LanguageTag.ES]: 'Español',
  [LanguageTag.UK]: 'Українська',
  [LanguageTag.VI]: 'Tiếng Việt',
  [LanguageTag.SV]: 'Svenska',
};
