import { defineMessages } from 'react-intl-next';

export const disabledPrivacy = defineMessages({
  fullName: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy-dropdown.full-name.disabled',
    defaultMessage:
      'The full name you provide is visible to anyone who can view your content, including people outside of your organization. Accessible by installed apps.',
    description:
      'Message shown on hover of disabled visibility selector explaining that the users full name will be available publicly if the user provides it',
  },
  nickname_managed: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy-dropdown.nickname.disabled.managed-account.xml',
    defaultMessage:
      'Always visible on Atlassian public forums, such as Atlassian Community. {br}' +
      'If you restrict the visibility of <strong>Full name</strong>, people who can’t view your full name see your public name instead. (This also applies to apps when they can’t access your full name.)',
    description:
      'Message shown on hover of disabled visibility selector explaining that the users public name will be available publicly if the user provides it',
  },
  nickname_unmanaged: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy-dropdown.nickname.disabled.unmanaged-account',
    defaultMessage:
      'The public name you provide is visible on Atlassian public forums, such as Atlassian Community.',
    description:
      'Message shown on hover of disabled visibility selector explaining that the users public name will be available publicly if the user provides it',
  },
  title: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy-dropdown.title.disabled',
    defaultMessage:
      'If you provide a job title, it will be visible to anyone who can view your content, including people outside of your organization. Accessible by installed apps.',
    description:
      'Message shown on hover of disabled visibility selector explaining that the users job title will be available publicly if the user provides it',
  },
  department: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy-dropdown.department.disabled',
    defaultMessage:
      'If you provide a department, it will be visible to anyone who can view your content, including people outside of your organization. Accessible by installed apps.',
    description:
      'Message shown on hover of disabled visibility selector explaining that the users department will be available publicly if the user provides it',
  },
  companyName: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy-dropdown.company-name.disabled',
    defaultMessage:
      'If you provide an organization, it will be visible to anyone who can view your content, including people outside of your organization. Accessible by installed apps.',
    description:
      'Message shown on hover of disabled visibility selector explaining that the users company name will be available publicly if the user provides it',
  },
});
