import React, { ReactNode } from 'react';
import * as Styled from './GenericInlineDialogContent.styled';

export interface Props {
  headline?: string | ReactNode;
  message: string | ReactNode;
}

const GenericInlineDialogContent = ({ headline, message }: Props) => {
  return (
    <>
      {headline ? <Styled.Headline>{headline}</Styled.Headline> : null}
      {typeof message === 'string' ? <p>{message}</p> : message}
    </>
  );
};

export default GenericInlineDialogContent;
