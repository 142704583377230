/** @jsx jsx */
// eslint-disable-next-line @repo/internal/fs/filename-pattern-match

import { css, jsx } from '@emotion/react';
import { N0 } from '@atlaskit/theme/colors';
import { BORDER_WIDTH } from './constants';
var iconWrapperStyles = css({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  alignContent: 'center',
  borderRadius: "var(--ds-border-radius-circle, 50%)",
  overflow: 'hidden'
});

/**
 * __Icon wrapper__
 *
 * An icon wrapper is used internally only.
 */
var IconWrapper = function IconWrapper(_ref) {
  var _ref$bgColor = _ref.bgColor,
    bgColor = _ref$bgColor === void 0 ? "var(--ds-surface-overlay, ".concat(N0, ")") : _ref$bgColor,
    children = _ref.children;
  return jsx("span", {
    css: iconWrapperStyles,
    role: "presentation",
    style: {
      border: "".concat(BORDER_WIDTH, "px solid ").concat(bgColor),
      backgroundColor: bgColor
    }
  }, children);
};
export default IconWrapper;