/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Spanish (International)
export default {
  'focused-task-close-account.cancel': 'Cancelar',
  'focused-task-close-account.deactivate-account': 'Desactivar la cuenta',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Estás a punto de desactivar la cuenta de:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Estás a punto de desactivar la cuenta de:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Desactivar la cuenta',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Desactivar la cuenta',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Puedes volver a activar la cuenta en cualquier momento.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Puedes volver a activar la cuenta en cualquier momento.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Cuando desactives la cuenta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Cuando desactives la cuenta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Dejaremos de facturarte por este usuario.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Dejaremos de facturarte por este usuario.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>perderá el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, tiene acceso a:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>perderá el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, no tiene acceso a ninguno de ellos, excepto a servicios como Community o Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Otros servicios de la cuenta de Atlassian, como Atlassian Community y Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': '<b>Perderás el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, tienes acceso a:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': '<b>Perderás el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, no tienes acceso a ninguno de ellos, excepto a servicios como Community y Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Sus datos personales, como su nombre completo y su dirección de correo electrónico, y el contenido que hayan creado permanecerán en los servicios de la cuenta de Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Sus datos personales, como su nombre completo y su dirección de correo electrónico, y el contenido que hayan creado permanecerán en los servicios de la cuenta de Atlassian.',
  'focused-task-close-account.delete-account': 'Eliminar la cuenta',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Nota: Si respondes a esta encuesta, nos ayudarás a ofrecer una experiencia mejor a todos los usuarios. El usuario seguirá apareciendo como "Usuario antiguo" una vez que hayas eliminado su cuenta.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Nota: Si respondes a esta encuesta, nos ayudarás a ofrecer una experiencia mejor a todos los usuarios, incluso a aquellos que eliminen sus cuentas. Una vez que elimines tu cuenta, te seguirán viendo como "Usuario antiguo".',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Usuario antiguo',
  'focused-task-close-account.delete-account.content-preview.heading.admin': '¿Cómo preferirías ver al usuario eliminado?',
  'focused-task-close-account.delete-account.content-preview.heading.self': '¿Cómo preferirías que se viese tu cuenta eliminada?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Si pudieses elegir, ¿cómo te gustaría que viesen los demás usuarios al usuario eliminado?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Si pudieses elegir, ¿cómo te gustaría que te viesen los demás usuarios?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Una vez que se elimine la cuenta del usuario, los demás lo verán como "Usuario antiguo". Tómate un momento para responder a la pregunta de nuestra encuesta.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Una vez que se elimine tu cuenta, los demás te verán como "Usuario antiguo". Tómate un momento para responder a la pregunta de nuestra encuesta.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Mostrar menos',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} más',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Estás a punto de eliminar la cuenta de:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Estás a punto de eliminar tu cuenta:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Eliminar la cuenta',
  'focused-task-close-account.delete-account.overview.heading.self': 'Eliminar tu cuenta',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Por ejemplo, páginas, incidencias y comentarios que han creado en los productos.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Por ejemplo, páginas, incidencias y comentarios que has creado en los productos.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Es posible que tú u otros usuarios hayáis instalado aplicaciones que añadan funciones a los productos de Atlassian. Estas aplicaciones pueden haber almacenado la información del perfil del usuario.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Es posible que tú u otros usuarios hayáis instalado aplicaciones que añadan funciones a los productos de Atlassian. Estas aplicaciones pueden haber almacenado la información de tu perfil.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Información relacionada con las compras, que debemos conservar para los informes financieros.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Información relacionada con las compras, que debemos conservar para los informes financieros.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Los registros que muestran que hemos eliminado la cuenta de alguien, que podríamos necesitar para facilitárselos a los organismos reguladores.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Los registros que muestran que hemos eliminado la cuenta de alguien, que podríamos necesitar para facilitárselos a los organismos reguladores.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Los datos que forman parte de una demanda judicial en curso, que estamos obligados a guardar por ley.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Los datos que forman parte de una demanda judicial en curso, que estamos obligados a guardar por ley.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'En caso de que los datos personales sean necesarios para fines empresariales o legales legítimos, los conservamos durante períodos de tiempo limitados. Por ejemplo:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'En caso de que los datos personales sean necesarios para fines empresariales o legales legítimos, los conservamos durante períodos de tiempo limitados. Por ejemplo:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'No eliminamos ningún dato personal del contenido creado por los usuarios, como nombres o direcciones de correo electrónico escritas en una página o una incidencia. Los administradores del producto son quienes deben eliminar esos datos manualmente.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'No eliminamos ningún dato personal del contenido que creas tú o crean otras personas, como nombres o direcciones de correo electrónico escritas en una página o una incidencia. Los administradores del producto son quienes deben eliminar esos datos manualmente.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Los usuarios tienen derecho a presentar denuncias a la autoridad de control de su país.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Tienes derecho a presentar denuncias a la autoridad de control de tu país.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Cuando elimines la cuenta:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Cuando elimines tu cuenta:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'El contenido que han creado permanecerá en los servicios de la cuenta de Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'El contenido que has creado permanecerá en los servicios de la cuenta de Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Tras un período de gracia de 14 días, no podrás cancelar la eliminación de la cuenta.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Te enviaremos por correo electrónico una lista de las aplicaciones que pueden haber almacenado datos personales.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Te enviaremos por correo electrónico una lista de las aplicaciones que pueden haber almacenado datos personales.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>perderá el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, tiene acceso a:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>perderá el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, no tiene acceso a ninguno, excepto a servicios como Community o Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Otros servicios de la cuenta de Atlassian, como Atlassian Community y Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': '<b>Perderás el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, tienes acceso a:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': '<b>Perderás el acceso de inmediato</b> a todos los servicios de la cuenta de Atlassian. En este momento, no tienes acceso a ninguno, excepto a servicios como Community o Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': '<b>Eliminaremos los datos personales</b>, como el nombre completo y la dirección de correo electrónico, de los servicios de la cuenta de Atlassian en un plazo de 30 días, excepto en algunos casos en los que sean necesarios para fines empresariales o legales legítimos.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': '<b>Eliminaremos tus datos personales</b>, como tu nombre completo y dirección de correo electrónico, de los servicios de la cuenta de Atlassian en un plazo de 30 días, excepto en algunos casos en los que sean necesarios para fines empresariales o legales legítimos.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Tras un período de gracia de 14 días, no podrás cancelar la eliminación de la cuenta. Si crees que necesitarás la cuenta más adelante, lo que debes hacer es desactivarla.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Tras un período de gracia de 14 días, no podrás cancelar la eliminación de la cuenta.',
  'focused-task-close-account.learnMore': 'Más información',
  'focused-task-close-account.next': 'Siguiente',
  'focused-task-close-account.previous': 'Anterior'
};