import { resolveConfig, ResolvedConfig } from './config';
import type {
  AccountClient,
  AssociatedSession,
  Config,
  User,
  UserListener,
} from './types';

export abstract class AbstractAccountClient implements AccountClient {
  protected readonly _config: ResolvedConfig;

  /** @override */
  abstract readonly currentUser: User | null | undefined;

  constructor(config: Config) {
    this._config = resolveConfig(config);
  }

  /** @override */
  abstract addUserListener(callback: UserListener): () => void;

  /** @override */
  abstract switchAccount(accountId: User['account_id']): Promise<void>;

  /** @override */
  abstract getRecentAssociatedSessions(): Promise<AssociatedSession[]>;

  /** @override */
  getManageProfileLink(this: this, user: User | null | undefined): string {
    return this.getSessionSyncLink(
      `${this._config.identityEnvironment.baseUrl}/manage-profile`,
      user?.email,
    );
  }

  /** @override */
  getSessionSyncLink(
    this: this,
    destination: string,
    email?: string,
  ): string {
    if (email) {
      const url = this._createLink(destination);
      url.pathname = '/login';
      url.searchParams.append('prompt', 'none');
      url.searchParams.append('login_hint', email);
      return url.toString();
    } else {
      return destination;
    }
  }

  /** @override */
  getLoginLink(
    this: this,
    continueUrl: string = this._config.defaultContinueUrl,
  ): string {
    const url = this._createLink(continueUrl);
    url.pathname = '/login';
    return url.toString();
  }

  /** @override */
  getSwitchAccountLink(
    this: this,
    continueUrl: string = this._config.defaultContinueUrl,
  ): string {
    const url = this._createLink(continueUrl);
    url.pathname = '/login';
    url.searchParams.append('prompt', 'select_account');
    return url.toString();
  }

  /** @override */
  getLogoutLink(
    this: this,
    continueUrl: string = this._config.defaultLogoutContinueUrl,
  ): string {
    const url = this._createLink(continueUrl);
    url.pathname = '/logout';
    url.searchParams.append('prompt', 'none');
    return url.toString();
  }

  private _createLink(this: this, continueUrl: string) {
    const url = new URL(this._config.identityEnvironment.baseUrl);
    if (this._config.application) {
      url.searchParams.append('application', this._config.application);
    }
    url.searchParams.append('continue', continueUrl);
    return url;
  }
}
