import React from 'react';
import Spinner from '@atlaskit/spinner';

import * as Styled from './ProfileHeader.styled';
import { ProfileHeaderProps } from './types';

const DEFAULT_HEADER_IMAGE_HEIGHT = 192;

type Props = Pick<
  ProfileHeaderProps,
  | 'hasRoundCorners'
  | 'height'
  // imgSrc is fetched from pf-directory service.
  | 'imgSrc'
> & {
  isUploading: boolean;
};

export default function UserHeaderImage(props: Props) {
  const {
    hasRoundCorners = false,
    height = DEFAULT_HEADER_IMAGE_HEIGHT,
    imgSrc,
    isUploading,
  } = props;

  return (
    <div>
      {/* rendering spinner which is an overlay of below image */}
      {isUploading && (
        <Styled.LoadingSpinner data-testid="spinner">
          <Spinner size="medium" />
        </Styled.LoadingSpinner>
      )}

      {imgSrc && (
        <Styled.Image
          height={height}
          hasRoundCorners={hasRoundCorners}
          data-testid="profile-header-image"
          isUploading={isUploading}
          src={imgSrc}
        />
      )}
    </div>
  );
}
