import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  UserProfilePrivacy,
  UserProfilePrivacySetting,
} from '../../../model/userProfilePrivacy';
import {
  PrivacySettingsTransformed,
  UserManagePrivacySettingsQueryKey,
} from './useUserManagePrivacySettings';
import {
  transformPrivacySettingsFormatFromApiToSchema,
  transformPrivacySettingsFormatFromSchemaToApi,
} from './helpers/manage-user-privacy-settings';
import { getConfig } from '../../../../../common';

const mapValuesToCC = {
  public: 'PUBLIC',
  private: 'PRIVATE',
  organisation: 'COLLABORATOR',
};

const updateProfilePrivacy = async (
  userId: string,
  newPrivacySetting: { [key: string]: UserProfilePrivacySetting },
): Promise<PrivacySettingsTransformed> => {
  const url = `${
    getConfig().stargateRoot
  }/users/${userId}/manage/privacy-settings`;
  const response = await fetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(
      transformPrivacySettingsFormatFromSchemaToApi(newPrivacySetting),
    ),
  });
  const data = await response.json();

  const transformedData = transformPrivacySettingsFormatFromApiToSchema(data);
  return { ...transformedData, userId };
};

export const useProfilePrivacyMutation = (userId: string) => {
  const queryClient = useQueryClient();
  const queryKey = [UserManagePrivacySettingsQueryKey, userId];

  const { mutateAsync } = useMutation({
    mutationFn: async ({
      privacyKey,
      value,
    }: {
      privacyKey: string;
      value: string;
    }) => {
      const newPrivacyLevel = {
        [privacyKey]: mapValuesToCC[value],
      };

      await queryClient.cancelQueries({ queryKey });

      // Optimistically update to the new state
      queryClient.setQueryData(queryKey, (old?: UserProfilePrivacy) => ({
        ...old,
        ...newPrivacyLevel,
      }));
      return updateProfilePrivacy(userId, newPrivacyLevel);
    },
    onSettled: () => {
      // Always refetch after error or success
      void queryClient.invalidateQueries(queryKey);
    },
  });

  return {
    mutateAsync,
  };
};
