import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export {
  SettingsDescription,
  SettingsSection,
  SectionMessageWrapper,
} from '../../../../styled/settings';

export const Header = styled.div`
  margin-bottom: ${token('space.150', '12px')};
`;

export const LabelWrapper = styled.div`
  margin-top: 20px;
`;

export const LanguageDropdownWrapper = styled.div`
  margin-top: ${token('space.100', '8px')};
  margin-left: -${token('space.100', '8px')};
`;

export const TimezoneDropdownWrapper = styled.div`
  margin-left: -${token('space.100', '8px')};
`;

export const DropdownWrapper = styled.div`
  max-width: 360px;
  height: ${token('space.500', '40px')};
`;

export const ErrorField = styled.div`
  height: 100%;
  width: 100%;
  color: ${token('color.text.subtlest', colors.N90)};
  display: inline-flex;
  align-items: center;
`;

export const TimezoneOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TimezoneName = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TimezoneOffset = styled.span`
  background: ${token('color.background.accent.blue.bolder', colors.B400)};
  border-radius: 3px;
  font-size: 10px;
  padding: 2px 4px;
  display: inline-block;
  margin-left: 8px;
  min-width: 48px;
  text-align: center;
  line-height: 14px;
  color: ${token('color.text.inverse', colors.B50)};
`;
