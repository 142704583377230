import identityClient from '../clients/identity-client';
import { HttpError, SentryIgnoreError } from '../model/errors';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD' as const;

export const updatePassword = (
  currentPassword: string,
  newPassword: string,
) => {
  return (dispatch): Promise<{ redirectUrl: string }> => {
    const changePasswordPromise = identityClient
      .changePassword(currentPassword, newPassword)
      .catch((error: SentryIgnoreError | HttpError) => {
        let errorJson = {};
        try {
          errorJson = JSON.parse(error.message);
        } catch (errorJsonParse) {
          throw error;
        }

        throw errorJson;
      });

    dispatch({
      type: UPDATE_PASSWORD,
      payload: changePasswordPromise,
    })
      // Prevent uncaught exception in console.
      .catch(() => null);
    return changePasswordPromise;
  };
};
