import React from 'react';
import EmailSettings from '../../components/Email/EmailSettings/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const SettingsEmailRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_EMAIL_SETTINGS} />
    <RecordTransition />
    <EmailSettings />
  </>
);

export default SettingsEmailRoute;
