import * as React from 'react';
import DropdownMenu from '@atlaskit/dropdown-menu';
import CameraFilledIcon from '@atlaskit/icon/glyph/camera-filled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import {
  ProfileAvatarDropdownMenuProps as Props,
  ProfileAvatarDropdownMenuIconSize as IconSize,
} from './types';
import * as Styled from './ProfileAvatarDropdownMenu.styled';
import Button from '@atlaskit/button';
import { useMemo, useState } from 'react';

const IconSizesThatNeedFullSizedTrigger = [
  IconSize.xlarge,
  IconSize.large,
  IconSize.medium,
];

const ProfileAvatarDropdownMenu = ({ children, iconSize }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openAvatarMenu = () => setIsMenuOpen(true);

  const closeAvatarMenu = () => setIsMenuOpen(false);

  const onAvatarMenuOpenChange = ({ isOpen }) => setIsMenuOpen(isOpen);

  const triggerButtonSpacing = useMemo((): 'default' | 'none' => {
    if (IconSizesThatNeedFullSizedTrigger.includes(iconSize)) {
      return 'default';
    }
    return 'none';
  }, [iconSize]);

  return (
    <Styled.DropdownWrapper>
      <DropdownMenu
        placement="bottom"
        isOpen={isMenuOpen}
        onOpenChange={onAvatarMenuOpenChange}
        trigger={({ triggerRef, ...props }) => (
          <Button
            {...props}
            ref={triggerRef}
            testId="profile-avatar-dropdown-trigger"
            appearance="subtle"
            spacing={triggerButtonSpacing}
            iconBefore={
              <CameraFilledIcon
                size={iconSize}
                label=""
                primaryColor={token('color.icon.subtle', colors.N20)}
              />
            }
            onClick={openAvatarMenu}
          />
        )}
      >
        {children}
      </DropdownMenu>

      {// This blanket maintains hover state of .hoverInfo when the mouse
      // leaves the menu and avatar
      isMenuOpen && (
        <Styled.MenuBlanket
          data-testid="profile-dropdown-blanket"
          onWheel={closeAvatarMenu}
        />
      )}
    </Styled.DropdownWrapper>
  );
};

export default ProfileAvatarDropdownMenu;
