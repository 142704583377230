import { useEffect } from 'react';
import { ApdexStopEvent } from './types';
import {
  withAnalyticsContext,
  InjectedAnalyticsProps,
} from '../AnalyticsContext';

type Props = ApdexStopEvent & InjectedAnalyticsProps;

/**
 * This component stops a specified Apdex event on mount.
 * Place this component so that it only renders when the apdex event is considered complete.
 */
export const ApdexStop = ({ analytics, task, taskId, threshold }: Props) => {
  useEffect(() => {
    setTimeout(() => {
      analytics.stopApdexEvent!({
        task,
        taskId,
        threshold,
      });
    }, 0);
  }, [analytics.stopApdexEvent, task, taskId, threshold]);

  return null;
};

export default withAnalyticsContext(ApdexStop);
