import { logException } from './sentry';

const PREFIX = 'ptc-dir-ui.';

// A facade for browser localstorage.
export default {
  setItem(key: string, value: string): boolean {
    try {
      localStorage.setItem(PREFIX + key, value);
      return true;
    } catch (error) {
      // maybe we can use cookie as alternative storage
      void logException(error, 'Cannot write into localstorage');
    }

    return false;
  },

  // tslint:disable-next-line:no-any
  getItem(key: string): string | null {
    try {
      return localStorage.getItem(PREFIX + key);
    } catch (error) {
      void logException(error, 'Cannot read into localstorage');
    }

    return null;
  },
};
