import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { FetchError } from './errors/fetch-error';
import { retryOnException } from './retry-operation';
import packageContext from '../utils/package-context';
var extraJsonFromResponse = function extraJsonFromResponse(response) {
  if (response.ok) {
    return response.json();
  }
  throw new FetchError("Unable to fetch ".concat(response.url, " ").concat(response.status, " ").concat(response.statusText), response.status);
};
export var fetchJson = function fetchJson(url, init) {
  return fetch(url, _objectSpread(_objectSpread({
    credentials: 'include'
  }, init), {}, {
    headers: _objectSpread({
      'Atl-App-Switcher-Version': packageContext.packageVersion
    }, init === null || init === void 0 ? void 0 : init.headers)
  })).then(extraJsonFromResponse);
};
export var fetchJsonWithNetworkRetries = function fetchJsonWithNetworkRetries(url, retryConfig, init) {
  return retryOnException(function () {
    return fetch(url, _objectSpread(_objectSpread({
      credentials: 'include'
    }, init), {}, {
      headers: _objectSpread({
        'Atl-App-Switcher-Version': packageContext.packageVersion
      }, init === null || init === void 0 ? void 0 : init.headers)
    }));
  }, retryConfig).then(extraJsonFromResponse);
};
export var postJson = function postJson(url, data) {
  return fetchJson(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};