/**
 * A singleton module that will store a referrer data in a closure
 * and retrieve that data in an analytics client untill the app has
 * a route transition happened.
 *
 * How it works:
 * 1. whenever we init our app with `src` or `ref` in the URL we gonna preserve them here
 * 2. in every screen event we gonna retrieve `src` and `ref` and add them to event attributes
 * 3. as soon as app transitions to any other route we gonna cleanup the stored referrer data
 *    to stop tracking it further.
 */

interface ReferrerInfoType {
  ref: string; // ref contains a product like confluence, jira etc.
  src: string | null; // src contains a location like appswitcher, peopledrawer etc.
}

// the storage for our referrer info;
let data: ReferrerInfoType | null = null;

const referrerInfo = {
  // as we preserving the data, we should at least get it once to record in analytics before allowing cleanup
  isUsed: false,

  markUsed() {
    referrerInfo.isUsed = true;
  },

  get() {
    return data;
  },

  set({ src, ref }: ReferrerInfoType) {
    data = { src, ref };
  },
  cleanup(force?: boolean) {
    if (!referrerInfo.isUsed && !force) {
      return;
    }
    data = null;
  },
};

export default referrerInfo;
