import React, { useCallback } from 'react';
import {
  useIntl,
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from 'react-intl-next';
import PageHeader from '@atlaskit/page-header';
import { Label } from '@atlaskit/form';

import { getManageProfileAppRootPath } from '../../../../utils/routes';
import { ApdexStop, ApdexTasks, Flag } from '../../../../common';
import * as Styled from './AccountPreferencesSettings.styled';
import LanguageDropdown from './LanguageDropdown';
import { reload } from '../../../../facades/window';
import TimezoneDropdown from './TimezoneDropdown';
import { FieldType } from '../../../../common/model/user';
import CloseAccountSettingsSection from '../CloseAccountSettingsTab/CloseAccountSettingsSection';

export type AccountPreferencesSettingsProps = {
  addFlag: (flag: Flag) => void;
  isManaged: boolean;
};

const MANAGE_PROFILE_SETTINGS_LANGUAGE = 'MANAGE_PROFILE_SETTINGS_LANGUAGE';

export const AccountPreferencesSettings = ({
  addFlag,
  isManaged,
}: AccountPreferencesSettingsProps) => {
  /**
   * Calculates whether to send the Apdex event to say the page is ready for user interaction.
   */
  const isPageReady = () => {
    // FIXME: PTC-1407 - we must ensure ApdexStop only renders once all relevant data is loaded
    return true;
  };

  const { formatMessage } = useIntl();

  const handleChangeSuccess = useCallback(
    (filedType: FieldType, shouldAutoReloadPage?: boolean) => {
      return () => {
        addFlag({
          type: 'success',
          title: formatMessage(messages[`${filedType}_changeSuccessFlagTitle`]),
          description: formatMessage({
            id:
              'ptc-directory.manage-account.account-preferences.change-propagation-takes-time',
            defaultMessage: 'Your change might take a while to show everywhere',
            description:
              'The message description shown after a user info is edited, mostly about changes not being visible everywhere immediately',
          }),
          id: MANAGE_PROFILE_SETTINGS_LANGUAGE,
        });

        if (shouldAutoReloadPage) {
          // give some time for the flag to show
          // PTC-2051: there is a bug which locale value in dropdown does not update
          setTimeout(reload, 500);
        }
      };
    },
    [addFlag, formatMessage],
  );

  const handleChangeError = useCallback(
    (filedType: FieldType) => {
      return () => {
        addFlag({
          type: 'error',
          title: formatMessage(messages[`${filedType}_changeErrorFlagTitle`]),
          description: formatMessage({
            id:
              'ptc-directory.manage-account.account-preferences.language.change.error.description',
            defaultMessage: 'Try again later.',
            description:
              'Body of error flag that appears when we fail to update the user’s preferred language',
          }),
          id: MANAGE_PROFILE_SETTINGS_LANGUAGE,
        });
      };
    },
    [addFlag, formatMessage],
  );

  return (
    <>
      <Styled.Header>
        <PageHeader>
          {formatMessage({
            id:
              'ptc-directory.manage-account.account-preferences.page-header-title',
            defaultMessage: 'Account preferences',
            description:
              'Title for the settings page for controlling preferences to do with how a user interacts with Atlassian products',
          })}
        </PageHeader>
        <FormattedMessage
          id="ptc-directory.manage-account.account-preferences.page-summary"
          defaultMessage="Control settings related to your account."
          description="A short description for this settings page"
        />
      </Styled.Header>

      <Styled.SettingsSection>
        <Styled.SettingsDescription>
          <FormattedMessage
            id="ptc-directory.manage-account.account-preferences.language.timezone"
            defaultMessage="Language & Region"
            description="A heading for the language & region section"
            tagName="h3"
          />
          <FormattedMessage
            id="ptc-directory.manage-account.account-preferences.language.description.unified.xml"
            defaultMessage="Changes to your language and timezone will be reflected across Jira, Confluence, Trello, Bitbucket and directory. Update your language and timezone for other products from your <product-settings-link>product settings.</product-settings-link>"
            description="Describes the scope of what the language setting. This message is used when unified language and timezone of Confluence feature and Jira turns on"
            tagName="p"
            values={{
              // eslint-disable-next-line react/display-name
              'product-settings-link': ((chunks => (
                <a href={`${getManageProfileAppRootPath()}/products`}>
                  {chunks}
                </a>
              )) as unknown) as React.ReactNode,
            }}
          />

          <Styled.LabelWrapper>
            <Label htmlFor="language-dropdown">
              {formatMessage({
                id: 'ptc-directory.manage-account.account-preferences.language',
                defaultMessage: 'Language',
                description: 'A heading for the language section',
              })}
            </Label>
          </Styled.LabelWrapper>
          <Styled.LanguageDropdownWrapper data-testid="language-settings">
            <LanguageDropdown
              inputId="language-dropdown"
              onMutationSuccess={handleChangeSuccess(FieldType.Language, true)}
              onMutationError={handleChangeError(FieldType.Language)}
            />
          </Styled.LanguageDropdownWrapper>
        </Styled.SettingsDescription>

        <Styled.SettingsDescription>
          <Styled.LabelWrapper>
            <Label htmlFor="timezone-dropdown">
              {formatMessage({
                id: 'ptc-directory.manage-account.account-preferences.timezone',
                defaultMessage: 'Time zone',
                description:
                  'Label for the input field for the users time zone',
              })}
            </Label>
          </Styled.LabelWrapper>
          <Styled.TimezoneDropdownWrapper data-testid="timezone-settings">
            <TimezoneDropdown
              inputId="timezone-dropdown"
              onMutationSuccess={handleChangeSuccess(FieldType.Timezone)}
              onMutationError={handleChangeError(FieldType.Timezone)}
            />
          </Styled.TimezoneDropdownWrapper>
        </Styled.SettingsDescription>
      </Styled.SettingsSection>
      {isPageReady() && (
        <ApdexStop task={ApdexTasks.VIEW_ACCOUNT_PREFERENCES_SETTINGS} />
      )}

      <CloseAccountSettingsSection isManaged={isManaged} />
    </>
  );
};

const messages: Record<string, MessageDescriptor> = defineMessages({
  language_changeSuccessFlagTitle: {
    id:
      'ptc-directory.manage-account.account-preferences.language.change.success.title',
    defaultMessage: 'You’ve updated your language. ',
    description:
      'Success flag that appears when the user has successfully updated their preferred language',
  },
  language_changeErrorFlagTitle: {
    id:
      'ptc-directory.manage-account.account-preferences.language.change.error.title',
    defaultMessage: 'We couldn’t update your language. ',
    description:
      'Title of error flag that appears when we fail to update the user’s preferred language',
  },
  timezone_changeSuccessFlagTitle: {
    id:
      'ptc-directory.manage-account.account-preferences.timezone.change.success.title',
    defaultMessage: 'We’re updating your time zone',
    description:
      'The success message title for when editing the timezone succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  timezone_changeErrorFlagTitle: {
    id:
      'ptc-directory.manage-account.account-preferences.timezone.change.error.title',
    defaultMessage: 'We couldn’t update your time zone',
    description:
      'The error message title for when editing the time zone fails. Appears in a notification flag upon failure to save new value.',
  },
});

export default AccountPreferencesSettings;
