import React from 'react';

import { Analytics, AnalyticsContextType } from './types';
import { logInfoMessage } from '../../utils/sentry';
import AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import {
  DEFAULT_PRODUCT_NAME,
  DEFAULT_SUB_PRODUCT_NAME,
} from '../../clients/analytics-client';

const defaultAnalyticsMethod = () => {
  // tslint:disable-next-line no-console
  console.warn('No analytics client has been provided.');

  void logInfoMessage(
    'Analytics Context accessed before AnalyticsClient was loaded',
  );
};

export const defaultAnalyticsClient: Analytics = {
  getAnalyticsWebClient: () =>
    new AnalyticsWebClient({
      env: 'local',
      product: DEFAULT_PRODUCT_NAME,
      subproduct: DEFAULT_SUB_PRODUCT_NAME,
    }),
  pushTrackEvent: defaultAnalyticsMethod,
  pushScreenEvent: defaultAnalyticsMethod,
  pushUIEvent: defaultAnalyticsMethod,
  pushOperationalEvent: defaultAnalyticsMethod,
  startUIViewedEvent: defaultAnalyticsMethod,
  startApdexEvent: defaultAnalyticsMethod,
  stopApdexEvent: defaultAnalyticsMethod,
};

const AnalyticsContextState: AnalyticsContextType = {
  analytics: defaultAnalyticsClient,
};

const AnalyticsContext = React.createContext(AnalyticsContextState);

export default AnalyticsContext;
