import React from 'react';
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import { FeedbackFlag } from '@atlaskit/feedback-collector';
import { Anchor, Flag, FEEDBACK_COLLECTOR_SUCCESS_FLAG_ID } from '../../common';
import { getTypeIcon } from '../FlagContext/flag-utils';
import { GENERIC_ERROR_FLAG_ID } from '../../constants';
import GenericErrorFlag from './GenericErrorFlag';
import { FlagType } from '../FlagContext/types';

type ProcessedFlag = Flag & {
  icon?: JSX.Element;
};

interface Props {
  flags: ProcessedFlag[];
  removeFlag: (flagId: string) => void;
}

const Flags = ({ flags, removeFlag }: Props) => {
  const flagsInGroup = flags.map(flagProps => {
    const {
      id,
      type,
      icon,
      appearance,
      title,
      description,
      links = [],
      actions,
    } = flagProps;

    if (typeof id === 'string') {
      if (id.startsWith(FEEDBACK_COLLECTOR_SUCCESS_FLAG_ID)) {
        return <FeedbackFlag key={id} />;
      } else if (id.startsWith(GENERIC_ERROR_FLAG_ID)) {
        return <GenericErrorFlag key={id} id={id} />;
      }
    }

    // we cannot get props type of AutoDismissFlag so we have to use any here
    // tslint:disable-next-line:no-any
    const flagComponentProps: any = {
      id,
      key: id,
      icon: icon || getTypeIcon(type || (appearance as FlagType)),
      title,
      description,
      appearance,
      actions:
        actions ||
        links.map(({ text, ...props }) => ({
          content: <Anchor {...props}>{text}</Anchor>,
        })),
    };

    return <AutoDismissFlag {...flagComponentProps} />;
  });

  return <FlagGroup onDismissed={removeFlag}>{flagsInGroup}</FlagGroup>;
};

export default Flags;
