/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Russian
export default {
  'focused-task-close-account.cancel': 'Отменить',
  'focused-task-close-account.deactivate-account': 'Деактивировать аккаунт',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Вы собираетесь отключить аккаунт пользователя:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Вы собираетесь отключить аккаунт пользователя:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Отключение аккаунта',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Отключение аккаунта',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Аккаунт можно восстановить в любое время.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Аккаунт можно восстановить в любое время.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Когда вы отключите аккаунт:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Когда вы отключите аккаунт:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Мы больше не будем взимать с вас плату за них.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Мы больше не будем взимать с вас плату за них.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>немедленно потеряет доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас этот пользователь работает со следующими сервисами:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>немедленно потеряет доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас у этого пользователя есть доступ только к Сообществу Atlassian и Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Другие сервисы, связанные с аккаунтом Atlassian, например Сообщество Atlassian и Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Вы <b>немедленно потеряете доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас вы работаете со следующими сервисами:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Вы <b>немедленно потеряете доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас у вас есть доступ только к Сообществу Atlassian и Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Персональные данные, например полное имя и адрес электронной почты, а также созданный пользователем контент, сохранятся в сервисах, связанных с аккаунтом Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Персональные данные, например полное имя и адрес электронной почты, а также созданный пользователем контент, сохранятся в сервисах, связанных с аккаунтом Atlassian.',
  'focused-task-close-account.delete-account': 'Удалить аккаунт',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Примечание. Ответив на эти вопросы, вы поможете нам сделать сервис еще удобнее для всех пользователей. У людей, чьи аккаунты вы удалите, по-прежнему будет статус «Бывший пользователь».',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Примечание. Пройдя этот опрос, вы поможете нам улучшить сервис для всех пользователей, в том числе тех, кто удаляет свои аккаунты. После удаления аккаунта у вас сохранится статус «Бывший пользователь».',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Бывший пользователь',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Как вы считаете, какой статус в системе должен быть у удаленных пользователей?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Как вы считаете, какой статус в системе должен быть у вашего удаленного аккаунта?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Если бы это зависело от вас, какой статус в системе был бы у пользователей с удаленными аккаунтами?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Если бы вы могли выбрать свой статус в системе, каким бы он был?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Человек, чей аккаунт вы удалите, получит в системе статус «Бывший пользователь». Вы очень поможете нам, если ответите на короткий вопрос. Это займет всего пару минут.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'После того как мы удалим ваш аккаунт, вы получите в системе статус «Бывший пользователь». Вы очень поможете нам, если ответите на короткий вопрос. Это займет всего пару минут.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Свернуть',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'Еще {num}',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Вы собираетесь удалить акканут:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Вы собираетесь удалить акканут:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Удаление аккаунта',
  'focused-task-close-account.delete-account.overview.heading.self': 'Удаление аккаунта',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Например, его страницы, задачи и комментарии в продуктах.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Например, ваши страницы, задачи и комментарии в продуктах.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Возможно, вы или другие пользователи устанавливали приложения, расширяющие функции продуктов Atlassian. В таких приложениях также может храниться информация из профилей пользователей.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Возможно, вы или другие пользователи устанавливали приложения, расширяющие функции продуктов Atlassian. В таких приложениях также может храниться информация из вашего профиля.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Информация о покупках, которая необходима нам для финансовой отчетности.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Информация о покупках, которая необходима нам для финансовой отчетности.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Мы удалили аккаунт пользователя, однако, возможно, нам понадобится предоставить его данные государственным органам по соответствующему запросу.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Мы удалили аккаунт пользователя, однако, возможно, нам понадобится предоставить его данные государственным органам по соответствующему запросу.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Данные, которые востребованы в рамках действующего судебного процесса, мы не имеем права удалять.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Данные, которые востребованы в рамках действующего судебного процесса, мы не имеем права удалять.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'В силу юридических причин мы храним личные данные пользователей в течение ограниченного времени. Вот несколько примеров:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'В силу юридических причин мы храним личные данные пользователей в течение ограниченного времени. Вот несколько примеров:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Мы не удаляем личные данные из контента, созданного пользователями (например, имена и адреса электронной почты, указанные на странице или в задаче). Эти данные удаляют администраторы вручную.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Мы не удаляем личные данные из контента, созданного вами или другими пользователями (например, имена и адреса электронной почты, указанные на странице или в задаче). Эти данные удаляют администраторы вручную.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Пользователи имеют право подать жалобу в контролирующие органы по месту жительства.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Вы имеете право подать жалобу в контролирующие органы по месту вашего жительства.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'После удаления аккаунта:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'После удаления аккаунта:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Контент, созданный пользователем, сохранится в сервисах, связанных с аккаунтом Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Созданный вами контент сохранится в сервисах, связанных с аккаунтом Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Отменить удаление аккаунта можно только в течение первых 14 дней.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Мы вышлем вам по электронной почте список приложений, которые могли сохранять личные данные пользователей.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Мы вышлем вам по электронной почте список приложений, которые могли сохранять ваши личные данные.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>немедленно потеряет доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас этот пользователь работает со следующими сервисами:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>немедленно потеряет доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас у этого пользователя есть доступ только к Сообществу Atlassian и Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Другие сервисы, связанные с аккаунтом Atlassian, например Сообщество Atlassian и Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Вы <b>немедленно потеряете доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас вы работаете со следующими сервисами:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Вы <b>немедленно потеряете доступ</b> ко всем сервисам, связанным с аккаунтом Atlassian. Сейчас у вас есть доступ только к Сообществу Atlassian и Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Мы удалим <b>личные данные пользователя</b> (например, его полное имя и адрес электронной почты) из сервисов, связанных с аккаунтом Atlassian, в течение 30 дней. Исключение составляют случаи, когда это невозможно сделать по юридическим причинам.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Мы удалим <b>ваши личные данные</b> (например, полное имя и адрес электронной почты) из сервисов, связанных с аккаунтом Atlassian, в течение 30 дней. Исключение составляют случаи, когда это невозможно сделать по юридическим причинам.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Отменить удаление аккаунта можно только в течение первых 14 дней. Если вы считаете, что аккаунт понадобится вам в дальнейшем, просто деактивируйте его.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Отменить удаление аккаунта можно только в течение первых 14 дней.',
  'focused-task-close-account.learnMore': 'Подробнее',
  'focused-task-close-account.next': 'Далее',
  'focused-task-close-account.previous': 'Назад'
};