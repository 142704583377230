import React from 'react';
import { DialogContextType } from './types';

const dialogsContextState: DialogContextType = {
  openDialog: () => null,
  closeDialog: () => null,
};

export const DialogsContext = React.createContext<DialogContextType>(
  dialogsContextState,
);
