import { ChangeEmailErrorKeys } from '../../apps/id-a-c/components/Email/ChangeEmailError.messages';
import identityClient from '../clients/identity-client';
import { logInfoMessage } from '../utils/sentry';

export interface ProfileEmailState {
  isSaving: boolean;
}

export const parseErrorMessage = (
  errorFromEndpoint,
): ChangeEmailErrorKeys | string => {
  try {
    const err = errorFromEndpoint
      ? errorFromEndpoint.message || errorFromEndpoint
      : undefined;

    const { formErrors, fieldErrors, error } = JSON.parse(err);

    if (formErrors?.[0]) {
      return formErrors[0];
    } else if (typeof error === 'string') {
      return error;
    }

    if (fieldErrors?.newEmail?.[0]) {
      return fieldErrors.newEmail[0];
    }
  } catch (caughtError) {
    void logInfoMessage('Failed to parse error message for updateUserEmail', {
      error: errorFromEndpoint,
      caughtError,
    });
  }

  return ChangeEmailErrorKeys.DEFAULT;
};

// ------------------------------------
// Constants
// ------------------------------------
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const REVERT_USER_EMAIL = 'REVERT_USER_EMAIL';

// ------------------------------------
// Actions
// ------------------------------------

// tslint:disable-next-line:no-any
export const updateUserEmail = email => async (dispatch): Promise<any> => {
  const updatePromise = identityClient.changeEmail(email);
  dispatch({
    type: UPDATE_USER_EMAIL,
    payload: updatePromise,
  }).catch(() => null);

  return updatePromise.catch(error => {
    throw parseErrorMessage(error);
  });
};

// tslint:disable-next-line:no-any
export const revertUserEmail = () => async (dispatch): Promise<any> => {
  const revertPromise = identityClient.revertEmail();
  dispatch({
    type: REVERT_USER_EMAIL,
    payload: revertPromise,
  }).catch(() => null);

  return revertPromise.catch(error => {
    throw parseErrorMessage(error);
  });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: ProfileEmailState = {
  isSaving: false,
};

const profileEmailReducer = (
  state: ProfileEmailState = initialState,
  action,
) => {
  switch (action.type) {
    case `${UPDATE_USER_EMAIL}_PENDING`:
      return {
        ...state,
        isSaving: true,
      };
    case `${UPDATE_USER_EMAIL}_REJECTED`:
      return {
        ...state,
        isSaving: false,
      };
    case `${UPDATE_USER_EMAIL}_FULFILLED`:
      return {
        ...state,
        isSaving: false,
      };
    case `${REVERT_USER_EMAIL}_PENDING`:
      return {
        ...state,
        isSaving: true,
      };
    case `${REVERT_USER_EMAIL}_REJECTED`:
      return {
        ...state,
        isSaving: false,
      };
    case `${REVERT_USER_EMAIL}_FULFILLED`:
      return {
        ...state,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default profileEmailReducer;
