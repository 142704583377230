export interface PrivacyOption {
  icon: JSX.Element;
  label: React.ReactNode;
  description: React.ReactNode;
  value: PrivacyLevel;
}

export enum PrivacyLevel {
  PUBLIC = 'public',
  ORGANISATION = 'organisation',
  PRIVATE = 'private',
}

export interface PrivacyOptions {
  [PrivacyLevel.PUBLIC]: PrivacyOption;
  [PrivacyLevel.ORGANISATION]?: PrivacyOption;
  [PrivacyLevel.PRIVATE]?: PrivacyOption;
}

export interface PrivacyLevelDropdownProps {
  options: PrivacyOptions;
  privacyLevel: PrivacyLevel;
  disabledMessage?: React.ReactNode;
  onChange: (value: PrivacyLevel) => void;
}
