import React from 'react';
import SecuritySettings from '../../components/Security/SecuritySettings/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const SettingsSecurityRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_SECURITY_SETTINGS} />
    <RecordTransition />
    <SecuritySettings />
  </>
);

export default SettingsSecurityRoute;
