import styled from '@emotion/styled';
import { typography, colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const AboutTitle = styled.p`
  ${typography.h500 as any};
  margin: 0 ${token('space.200', '16px')} ${token('space.250', '20px')};
`;

export const FormCard = styled.div`
  padding: ${token('space.250', '20px')} ${token('space.200', '16px')};
  border-radius: ${token('border.radius.100', '3px')};

  box-shadow: ${token(
    'elevation.shadow.raised',
    '0px 1px 1px #091E4240, 0px 0px 1px #091E424F',
  )};
  background: ${token('elevation.surface.raised', colors.N0)};
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PrivacyLabel = styled.div`
  font-size: 11px;
  padding-left: ${token('space.100', '8px')};
  margin-bottom: -${token('space.250', '20px')};
  color: ${token('color.text.subtlest', colors.N200)};
  line-height: ${token('space.200', '16px')};
  width: 200px;
  float: right;
`;

export const Row = styled.div`
  display: flex;
  & + & {
    margin-top: ${token('space.300', '24px')};
  }
`;

export const Field = styled.div`
  flex-grow: 1;
  margin-left: ${token('space.100', '8px')};
`;

export const VisibilityDropdown = styled.div`
  width: 200px;
  margin-left: ${token('space.400', '32px')};
  align-self: flex-end;
`;
