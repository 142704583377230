import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { AutoDismissFlag } from '@atlaskit/flag';
import { Anchor } from '../../common';

import { flagIconByType } from '../FlagContext/flag-utils';

const GenericErrorFlag = props => (
  <AutoDismissFlag
    {...props}
    icon={flagIconByType.error}
    title={
      <FormattedMessage
        id="ptc-directory.profile.flags.generic-error-flag.title"
        defaultMessage="Something went wrong"
        description="Title of flag shown when there was a generic error"
      />
    }
    description={
      <FormattedMessage
        id="ptc-directory.profile.flags.generic-error-flag.description"
        defaultMessage="Refresh the page and try again. If this keeps happening, let us know using the link below."
        description="Body of flag shown when there was a generic error"
      />
    }
    actions={[
      {
        content: (
          <Anchor href="https://getsupport.atlassian.com/" target="_blank">
            <FormattedMessage
              id="ptc-directory.profile.flags.generic-error-flag.link"
              defaultMessage="Visit Atlassian support"
              description="Link text in flag shown when there was a generic error"
            />
          </Anchor>
        ),
      },
    ]}
  />
);

export default GenericErrorFlag;
