import React from 'react';
import ApiTokens from '../../components/Security/ApiTokens/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const ApiTokensRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_API_TOKENS} />
    <RecordTransition />
    <ApiTokens />
  </>
);

export default ApiTokensRoute;
