import { Flag } from '../../../../../common';
import { FieldInputType } from '../../../../../common/components/ProfileDetailsInlineEdit/types';
import { FieldType } from '../../../../../common/model/user';
import {
  UserProfilePrivacy,
  UserProfilePrivacyOptions,
} from '../../../model/userProfilePrivacy';
import { UpdateUserInfo } from '../../AccountPreferencesSettings/hooks/useAccountPreferencesMutation';
import { Fields } from '../../UserProfileAndVisibilitySettings/DataTransform';

export { FieldType } from '../../../../../common/model/user';

export interface ProfileDetailsFormProps {
  userId: string;
  isManaged: boolean;
  managedBy: string | null;
  values: Fields;
  privacyValues: UserProfilePrivacy;
  privacyOptions: UserProfilePrivacyOptions;
  usersOrg: string | null;
  fieldTypes: FieldType[];
  onSubmit: (userInfo: UpdateUserInfo) => Promise<void>;
  onPrivacyLevelChange: (field: FieldType, value: string) => Promise<void>;
  addFlag: (flag: Flag) => void;
}

export interface FieldConfig {
  label: string;
  placeholder?: string;
  inputType?: FieldInputType;
  info?: JSX.Element | string;
  disabled?: JSX.Element;
  managed?: JSX.Element;
  privacy: {
    key: string;
  };
  isRequired?: boolean;
  requiredInvalidMessage?: string;
}

export type FieldsConfigMap = Partial<{ [key in FieldType]: FieldConfig }>;
