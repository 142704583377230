import { defineMessages } from 'react-intl-next';
var messages = defineMessages({
  emptyStateExploreProductsTitle: {
    id: 'fabric.atlassianSwitcher.emptyState.exploreProducts.title',
    defaultMessage: 'Get started with cloud',
    description: 'The title text for the empty state explore products content, which prompts users to try new products.'
  },
  emptyStateExploreProductsBody: {
    id: 'fabric.atlassianSwitcher.emptyState.exploreProducts.body',
    defaultMessage: 'Try a product to start collaborating with your team.',
    description: 'The body text for the empty state explore products content, which prompts users to try new products.'
  },
  emptyStateExploreProductsLink: {
    id: 'fabric.atlassianSwitcher.emptyState.exploreProducts.link',
    defaultMessage: 'Explore products',
    description: 'The text for the empty state explore products link, which prompts users to try new products.'
  },
  atlassianHome: {
    id: 'fabric.atlassianSwitcher.home',
    defaultMessage: 'Atlassian Home',
    description: 'The text of a link redirecting the user to the Home site'
  },
  atlassianStart: {
    id: 'fabric.atlassianSwitcher.start',
    defaultMessage: 'Atlassian Start',
    description: 'The text of a link redirecting the user to the Start site'
  },
  recent: {
    id: 'fabric.atlassianSwitcher.recent',
    defaultMessage: 'Recent',
    description: "In a context in which users are able to view recent projects or spaces they've viewed, this text is the title of the section displaying all the recent projects or spaces."
  },
  more: {
    id: 'fabric.atlassianSwitcher.more',
    defaultMessage: 'More',
    description: 'In a context in which users are able to view predefined custom links, this text is the title of the section displaying all existing custom links.'
  },
  try: {
    id: 'fabric.atlassianSwitcher.try',
    defaultMessage: 'Try',
    description: 'This text appears as a way to encourage the user to try a new Atlassian product.'
  },
  manageList: {
    id: 'fabric.atlassianSwitcher.manageList',
    defaultMessage: 'Manage list',
    description: 'This text is for the action for a user to manage the values present in an editable list of links.'
  },
  jiraProject: {
    id: 'fabric.atlassianSwitcher.jiraProject',
    defaultMessage: 'Jira project',
    description: 'In a context in which several items are listed , this text describes that the specific type of a given item is a Jira project'
  },
  confluenceSpace: {
    id: 'fabric.atlassianSwitcher.confluenceSpace',
    defaultMessage: 'Confluence space',
    description: 'In a context in which several items are listed , this text describes that the specific type of a given item is a Confluence space'
  },
  administration: {
    id: 'fabric.atlassianSwitcher.administration',
    defaultMessage: 'Administration',
    description: 'The text of a link redirecting the user to the site administration'
  },
  moreAtlassianProductsLink: {
    id: 'fabric.atlassianSwitcher.moreAtlassianProducts',
    defaultMessage: 'More Atlassian products',
    description: 'The text of a link redirecting the user to Discover More Atlassian products'
  },
  slackIntegrationLink: {
    id: 'fabric.atlassianSwitcher.slackIntegrationLink',
    defaultMessage: 'Slack',
    description: 'The text of a link which opens the Slack Authentication popup'
  },
  slackIntegrationLinkDescription: {
    id: 'fabric.atlassianSwitcher.slackIntegrationLinkDescription',
    defaultMessage: 'Integrate Slack with your Atlassian products',
    description: 'The description of a link which opens the Slack Authentication popup'
  },
  browseApps: {
    id: 'fabric.atlassianSwitcher.browseApps',
    defaultMessage: 'Browse Marketplace apps',
    description: 'The text of a link redirecting the user to Discover Embedded Marketplace within in the product (Marketplace is a brand name. Please dont translate it)'
  },
  errorEmptyPartialResults: {
    id: 'fabric.atlassianSwitcher.errorEmptyPartialResults',
    defaultMessage: "We can't display your products right now.",
    description: 'Heading of the error message when available products returns a partial result and no sites'
  },
  errorHeading: {
    id: 'fabric.atlassianSwitcher.errorHeading',
    defaultMessage: 'Something’s gone wrong',
    description: 'Heading of the error screen which is shown when an unknown error happens in the Atlassian Switcher. Usually due to failed network requests.'
  },
  errorText: {
    id: 'fabric.atlassianSwitcher.errorText',
    defaultMessage: 'We keep track of these errors, but feel free to contact us if refreshing doesn’t fix things',
    description: 'Text that is displayed when an unknown error happens in the Atlassian Switcher.'
  },
  errorImageAltText: {
    id: 'fabric.atlassianSwitcher.errorImageAltText',
    defaultMessage: 'A broken robot and a number of people busy fixing it.',
    description: 'Text displayed as alt text when an error occurs in the Atlassian Switcher'
  },
  errorTextNetwork: {
    id: 'fabric.atlassianSwitcher.errorTextNetwork',
    defaultMessage: 'We couldn’t load this list. Please reload the page and try again.',
    description: 'Text that is displayed when we detect a network issue.'
  },
  errorHeadingLoggedOut: {
    id: 'fabric.atlassianSwitcher.errorHeadingLoggedOut',
    defaultMessage: 'Your Atlassian account is logged out',
    description: 'Heading of the error screen which is shown when an unknown error happens in the Atlassian Switcher. Usually due to failed network requests.'
  },
  errorTextLoggedOut: {
    id: 'fabric.atlassianSwitcher.errorTextLoggedOut',
    defaultMessage: "Log in again to use the Atlassian\xA0switcher.",
    description: 'Text that is displayed when we detect user is logged out.'
  },
  errorHeadingUnverified: {
    id: 'fabric.atlassianSwitcher.errorHeadingUnverified',
    defaultMessage: 'Your account is unverified',
    description: 'Heading that is displayed when we detect user account is unverified.'
  },
  errorPartialResults: {
    id: 'fabric.atlassianSwitcher.errorPartialResults',
    defaultMessage: "We can't display some of your products right now.",
    description: 'Heading of the error message when available products returns a partial result and 1 or more sites'
  },
  errorPartialResultsRefresh: {
    id: 'fabric.atlassianSwitcher.errorPartialResultsRefresh',
    defaultMessage: 'Refresh the page, or try again later.',
    description: 'Subtitle of the error message when available products returns a partial result'
  },
  errorTextUnverified: {
    id: 'fabric.atlassianSwitcher.errorTextUnverified',
    defaultMessage: 'Please confirm your email address to view a list of available products.',
    description: 'Text that is displayed when we detect user account is unverified.'
  },
  login: {
    id: 'fabric.atlassianSwitcher.login',
    defaultMessage: 'Log in',
    description: 'Text in log in button.'
  },
  discover: {
    id: 'fabric.atlassianSwitcher.discover',
    defaultMessage: 'Discover',
    description: 'The header of "Discover" section'
  },
  productDescriptionConfluence: {
    id: 'fabric.atlassianSwitcher.product.description.confluence',
    defaultMessage: 'Document collaboration',
    description: 'Text displayed under the Confluence product recommendation.'
  },
  productDescriptionJiraServiceManagement: {
    id: 'fabric.atlassianSwitcher.product.description.jsm',
    defaultMessage: 'Collaborative IT service management',
    description: 'Text displayed under the Jira Service Management product recommendation.'
  },
  productDescriptionJiraSoftware: {
    id: 'fabric.atlassianSwitcher.product.description.jsw',
    defaultMessage: 'Project and issue tracking',
    description: 'Text displayed under the Jira Software product recommendation.'
  },
  productDescriptionOpsgenie: {
    id: 'fabric.atlassianSwitcher.product.description.opsgenie',
    defaultMessage: 'Modern incident management',
    description: 'Text displayed under the Opsgenie product recommendation.'
  },
  productDescriptionCompass: {
    id: 'fabric.atlassianSwitcher.product.description.compass',
    defaultMessage: 'Component manager',
    description: 'Text displayed under the Compass product recommendation.'
  },
  join: {
    id: 'fabric.atlassianSwitcher.join',
    defaultMessage: 'Join',
    description: 'Section Header in Atlassian Switcher. To set the expectation of what items would be shown in following section. Shown when an user has at least one joinable site via Domain Enabled Sign up with common collaborators.'
  }
});
export default messages;