import React from 'react';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';
import LazyPrivacySettings from '../../components/Privacy/PrivacySettings/lazy';

const SettingsPrivacyRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_PRIVACY_SETTINGS} />
    <RecordTransition />
    <LazyPrivacySettings />
  </>
);

export default SettingsPrivacyRoute;
