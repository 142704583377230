import React from 'react';
import RecentDevices from '../../components/Security/RecentDevices/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const RecentDevicesRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_RECENT_DEVICES} />
    <RecordTransition />
    <RecentDevices />
  </>
);

export default RecentDevicesRoute;
