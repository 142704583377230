import { UserProfilePrivacy } from '../../../../model/userProfilePrivacy';

const schemaKeys = {
  name: 'name',
  nickname: 'nickname',
  zoneinfo: 'zoneinfo',
  picture: 'picture',
  email: 'email',
  phoneNumber: 'phoneNumber',
  jobTitle: 'jobTitle',
  organization: 'organization',
  department: 'department',
  location: 'location',
};

const apiKeys = {
  name: 'name',
  nickname: 'nickname',
  zoneinfo: 'zoneinfo',
  picture: 'picture',
  email: 'email',
  phoneNumber: 'extended_profile.phone_number',
  jobTitle: 'extended_profile.job_title',
  organization: 'extended_profile.organization',
  department: 'extended_profile.department',
  location: 'extended_profile.location',
};

export const PRIVACY_SETTINGS_SCHEMA_TO_API_MAP = {
  [schemaKeys.name]: apiKeys.name,
  [schemaKeys.nickname]: apiKeys.nickname,
  [schemaKeys.zoneinfo]: apiKeys.zoneinfo,
  [schemaKeys.picture]: apiKeys.picture,
  [schemaKeys.email]: apiKeys.email,
  [schemaKeys.phoneNumber]: apiKeys.phoneNumber,
  // extended profile
  [schemaKeys.jobTitle]: apiKeys.jobTitle,
  [schemaKeys.organization]: apiKeys.organization,
  [schemaKeys.department]: apiKeys.department,
  [schemaKeys.location]: apiKeys.location,
};

const toUpperCase = input => {
  if (typeof input === 'string') {
    return input.toUpperCase();
  }
  return null;
};

/**
 * Convert shape used to communicate with privacy APIs to shape that is used within our app.
 */
export const transformPrivacySettingsFormatFromApiToSchema = (
  data,
): UserProfilePrivacy =>
  Object.entries(PRIVACY_SETTINGS_SCHEMA_TO_API_MAP).reduce(
    (memo, [schemaKey, apiResponseKey]) => {
      const privacySetting = data[apiResponseKey];
      memo[schemaKey] = toUpperCase(privacySetting);
      return memo;
    },
    {},
  );

export const PRIVACY_SETTINGS_API_TO_SCHEMA_MAP = {
  [apiKeys.name]: schemaKeys.name,
  [apiKeys.nickname]: schemaKeys.nickname,
  [apiKeys.zoneinfo]: schemaKeys.zoneinfo,
  [apiKeys.picture]: schemaKeys.picture,
  [apiKeys.email]: schemaKeys.email,
  [apiKeys.phoneNumber]: schemaKeys.phoneNumber,
  // extended profile
  [apiKeys.jobTitle]: schemaKeys.jobTitle,
  [apiKeys.organization]: schemaKeys.organization,
  [apiKeys.department]: schemaKeys.department,
  [apiKeys.location]: schemaKeys.location,
};

/**
 * Convert shape that is used within our app to shape used to communicate with privacy APIs.
 */
export const transformPrivacySettingsFormatFromSchemaToApi = data =>
  Object.keys(PRIVACY_SETTINGS_API_TO_SCHEMA_MAP).reduce((memo, apiKey) => {
    const apiResponseKey = PRIVACY_SETTINGS_API_TO_SCHEMA_MAP[apiKey];
    const privacySetting = data[apiResponseKey];
    if (privacySetting) {
      memo[apiKey] = (privacySetting as string).toLowerCase();
    }
    return memo;
  }, {});
