import { combineReducers } from 'redux';

import userReducer from '../../../common/modules/user';
import flagsReducer from '../../../common/modules/flags';

import profileEmailReducer from '../../../common/modules/profile-email';
import profileSettingsPasswordReducer from '../../../common/modules/password-reducer';
import connectedAppsReducer from '../../../common/modules/connected-apps-reducer';
import lpUserPreferencesReducer from '../../../common/modules/lp-user-preferences-reducer';

export const makeRootReducer = () => {
  return combineReducers({
    user: userReducer,
    flags: flagsReducer,

    // Profile settings
    profileEmail: profileEmailReducer,
    profileSettingsPassword: profileSettingsPasswordReducer,
    connectedApps: connectedAppsReducer,
    lpUserPreferences: lpUserPreferencesReducer,
  });
};

export default makeRootReducer;
