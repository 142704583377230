import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var getPrimaryButtonTheme = function getPrimaryButtonTheme(_ref) {
  var primaryButton = _ref.mode.primaryButton;
  return function (current, props) {
    var _current = current(props),
      buttonStyles = _current.buttonStyles,
      spinnerStyles = _current.spinnerStyles;
    return {
      buttonStyles: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, buttonStyles), primaryButton.default), props.isSelected && primaryButton.selected), {}, {
        fontWeight: 500,
        padding: "0 ".concat("var(--ds-space-050, 4px)"),
        marginLeft: 0,
        marginRight: 0,
        ':hover': props.isSelected ? primaryButton.selectedHover : primaryButton.hover,
        ':focus': primaryButton.focus,
        // :active doesn't work in FF, because we do a
        // e.preventDefault() on mouse down in Button.
        // '&&' is required to add more CSS specificity
        // && it not a valid CSSObject property
        // @ts-ignore
        '&&': _objectSpread({}, props.state === 'active' && primaryButton.active)
      }),
      spinnerStyles: spinnerStyles
    };
  };
};