import React from 'react';

export interface DialogProps {
  onClose?: () => void;
  [key: string]: any;
}

export type DialogType = React.ComponentType<DialogProps>;

export interface DialogEntry {
  DialogComponent: DialogType | null;
  dialogProps: DialogProps | null;
  isOpen: boolean;
  onClose?: () => void;
}

export interface DialogContextType {
  openDialog: (el: DialogType, props?: DialogProps) => void;
  closeDialog: () => void;
}

export type InjectedDialogContextProps = DialogContextType;
