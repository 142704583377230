// @ts-ignore
import AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import { ApdexStartEvent, ApdexStopEvent } from '../Apdex';
import { AnalyticsEventSource } from './constants';

type CallBackFn = () => void;

export interface Analytics {
  pushTrackEvent: (event: CommonEvent, callback?: CallBackFn) => void;
  pushScreenEvent: (event: ScreenEvent, callback?: CallBackFn) => void;
  pushUIEvent: (event: CommonEvent, callback?: CallBackFn) => void;
  pushOperationalEvent: (event: CommonEvent, callback?: CallBackFn) => void;
  startUIViewedEvent: () => void;
  startApdexEvent?: (apdexEvent: ApdexStartEvent) => void;
  stopApdexEvent?: (apdexEvent: ApdexStopEvent) => void;
  getAnalyticsWebClient(): AnalyticsWebClient;
}

export interface EventAttributes {
  [k: string]: boolean | string | number | string[] | null;
}

/**
 * Common event can be used to send Screen events to Data Portal
 */
export interface ScreenEvent {
  name: string;
  attributes?: EventAttributes;
}

/**
 * Common event can be used to send Track, Operational, and UI events to Data Portal
 */
export interface CommonEvent {
  // Name of the screen the component was on
  source: AnalyticsEventSource;
  // Component type involved in the action
  actionSubject: string;
  // Past tense verb describing the action
  action: string;
  // Unique instance of the component
  actionSubjectId?: string;
  // Additional values that need to be sent as part of the event
  attributes?: EventAttributes;
  // Additional extra tags associated with the event
  tags?: string[];
}

export interface AnalyticsContextType {
  analytics: Analytics;
}

export type InjectedAnalyticsProps = AnalyticsContextType;
export type AnalyticsProviderProps = AnalyticsContextType;
