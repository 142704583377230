/**
 * NOTE:
 *
 * This file is automatically generated by scripts/generate-i18n-index.ts.
 * DO NOT UPDATE IT BY HAND. Instead, run yarn generate-i18n-index.
 */
var lazyMessages = {
  cs: function cs() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/cs" */'./cs');
  },
  cs_CZ: function cs_CZ() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/cs_CZ" */'./cs_CZ');
  },
  da: function da() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/da" */'./da');
  },
  da_DK: function da_DK() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/da_DK" */'./da_DK');
  },
  de: function de() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/de" */'./de');
  },
  de_DE: function de_DE() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/de_DE" */'./de_DE');
  },
  en_GB: function en_GB() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/en_GB" */'./en_GB');
  },
  en_ZZ: function en_ZZ() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/en_ZZ" */'./en_ZZ');
  },
  es: function es() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/es" */'./es');
  },
  fi: function fi() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/fi" */'./fi');
  },
  fi_FI: function fi_FI() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/fi_FI" */'./fi_FI');
  },
  fr: function fr() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/fr" */'./fr');
  },
  fr_FR: function fr_FR() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/fr_FR" */'./fr_FR');
  },
  hu: function hu() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/hu" */'./hu');
  },
  hu_HU: function hu_HU() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/hu_HU" */'./hu_HU');
  },
  it: function it() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/it" */'./it');
  },
  it_IT: function it_IT() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/it_IT" */'./it_IT');
  },
  ja: function ja() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/ja" */'./ja');
  },
  ja_JP: function ja_JP() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/ja_JP" */'./ja_JP');
  },
  ko: function ko() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/ko" */'./ko');
  },
  ko_KR: function ko_KR() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/ko_KR" */'./ko_KR');
  },
  nb: function nb() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/nb" */'./nb');
  },
  nb_NO: function nb_NO() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/nb_NO" */'./nb_NO');
  },
  nl: function nl() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/nl" */'./nl');
  },
  nl_NL: function nl_NL() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/nl_NL" */'./nl_NL');
  },
  pl: function pl() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/pl" */'./pl');
  },
  pl_PL: function pl_PL() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/pl_PL" */'./pl_PL');
  },
  pt_BR: function pt_BR() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/pt_BR" */'./pt_BR');
  },
  ru: function ru() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/ru" */'./ru');
  },
  ru_RU: function ru_RU() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/ru_RU" */'./ru_RU');
  },
  sv: function sv() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/sv" */'./sv');
  },
  sv_SE: function sv_SE() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/sv_SE" */'./sv_SE');
  },
  th: function th() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/th" */'./th');
  },
  th_TH: function th_TH() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/th_TH" */'./th_TH');
  },
  tr: function tr() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/tr" */'./tr');
  },
  tr_TR: function tr_TR() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/tr_TR" */'./tr_TR');
  },
  uk: function uk() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/uk" */'./uk');
  },
  uk_UA: function uk_UA() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/uk_UA" */'./uk_UA');
  },
  vi: function vi() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/vi" */'./vi');
  },
  vi_VN: function vi_VN() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/vi_VN" */'./vi_VN');
  },
  zh: function zh() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/zh" */'./zh');
  },
  zh_CN: function zh_CN() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/zh_CN" */'./zh_CN');
  },
  zh_TW: function zh_TW() {
    return import( /* webpackChunkName: "@atlassian/account-menu/locale/zh_TW" */'./zh_TW');
  }
};
export default lazyMessages;