import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';

import ErrorImageSvg from './svg-error-window.svg';

import * as Styled from './ErrorPage.styled';

interface Props {
  title: JSX.Element | string;
  body?: JSX.Element | string;
  /** Element that shows up in the image slot, it needs to have its own width and margin-bottom */
  image?: JSX.Element;
  actions?: JSX.Element;
}

export const DefaultErrorImage = <Styled.ErrorImage src={ErrorImageSvg} />;

const ErrorPage = ({ title, body, image, actions }: Props): JSX.Element => {
  return (
    <Styled.Container data-test-selector="error-page">
      <Styled.ImageOuter>{image || DefaultErrorImage}</Styled.ImageOuter>
      <Styled.ErrorTextContainer>
        <Styled.TitleWrapper>
          <Styled.Title>{title}</Styled.Title>
        </Styled.TitleWrapper>
        {body && (
          <Styled.DescriptionWrapper>
            <Styled.Description>{body}</Styled.Description>
          </Styled.DescriptionWrapper>
        )}
      </Styled.ErrorTextContainer>
      {actions && <Styled.ButtonContainer>{actions}</Styled.ButtonContainer>}
    </Styled.Container>
  );
};

export const GenericErrorPage = (props: {
  hasSupportLink?: boolean;
}): JSX.Element => {
  const actions = props.hasSupportLink && (
    <Button
      rel="noopener noreferrer"
      target="_blank"
      href="https://support.atlassian.com/contact/#/"
    >
      <FormattedMessage
        id="ptc-directory.error-boundary.message-link-text"
        defaultMessage="Contact support"
        description="The text of the link leading to the Atlassian support website, shown below the error description on the error pages"
      />
    </Button>
  );

  return (
    <ErrorPage
      title={
        <FormattedMessage
          id="pf-home.generic-error-title"
          defaultMessage="Something went wrong"
        />
      }
      body={
        <FormattedMessage
          id="pf-home.generic-error-description"
          defaultMessage="We keep track of these errors, but feel free to contact us if refreshing doesn’t fix things."
        />
      }
      actions={actions || undefined}
    />
  );
};

export default ErrorPage;
