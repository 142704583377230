/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { CONTENT } from '../../common/constants';
var contentStyles = css({
  display: 'flex',
  height: '100%',
  position: 'relative',
  gridArea: CONTENT
});

/**
 * __Content__
 *
 * Provides a slot for your application content within the PageLayout.
 *
 * - [Examples](https://atlassian.design/components/page-layout/examples)
 * - [Code](https://atlassian.design/components/page-layout/code)
 */
var Content = function Content(props) {
  var children = props.children,
    testId = props.testId;
  return jsx("div", {
    "data-testid": testId,
    css: contentStyles
  }, children);
};
export default Content;