import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export function invertRecordOfArrays(record) {
  var invertedRecord = {};
  Object.entries(record).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      values = _ref2[1];
    values.forEach(function (value) {
      if (!invertedRecord[value]) {
        invertedRecord[value] = [];
      }
      invertedRecord[value].push(key);
    });
  });
  return invertedRecord;
}