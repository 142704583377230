// TODO: PTC-2088 - simplify list of locales
// TODO: after @atlassian/ptc-embeddable-directory can load mesages by itself we can stop load AK profilecard messages in root src
const messagesConfig = [
  // Czech
  {
    locales: ['cs', 'cs-CZ'],
    loader: () => import('./loaders/cs_CZ'),
  },
  // Danish
  {
    locales: ['da', 'da-DK'],
    loader: () => import('./loaders/da_DK'),
  },
  // German
  {
    locales: ['de', 'de-DE'],
    loader: () => import('./loaders/de_DE'),
  },
  // Spain
  {
    locales: ['es', 'es-ES'],
    loader: () => import('./loaders/es_ES'),
  },
  // Estonian
  {
    locales: ['et', 'et-EE'],
    loader: () => import('./loaders/et_EE'),
  },
  // Finnish
  {
    locales: ['fi', 'fi-FI'],
    loader: () => import('./loaders/fi_FI'),
  },
  // French
  {
    locales: ['fr', 'fr-FR'],
    loader: () => import('./loaders/fr_FR'),
  },
  // Hungarian
  {
    locales: ['hu', 'hu-HU'],
    loader: () => import('./loaders/hu_HU'),
  },
  // Icelandic
  {
    locales: ['is', 'is-IS'],
    loader: () => import('./loaders/is_IS'),
  },
  // Italian
  {
    locales: ['it', 'it-IT'],
    loader: () => import('./loaders/it_IT'),
  },
  // Japanese
  {
    locales: ['ja', 'ja-JP'],
    loader: () => import('./loaders/ja_JP'),
  },
  // Korean
  {
    locales: ['ko', 'ko-KR'],
    loader: () => import('./loaders/ko_KR'),
  },
  // Dutch
  {
    locales: ['nl', 'nl-NL'],
    loader: () => import('./loaders/nl_NL'),
  },
  // Norwegian (Norway)
  {
    locales: ['nb', 'nb-NO'],
    loader: () => import('./loaders/nb_NO'),
  },
  // Polish
  {
    locales: ['pl', 'pl-PL'],
    loader: () => import('./loaders/pl_PL'),
  },
  // Portuguese
  {
    locales: ['pt', 'pt-PT'],
    loader: () => import('./loaders/pt_PT'),
  },
  // Portuguese -
  {
    locales: ['pt-BR'],
    loader: () => import('./loaders/pt_BR'),
  },
  // Romanian
  {
    locales: ['ro', 'ro-RO'],
    loader: () => import('./loaders/ro_RO'),
  },
  // Russian
  {
    locales: ['ru', 'ru-RU'],
    loader: () => import('./loaders/ru_RU'),
  },
  // Slovakian
  {
    locales: ['sk', 'sk-SK'],
    loader: () => import('./loaders/sk_SK'),
  },
  // Swedish
  {
    locales: ['sv', 'sv-SE'],
    loader: () => import('./loaders/sv_SE'),
  },
  // China uses Chinese (Simplified)
  {
    locales: ['zh', 'zh-CN', 'zh-Hans'],
    loader: () => import('./loaders/zh_CN'),
  },
  // Taiwan or Hong Kong uses Chinese (Traditional)
  {
    locales: ['zh-TW', 'zh-HK', 'zh-Hant'],
    loader: () => import('./loaders/zh_TW'),
  },
  // Turkish
  {
    locales: ['tr', 'tr-TR'],
    loader: () => import('./loaders/tr_TR'),
  },
  // Thai
  {
    locales: ['th', 'th-TH'],
    loader: () => import('./loaders/th_TH'),
  },
  // Ukrainian
  {
    locales: ['uk', 'uk-UA'],
    loader: () => import('./loaders/uk_UA'),
  },
  // Vietnamese
  {
    locales: ['vi', 'vi-VN'],
    loader: () => import('./loaders/vi_VN'),
  },
  // English (UK)
  {
    locales: ['en-GB'],
    loader: () => import('./loaders/en_GB'),
  },
];

export default messagesConfig;
