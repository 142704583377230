import { BaseGraphQlClient } from './graphql-client';

type PagedQueryParameters = {
  first?: number;
  last?: number;
  before?: string;
  after?: string;
};

const buildConnectedAppsQuery = (parameters?: PagedQueryParameters) => ({
  query: `query ConnectedApps(
    $first: Int
    $last: Int
    $before: String
    $after: String
  ){
    ecosystem {
      userGrants(first: $first, last: $last, before: $before, after: $after){
        nodes {
          id
          accountId
          oauthClientId
          appId
          appDetails {
            name
            description
            avatarUrl
            vendorName
            contactLink
          }
          scopes {
            description
          }
        }
      }
    }
  }`,
  variables: {
    first: parameters?.first,
    last: parameters?.last,
    before: parameters?.before,
    after: parameters?.after,
  },
});

const buildAccountGrantConnectedAppsQuery = (
  parameters?: PagedQueryParameters,
) => ({
  query: `query AccountGrants(
    $first: Int
    $after: String
  ){
    oauthClients {
      allAccountGrantsForUser(first: $first, after: $after) {
        nodes {
          accountId
          clientId
          scopeDetails {
            description
            key
          }
          appEnvironment {
            app {
              name
              id
              description
              vendorName
              termsOfService
              contactLink
            }
          }
        }
      }
    }
  }`,
  variables: {
    first: parameters?.first,
    after: parameters?.after,
  },
});

type DeleteUserGrantInput = {
  oauthClientId: string;
};

const buildRemoveConnectedAppsMutation = (input: DeleteUserGrantInput) => ({
  query: `mutation RemoveConnectedApps($input: DeleteUserGrantInput!) {
    ecosystem {
      deleteUserGrant(input: $input) {
        success
        errors {
          message
          extensions {
            statusCode
            errorType
          }
        }
      }
    }
  }`,
  variables: {
    input,
  },
});

class AtlassianGraphQLGatewayClient extends BaseGraphQlClient {
  /**
   * Get connected apps for user with paging parameters
   * @param {Object} parameters - Paging parameters
   * @param {string} parameters.first - Returns the first n results
   * @param {string} parameters.last - Returns the last n results
   * @param {string} parameters.before - Returns results before a date
   * @param {string} parameters.after - Returns results after a date
   * @returns {Promise}
   */
  queryConnectedApps(parameters?: PagedQueryParameters) {
    const connectedAppsQuery = buildConnectedAppsQuery(parameters);
    return this.makeGraphQLRequest(connectedAppsQuery, {
      operationName: 'ConnectedApps',
      errorPolicy: 'all',
    });
  }

  /**
   * Get connected apps for user with paging parameters
   * @param {Object} parameters - Paging parameters
   * @param {string} parameters.first - Returns the first n results
   * @param {string} parameters.last - Returns the last n results
   * @param {string} parameters.before - Returns results before a date
   * @param {string} parameters.after - Returns results after a date
   * @returns {Promise}
   */
  queryAccountGrantConnectedApps(parameters?: PagedQueryParameters) {
    const accountGrantConnectedAppsQuery = buildAccountGrantConnectedAppsQuery(
      parameters,
    );
    return this.makeGraphQLRequest(accountGrantConnectedAppsQuery, {
      operationName: 'AccountGrants',
      errorPolicy: 'all',
    });
  }

  /**
   * Remove access for a connected app with oauthClientId
   * @param {string} oauthClientId
   * @returns {Promise<string>}
   */
  removeConnectedApps(oauthClientId): Promise<void> {
    const removeConnectedAppsquery = buildRemoveConnectedAppsMutation({
      oauthClientId,
    });
    return this.makeGraphQLRequest(removeConnectedAppsquery, {
      operationName: 'RemoveConnectedApps',
    });
  }
}

export const atlassianGraphQLGatewayClient = new AtlassianGraphQLGatewayClient(
  '/graphql',
);
