/**
 * NOTE:
 *
 * This file is automatically generated by i18n-tools.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
// Romanian
export default {
  'focused-task-close-account.deactivate-account': 'Dezactivare cont',
  'focused-task-close-account.delete-account': 'Ștergere cont',
  'focused-task-close-account.learnMore': 'Aflați mai multe',
  'focused-task-close-account.cancel': 'Anulare',
  'focused-task-close-account.next': 'Înainte',
  'focused-task-close-account.previous': 'Înapoi',
  'focused-task-close-account.delete-account.overview.heading.self': 'Ștergerea contului dvs.',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Ștergere cont',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Urmează să vă ștergeți contul:',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Urmează să ștergeți contul lui:',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'După perioada de grație de 14 zile, nu vom mai putea anula ștergerea contului. Dacă credeți că veți avea nevoie de cont ulterior, dezactivați-l mai bine.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'După perioada de grație de 14 zile, nu vom mai putea anula ștergerea contului.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Când ștergeți contul:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Când vă ștergeți contul:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} va <b>pierde imediat accesul</b> la toate serviciile din contul Atlassian. În acest moment, persoana are acces la:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Veți <b>pierde imediat accesul</b> la toate serviciile din contul Altassian. În acest moment, aveți acces la:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} va <b>pierde imediat accesul</b> la toate serviciile din contul Atlassian. În acest moment, persoana nu are acces la niciun serviciu, cu excepția Comunitate și Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Veți <b>pierde imediat accesul</b> la toate serviciile din contul Altassian. În acest moment, nu aveți acces la niciun serviciu, cu excepția Comunitate și Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Alte servicii din contul Atlassian, precum Comunitate și Marketplace Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Conținutul creat va rămâne în serviciile din contul Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Conținutul pe care l-ați creat va rămâne în serviciile din contul Atlassian.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'De exemplu, paginile, problemele și comentariile pe care le-au creat în produse.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'De exemplu, paginile, problemele și comentariile pe care le-ați creat în produse.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Vom <b>șterge datele cu caracter personal</b>, precum numele complet și adresa de e-mail, din serviciile contului Atlassian într-un interval de 30 de zile, cu excepția unor cazuri obligatorii din motive legale sau de afaceri.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Vom <b>șterge datele dvs. cu caracter personal</b>, precum numele complet și adresa de e-mail, din serviciile contului Atlassian într-un interval de 30 de zile, cu excepția unor cazuri obligatorii din motive legale sau de afaceri.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Vă vom trimite prin e-mail o listă cu aplicațiile care au stocat datele acestora cu caracter personal.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Vă vom trimite prin e-mail o listă cu aplicațiile care au stocat datele dvs. cu caracter personal.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'După perioada de grație de 14 zile, nu vom mai putea anula ștergerea contului.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Păstrăm datele cu caracter personal pentru perioade limitate de timp, din motive legale sau de afaceri. Unele exemple includ:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Păstrăm datele cu caracter personal pentru perioade limitate de timp, din motive legale sau de afaceri. Unele exemple includ:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Informații privind achizițiile, pe care suntem obligați să le păstrăm pentru raportările financiare.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Informații privind achizițiile, pe care suntem obligați să le păstrăm pentru raportările financiare.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Înregistrările care demonstrează că am șters contul unei persoane, pe care le putem prezenta agențiilor de reglementare.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Înregistrările care demonstrează că am șters contul unei persoane, pe care le putem prezenta agențiilor de reglementare.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Datele care fac parte dintr-o acțiune în justiție, pe care avem obligația legală de a le păstra.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Datele care fac parte dintr-o acțiune în justiție, pe care avem obligația legală de a le păstra.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Nu vom șterge datele cu caracter personal din conținutul creat de utilizatori, precum numele sau adresele de e-mail introduse pe o pagină sau la o problemă. Adminii de produs trebuie să șteargă aceste date manual.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Nu vom șterge datele cu caracter personal din conținutul creat de dvs. sau de alte persoane, precum numele sau adresele de e-mail introduse pe o pagină sau la o problemă. Adminii produselor dvs. trebuie să șteargă aceste date manual.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Utilizatorii au dreptul de a trimite plângeri către autoritatea de supraveghere din țara natală.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Aveți dreptul de a trimite plângeri către autoritatea de supraveghere din țara natală.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Dvs. sau alți utilizatori ați instalat aplicații care adaugă funcții produselor Atlassian. Este posibil ca aceste aplicații să fi stocat informații privind profilul utilizatorului.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Dvs. sau alți utilizatori ați instalat aplicații care adaugă funcții produselor Atlassian. Este posibil ca aceste aplicații să fi stocat informații privind profilul dvs.',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Dezactivare cont',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Dezactivare cont',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Urmează să dezactivați contul de utilizator al lui:',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Urmează să dezactivați contul de utilizator al lui:',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Puteți reactiva contul oricând.',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Puteți reactiva contul oricând.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Când dezactivați contul:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Când dezactivați contul:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} va <b>pierde imediat accesul</b> la toate serviciile din contul Atlassian. În acest moment, persoana are acces la:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Veți <b>pierde imediat accesul</b> la toate serviciile din contul Altassian. În acest moment, aveți acces la:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} va <b>pierde imediat accesul</b> la toate serviciile din contul Atlassian. În acest moment, persoana nu are acces la niciun serviciu, cu excepția Comunitate și Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Veți <b>pierde imediat accesul</b> la toate serviciile din contul Altassian. În acest moment, nu aveți acces la niciun serviciu, cu excepția Comunitate și Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Alte servicii din contul Atlassian, precum Comunitate și Marketplace Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Datele cu caracter personal, precum numele complet și adresa de e-mail, și conținutul creat vor rămâne în serviciile de cont Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Datele cu caracter personal, precum numele complet și adresa de e-mail, și conținutul creat vor rămâne în serviciile de cont Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Nu veți mai fi tarifat pentru aceste conturi.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Nu veți mai fi tarifat pentru aceste conturi.',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Cum ați dori să apară utilizatorul șters?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Cum ați dori să apară contul șters?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Ulterior ștergerii contului utilizatorului, acesta va apărea ca „Fost utilizator” față de alți utilizatori. Acordați un moment răspunsului la întrebarea din sondajul nostru.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Ulterior ștergerii contului dvs., veți apărea ca „Fost utilizator” față de alți utilizatori. Acordați un moment răspunsului la întrebarea din sondajul nostru.',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Dacă ați avea de ales, cum ați dori să apară utilizatorul șters față de ceilalți utilizatori?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Dacă ați avea de ales, cum ați dori să apăreți față de ceilalți utilizatori?',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Notă: Răspunsul în acest sondaj ne va ajuta să creăm o experiență îmbunătățită pentru toti utilizatorii. Utilizatorul va apărea în continuare ca „Fost utilizator” ulterior ștergerii contului.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Notă: Răspunsul în acest sondaj ne va ajuta să creăm o experiență îmbunătățită pentru toți utilizatorii, inclusiv cei care își vor șterge conturile. Veți apărea în continuare ca „Fost utilizator” ulterior ștergerii contului.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Fost utilizator',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'Încă {num}',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Afișare mai puține'
};