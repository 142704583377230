import { defineMessages } from 'react-intl-next';
import { FieldType } from '../../../../../common/model/user';
import { PrivacyLevel } from './types';

export const labels = {
  default: defineMessages({
    public: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.public',
      defaultMessage: 'Anyone',
      description:
        'A text on visibility option of profile visibility dropdown. Means that particular profile information will be visible to anyone who can view content, including people outside the organization. Accessible by installed apps.',
    },
    organisation: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.organisation.generic',
      defaultMessage: 'Your organization',
      description:
        'A text on visibility option of profile visibility dropdown. Means that particular profile information will be visible to people who belongs to the organization. This is used when the actual name of the organization is not known.',
    },
    private: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.private',
      defaultMessage: 'Only you',
      description:
        'A text on visibility option of profile visibility dropdown. Means that particular profile information will be visible only to the user himself',
    },
  }),
  email: defineMessages({
    private: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.email.private',
      defaultMessage: 'Only you and admins',
      description:
        'A text on visibility option of profile visibility dropdown. Means that particular profile information will be visible only to the user themself and admins of the site',
    },
  }),
};

export const getLabel = (fieldType: FieldType, level: PrivacyLevel) => {
  const labelKey = level.toString();

  if (labels[fieldType] && labels[fieldType][labelKey]) {
    return labels[fieldType][labelKey];
  }
  return labels.default[labelKey];
};

export const descriptions = {
  default: defineMessages({
    public_named: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.public.description',
      defaultMessage:
        'Visible to anyone who can view your content, including people outside of the {organizationName} organization. Accessible by installed apps.',
      description:
        'Description of the profile visibility dropdown option that will make particular profile information visible publicly',
    },
    public_unmanaged: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.public.unmanaged.description',
      defaultMessage:
        'Visible to anyone who can view your content. Accessible by installed apps.',
      description:
        'Description of the profile visibility dropdown option that will make particular profile information visible publicly. Context: "apps" are referring to the apps described in ptc-directory.settings.profile-and-visibility.description-p1',
    },
    public: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.public.generic.description',
      defaultMessage:
        'Visible to anyone who can view your content, including people outside of your organization. Accessible by installed apps.',
      description:
        'Description of the profile visibility dropdown option that will make particular profile information visible publicly',
    },
    organisation_named: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.organisation.description',
      defaultMessage:
        'Only visible to people who have access to the {organizationName} organization.',
      description:
        'Description of the profile visibility dropdown option that will make particular profile information visible only to users in the same organisation `{organizationName}`, where `{organizationName}` will be replaced with the actual name of the organization.',
    },
    organisation: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.organisation.generic.description',
      defaultMessage: 'Only visible to people who belong to your organization.',
      description:
        'Description of the profile visibility dropdown option that will make particular profile information visible only to users in the same organisation. This is used when the actual name of the organization is not known.',
    },
    private: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.private.description',
      defaultMessage: 'Only visible to you.',
      description:
        'Description of the profile visibility dropdown option that will make particular profile information visible only to the user himself',
    },
  }),
  email: defineMessages({
    private: {
      id:
        'ptc-directory.settings.profile-and-visibility.visibility-level.email.private.description',
      defaultMessage:
        'Only visible to you and admins. To manage access to Atlassian products and services, admins need to view your email address too.',
      description:
        'Description of the profile visibility dropdown option that will make particular profile information visible only to the user themself and admins of the site.',
    },
  }),
};

export const getDescription = (
  fieldType: FieldType,
  level: PrivacyLevel,
  options: {
    orgName?: string;
    isManaged?: boolean;
  } = {},
) => {
  const { orgName, isManaged } = options;
  let descriptionKey = level.toString();

  if (level === PrivacyLevel.ORGANISATION && orgName) {
    descriptionKey = 'organisation_named';
  } else if (level === PrivacyLevel.PUBLIC && orgName) {
    descriptionKey = 'public_named';
  } else if (level === PrivacyLevel.PUBLIC && !isManaged) {
    descriptionKey = 'public_unmanaged';
  }

  if (descriptions[fieldType] && descriptions[fieldType][descriptionKey]) {
    return descriptions[fieldType][descriptionKey];
  }
  return descriptions.default[descriptionKey];
};
