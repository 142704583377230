import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @jsx jsx */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { N0, N20A, N90 } from '@atlaskit/theme/colors';
import { useImageState } from '../../hooks/use-image-state';
var imgStyles = css({
  display: 'block',
  borderRadius: '50%'
});
export var ProfilePicture = function ProfilePicture(_ref) {
  var testId = _ref.testId,
    src = _ref.src,
    size = _ref.size,
    className = _ref.className;
  var state = useImageState(src || '');
  var displayImage = src && state === 'loaded';
  var displayFallback = src && state === 'error';
  var displaySkeleton = !displayImage && !displayFallback;
  return jsx("div", {
    "data-testid": testId
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
    ,
    className: className
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
    ,
    css: _objectSpread(_objectSpread({
      width: size,
      height: size
    }, displaySkeleton && {
      borderRadius: '50%',
      backgroundColor: "var(--ds-background-neutral, ".concat(N20A, ")")
    }), {}, {
      '& svg, & img': {
        width: size,
        height: size
      }
    })
  }, displayImage && jsx("img", {
    "data-testid": testId && "".concat(testId, "--img"),
    src: src,
    css: imgStyles
  }), displayFallback && jsx(UserAvatarCircleIcon, {
    testId: testId && "".concat(testId, "--fallback"),
    label: "",
    primaryColor: "var(--ds-text-subtlest, ".concat(N90, ")"),
    secondaryColor: "var(--ds-text-inverse, ".concat(N0, ")")
  }));
};