/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Slovak
export default {
  'focused-task-close-account.cancel': 'Zrušiť',
  'focused-task-close-account.deactivate-account': 'Deaktivovať konto',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Chystáte sa deaktivovať používateľské konto:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Chystáte sa deaktivovať používateľské konto:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Deaktivácia konta',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Deaktivácia konta',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Konto môžete kedykoľvek znovu aktivovať.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Konto môžete kedykoľvek znovu aktivovať.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Po deaktivácii konta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Po deaktivácii konta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Túto položku vám už nebudeme účtovať.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Túto položku vám už nebudeme účtovať.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': 'Používateľ {fullName} <b>okamžite stratí prístup</b> k všetkým službám konta Atlassian. Momentálne má prístup k:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': 'Používateľ {fullName} <b>okamžite stratí prístup</b> k všetkým službám konta Atlassian. Momentálne nemá prístup k žiadnym službám, okrem služieb typu Komunita a Obchod.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Ďalšie služby konta Atlassian, ako napríklad Komunita Atlassian a Obchod.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': '<b>Okamžite stratíte prístup</b> k všetkým službám konta Atlassian. Momentálne máte prístup k:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': '<b>Okamžite stratíte prístup</b> k všetkým službám konta Atlassian. Momentálne nemáte prístup k žiadnym službám, okrem služieb typu Komunita a Obchod.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Osobné údaje, ako napríklad celé meno a e-mailová adresa, a obsah, ktorý vytvorili, ostane v službách konta Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Osobné údaje, ako napríklad celé meno a e-mailová adresa, a obsah, ktorý vytvorili, ostane v službách konta Atlassian.',
  'focused-task-close-account.delete-account': 'Odstrániť konto',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Poznámka: Vyplnením tohto prieskumu nám pomôžete vytvoriť lepšiu skúsenosť pre všetkých používateľov. Po odstránení konta sa používateľ bude stále zobrazovať ako „bývalý používateľ“.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Poznámka: Vyplnením tohto prieskumu nám pomôžete vytvoriť lepšiu skúsenosť pre všetkých používateľov vrátane tých, ktorí odstránili svoje kontá. Po odstránení konta sa stále budete zobrazovať ako „bývalý používateľ“.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Bývalý používateľ',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Ako chcete zobrazovať odstráneného používateľa?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Ako chcete zobrazovať odstránené konto?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Keby ste mali na výber, ako by sa mal odstránený používateľ zobrazovať ostatným používateľom?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Keby ste mali na výber, ako by ste sa chceli zobrazovať ostatným používateľom?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Po odstránení konta používateľa sa používateľ bude ostatným používateľom zobrazovať ako „bývalý používateľ“. Nájdite si chvíľu čas a odpovedzte na otázku z nášho prieskumu.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Po odstránení vášho konta sa ostatným používateľom budete zobrazovať ako „bývalý používateľ“. Nájdite si chvíľu čas a odpovedzte na otázku z nášho prieskumu.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Zobraziť menej',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'Ďalšie: {num}',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Chystáte sa odstrániť konto:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Chystáte sa odstrániť svoje konto:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Odstránenie konta',
  'focused-task-close-account.delete-account.overview.heading.self': 'Odstránenie konta',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Napríklad stránky, problémy a komentáre, ktoré vytvorili v produktoch.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Napríklad stránky, problémy a komentáre, ktoré ste vytvorili v produktoch.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Vy alebo iní používatelia môžete mať nainštalované aplikácie, ktoré pridávajú funkcie do produktov spoločnosti Atlassian. Tieto aplikácie môžu ukladať informácie o profile používateľa.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Vy alebo iní používatelia môžete mať nainštalované aplikácie, ktoré pridávajú funkcie do produktov spoločnosti Atlassian. Tieto aplikácie môžu ukladať informácie o vašom profile.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Informácie týkajúce sa nákupov, ktoré musíme uchovávať pre finančné výkazníctvo.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Informácie týkajúce sa nákupov, ktoré musíme uchovávať pre finančné výkazníctvo.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Záznamy, ktoré obsahujú informácie o odstránení konta určitej osoby, ktoré možno budeme musieť poskytnúť regulačným orgánom.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Záznamy, ktoré obsahujú informácie o odstránení konta určitej osoby, ktoré možno budeme musieť poskytnúť regulačným orgánom.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Údaje, ktoré sú súčasťou prebiehajúceho súdneho sporu, ktoré musíme podľa zákona uchovávať.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Údaje, ktoré sú súčasťou prebiehajúceho súdneho sporu, ktoré musíme podľa zákona uchovávať.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Osobné údaje uchovávame na obmedzené obdobia, ak máme legitímne obchodné alebo právne záujmy. Niektoré príklady zahŕňajú:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Osobné údaje uchovávame na obmedzené obdobia, ak máme legitímne obchodné alebo právne záujmy. Niektoré príklady zahŕňajú:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Neodstránime žiadne osobné údaje z obsahu vytvoreného používateľmi, ako napríklad mená alebo e-mailové adresy, ktoré zadali na stránke alebo v probléme. Správcovia produktov musia tieto údaje odstrániť manuálne.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Neodstránime žiadne osobné údaje z obsahu vytvoreného vami alebo inými osobami, ako napríklad mená alebo e-mailové adresy, ktoré sa zadali na stránke alebo v probléme. Správcovia produktov musia tieto údaje odstrániť manuálne.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Používatelia majú právo podávať sťažnosti dozornému orgánu vo svojej krajine.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Máte právo podávať sťažnosti dozornému orgánu vo svojej krajine.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Po odstránení konta:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Po odstránení konta:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Obsah, ktorý vytvorili, ostane v službách konta Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Obsah, ktorý ste vytvorili, ostane v službách konta Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Po uplynutí 14-dňového prechodného obdobia nebudete môcť zrušiť odstránenie konta.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Pošleme vám e-mail so zoznamom aplikácií, ktoré môžu ukladať ich osobné údaje.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Pošleme vám e-mail so zoznamom aplikácií, ktoré môžu ukladať vaše osobné údaje.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': 'Používateľ {fullName} <b>okamžite stratí prístup</b> k všetkým službám konta Atlassian. Momentálne má prístup k:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': 'Používateľ {fullName} <b>okamžite stratí prístup</b> k všetkým službám konta Atlassian. Momentálne nemá prístup k žiadnym službám, okrem služieb typu Komunita a Obchod.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Ďalšie služby konta Atlassian, ako napríklad Komunita Atlassian a Obchod.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': '<b>Okamžite stratíte prístup</b> k všetkým službám konta Atlassian. Momentálne máte prístup k:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': '<b>Okamžite stratíte prístup</b> k všetkým službám konta Atlassian. Momentálne nemáte prístup k žiadnym službám, okrem služieb typu Komunita a Obchod.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': '<b>Odstránime ich osobné údaje</b>, ako napríklad celé meno a e-mailovú adresu zo služieb konta Atlassian do 30 dní, s výnimkou niekoľkých prípadov, keď sú potrebné na legitímne obchodné alebo právne účely.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': '<b>Odstránime vaše osobné údaje</b>, ako napríklad celé meno a e-mailovú adresu zo služieb konta Atlassian do 30 dní, s výnimkou niekoľkých prípadov, keď sú potrebné na legitímne obchodné alebo právne účely.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Po uplynutí 14-dňového prechodného obdobia nebudete môcť zrušiť odstránenie konta. Ak si myslíte, že konto budete neskôr potrebovať, namiesto toho ho deaktivujte.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Po uplynutí 14-dňového prechodného obdobia nebudete môcť zrušiť odstránenie konta.',
  'focused-task-close-account.learnMore': 'Ďalšie informácie',
  'focused-task-close-account.next': 'Ďalej',
  'focused-task-close-account.previous': 'Späť'
};