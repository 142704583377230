import { useEffect } from 'react';
import { ApdexStartEvent } from './types';
import {
  withAnalyticsContext,
  InjectedAnalyticsProps,
} from '../AnalyticsContext';

type Props = ApdexStartEvent & InjectedAnalyticsProps;

/**
 * This component starts a specified Apdex event on mount.
 * Place this component so that it renders as soon as possible for the route.
 */
export const ApdexStart = ({ analytics, task }: Props) => {
  useEffect(() => {
    analytics.startApdexEvent!({
      task,
    });
  });

  return null;
};

export default withAnalyticsContext(ApdexStart);
