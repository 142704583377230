export interface DialogOptions {
  [key: string]: any; // tslint:disable-line no-any
}

export interface DialogContextState {
  openDialogKey: string | null;
  options?: DialogOptions | null;
  openDialog: (dialogKey: string, options?: DialogOptions) => void;
  closeDialog: () => void;
}
