import React from 'react';
import TwoStepVerification from '../../components/Security/TwoStepVerification/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const TwoStepVerificationRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_TWO_STEP_VERIFICATION} />
    <RecordTransition />
    <TwoStepVerification />
  </>
);

export default TwoStepVerificationRoute;
