import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const loadingPlaceholder = keyframes`
  from {
    background-position: -360px 0
  }

  to {
    background-position: 100% 0
  }
`;

export const LoadingPlaceHolder = styled.div`
  height: ${token('space.500', '40px')};
  width: 100%;
  background-color: ${token(
    'color.background.accent.gray.subtlest',
    colors.N10,
  )};
  background: linear-gradient(
    to right,
    ${token('color.background.accent.gray.subtlest', colors.N20)} 8%,
    ${token('color.background.accent.gray.subtler', colors.N30)} 18%,
    ${token('color.background.accent.gray.subtlest', colors.N20)} 33%
  );
  animation: ${loadingPlaceholder} 2s forwards linear infinite;
`;
