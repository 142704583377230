/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//French (France)
export default {
  'focused-task-close-account.cancel': 'Annuler',
  'focused-task-close-account.deactivate-account': 'Désactiver le compte',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Vous êtes sur le point de désactiver le compte utilisateur de :',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Vous êtes sur le point de désactiver le compte utilisateur de :',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Désactiver le compte',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Désactiver le compte',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Vous pouvez réactiver le compte à tout moment.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Vous pouvez réactiver le compte à tout moment.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Lorsque vous désactivez le compte :',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Lorsque vous désactivez votre compte :',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Il ne vous sera plus facturé.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Il ne vous sera plus facturé.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': "{fullName} <b>perdra immédiatement l'accès</b> à tous les services de compte Atlassian. Cet utilisateur a actuellement accès à :",
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': "{fullName} <b>perdra immédiatement l'accès</b> à tous les services de compte Atlassian. Cet utilisateur a pour le moment seulement accès à des services comme Community et Marketplace.",
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Autres services de compte Atlassian, comme Atlassian Community et Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': "Vous <b>perdrez immédiatement l'accès</b> à tous les services de compte Atlassian. Vous avez actuellement accès à :",
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': "Vous <b>perdrez immédiatement l'accès</b> à tous les services de compte Atlassian. Vous avez pour le moment seulement accès à des services comme Community et Marketplace.",
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Ses données personnelles, comme son nom complet et son adresse e-mail, ainsi que son contenu créé seront toujours disponibles dans les services de compte Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Ses données personnelles, comme son nom complet et son adresse e-mail, ainsi que son contenu créé seront toujours disponibles dans les services de compte Atlassian.',
  'focused-task-close-account.delete-account': 'Supprimer le compte',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': "Remarque : Répondez à cette enquête pour nous permettre de proposer une meilleure expérience à tous les utilisateurs. L'utilisateur apparaîtra toujours comme « Ancien utilisateur » après la suppression de son compte.",
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Remarque : Répondez à cette enquête pour nous permettre de proposer une meilleure expérience à tous les utilisateurs, y compris ceux qui suppriment leur compte. Vous apparaîtrez toujours comme « Ancien utilisateur » après la suppression de votre compte.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Ancien utilisateur',
  'focused-task-close-account.delete-account.content-preview.heading.admin': "Comment souhaiteriez-vous afficher l'utilisateur supprimé ?",
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Comment souhaiteriez-vous afficher votre compte supprimé ?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': "Si vous aviez le choix, comment souhaiteriez-vous afficher l'utilisateur supprimé ?",
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Si vous aviez le choix, comment souhaiteriez-vous apparaître aux autres utilisateurs ?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': "Après la suppression du compte de l'utilisateur, il apparaît comme « Ancien utilisateur ». Prenez un moment pour répondre à notre question d'enquête.",
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': "Une fois votre compte supprimé, vous apparaissez comme « Ancien utilisateur ». Prenez un moment pour répondre à notre question d'enquête.",
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Afficher moins',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} autres',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Vous êtes sur le point de supprimer le compte de :',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Vous êtes sur le point de supprimer votre compte :',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Supprimer le compte',
  'focused-task-close-account.delete-account.overview.heading.self': 'Supprimer votre compte',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': "Par exemple, les pages, les tickets et les commentaires qu'il a créés dans les produits.",
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Par exemple, les pages, les tickets et les commentaires que vous avez créés dans les produits.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': "Vous ou d'autres utilisateurs avez pu installer des applications permettant d'ajouter des fonctionnalités aux produits Atlassian. Ces applications ont pu enregistrer les informations du profil de l'utilisateur.",
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': "Vous ou d'autres utilisateurs avez pu installer des applications permettant d'ajouter des fonctionnalités aux produits Atlassian. Ces applications ont pu conserver les informations de votre profil.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': "Informations relatives aux achats que nous devons conserver pour l'établissement des rapports financiers.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': "Informations relatives aux achats que nous devons conserver pour l'établissement des rapports financiers.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': "Enregistrements indiquant que nous avons supprimé le compte d'une personne (ils peuvent être demandés par les organismes de réglementation)",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': "Enregistrements indiquant que nous avons supprimé le compte d'une personne (ils peuvent être demandés par les organismes de réglementation)",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Les données concernées par une poursuite active que nous sommes tenus de conserver en vertu de la loi.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Les données concernées par une poursuite active que nous sommes tenus de conserver en vertu de la loi.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Nous conservons les données personnelles pendant une période limitée pour des raisons juridiques ou commerciales légitimes. En voici quelques exemples :',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Nous conservons les données personnelles pendant une période limitée pour des raisons juridiques ou commerciales légitimes. En voici quelques exemples :',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Nous ne supprimons aucune donnée personnelle pour le contenu créé par les utilisateurs, comme les noms ou les adresses e-mail saisis dans une page ou un ticket. Les administrateurs de produit doivent supprimer ces données manuellement.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': "Nous ne supprimons aucune donnée personnelle pour le contenu créé par d'autres personnes ou par vous, comme les noms ou les adresses e-mail saisis dans une page ou un ticket. Les administrateurs de votre produit doivent supprimer ces données manuellement.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Les utilisateurs peuvent déposer des réclamations auprès des autorités de surveillance de leur pays.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Vous pouvez déposer des réclamations auprès des autorités de surveillance de votre pays.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Lorsque vous supprimez le compte :',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Lorsque vous supprimez votre compte :',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': "Le contenu qu'il a créé sera toujours disponible dans les services de compte Atlassian.",
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Le contenu que vous avez créé sera toujours disponible dans les services de compte Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Après un délai de grâce de 14 jours, vous ne pourrez plus annuler la suppression du compte.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Nous vous enverrons par e-mail une liste des applications qui ont pu conserver ses données personnelles.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Nous vous enverrons par e-mail une liste des applications qui ont pu conserver vos données personnelles.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': "{fullName} <b>perdra immédiatement l'accès</b> à tous les services de compte Atlassian. Cet utilisateur a actuellement accès à :",
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': "{fullName} <b>perdra immédiatement l'accès</b> à tous les services de compte Atlassian. Cet utilisateur a pour le moment seulement accès à des services comme Community et Marketplace.",
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Autres services de compte Atlassian, comme Atlassian Community et Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': "Vous <b>perdrez immédiatement l'accès</b> à tous les services de compte Atlassian. Vous avez actuellement accès à :",
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': "Vous <b>perdrez immédiatement l'accès</b> à tous les services de compte Atlassian. Vous avez pour le moment seulement accès à des services comme Community et Marketplace.",
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Ses <b>données personnelles</b>, comme son nom complet et son adresse e-mail, seront supprimées des services de compte Atlassian dans les 30 jours, sauf dans de rares cas lorsque des raisons juridiques ou commerciales légitimes le requièrent.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Vos <b>données personnelles</b>, comme votre nom complet et votre adresse e-mail, seront supprimées des services de compte Atlassian dans les 30 jours, sauf dans de rares cas lorsque des raisons juridiques ou commerciales légitimes le requièrent.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Après un délai de grâce de 14 jours, vous ne pourrez plus annuler la suppression du compte. Si vous pensez avoir besoin de ce compte ultérieurement, il vaut mieux le désactiver.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Après un délai de grâce de 14 jours, vous ne pourrez plus annuler la suppression du compte.',
  'focused-task-close-account.learnMore': 'En savoir plus',
  'focused-task-close-account.next': 'Suivant',
  'focused-task-close-account.previous': 'Précédent'
};