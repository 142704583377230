import { createBrowserHistory, History } from 'history';

let preservedHistory: History | null = null;

export const getHistory = () => {
  if (!preservedHistory) {
    preservedHistory = createBrowserHistory();
  }
  return preservedHistory;
};
