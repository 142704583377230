import React from 'react';

import AccountMenu, { AccountMenuProps } from '@atlassian/account-menu';

import { AccountLinks, AccountLinksParams, useAccountLinks } from '../../hooks';

export type AccountProps = AccountLinksParams &
  Omit<AccountMenuProps, keyof AccountLinks>;

export const Account: React.ComponentType<AccountProps> = ({
  identityEnvironment,
  application,
  continueUrl,
  logOutContinueUrl,
  isLoggedOut,
  disableSwitchAccountLink,
  ...props
}: AccountProps) => {
  const links = useAccountLinks({
    email: props.email,
    identityEnvironment,
    application,
    continueUrl,
    logOutContinueUrl,
    isLoggedOut,
    disableSwitchAccountLink,
  });

  return <AccountMenu {...props} {...links} />;
};
