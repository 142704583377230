import styled from '@emotion/styled';
interface SpinnerWrapperProps {
  height?: number | string;
}

const getHeight = ({ height }: SpinnerWrapperProps) => {
  if (typeof height === 'number') {
    return `${height}px`;
  }
  return height;
};
export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  height: ${getHeight};
`;
