import window from 'window-or-global';
import queryString from 'query-string';
import { userInfoProvider } from '../../clients/user-info-provider';
import { I18nDataLoaderConfig, LoaderResponse } from './type';
import { intlLocales } from './intlLocales';

export const DEFAULT_LOCALE = 'en';

// Generated list of supported locales based on locales config
const SUPPORTED_LOCALES = intlLocales;

// In URL, a tester can pass lang code or locale into URL, e.g `?locale=fr` or `?locale=cs-CZ`
const getLocaleFromUrl = () => {
  const locales = queryString.parse(window.location.search).locale;
  return Array.isArray(locales) ? locales[0] : locales;
};

function getSupportedLocale(locale?: string | null) {
  if (typeof locale !== 'string') {
    return null;
  }

  locale = locale.replace('_', '-');
  if (SUPPORTED_LOCALES.includes(locale)) {
    return locale;
  }
  // If we don't have the exact locale available, attempt to match just the language
  const languageCode = locale.substring(0, 2);
  if (SUPPORTED_LOCALES.includes(languageCode)) {
    return languageCode;
  }
}

export const getEffectiveLocale = async (predefinedLocale?: string) => {
  // locale that we can manually pass to intl provider
  const receivedLocale = getSupportedLocale(predefinedLocale);
  if (receivedLocale) {
    return receivedLocale;
  }

  // locale that we can pass to intl provider via `locale` GET param
  const localeInUrl = getSupportedLocale(getLocaleFromUrl());
  if (localeInUrl) {
    return localeInUrl;
  }

  let userLocale = DEFAULT_LOCALE;
  try {
    userLocale = (await userInfoProvider.get()).locale;
  } catch (e) {
    userLocale = DEFAULT_LOCALE;
  }

  return getSupportedLocale(userLocale) || DEFAULT_LOCALE;
};

export const loadIntlData = (
  localesConfig: I18nDataLoaderConfig[],
  locale: string,
): Promise<LoaderResponse> => {
  return new Promise((resolve, reject) => {
    const pickedConfig = localesConfig.find(config =>
      config.locales.includes(locale),
    );

    if (!pickedConfig || !pickedConfig.loader) {
      resolve(null);
      return;
    }

    // Fails if we haven't loaded/resolved after 30 seconds
    const rejectTimer = setTimeout(reject, 30000);

    pickedConfig
      .loader()
      .then(res => {
        clearTimeout(rejectTimer);

        if (!res) {
          resolve(null);
          return;
        }
        // depending on a builder and config used in a project the result of dynamic imports could be behind `default`
        // this way we'll cover both cases:
        const response = res as { default: LoaderResponse } | LoaderResponse;

        resolve(
          response && 'default' in response
            ? (response.default as LoaderResponse)
            : response,
        );
      })
      .catch(reject);
  });
};
