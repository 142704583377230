/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Czech
export default {
  'focused-task-close-account.cancel': 'Zrušit',
  'focused-task-close-account.deactivate-account': 'Deaktivovat účet',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Chystáte se deaktivovat uživatelský účet:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Chystáte se deaktivovat uživatelský účet:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Deaktivovat účet',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Deaktivovat účet',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Účet můžete kdykoli zase aktivovat.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Účet můžete kdykoli zase aktivovat.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Když deaktivujete účet:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Když deaktivujete účet:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Tohoto uživatele vám už nebudeme fakturovat.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Tohoto uživatele vám už nebudeme fakturovat.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': 'Uživatel {fullName} <b>okamžitě ztratí přístup</b> ke všem službám účtu Atlassian. Nyní má přístup k:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': 'Uživatel {fullName} <b>okamžitě ztratí přístup</b> ke všem službám účtu Atlassian. Nyní nemá přístup k žádným službám kromě služeb jako Community nebo Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Další služby účtu Atlassian jako Atlassian Community a Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': '<b>Okamžitě ztratíte přístup</b> ke všem službám účtu Atlassian. Nyní máte přístup k:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': '<b>Okamžitě ztratíte přístup</b> ke všem službám účtu Atlassian. Nyní nemáte přístup k žádným službám kromě služeb jako Community nebo Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Osobní údaje uživatelů, jako je celé jméno a e-mailová adresa, a obsah, který vytvořili, ve službách účtu Atlassian zůstane.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Osobní údaje uživatelů, jako je celé jméno a e-mailová adresa, a obsah, který vytvořili, ve službách účtu Atlassian zůstane.',
  'focused-task-close-account.delete-account': 'Smazat účet',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Poznámka: Vyplněním tohoto dotazníku nám umožníte vytvářet pro všechny uživatele lepší prostředí. Uživatel se stále bude zobrazovat jako „bývalý uživatel“, jakmile jeho účet smažete.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Poznámka: Vyplněním tohoto dotazníku nám umožníte vytvářet pro všechny uživatele, včetně těch, ktěří své účty smažou, lepší prostředí. Jakmile svůj účet smažete, stále se budete zobrazovat jako „bývalý uživatel“.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Bývalý uživatel',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Jak by se měl smazaný uživatel zobrazovat?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Jak by se měl váš smazaný účet zobrazovat?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Kdybyste měli na vybranou, jak by se smazaný uživatel zobrazoval dalším uživatelům?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Kdybyste měli na vybranou, jak byste se měli zobrazovat dalším uživatelům?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Jakmile dojde ke smazání účtu uživatele, ostatním uživatelům se zobrazuje jako „bývalý uživatel“. Udělejte si prosím chvilku a zodpovězte otázku z našeho průzkumu.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Jakmile dojde ke smazání vašeho účtu, ostatním uživatelům se zobrazujete jako „bývalý uživatel“. Udělejte si prosím chvilku a zodpovězte otázku z našeho průzkumu.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Zobrazit méně',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} další(ch)',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Chystáte se smazat účet:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Chystáte se smazat svůj účet:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Smazat účet',
  'focused-task-close-account.delete-account.overview.heading.self': 'Smazání vašeho účtu',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Například stránky, požadavky a komentáře, které v produktech vytvořili.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Například stránky, požadavky a komentáře, které jste v produktech vytvořili.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Vy nebo jiní uživatelé jste si mohli nainstalovat aplikace, které přidávají do produktů Atlassian funkce. Tyto aplikace mohly uložit údaje o uživatelském profilu.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Vy nebo jiní uživatelé jste si mohli nainstalovat aplikace, které přidávají do produktů Atlassian funkce. Tyto aplikace mohly uložit údaje o vašem profilu.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Informace související s nákupy, které musíme uchovat kvůli finančním zprávám.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Informace související s nákupy, které musíme uchovat kvůli finančním zprávám.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Záznamy, které ukazují, že jsme smazali něčí účet, a které můžeme být povinni poskytnout kontrolním orgánům.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Záznamy, které ukazují, že jsme smazali něčí účet, a které můžeme být povinni poskytnout kontrolním orgánům.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Údaje, které jsou součástí probíhajícího soudního řízení a které jsme dle zákona povinni uchovávat.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Údaje, které jsou součástí probíhajícího soudního řízení a které jsme dle zákona povinni uchovávat.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Osobní údaje uchováváme po omezenou dobu, z oprávněných obchodních nebo právných důvodů. Některé příklady zahrnují:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Osobní údaje uchováváme po omezenou dobu, z oprávněných obchodních nebo právných důvodů. Některé příklady zahrnují:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Nemažeme žádné osobní údaje z obsahu vytvořeného uživateli, jako jsou jména nebo e-mailové adresy, které uvedli na stránce nebo v požadavku. Administrátoři produktů musí tyto údaje smazat ručně.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Nemažeme žádné osobní údaje z obsahu vytvořeného vámi nebo dalšími lidmi, jako jsou jména nebo e-mailové adresy uvedené na stránce nebo v požadavku. Administrátoři vašich produktů musí tyto údaje smazat ručně.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Uživatelé mají právo podat stížnost dozorovému úřadu ve své zemi.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Máte právo podat stížnost dozorovému úřadu ve své zemi.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Když smažete účet:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Když smažete svůj účet:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Obsah, který vytvořili, zůstane ve službách účtu Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Obsah, který jste vytvořili, zůstane ve službách účtu Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Po čtrnáctidenní lhůtě nebudete moci zrušit smazání účtu.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'E-mailem vám pošleme seznam aplikací, které mohly uložit jejich osobní údaje.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'E-mailem vám pošleme seznam aplikací, které mohly uložit vaše osobní údaje.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': 'Uživatel {fullName} <b>okamžitě ztratí přístup</b> ke všem službám účtu Atlassian. Nyní má přístup k:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': 'Uživatel {fullName} <b>okamžitě ztratí přístup</b> ke všem službám účtu Atlassian. Nyní nemá přístup k žádným službám kromě služeb jako Community nebo Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Další služby účtu Atlassian jako Atlassian Community a Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': '<b>Okamžitě ztratíte přístup</b> ke všem službám účtu Atlassian. Nyní máte přístup k:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': '<b>Okamžitě ztratíte přístup</b> ke všem službám účtu Atlassian. Nyní nemáte přístup k žádným službám kromě služeb jako Community nebo Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Ze služeb účtu Atlassian během 30 dní <b>smažeme jejich osobní údaje</b>, jako je jejich celé jméno a e-mailová adresa, s výjimkou případů, kdy jsou potřeba z oprávněných obchodních nebo právných důvodů.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Ze služeb účtu Atlassian během 30 dní <b>smažeme vaše osobní údaje</b>, jako je vaše celé jméno a e-mailová adresa, s výjimkou případů, kdy jsou potřeba z oprávněných obchodních nebo právných důvodů.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Po čtrnáctidenní lhůtě nebudete moci zrušit smazání účtu. Pokud si myslíte, že byste později mohli účet potřebovat, místo toho jej deaktivujte.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Po čtrnáctidenní lhůtě nebudete moci zrušit smazání účtu.',
  'focused-task-close-account.learnMore': 'Další informace',
  'focused-task-close-account.next': 'Další',
  'focused-task-close-account.previous': 'Předchozí'
};