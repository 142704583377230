import React from 'react';
import LazyLinkPreferencesSettings from '../../components/LinkPreferencesSettings/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const LinkPreferencesRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_LINK_PREFERENCES_SETTINGS} />
    <RecordTransition />
    <LazyLinkPreferencesSettings />
  </>
);

export default LinkPreferencesRoute;
