import { useLayoutEffect } from 'react';

import { FeatureFlag, getFeatureFlagValue } from '../../common';

import { setGlobalTheme } from '@atlaskit/tokens';

export const useLocalStorageTheme = () => {
  useLayoutEffect(() => {
    if (
      getFeatureFlagValue(FeatureFlag.ENABLE_SETTINGS_MENU_AND_COLOR_THEMES)
    ) {
      const currentTheme = window.localStorage.getItem('theme');
      if (
        currentTheme === 'light' ||
        currentTheme === 'dark' ||
        currentTheme === 'auto'
      ) {
        void setGlobalTheme({ colorMode: currentTheme });
      }
    }
  }, []);
};
