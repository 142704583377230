import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { DEFAULT_LEFT_SIDEBAR_WIDTH, DIMENSIONS, LEFT_PANEL_WIDTH, PAGE_LAYOUT_LS_KEY, PAGE_LAYOUT_SLOT_SELECTOR } from './constants';
import safeLocalStorage from './safe-local-storage';
var emptyGridState = DIMENSIONS.reduce(function (acc, currentValue) {
  return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, currentValue, 0));
}, {});
var mergeGridStateIntoStorage = function mergeGridStateIntoStorage(key, value) {
  var storageValue = JSON.parse(safeLocalStorage().getItem(PAGE_LAYOUT_LS_KEY) || '{}');
  if (value !== null && _typeof(value) === 'object') {
    storageValue[key] = _objectSpread(_objectSpread({}, storageValue[key]), value);
  } else {
    storageValue[key] = value;
  }
  safeLocalStorage().setItem(PAGE_LAYOUT_LS_KEY, JSON.stringify(storageValue));
};
var getGridStateFromStorage = function getGridStateFromStorage(key) {
  var storageValue = JSON.parse(safeLocalStorage().getItem(PAGE_LAYOUT_LS_KEY) || '{}');
  return storageValue[key];
};
var removeFromGridStateInStorage = function removeFromGridStateInStorage(key, secondKey) {
  var storageValue = JSON.parse(safeLocalStorage().getItem(PAGE_LAYOUT_LS_KEY) || '{}');
  if (secondKey && storageValue[key]) {
    delete storageValue[key][secondKey];
  } else {
    delete storageValue[key];
  }
  safeLocalStorage().setItem(PAGE_LAYOUT_LS_KEY, JSON.stringify(storageValue));
};
var resolveDimension = function resolveDimension(key) {
  var dimension = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var shouldPersist = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (shouldPersist) {
    var cachedGridState = getGridStateFromStorage('gridState');
    return cachedGridState && Object.keys(cachedGridState).length > 0 && cachedGridState[key] ? cachedGridState[key] : dimension;
  }
  return dimension;
};
var getLeftPanelWidth = function getLeftPanelWidth() {
  if (typeof window === 'undefined') {
    return 0;
  }
  return parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--".concat(LEFT_PANEL_WIDTH)), 10) || 0;
};
var getLeftSidebarPercentage = function getLeftSidebarPercentage(currentWidth, maxWidth) {
  var total = (currentWidth - DEFAULT_LEFT_SIDEBAR_WIDTH) / (maxWidth - DEFAULT_LEFT_SIDEBAR_WIDTH);
  if (total < 0) {
    return 0;
  }
  if (total > 1) {
    return 100;
  }
  return Math.floor(total * 100);
};
var getPageLayoutSlotSelector = function getPageLayoutSlotSelector(slotName) {
  return _defineProperty({}, PAGE_LAYOUT_SLOT_SELECTOR, slotName);
};
var getPageLayoutSlotCSSSelector = function getPageLayoutSlotCSSSelector(slotName) {
  return "[".concat(PAGE_LAYOUT_SLOT_SELECTOR, "='").concat(slotName, "']");
};
export { emptyGridState, mergeGridStateIntoStorage, getGridStateFromStorage, removeFromGridStateInStorage, resolveDimension, getLeftPanelWidth, getLeftSidebarPercentage, getPageLayoutSlotSelector, getPageLayoutSlotCSSSelector };