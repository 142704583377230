import { connect } from 'react-redux';

import Flags from './Flags';
import { RootState, removeFlag } from '../../common';

const mapActionCreators = {
  removeFlag,
};

const mapStateToProps = ({ flags }: RootState) => ({
  flags: flags.flags,
});

export default connect(mapStateToProps, mapActionCreators)(Flags);
