import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-next';

import PageHeader from '@atlaskit/page-header';
import {
  AnalyticsEventActions,
  AnalyticsEventSource,
  Anchor,
  ApdexStop,
  ApdexTasks,
  GenericErrorPage,
  logException,
  Spinner,
  useAnalytics,
} from '../../../../common';
import { FieldType, ConnectedProps, ConnectedDispatchProps } from './types';
import * as Styled from './UserProfileAndVisibilitySettings.styled';
import { visibilityFlagMessages } from './messages';

import AvatarAndHeaderImageSection from '../ProfileAndPrivacySettings/AvatarAndHeaderImageSection';
import ProfileDetailsForm from '../ProfileAndPrivacySettings/ProfileDetailsForm';
import {
  generatePrivacyOptions,
  PRIVACY_VALUE_MAP,
} from '../ProfileAndPrivacySettings/PrivacyLevelDropdown';
import AdminManagesAccountBanner from '../AdminManagesAccountBanner';
import { FIELD_TO_PRIVACY_KEY } from '../../model/userProfilePrivacy';
import { DeviceContextConsumer } from '../../../../components/DeviceContext';
import { useManageProfileUser } from '../../hooks/useManageProfileUser';
import { transformUserData } from './DataTransform';
import { useUserOrganization } from './useUserOrganization';
import { useUserManagePrivacySettings } from './hooks/useUserManagePrivacySettings';
import { useUserAllowedPrivacySettingsQuery } from './hooks/useUserAllowedPrivacySettingsQuery';
import { useProfilePrivacyMutation } from './hooks/useProfilePrivacyMutation';
import { useAccountPreferencesMutation } from '../AccountPreferencesSettings/hooks/useAccountPreferencesMutation';

enum Sections {
  PRIVACY_POLICY = 'privacyPolicy',
  PROFILE_VISIBILITY = 'profileVisibility',
}

const MAP_UI_TO_PRS_PRIVACY_VALUES = {
  public: 'PUBLIC',
  organisation: 'COLLABORATOR',
  private: 'PRIVATE',
};

const FIELD_TYPES_DETAILS = [
  FieldType.FullName,
  FieldType.Nickname,
  FieldType.Title,
  FieldType.Department,
  FieldType.CompanyName,
  FieldType.Location,
  FieldType.LocalTime,
];

const FIELD_TYPES_CONTACT = [FieldType.Email];

export type UserProfileAndVisibilitySettingsProps = ConnectedProps &
  ConnectedDispatchProps;

export const UserProfileAndVisibilitySettings = ({
  addFlag,
  isManaged,
  userId,
}: UserProfileAndVisibilitySettingsProps) => {
  const { formatMessage } = useIntl();
  const analytics = useAnalytics();

  const {
    userPrivacySettings: privacyValues,
    isError: isUserManagePrivacySettingsError,
    isLoading: isLoadingUserManagePrivacySettings,
  } = useUserManagePrivacySettings(userId);

  const {
    userAllowedPrivacySettings: privacyOptions,
    isLoading: isAllowedPrivacySettingsLoading,
    isError: isAllowedPrivacySettingsError,
  } = useUserAllowedPrivacySettingsQuery(userId);

  const { mutateAsync: onSubmit } = useAccountPreferencesMutation({
    userId,
  });

  const { mutateAsync: onPrivacyLevelChange } = useProfilePrivacyMutation(
    userId,
  );

  const {
    user: userDetails,
    isLoading: isLoadingManageProfileUser,
    isError: isManageProfileUserError,
  } = useManageProfileUser(userId);

  const {
    usersOrg,
    isLoading: isLoadingUserOrganization,
    isError: isUserOrganizationError,
  } = useUserOrganization();
  const usersOrgName = isLoadingUserOrganization
    ? null
    : (usersOrg && usersOrg.name) || '';

  const userDetailsEditValues = useMemo(
    () => userDetails && transformUserData(userDetails),
    [userDetails],
  );

  useEffect(() => {
    analytics.pushScreenEvent({
      name: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrivacyLevelChangeHandler = (fieldKey: FieldType) => async (
    value: string,
  ) => await privacyLevelChangeHandler(fieldKey, value);

  const privacyLevelChangeHandler = useCallback(
    async (fieldKey: FieldType, value: string) => {
      const privacyKey = FIELD_TO_PRIVACY_KEY[fieldKey] || fieldKey;
      try {
        const oldValue = privacyValues?.[privacyKey];
        const newValue = MAP_UI_TO_PRS_PRIVACY_VALUES[value];

        // Do not update if the new level is the same as the old level
        if (oldValue === newValue) {
          return;
        }

        await onPrivacyLevelChange({ privacyKey, value });

        analytics.pushTrackEvent({
          source:
            AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
          actionSubject: 'profilePrivacyItem',
          action: AnalyticsEventActions.EDITED,
          attributes: {
            fieldKey,
            isManaged,
            oldValue,
            newValue,
          },
        });

        // In term of UI, user is updating visibility of local time.
        // in term of calling our API, user is updating visibility of time zone.
        // So that is the reason, prop name is `timezone_xxxx`, but message has local time.
        let flagMessage = visibilityFlagMessages[`${fieldKey}_${value}`];

        if (fieldKey === FieldType.Timezone) {
          flagMessage =
            visibilityFlagMessages[`${FieldType.LocalTime}_${value}`];
        }

        addFlag({
          type: 'success',
          title: formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.privacy.success-heading',
            defaultMessage: 'We’re updating your visibility',
            description:
              'The success notification flag title for when a field visibility modification succeeded.',
          }),
          description: flagMessage && formatMessage(flagMessage),
          id: 'PRIVACY_LEVEL_CHANGE_SUCCESS',
        });
      } catch (error) {
        void logException(error, 'Failed to change privacy level in UPAVS');

        addFlag({
          type: 'error',
          title: formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.privacy.error-heading',
            defaultMessage: 'We couldn’t update your visibility.',
            description:
              'The error notification title for when a field visibility modification fails.',
          }),
          description: formatMessage({
            id: 'ptc-directory.settings.profile-and-visibility.try-again-later',
            defaultMessage: 'Try again later',
            description:
              'The error message description for when a field edit fails. Appears under the title in a notification flag when a field edit fails.',
          }),
          id: 'PRIVACY_LEVEL_CHANGE_ERROR',
        });
      }
    },
    [
      addFlag,
      analytics,
      formatMessage,
      isManaged,
      onPrivacyLevelChange,
      privacyValues,
    ],
  );

  const handleLearnMoreClick = (section: string) => () => {
    analytics.pushTrackEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: `${section}LearnMore`,
      action: AnalyticsEventActions.CLICKED,
    });
  };

  const renderAvatarAndHeader = () => {
    if (!privacyValues?.picture) {
      return null;
    }

    const privacyDropdownOptions = privacyOptions && privacyOptions.picture;

    return (
      <AvatarAndHeaderImageSection
        privacyLevelOptions={generatePrivacyOptions(
          FieldType.Avatar,
          privacyDropdownOptions || undefined,
          {
            orgName: usersOrgName,
            isManaged,
          },
        )}
        privacyLevel={PRIVACY_VALUE_MAP[privacyValues.picture]}
        onPrivacyLevelChange={getPrivacyLevelChangeHandler(FieldType.Avatar)}
        userId={userId}
      />
    );
  };

  const renderHeader = () => (
    <PageHeader>
      <FormattedMessage
        id="ptc-directory.settings.profile-and-visibility.title"
        defaultMessage="Profile and visibility"
        description="Page title shown at the top of the page"
      />
    </PageHeader>
  );

  if (
    isLoadingManageProfileUser ||
    isLoadingUserOrganization ||
    isLoadingUserManagePrivacySettings ||
    isAllowedPrivacySettingsLoading
  ) {
    return <Spinner wrapperHeight={256} />;
  }

  const loadedWithoutData = !privacyValues || !privacyOptions;

  if (
    loadedWithoutData ||
    isManageProfileUserError ||
    isUserOrganizationError ||
    isUserManagePrivacySettingsError ||
    isAllowedPrivacySettingsError
  ) {
    return (
      <>
        <GenericErrorPage />
        <ApdexStop task={ApdexTasks.VIEW_PROFILE_AND_VISIBILITY_SETTINGS} />
      </>
    );
  }

  return (
    <Styled.Page>
      <DeviceContextConsumer>
        {({ isMobile }) => (
          // DeviceContextConsumer is needed here to allow
          // put banner under header on mobiles to prevent
          // UI issues
          <>
            {isMobile && renderHeader()}

            {isManaged && (
              <Styled.AdminManagesAccountBannerWrapper>
                <AdminManagesAccountBanner />
              </Styled.AdminManagesAccountBannerWrapper>
            )}

            {!isMobile && renderHeader()}
          </>
        )}
      </DeviceContextConsumer>

      <Styled.SettingsDescription>
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.description-p1"
          defaultMessage="Manage your personal information, and control which information other people see and apps may access."
          description='First paragraph of "Profile and visibility" settings page description'
          tagName="p"
        />
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.description-p2.xml"
          defaultMessage="<learn-more-link>Learn more about your profile and visibility</learn-more-link> or <privacy-policy-link>view our privacy policy</privacy-policy-link>."
          description='Second paragraph of "Profile and visibility" settings page description, containing two links'
          values={{
            // eslint-disable-next-line react/display-name
            'learn-more-link': ((chunks => (
              <Anchor
                href="https://confluence.atlassian.com/x/6IS8OQ"
                target="_blank"
                onClick={handleLearnMoreClick(Sections.PROFILE_VISIBILITY)}
              >
                {chunks}
              </Anchor>
            )) as unknown) as React.ReactNode,
            // eslint-disable-next-line react/display-name
            'privacy-policy-link': ((chunks => (
              <Anchor
                href="https://www.atlassian.com/legal/privacy-policy"
                target="_blank"
                onClick={handleLearnMoreClick(Sections.PRIVACY_POLICY)}
              >
                {chunks}
              </Anchor>
            )) as unknown) as React.ReactNode,
          }}
        />
      </Styled.SettingsDescription>

      <Styled.SubHeading>
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.profile-header-title"
          defaultMessage="Profile photo and header image"
          description="Title of a section that contains profile photo and header image settings"
        />
      </Styled.SubHeading>
      {renderAvatarAndHeader()}

      <Styled.SubHeading>
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.profile-about-title"
          defaultMessage="About you"
          description="Title of a section that contains profile details settings"
        />
      </Styled.SubHeading>
      {userDetailsEditValues && (
        <ProfileDetailsForm
          fieldTypes={FIELD_TYPES_DETAILS}
          values={userDetailsEditValues}
          privacyValues={privacyValues}
          privacyOptions={privacyOptions}
          usersOrg={usersOrgName}
          onPrivacyLevelChange={privacyLevelChangeHandler}
          onSubmit={onSubmit}
        />
      )}

      <Styled.SubHeading>
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.profile-contact-title"
          defaultMessage="Contact"
          description="Title of a section that contains profile contact details settings"
        />
      </Styled.SubHeading>
      {userDetailsEditValues && (
        <ProfileDetailsForm
          fieldTypes={FIELD_TYPES_CONTACT}
          values={userDetailsEditValues}
          privacyValues={privacyValues}
          privacyOptions={privacyOptions}
          usersOrg={usersOrgName}
          onSubmit={onSubmit}
          onPrivacyLevelChange={privacyLevelChangeHandler}
        />
      )}
      {userDetailsEditValues && (
        <ApdexStop task={ApdexTasks.VIEW_PROFILE_AND_VISIBILITY_SETTINGS} />
      )}
    </Styled.Page>
  );
};

export default UserProfileAndVisibilitySettings;
