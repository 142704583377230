const FORBIDDEN_CODE_CHARS: number[] = [
  8207, // https://www.fileformat.info/info/unicode/char/200f/index.htm
];

export function sanitiseValue(value: string): string {
  let trimmedStr = value.trim();

  FORBIDDEN_CODE_CHARS.forEach(codeChar => {
    const char = String.fromCharCode(codeChar);
    const re = new RegExp(char, 'gm');
    trimmedStr = trimmedStr.replace(re, '');
  });

  return trimmedStr;
}
