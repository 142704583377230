import { prefetchAll } from './common/providers/instance-data-providers';
import { prefetchAvailableProducts } from './common/providers/products-data-provider';
import { prefetchJoinableSites } from './cross-join/providers/joinable-sites-data-provider';
import prefetchSwitcherBundles from './common/utils/prefetch-bundles';
export var prefetch = function prefetch(props) {
  var cloudId = props.cloudId,
    product = props.product;
  prefetchSwitcherBundles(product);
  prefetchAvailableProducts();
  prefetchJoinableSites(props.joinableSitesDataProvider);
  if (cloudId) {
    prefetchAll({
      cloudId: cloudId
    });
  }
};