import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { SubtleDropdownProps } from './types';
import { token } from '@atlaskit/tokens';

// Color taken from atlaskit/button theme defintions
const focusOutlineColor = 'rgba(38, 132, 255, 0.6)';

export const Root = styled.div`
  height: ${token('space.500', '40px')};
`;

export const EmptyIconSpace = styled.div`
  width: ${token('space.300', '24px')};
  height: ${token('space.300', '24px')};
`;

export const DisabledTrigger = styled.div`
  outline: none;
  border-radius: ${token('border.radius.100', '3px')};
  border-width: 0px;
  width: 200px;
  display: inline-flex;
  user-select: none;
  cursor: not-allowed;
  box-shadow: transparent 0px 0px 0px ${token('space.025', '2px')};
  color: ${token('color.text.subtlest', colors.N70)};

  &:focus {
    box-shadow: ${token('elevation.surface.overlay.hovered', focusOutlineColor)}
      0px 0px 0px ${token('space.025', '2px')};
  }
`;

export const ErrorState = styled.div`
  margin: 0 ${token('space.200', '16px')};
  height: ${token('space.500', '40px')};
  width: 100%;
  color: ${token('color.text.subtlest', colors.N90)};
  display: inline-flex;
  align-items: center;
`;

export const TriggerWrapper = styled.div<
  Pick<SubtleDropdownProps, 'appearance'>
>`
  display: flex;
  align-items: center;
  /* Need to account for 32px icon-after.*/
  width: ${props => (props.appearance === 'default' ? 328 : 168)}px;
  height: ${token('space.500', '40px')};
  padding: 0 ${token('space.100', '8px')};
`;

export const Triggertext = styled.div`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  max-width: 120px;

  &:not(:first-child) {
    margin-left: ${token('space.050', '4px')};
  }
`;

export const TriggerIconAfterWrapper = styled.span`
  padding: 0 8px 0 0;
`;

export const DisabledDialogHeadline = styled.h5`
  margin: 0;
`;

export const DisabledDialogMessage = styled.p`
  margin-top: ${token('space.150', '12px')};
`;
