import React from 'react';
import { AnalyticsProviderProps } from './types';
import AnalyticsContext from './AnalyticsContext';

const AnalyticsProvider = ({
  analytics,
  children,
}: AnalyticsProviderProps & { children: React.ReactNode }) => {
  const state = { analytics };
  return (
    <AnalyticsContext.Provider value={state}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
