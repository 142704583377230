import { gql } from 'graphql-request';
import { fetchApi } from '../../../../utils/fetchApi';
import { UpdateUserHeaderImageProps, UpdateUserHeaderOutput } from '../types';
import { getConfig } from '../../../config/envConfig';

const updateUserHeaderImage = async ({
  userId,
  fields,
}: UpdateUserHeaderImageProps) => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/directory/graphql?q=UpdateProfileHeaderUser`;
  const query = gql`
    mutation ProfileHeaderUser($userId: String!, $fields: UserInput) {
      User: updateUser(id: $userId, input: $fields) {
        id
        headerImageUrl(width: 1500)
      }
    }
  `;
  const response = await fetchApi(url, {
    method: 'POST',
    body: JSON.stringify({
      query: query,
      variables: {
        userId,
        fields,
      },
    }),
  });

  const data = (await response.json()) as UpdateUserHeaderOutput;
  return data;
};

export default updateUserHeaderImage;
