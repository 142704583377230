import React from 'react';
import { DialogContextState } from './types';

const defaultState: DialogContextState = {
  openDialogKey: null,
  openDialog: () => null,
  closeDialog: () => null,
  options: {},
};

export default React.createContext(defaultState);
