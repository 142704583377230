// This file will add transition to normal dialogs based on their `isOpen` prop
// It's a temporary solution before we made all dialogs to use the same pattern.
// After this ticket is done - https://product-fabric.atlassian.net/browse/PTC-1866
// there will be once place that has ModalTransition - the HOC for dialog

// ^ NO LONGER TRUE (But still valid thing)
// Once used embeddable-directory inside Confluence dialogs aren't closing if we keep them wrapped in transition components
// Bring back dialog transitions: https://product-fabric.atlassian.net/browse/PTC-2321

import React from 'react';

export interface WithDialogTransitionProps {
  isOpen: boolean;
}

const withDialogTransition = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P & WithDialogTransitionProps> => {
  const name =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ModalDialogWithTransition: React.FC<P &
    WithDialogTransitionProps> = props => {
    const { isOpen } = props;
    return <>{isOpen && <WrappedComponent {...props} />}</>;
  };

  ModalDialogWithTransition.displayName = `withDialogTransition(${name})`;

  return ModalDialogWithTransition;
};

export default withDialogTransition;
