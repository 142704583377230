/** @jsx jsx */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import * as colors from '@atlaskit/theme/colors';
import { SectionContainer } from './section-container';
import SwitcherItem from './item';
import { useExperienceRenderAndMountMark, ExperienceMark } from '../../common/utils/experience-tracker';
var iconSkeletonStyles = css({
  display: 'inline-block',
  width: 32,
  height: 32,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  backgroundColor: "var(--ds-skeleton, ".concat(colors.N20, ")"),
  borderRadius: "var(--ds-border-radius-200, 8px)"
});
var lineSkeletonStyles = css({
  display: 'inline-block',
  width: 98,
  height: 10,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  backgroundColor: "var(--ds-skeleton, ".concat(colors.N20, ")"),
  borderRadius: "var(--ds-border-radius, 3px)"
});

// TODO: add loading text for screen reader
// https://product-fabric.atlassian.net/browse/CXP-2998
var iconSkeleton = jsx("div", {
  css: iconSkeletonStyles
});
var lineSkeleton = jsx("div", {
  css: lineSkeletonStyles
});
export default (function () {
  useExperienceRenderAndMountMark(ExperienceMark.SWITCHER_SKELETON_MOUNT);
  return jsx(SectionContainer, {
    sectionId: "skeleton",
    title: lineSkeleton
  }, jsx(SwitcherItem, {
    tag: "li",
    icon: iconSkeleton,
    isDisabled: true
  }, lineSkeleton), jsx(SwitcherItem, {
    tag: "li",
    icon: iconSkeleton,
    isDisabled: true
  }, lineSkeleton), jsx(SwitcherItem, {
    tag: "li",
    icon: iconSkeleton,
    isDisabled: true
  }, lineSkeleton));
});