import { connect } from 'react-redux';

import AdminManagesAccountBanner from './AdminManagesAccountBanner';
import { UserState } from './types';

const mapStateToProps = ({ user }): UserState => ({
  isUserLoading: user.isLoading,
  isManaged: user.data.isManaged,
  timestamp: user.data.manageStartedTimestamp,
});

export default connect(mapStateToProps)(AdminManagesAccountBanner);
