import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["testId", "closePopup", "icon", "href", "onClick", "actionSubjectId", "children"];
/** @jsx jsx */
import React, { useCallback } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl-next';
import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { LinkItem, MenuGroup, Section } from '@atlaskit/menu';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
import { NAVIGATION_CHANNEL } from '../../analytics';
import { AccountDetails } from '../account-details';
import { PeopleIcon } from '../icons/people';
import { PersonIcon } from '../icons/person';
import { SignOutIcon } from '../icons/sign-out';
import { ThemeSwitcher } from '../theme-switcher';
export var AccountMenuContent = function AccountMenuContent(_ref) {
  var testId = _ref.testId,
    closePopup = _ref.closePopup,
    name = _ref.name,
    picture = _ref.picture,
    email = _ref.email,
    managedBy = _ref.managedBy,
    manageProfileLink = _ref.manageProfileLink,
    viewProfileLink = _ref.viewProfileLink,
    onViewProfileClick = _ref.onViewProfileClick,
    switchAccountLink = _ref.switchAccountLink,
    logOutLink = _ref.logOutLink,
    onThemeChange = _ref.onThemeChange,
    currentTheme = _ref.currentTheme;
  var themeSwitcherProps = onThemeChange && currentTheme;
  var shouldRenderProfileThemeSwitcherSection = Boolean(viewProfileLink || manageProfileLink || themeSwitcherProps);
  return jsx(Container, {
    "data-testid": testId
  }, jsx(MenuGroup, null, jsx(Section, null, jsx(AccountDetails, {
    testId: testId && "".concat(testId, "--details"),
    name: name,
    picture: picture,
    email: email,
    managedBy: managedBy
  })), shouldRenderProfileThemeSwitcherSection && jsx(Section, null, viewProfileLink && jsx(ViewProfileLink, {
    onClick: onViewProfileClick,
    testId: testId && "".concat(testId, "--view-profile"),
    href: viewProfileLink,
    closePopup: closePopup
  }), manageProfileLink && jsx(ManageProfileLink, {
    testId: testId && "".concat(testId, "--manage-profile"),
    href: manageProfileLink,
    closePopup: closePopup
  }), themeSwitcherProps && jsx(ThemeSwitcher, {
    testId: testId && "".concat(testId, "--theme-switcher"),
    onThemeChange: onThemeChange,
    currentTheme: currentTheme
  })), jsx(Section, {
    hasSeparator: shouldRenderProfileThemeSwitcherSection
  }, switchAccountLink && jsx(SwitchAccountLink, {
    testId: testId && "".concat(testId, "--switch-account"),
    href: switchAccountLink,
    closePopup: closePopup
  }), jsx(LogOutLink, {
    testId: testId && "".concat(testId, "--log-out"),
    href: logOutLink,
    closePopup: closePopup
  }))));
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var Container = styled.div({
  boxSizing: 'border-box',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  minWidth: gridSize() * 38
});
var ViewProfileLink = function ViewProfileLink(_ref2) {
  var testId = _ref2.testId,
    href = _ref2.href,
    onClick = _ref2.onClick,
    closePopup = _ref2.closePopup;
  return jsx(Link, {
    testId: testId,
    href: href,
    onClick: onClick,
    icon: PersonIcon,
    closePopup: closePopup,
    actionSubjectId: "viewProfileLink"
  }, jsx(FormattedMessage, {
    id: "identity.account-menu.account-details.view-profile-tooltip",
    defaultMessage: "Profile",
    description: "Text shown when hovering over profile information"
  }));
};
var ManageProfileLink = function ManageProfileLink(_ref3) {
  var testId = _ref3.testId,
    href = _ref3.href,
    closePopup = _ref3.closePopup;
  return jsx(Link, {
    testId: testId,
    href: href,
    icon: SettingsIcon,
    closePopup: closePopup,
    actionSubjectId: "manageProfileLink",
    target: "_blank",
    rel: "noopener"
  }, jsx(FormattedMessage, {
    id: "identity.account-menu.account-details.manage-profile-link",
    defaultMessage: "Account settings",
    description: "Text for link to account management page"
  }));
};
var SwitchAccountLink = function SwitchAccountLink(_ref4) {
  var testId = _ref4.testId,
    href = _ref4.href,
    closePopup = _ref4.closePopup;
  return jsx(Link, {
    testId: testId,
    icon: PeopleIcon,
    href: href,
    closePopup: closePopup,
    actionSubjectId: "switchAccountLink"
  }, jsx(FormattedMessage, {
    id: "identity.account-menu.account-menu-content.switch-account-button",
    defaultMessage: "Switch account",
    description: "Label for button in account menu to switch to another another account"
  }));
};
var LogOutLink = function LogOutLink(_ref5) {
  var testId = _ref5.testId,
    href = _ref5.href,
    closePopup = _ref5.closePopup;
  return jsx(Link, {
    testId: testId,
    icon: SignOutIcon,
    href: href,
    closePopup: closePopup,
    actionSubjectId: "logOutLink"
  }, jsx(FormattedMessage, {
    id: "identity.account-menu.account-menu-content.log-out-button",
    defaultMessage: "Log out",
    description: "Label for button in account menu to log out"
  }));
};
var Link = function Link(_ref6) {
  var testId = _ref6.testId,
    closePopup = _ref6.closePopup,
    Icon = _ref6.icon,
    href = _ref6.href,
    onClick = _ref6.onClick,
    actionSubjectId = _ref6.actionSubjectId,
    children = _ref6.children,
    props = _objectWithoutProperties(_ref6, _excluded);
  var _useAnalyticsEvents = useAnalyticsEvents(),
    createAnalyticsEvent = _useAnalyticsEvents.createAnalyticsEvent;
  var handleClick = useCallback(function (event) {
    var analyticsEvent = createAnalyticsEvent({
      eventType: UI_EVENT_TYPE,
      action: 'clicked',
      actionSubject: 'menuLinkItem',
      actionSubjectId: actionSubjectId
    });
    var clone = analyticsEvent.clone();
    if (clone) {
      clone.fire(NAVIGATION_CHANNEL);
    }
    onClick === null || onClick === void 0 || onClick(event, analyticsEvent);
    closePopup === null || closePopup === void 0 || closePopup(event);
  }, [createAnalyticsEvent, onClick, closePopup, actionSubjectId]);
  return jsx(LinkItem, _extends({
    testId: testId,
    iconBefore: jsx(Icon, {
      label: "",
      size: "medium"
    }),
    href: href,
    onClick: handleClick
  }, props), children);
};