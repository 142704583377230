import { logException } from '../common/utils/sentry';

const removeEmptyHeaders = (headers: RequestInit['headers']) => {
  const result = { ...headers };

  Object.entries(result).forEach(([key, val]) => {
    if (val === undefined || val === null) {
      delete result[key];
    }
  });

  return result;
};

export const fetchApi = async (url: string, init: RequestInit = {}) => {
  const response = await fetch(url, {
    credentials: 'include',
    ...init,
    headers: removeEmptyHeaders({
      'Content-Type': 'application/json',
      ...init.headers,
    }),
  });

  if (!response.ok) {
    const message = `Failed to fetch ${url} [${response.status} status]`;
    const data = await response.json();
    const error = new Error(message);
    void logException(error, message, data);
    throw error;
  }

  return response;
};
