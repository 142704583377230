import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  RootState,
  FeatureFlag,
  getFeatureFlagValue,
  useAnalytics,
  AnalyticsEventSource,
  AnalyticsEventSubjects,
  AnalyticsEventActions,
} from '../../common';
import { getConfig } from '../../common/config/envConfig';
import { getManageProfileAppRootPath } from '../../utils/routes';
import AccountMenu, {
  AccountMenuProps,
} from '../../apps/id-a-c/components/atlassian-frontend-packages/account-menu';
import {
  useAccountClient,
  useRecentAsscociatedSessions,
} from '../../apps/id-a-c/components/atlassian-frontend-packages/account';
import { useAvatarByAccountIdQuery } from '../../common/components/Avatar/hooks';
import { setGlobalTheme, ThemeColorModes } from '@atlaskit/tokens';

export type AccountMenuContainerProps = Omit<AccountMenuProps, 'picture'> & {
  accountId: string;
};

const mapStateToProps = ({ user }: RootState): AccountMenuContainerProps => {
  return {
    accountId: user.data.accountId || '',
    name: user.data.fullName ?? undefined,
    email: user.data.email ?? undefined,
    switchAccountLink: `/login?application=manage-profile&prompt=select_account&continue=${encodeURIComponent(
      getConfig().atlassianAccountUrl + getManageProfileAppRootPath(),
    )}`,
    logOutLink: `/logout?application=manage-profile&prompt=none&continue=${encodeURIComponent(
      getConfig().atlassianAccountUrl + getManageProfileAppRootPath(),
    )}`,
  };
};

const AccountMenuContainerWithAccountSwitcher = ({
  accountId,
  ...props
}: AccountMenuContainerProps) => {
  const client = useAccountClient();
  const associatedSessions = useRecentAsscociatedSessions();
  const { avatarByAccountIdData } = useAvatarByAccountIdQuery(accountId, 96);
  const [themeState, setThemeState] = useState<ThemeColorModes>(() => {
    const currentTheme = window.localStorage.getItem('theme');
    if (
      currentTheme === 'light' ||
      currentTheme === 'dark' ||
      currentTheme === 'auto'
    ) {
      return currentTheme;
    }
    return 'auto';
  });
  const analytics = useAnalytics();

  const isActive = (theme: ThemeColorModes) => themeState === theme;

  const toggleTheme = (theme: ThemeColorModes) => {
    if (isActive(theme)) {
      return;
    }
    setThemeState(theme);
    window.localStorage.setItem('theme', theme);
    void setGlobalTheme({ colorMode: theme });

    analytics.pushTrackEvent({
      source: AnalyticsEventSource.THEME_SWITCHER,
      actionSubject: AnalyticsEventSubjects.THEME_STATE,
      action: AnalyticsEventActions.CHANGED_THEME,
      actionSubjectId: `${theme}-theme`,
    });
  };

  const isThemeSwitcherEnabled = getFeatureFlagValue(
    FeatureFlag.ENABLE_SETTINGS_MENU_AND_COLOR_THEMES,
  );

  return (
    <AccountMenu
      {...props}
      picture={avatarByAccountIdData?.CloudUser.avatarUrl}
      onThemeChange={isThemeSwitcherEnabled ? toggleTheme : undefined}
      currentTheme={isThemeSwitcherEnabled ? themeState : undefined}
      getAccountSwitchLink={(destination, email) =>
        client.getSessionSyncLink(destination, email)
      }
      associatedSessions={associatedSessions}
    />
  );
};

export const AccountMenuContainer = ({
  accountId,
  ...props
}: AccountMenuContainerProps) => {
  const { avatarByAccountIdData } = useAvatarByAccountIdQuery(accountId, 96);
  const [themeState, setThemeState] = useState<ThemeColorModes>(() => {
    const currentTheme = window.localStorage.getItem('theme');
    if (
      currentTheme === 'light' ||
      currentTheme === 'dark' ||
      currentTheme === 'auto'
    ) {
      return currentTheme;
    }
    return 'auto';
  });
  const analytics = useAnalytics();
  const isActive = (theme: ThemeColorModes) => themeState === theme;

  const toggleTheme = (theme: ThemeColorModes) => {
    if (isActive(theme)) {
      return;
    }
    setThemeState(theme);
    window.localStorage.setItem('theme', theme);
    void setGlobalTheme({ colorMode: theme });

    analytics.pushTrackEvent({
      source: AnalyticsEventSource.THEME_SWITCHER,
      actionSubject: AnalyticsEventSubjects.THEME_STATE,
      action: AnalyticsEventActions.CHANGED_THEME,
      actionSubjectId: `${theme}-theme`,
    });
  };

  const isThemeSwitcherEnabled = getFeatureFlagValue(
    FeatureFlag.ENABLE_SETTINGS_MENU_AND_COLOR_THEMES,
  );

  return (
    <AccountMenu
      {...props}
      picture={avatarByAccountIdData?.CloudUser.avatarUrl}
      onThemeChange={isThemeSwitcherEnabled ? toggleTheme : undefined}
      currentTheme={isThemeSwitcherEnabled ? themeState : undefined}
    />
  );
};

const AccountSwitcherFeatureFlag = (props: AccountMenuContainerProps) => {
  const isInProductAccountSwitchingEnabled = getFeatureFlagValue(
    FeatureFlag.ENABLE_INPRODUCT_SWITCHER,
  );
  return isInProductAccountSwitchingEnabled ? (
    <AccountMenuContainerWithAccountSwitcher {...props} />
  ) : (
    <AccountMenuContainer {...props} />
  );
};

export default connect(mapStateToProps)(AccountSwitcherFeatureFlag);
