import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import * as Styled from './Feedback.styled';
import { FuncType } from './types';
import {
  AnalyticsEventActions,
  AnalyticsEventSource,
} from '../AnalyticsContext';
import FeedbackDialogLazy from './lazy';
import { Flag } from '../../modules/flags-types';
import useAnalytics from '../AnalyticsContext/useAnalytics';
import useDialogs from '../Dialog/useDialogs';

export type FeedbackTriggerProps = {
  children?: (openFeedbackDialogFn: FuncType) => React.ReactNode;
  source: AnalyticsEventSource;
  addFlag?: (flag: Flag) => void;
};

const FeedbackTrigger = ({
  source = AnalyticsEventSource.USER_MENU,
  addFlag,
  children,
}: FeedbackTriggerProps) => {
  const { openDialog } = useDialogs();
  const analytics = useAnalytics();

  const openFeedbackDialog = () => {
    analytics.pushUIEvent({
      source,
      action: AnalyticsEventActions.CLICKED,
      actionSubject: 'sendFeedback',
    });

    openDialog(FeedbackDialogLazy, { addFlag });
  };

  const renderButton = () => (
    <>
      <FormattedMessage
        id="feedback.question"
        defaultMessage="Tell us about your experience with profiles and search within this directory."
        description="A description of feedback button in user and team profile pages."
      />
      <Styled.FeedbackButton onClick={openFeedbackDialog}>
        <FormattedMessage
          id="feedback.give-feedback"
          defaultMessage="Send Atlassian feedback"
          description='Opens a dialog for the user to enter and send feedback. Don‘t translate "Atlassian"'
        />
      </Styled.FeedbackButton>
    </>
  );

  // when passing children as a function, we use it to render Feedback trigger
  return typeof children === 'function' ? (
    <>{children(openFeedbackDialog)}</>
  ) : (
    renderButton()
  );
};

export default FeedbackTrigger;
