import React, { useLayoutEffect } from 'react';

import { browserMetrics } from '@atlassian/browser-metrics';
import { History } from 'history';

type BrowserMetricsBoundaryProps = {
  children: React.ReactNode;
  history: History;
};

export const BrowserMetricsBoundary = ({
  children,
  history,
}: BrowserMetricsBoundaryProps) => {
  const pathname = history.location.pathname;

  useLayoutEffect(() => {
    browserMetrics.startPageLoad();
  }, [pathname]);

  return <>{children}</>;
};
