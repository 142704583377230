import { fetchApi } from '../../../../utils/fetchApi';
import { getConfig } from '../../../config/envConfig';
const buildHeaderImageUrl = async (accountId: string): Promise<string> => {
  const { stargateRoot } = getConfig();
  const getUserHeaderImageToken = `${stargateRoot}/api/user-image/${accountId}/header-image/read`;
  const response = await fetchApi(getUserHeaderImageToken, {
    method: 'GET',
  });
  const { baseUrl, clientId, headerImageId, token } = await response.json();
  const params = new URLSearchParams({
    allowAnimated: 'false',
    width: '1500',
    mode: 'fit',
    upscale: 'false',
    token,
    client: clientId,
  });
  return `${baseUrl}/file/${headerImageId}/image?${params.toString()}`;
};
export default buildHeaderImageUrl;
