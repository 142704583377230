import window from 'window-or-global';

export type EnvType =
  | 'local'
  | 'test'
  | 'ddev'
  | 'fedex'
  | 'stg'
  | 'stg-fedm'
  | 'prod'
  | 'prod-fedm';

const inferEnvFromHostname = (hostname: string): EnvType | undefined => {
  if (hostname === 'localhost') {
    return 'local';
  }

  if (
    hostname.endsWith('.dev.internal.atlassian.com') ||
    hostname.endsWith('.dev.atlassian.com')
  ) {
    return 'ddev';
  }

  if (
    hostname.endsWith('.stg.internal.atlassian.com') ||
    hostname.endsWith('.stg.atlassian.com')
  ) {
    return 'stg';
  }

  if (hostname.endsWith('.atlassian-fex.com')) {
    return 'fedex';
  }

  if (hostname.endsWith('.stg.atlassian-us-gov-mod.com')) {
    return 'stg-fedm';
  }

  if (hostname.endsWith('.atlassian-us-gov-mod.com')) {
    return 'prod-fedm';
  }

  if (hostname.endsWith('.atlassian.com')) {
    return 'prod';
  }

  return undefined;
};

export function getEnv(): EnvType {
  try {
    const inferredEnv = inferEnvFromHostname(window.location.hostname);

    if (inferredEnv) {
      return inferredEnv;
    }
  } catch (e) {
    // No action, let it fall through to safe default
  }

  // Safe default:
  return 'prod';
}

export type Perimeter = 'commercial' | 'fedramp-moderate';

const perimeters: Record<EnvType, Perimeter> = {
  local: 'commercial',
  test: 'commercial',
  ddev: 'commercial',
  stg: 'commercial',
  fedex: 'commercial',
  'stg-fedm': 'fedramp-moderate',
  prod: 'commercial',
  'prod-fedm': 'fedramp-moderate',
};

export const currentPerimeter = perimeters[getEnv()];

export const isFedRamp = () => currentPerimeter.includes('fedramp');
