/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import { N0, N20A, N90 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { useImageState } from '../../use-image-state';

export type ProfilePictureProps = {
  testId?: string;
  src: string | undefined;
  size: number;
  className?: string;
};

export const ProfilePicture = ({
  testId,
  src,
  size,
  className,
}: ProfilePictureProps) => {
  const state = useImageState(src || '');

  const displayImage = src && state === 'loaded';
  const displayFallback = src && state === 'error';
  const displaySkeleton = !displayImage && !displayFallback;

  return (
    <div
      data-testid={testId}
      className={className}
      css={{
        width: size,
        height: size,
        ...(displaySkeleton && {
          borderRadius: '50%',
          backgroundColor: token('color.background.neutral', N20A),
        }),
        '& svg, & img': {
          width: size,
          height: size,
        },
      }}
    >
      {displayImage && (
        <img
          alt="display image"
          data-testid={testId && `${testId}--img`}
          src={src}
          css={{ display: 'block', borderRadius: '50%' }}
        />
      )}
      {displayFallback && (
        <UserAvatarCircleIcon
          testId={testId && `${testId}--fallback`}
          label=""
          primaryColor={token('color.text.subtlest', N90)}
          secondaryColor={token('color.text.inverse', N0)}
        />
      )}
    </div>
  );
};
