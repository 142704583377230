import styled from '@emotion/styled';

export interface BoxSizingStyleProps {
  value: 'border-box' | 'content-box';
}

export const BoxSizing = styled.div`
  && * {
    box-sizing: ${({ value }: BoxSizingStyleProps) => value};
  }
`;
