import styled from '@emotion/styled';

import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const SkeletonText = styled.span<{ length: string | number }>(
  {
    display: 'inline-block',
    height: '1em',
    borderRadius: '0.5em',
    backgroundColor: token('color.background.neutral', N20A),
  },
  ({ length }) => ({ width: length }),
);
