import React, {
  MutableRefObject,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import DocumentTitle from 'react-document-title';
import {
  useIntl,
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl-next';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import window from 'window-or-global';

import { AtlassianIcon } from '@atlaskit/logo';
import { CustomItem } from '@atlaskit/menu';
import {
  AtlassianNavigation,
  useOverflowStatus,
} from '@atlaskit/atlassian-navigation';
import { Content, Main, TopNavigation } from '@atlaskit/page-layout';
import Popup from '@atlaskit/popup';
import { ButtonProps } from '@atlaskit/button';

import '../../../../styled/core';
import UserSettingsFlagByHash from '../UserSettingsFlagByHash';
import { getManageProfileAppRootPath } from '../../../../utils/routes';
import { UserSettingsLayoutProps } from './types';
import * as Styled from './UserSettingsLayout.styled';
import AccountMenuContainer from '../../../../components/AccountMenu/AccountMenuContainer';
import Flags from '../../../../components/Flags';
import { HelpMenuPopup } from '../../../../components/HelpMenu';
import {
  DeviceContextConsumer,
  DeviceContextProvider,
} from '../../../../components/DeviceContext';
import { AtlassianSwitcherPopup } from '../../../../components/AtlassianSwitcherPopup';
import { ResponsivePageLayout } from '../../../../components/ResponsivePageLayout';
import {
  withAnalyticsContext,
  FeatureFlag,
  getFeatureFlagValue,
  useAnalytics,
} from '../../../../common';
import { AtlassianAccountLogo } from '../../../../components/AtlassianAccountLogo';

interface NavItem {
  message: MessageDescriptor;
  dropdownAriaLabel?: MessageDescriptor;
  path?: string;
  backMessage?: string;
  children?: NavItem[];
  featureFlags?: { [K in FeatureFlag]?: boolean };
}

const AtlassianAccountProductHome = withRouter(({ history }) => {
  const rootPath = getManageProfileAppRootPath();

  return (
    <Styled.StyledProductHome
      icon={AtlassianIcon}
      logo={AtlassianAccountLogo}
      onClick={event => {
        event.preventDefault();
        history.push(rootPath);
      }}
      href={rootPath}
    />
  );
});

const getHorizontalNavItems = (): NavItem[] => {
  const rootPath = getManageProfileAppRootPath();

  const tabs: NavItem[] = [
    {
      message: messages.profileAndVisibility,
      path: `${rootPath}/profile-and-visibility`,
    },
    {
      message: messages.email,
      path: `${rootPath}/email`,
    },
    {
      message: messages.security,
      path: `${rootPath}/security`,
      dropdownAriaLabel: messages.securityDropdownLabel,
    },
    {
      message: messages.privacy,
      path: `${rootPath}/privacy`,
    },
    {
      message: messages.accountPreferences,
      path: `${rootPath}/account-preferences`,
    },
    {
      message: messages.apps,
      path: `${rootPath}/apps`,
    },
    {
      message: messages.linkPreferences,
      path: `${rootPath}/link-preferences`,
    },
    {
      message: messages.products,
      path: `${rootPath}/products`,
    },
  ];

  return tabs;
};

const TabLink = ({ tab }: { tab: NavItem }) => {
  const {
    isVisible: isHorizontalVisible,
    closeOverflowMenu,
  } = useOverflowStatus();

  if (!isHorizontalVisible) {
    return tab.children ? (
      <SubMenuNavTab tab={tab} closeOverflow={closeOverflowMenu} />
    ) : (
      <CustomItem
        key={tab.path}
        onClick={() => closeOverflowMenu()}
        to={String(tab.path)}
        component={Link}
      >
        <FormattedMessage {...tab.message} />
      </CustomItem>
    );
  } else {
    return tab.children ? (
      <DropDownNavTab tab={tab} />
    ) : (
      <Styled.StyledButton
        href={String(tab.path)}
        component={RouterLink}
        key={tab.path}
        isHighlighted={window.location.pathname.startsWith(tab.path)}
      >
        <FormattedMessage {...tab.message} />
      </Styled.StyledButton>
    );
  }
};

const RouterLink = forwardRef<HTMLAnchorElement | null, ButtonProps>(
  function Component({ href = '', ...rest }, ref) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    return <Link {...rest} to={href} ref={ref} />;
  },
);

const SubMenuNavTab = ({
  tab,
  closeOverflow,
}: {
  tab: NavItem;
  closeOverflow(): void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const DropDownItems = tab.children?.map(child => {
    return (
      <CustomItem
        key={child.path}
        to={String(child.path)}
        component={Link}
        onClick={() => closeOverflow()}
      >
        <FormattedMessage {...child.message} />
      </CustomItem>
    );
  });

  return (
    <Popup
      placement="bottom"
      content={() => DropDownItems}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={({ ref, ...otherTriggerProps }) => {
        return (
          <Styled.SubMenuDropdownWrapper>
            <CustomItem
              key={tab.path}
              onClick={() => closeOverflow()}
              to={String(tab.path)}
              component={Link}
            >
              <FormattedMessage {...tab.message} />
            </CustomItem>
            <Styled.SubMenuPrimaryDropdownButton
              {...otherTriggerProps}
              onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
              ref={ref}
            />
          </Styled.SubMenuDropdownWrapper>
        );
      }}
    />
  );
};

const DropDownNavTab = ({ tab }: { tab: NavItem }) => {
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const isHighlighted = window.location.pathname.includes(tab.path);

  const DropDownItems = tab.children?.map(child => {
    return (
      <CustomItem
        key={child.path}
        onClick={() => {
          setIsOpen(prevIsOpen => {
            return !prevIsOpen;
          });
        }}
        to={String(child.path)}
        component={Link}
      >
        <FormattedMessage {...child.message} />
      </CustomItem>
    );
  });
  return (
    <Popup
      content={() => DropDownItems}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="top-start"
      trigger={({ ref, ...triggerProps }) => {
        return (
          <Styled.TabWithChildrenButtonsContainer
            ref={ref as MutableRefObject<HTMLDivElement>}
            isOpen={isOpen}
            isHighlighted={isHighlighted}
          >
            <Styled.StyledPrimaryButton
              href={String(tab.path)}
              component={RouterLink}
            >
              <FormattedMessage {...tab.message} />
            </Styled.StyledPrimaryButton>
            <Styled.StyledPrimaryDropdownButton
              aria-label={
                tab.dropdownAriaLabel
                  ? formatMessage(tab.dropdownAriaLabel)
                  : 'Dropdown menu'
              }
              onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
              {...triggerProps}
            />
            {isHighlighted && <Styled.IsHighlightedBar />}
          </Styled.TabWithChildrenButtonsContainer>
        );
      }}
    />
  );
};

interface HorizontalNavProps {
  children: React.ReactNode;
  isMobile: boolean;
}

const HorizontalNav = ({ children, isMobile }: HorizontalNavProps) => {
  const { formatMessage } = useIntl();
  const tabs = getHorizontalNavItems();
  return (
    <ResponsivePageLayout collapseSize={850} expandSize={1000}>
      <TopNavigation testId="HorizontalNavTopNavigation" height={60}>
        <AtlassianNavigation
          label={formatMessage({
            id: 'ptc-directory-ui.account-settings.horizontal.navigation.label',
            defaultMessage: 'manage profile menu',
            description: 'Horizontal navigation label.',
          })}
          primaryItems={tabs.map(tab => {
            const featureEnabled =
              // Enabled by default if no feature flags are set on the NavItem
              !tab.featureFlags ||
              Object.entries(tab?.featureFlags).every(
                ([featureFlagKey, featureFlagValue]: [FeatureFlag, boolean]) =>
                  getFeatureFlagValue(featureFlagKey) === featureFlagValue,
              );

            return featureEnabled && <TabLink key={tab.path} tab={tab} />;
          })}
          renderProductHome={AtlassianAccountProductHome}
          renderAppSwitcher={AtlassianSwitcherPopup}
          renderHelp={HelpMenuPopup}
          renderProfile={AccountMenuContainer}
          moreLabel={
            isMobile ? (
              <FormattedMessage
                id="ptc-directory-ui.account-settings.navigation.mobile.label"
                defaultMessage="Menu"
                description="Horizontal navigation label, when all menu items are hidden."
              />
            ) : (
              <FormattedMessage
                id="ptc-directory-ui.account-settings.navigation.label"
                defaultMessage="More"
                description="Horizontal navigation label, when some menu items are hidden."
              />
            )
          }
        />
      </TopNavigation>
      <Content testId="content">
        <Main testId="main">
          <Styled.MainSettings>{children}</Styled.MainSettings>
        </Main>
      </Content>
    </ResponsivePageLayout>
  );
};

export const UserSettingsLayout = ({
  children,
  getCurrentUser,
}: UserSettingsLayoutProps) => {
  const { formatMessage } = useIntl();
  const analytics = useAnalytics();
  useEffect(() => {
    // start to fetch user info as soon as possible and store data in redux-store
    getCurrentUser();
    // Initialize analytics client. Required for events to start sending.
    analytics.startUIViewedEvent();
  }, [getCurrentUser, analytics]);

  return (
    <DocumentTitle
      title={formatMessage({
        id: 'ptc-directory-ui.siteless-page-title',
        defaultMessage: 'Atlassian account',
        description: 'Title of the Atlassian account product.',
      })}
    >
      <DeviceContextProvider mobileWidthThreshold={472}>
        <Styled.Page>
          <DeviceContextConsumer>
            {({ isMobile }) => {
              return (
                <HorizontalNav isMobile={Boolean(isMobile)}>
                  {children}
                </HorizontalNav>
              );
            }}
          </DeviceContextConsumer>
          <Flags />
          <UserSettingsFlagByHash />
        </Styled.Page>
      </DeviceContextProvider>
    </DocumentTitle>
  );
};

const messages = defineMessages({
  email: {
    id: 'ptc-directory-ui.account-settings.item.email',
    defaultMessage: 'Email',
    description:
      'Navigation item label in the left side navigation bar that leads to the email settings screen',
  },
  profileAndVisibility: {
    id: 'ptc-directory-ui.account-settings.item.profile-and-visibility',
    defaultMessage: 'Profile and visibility',
    description:
      'Navigation item label in the left side navigation bar that leads to the profile and visibility settings screen',
  },
  products: {
    id: 'ptc-directory-ui.account-settings.item.product-settings',
    defaultMessage: 'Product settings',
    description:
      'Navigation item label in the left side navigation bar that leads to the products settings screen',
  },
  security: {
    id: 'ptc-directory-ui.account-settings.item.security',
    defaultMessage: 'Security',
    description:
      'Navigation item label in the left side navigation bar that leads to the security settings screen',
  },
  securityDropdownLabel: {
    id: 'ptc-directory-ui.account-settings.item.security-dropdown-label',
    defaultMessage: 'Security dropdown',
    description:
      'Navigation item label in the navigation bar that opens the dropdown menu to show more security settings',
  },
  securityBack: {
    id: 'ptc-directory-ui.account-settings.item.security.back',
    defaultMessage: 'Account settings',
    description:
      'Navigation back item label in the left side navigation bar that leads back to the security settings screen from a child page',
  },
  securityHeading: {
    id: 'ptc-directory-ui.account-settings.item.security.heading',
    defaultMessage: 'Security settings',
    description:
      'Heading for the left side navigation bar when on the security settings screen',
  },
  twoStepVerification: {
    id: 'ptc-directory-ui.account-settings.item.security.two-step-verification',
    defaultMessage: 'Two-step verification',
    description:
      'Navigation item label in the left side navigation bar that leads to the two step verification settings screen',
  },
  apiTokens: {
    id: 'ptc-directory-ui.account-settings.item.security.api-tokens',
    defaultMessage: 'API tokens',
    description:
      'Navigation item label in the left side navigation bar that leads to the two step verification settings screen',
  },
  recentDevices: {
    id: 'ptc-directory-ui.account-settings.item.security.recent-devices',
    defaultMessage: 'Recent devices',
    description:
      'Navigation item label in the left side navigation bar that leads to the two step verification settings screen',
  },
  privacy: {
    id: 'ptc-directory-ui.account-settings.item.privacy',
    defaultMessage: 'Privacy',
    description:
      'Navigation item label in the left side navigation bar that leads to the privacy settings screen',
  },
  accountPreferences: {
    id:
      'ptc-directory-ui.account-settings.item.account-preferences.preferences',
    defaultMessage: 'Account preferences',
    description:
      'Navigation item label in the left side navigation bar that leads to the account preferences settings screen',
  },
  apps: {
    id: 'ptc-directory-ui.account-settings.item.apps',
    defaultMessage: 'Connected apps',
    description:
      'Navigation item label in the left side navigation bar that leads to the connected apps settings screen',
  },
  linkPreferences: {
    id: 'ptc-directory-ui.account-settings.item.link-preferences',
    defaultMessage: 'Link preferences',
    description:
      'Navigation item label in the left side navigation bar that leads to the link preferences settings screen',
  },
});

export default withAnalyticsContext(UserSettingsLayout);
