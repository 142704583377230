import { FieldType } from '../../../common/model/user';
import { PrivacyLevel } from '../components/ProfileAndPrivacySettings/PrivacyLevelDropdown/types';

export enum UserProfilePrivacySetting {
  COLLABORATOR = 'COLLABORATOR',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface UserProfilePrivacyOptions {
  nickname: PrivacyLevel[] | null;
  name: PrivacyLevel[] | null;
  picture: PrivacyLevel[] | null;
  email: PrivacyLevel[] | null;
  location: PrivacyLevel[] | null;
  zoneinfo: PrivacyLevel[] | null;
  organization: PrivacyLevel[] | null;
  department: PrivacyLevel[] | null;
  jobTitle: PrivacyLevel[] | null;
  phoneNumber: PrivacyLevel[] | null;
}
export interface UserProfilePrivacy {
  nickname?: UserProfilePrivacySetting;
  name?: UserProfilePrivacySetting;
  picture?: UserProfilePrivacySetting;
  email?: UserProfilePrivacySetting;
  location?: UserProfilePrivacySetting;
  zoneinfo?: UserProfilePrivacySetting;
  organization?: UserProfilePrivacySetting;
  department?: UserProfilePrivacySetting;
  jobTitle?: UserProfilePrivacySetting;
  phoneNumber?: UserProfilePrivacySetting;
}

// this enum keys should be equal to users' FieldType enum keys in order to have correct mapping
export enum PrivacyItemType {
  FullName = 'name',
  Timezone = 'zoneinfo',
  CompanyName = 'organization',
  Title = 'jobTitle',
}

// This will defined a map of FieldType <=> PrivacyItemType convertion
// Not explicitly defined PrivacyItemType meand it's the same value as FieldType
export const FIELD_TO_PRIVACY_KEY = Object.keys(FieldType).reduce(
  (memo, key) => {
    memo[FieldType[key]] = PrivacyItemType[key] || FieldType[key];
    return memo;
  },
  {},
);
