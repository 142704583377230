/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Thai (Thailand)
export default {
  'focused-task-close-account.cancel': 'ยกเลิก',
  'focused-task-close-account.deactivate-account': 'ปิดใช้งานบัญชี',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'คุณกำลังปิดการใช้งานบัญชีของ:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'คุณกำลังปิดการใช้งานบัญชีของ:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'ปิดใช้งานบัญชี',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'ปิดใช้งานบัญชี',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'คุณสามารถเปิดใช้งานบัญชีอีกครั้งได้ทุกเมื่อ',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'คุณสามารถเปิดใช้งานบัญชีอีกครั้งได้ทุกเมื่อ',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'เมื่อคุณปิดใช้งานบัญชี:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'เมื่อคุณปิดใช้งานบัญชี:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'เราจะไม่เรียกเก็บเงินสำหรับบัญชีเหล่านั้นกับคุณอีกต่อไป',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'เราจะไม่เรียกเก็บเงินสำหรับบัญชีเหล่านั้นกับคุณอีกต่อไป',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} จะ<b>เสียสิทธิ์การเข้าถึง</b>บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน ผู้ใช้มีสิทธิ์เข้าถึง:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} จะ<b>เสียสิทธิ์การเข้าถึง</b>บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน ผู้ใช้ไม่มีสิทธิ์เข้าถึงบริการใด ๆ ยกเว้นบริการ เช่น ชุมชนและ Marketplace',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'บริการบัญชี Atlassian อื่น ๆ เช่น ชุมชน Atlassian และ Marketplace',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'คุณจะ<b>เสียสิทธิ์การเข้าถึง</b> บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน คุณมีสิทธิ์เข้าถึง:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'คุณจะ<b>เสียสิทธิ์การเข้าถึง</b> บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน คุณไม่มีสิทธิ์เข้าถึงบริการใด ๆ ยกเว้นบริการ เช่น ชุมชน และ Marketplace',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'ข้อมูลส่วนตัวของบัญชี เช่น ชื่อ นามสกุลและที่อยู่อีเมล หรือเนื้อหาที่สร้างขึ้นจะยังคงอยู่ในบริการบัญชี Atlassian',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'ข้อมูลส่วนตัวของบัญชี เช่น ชื่อ นามสกุลและที่อยู่อีเมล หรือเนื้อหาที่สร้างขึ้นจะยังคงอยู่ในบริการบัญชี Atlassian',
  'focused-task-close-account.delete-account': 'ลบบัญชี',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'หมายเหตุ: การตอบแบบสำรวจนี้จะช่วยให้เราปรับปรุงประสบการณ์สำหรับผู้ใช้ทุกคนให้ดียิ่งขึ้นได้ ผู้ใช้จะยังคงปรากฏเป็น "ผู้ใช้เดิม" หลังจากคุณลบบัญชีของพวกเขาแล้ว',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'หมายเหตุ: การตอบแบบสำรวจนี้จะช่วยให้เราปรับปรุงประสบการณ์สำหรับผู้ใช้ทุกคนให้ดียิ่งขึ้นได้ รวมถึงผู้ใช้ที่ลบบัญชีของตนออกไปแล้วด้วย คุณจะยังคงปรากฏเป็น "ผู้ใช้เดิม" หลังจากคุณลบบัญชีของคุณแล้ว',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'ผู้ใช้เดิม',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'คุณต้องการให้ผู้ใช้ที่ลบออกไปแล้วปรากฏขึ้นอย่างไร',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'คุณต้องการให้บัญชีที่ลบออกไปแล้วของคุณปรากฏขึ้นอย่างไร',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'หากเราให้ตัวเลือกแก่คุณ คุณจะต้องการให้ผู้ใช้ที่ลบออกไปแล้วปรากฏต่อผู้ใช้อื่นอย่างไร',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'หากเราให้ตัวเลือกแก่คุณ คุณจะต้องการปรากฏต่อผู้ใช้อื่นอย่างไร',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'หลังจากบัญชีของผู้ใช้ถูกลบแล้ว บัญชีเหล่านั้นจะปรากฏเป็น "ผู้ใช้เดิม" ให้ผู้ใช้อื่นได้เห็น โปรดสละเวลาสักครู่ในการตอบคำถามแบบสำรวจของเรา',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'หลังจากบัญชีของคุณถูกลบแล้ว คุณจะปรากฏเป็น "ผู้ใช้เดิม" ให้ผู้ใช้อื่นได้เห็น โปรดสละเวลาสักครู่ในการตอบคำถามแบบสำรวจของเรา',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'แสดงน้อยลง',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'มากกว่า {num}',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'คุณกำลังจะลบบัญชีของ:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'คุณกำลังจะลบบัญชีของคุณ:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'ลบบัญชี',
  'focused-task-close-account.delete-account.overview.heading.self': 'ลบบัญชีของคุณ',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'ตัวอย่างเช่น หน้าข้อมูล ปัญหา และความคิดเห็นต่าง ๆ ที่ผู้ใช้เหล่านั้นได้สร้างไว้ในผลิตภัณฑ์',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'ตัวอย่างเช่น หน้าข้อมูล ปัญหา และความคิดเห็นต่าง ๆ ที่คุณได้สร้างไว้ในผลิตภัณฑ์',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'คุณหรือผู้ใช้อื่นอาจได้ติดตั้งแอปที่เพิ่มคุณสมบัติต่าง ๆ ไปยังผลิตภัณฑ์ของ Atlassian ซึ่งแอปเหล่านี้อาจเก็บข้อมูลโปรไฟล์ของผู้ใช้',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'คุณหรือผู้ใช้อื่นอาจได้ติดตั้งแอปที่เพิ่มคุณสมบัติต่าง ๆ ไปยังผลิตภัณฑ์ของ Atlassian ซึ่งแอปเหล่านี้อาจเก็บข้อมูลโปรไฟล์ของคุณ',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'ข้อมูลเกี่ยวกับการซื้อขาย ซึ่งเราจำเป็นต้องเก็บไว้สำหรับการรายงานทางการเงิน',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'ข้อมูลเกี่ยวกับการซื้อขาย ซึ่งเราจำเป็นต้องเก็บไว้สำหรับการรายงานทางการเงิน',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'บันทึกที่แสดงให้เห็นว่าเราได้ลบบัญชีของผู้อื่นไป ซึ่งเราอาจจำเป็นต้องให้ข้อมูลแก่ผู้ควบคุม',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'บันทึกที่แสดงให้เห็นว่าเราได้ลบบัญชีของผู้อื่นไป ซึ่งเราอาจจำเป็นต้องให้ข้อมูลแก่ผู้ควบคุม',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'ข้อมูลที่เป็นส่วนหนึ่งของคดีความที่มีการฟ้องร้อง ซึ่งเราจำเป็นต้องเก็บไว้ตามกฎหมาย',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'ข้อมูลที่เป็นส่วนหนึ่งของคดีความที่มีการฟ้องร้อง ซึ่งเราจำเป็นต้องเก็บไว้ตามกฎหมาย',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'เราเก็บข้อมูลส่วนบุคคลไว้ในระยะเวลาที่จำกัดเมื่อเรามีธุรกิจที่ถูกต้องตามกฎหมายหรือมีจุดประสงค์ในเชิงกฎหมาย ตัวอย่างเช่น:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'เราเก็บข้อมูลส่วนบุคคลไว้ในระยะเวลาที่จำกัดเมื่อเรามีธุรกิจที่ถูกต้องตามกฎหมายหรือมีจุดประสงค์ในเชิงกฎหมาย ตัวอย่างเช่น:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'เราไม่ลบข้อมูลส่วนบุคคลใด ๆ จากเนื้อหาที่ผู้ใช้สร้างขึ้นเอง เช่น ชื่อหรือที่อยู่อีเมลที่ผู้ใช้พิมพ์ไว้ในหน้าเพจหรือปัญหา ผู้ดูแลผลิตภัณฑ์จำเป็นต้องลบข้อมูลนั้นด้วยตนเอง',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'เราไม่ลบข้อมูลส่วนบุคคลใด ๆ จากเนื้อหาที่คุณหรือผู้ใช้อื่นสร้างขึ้นเอง เช่น ชื่อหรือที่อยู่อีเมลที่คุณพิมพ์ไว้ในหน้าเพจหรือปัญหา ผู้ดูแลผลิตภัณฑ์ของคุณจำเป็นต้องลบข้อมูลนั้นด้วยตนเอง',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'ผู้ใช้มีสิทธิ์ส่งคำร้องไปยังหน่วยงานที่มีหน้าที่ในการกำกับดูแลในประเทศของตนได้',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'คุณมีสิทธิ์ส่งคำร้องไปยังหน่วยงานที่มีหน้าที่ในการกำกับดูแลในประเทศของคุณได้',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'เมื่อคุณลบบัญชี:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'เมื่อคุณลบบัญชีของคุณ:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'เนื้อหาที่ผู้ใช้สร้างขึ้นจะยังคงอยู่ในบริการของบัญชี Atlassian',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'เนื้อหาที่คุณสร้างขึ้นจะยังคงอยู่ในบริการของบัญชี Atlassian',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'หลังจากผ่านเวลาผ่อนผัน 14 วันแล้ว คุณจะไม่สามารถยกเลิกการลบบัญชีได้',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'เราจะส่งรายชื่อแอปที่อาจจัดเก็บข้อมูลส่วนบุคคลของผู้ใช้ไปให้คุณทางอีเมล',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'เราจะส่งรายชื่อแอปที่อาจจัดเก็บข้อมูลส่วนบุคคลของคุณไปให้คุณทางอีเมล',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} จะ<b>เสียสิทธิ์การเข้าถึง</b>บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน ผู้ใช้มีสิทธิ์เข้าถึง:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} จะ<b>เสียสิทธิ์การเข้าถึง</b>บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน ผู้ใช้ไม่มีสิทธิ์เข้าถึงบริการใด ๆ ยกเว้นบริการ เช่น ชุมชนและ Marketplace',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'บริการบัญชี Atlassian อื่น ๆ เช่น ชุมชน Atlassian และ Marketplace',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'คุณจะ<b>เสียสิทธิ์การเข้าถึง</b> บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน คุณมีสิทธิ์เข้าถึง:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'คุณจะ<b>เสียสิทธิ์การเข้าถึง</b> บริการของบัญชี Atlassian ทั้งหมดทันที โดยปัจจุบัน คุณไม่มีสิทธิ์เข้าถึงบริการใด ๆ ยกเว้นบริการ เช่น ชุมชน และ Marketplace',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'เราจะ<b>ลบข้อมูลส่วนบุคคลของผู้ใช้เหล่านั้น</b> เช่น ชื่อ นามสกุล และที่อยู่อีเมลของพวกเขาออกจากบริการบัญชี Atlassian ภายใน 30 วัน ยกเว้นในบางกรณีที่อาจจำเป็นต้องมีการเก็บไว้สำหรับธุรกิจที่ถูกต้องตามกฎหมายหรือมีจุดประสงค์ในเชิงกฎหมาย',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'เราจะ<b>ลบข้อมูลส่วนบุคคลของคุณ</b> เช่น ชื่อจริงและนามสกุล และที่อยู่อีเมลของคุณออกจากบริการบัญชี Atlassian ภายใน 30 วัน ยกเว้นในบางกรณีที่อาจจำเป็นต้องมีการเก็บไว้สำหรับธุรกิจที่ถูกต้องตามกฎหมายหรือมีจุดประสงค์ในเชิงกฎหมาย',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'หลังจากผ่านเวลาผ่อนผัน 14 วันแล้ว คุณจะไม่สามารถยกเลิกการลบบัญชีได้ หากคุณคิดว่าคุณอาจต้องการบัญชีในภายหลัง ให้ทำการยกเลิกการใช้งานแทนการลบ',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'หลังจากผ่านเวลาผ่อนผัน 14 วันแล้ว คุณจะไม่สามารถยกเลิกการลบบัญชีได้',
  'focused-task-close-account.learnMore': 'เรียนรู้เพิ่มเติม',
  'focused-task-close-account.next': 'ถัดไป',
  'focused-task-close-account.previous': 'ก่อนหน้า'
};