import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const dropDownWidth = 360;
// 360 dropDownWidth - 2 border - 24 padding - 24 icon - 8 icon margin
const dropDownLabelWidth = 304;

export const DropdownContainer = styled.div`
  max-width: ${dropDownWidth}px;
`;

export const Item = styled.div`
  display: flex;
`;

export const ItemIcon = styled.div`
  color: ${token('color.text.subtlest', colors.N200)};
  margin-right: ${token('space.100', '8px')};
`;

export const ItemTextWrapper = styled.div`
  min-width: 0;
`;

export const ItemLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${dropDownLabelWidth}px;
  line-height: ${token('space.300', '24px')};
`;

export const ItemDescription = styled.div`
  color: ${token('color.text.subtlest', colors.N200)};
  white-space: normal;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;
