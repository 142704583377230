import React from 'react';

import UserProfileAndVisibilitySettings from '../../components/UserProfileAndVisibilitySettings';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const SettingsProfileAndPrivacyRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_PROFILE_AND_VISIBILITY_SETTINGS} />
    <RecordTransition />
    <UserProfileAndVisibilitySettings />
  </>
);

export default SettingsProfileAndPrivacyRoute;
