// See Sentry accepted options in here https://docs.sentry.io/error-reporting/configuration/?platform=javascript
import { Severity } from '@sentry/types';
import type { Client, Options, Scope } from '@sentry/types';
import { EnvType } from '../config/env';

// See Sentry accepted options in here https://docs.sentry.io/error-reporting/configuration/?platform=javascript
interface SentryOptions extends Options {
  whitelistUrls?: (string | RegExp)[];
  ignoreUrls?: (string | RegExp)[];
}

interface SentryTags {
  [key: string]: string;
}

interface SentryClient extends Client {
  // some prop types I cannot find in `@sentry/types`
  init: (sentryOptions: SentryOptions) => SentryClient;
  Integrations: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GlobalHandlers: any;
  };
  withScope(callback: (scope: Scope) => void): void;
}

interface SentryInstallConfig {
  env: EnvType;
  version?: string;
  tags?: SentryTags;
  dsn: string;
}

// return value is an Sentry configuration object:
// https://docs.sentry.io/learn/configuration/?platform=javascript
export function getSentryConfig(options: SentryInstallConfig): SentryOptions {
  const { env, version, ...otherOptions } = options;
  return {
    ...otherOptions,
    debug: env !== 'prod',
    ignoreErrors: [
      /^SentryIgnore:/,
      // Network related errors
      'The operation was aborted',
      'Software caused connection abort',
      'GraphQL error: Failed to fetch',
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'AbortError: The user aborted a request.',
    ],
    ignoreUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    // VERSION comes from process.env and is set when building to deploy to production
    release: version || '---',
    environment: env || 'prod',
  };
}

let sentryInitialised = false;

export async function requireSentryLib(): Promise<SentryClient | undefined> {
  // saving ~40K
  try {
    // @ts-ignore
    return import(/* webpackChunkName: "ptc_sentry" */ '@sentry/browser');
  } catch (error) {
    console.warn(
      'Ignoring rejected promise when loading sentry resource.',
      error,
    );
  }
}

export async function installSentry(options: SentryInstallConfig) {
  if (sentryInitialised) {
    return;
  }

  const sentry = await requireSentryLib();
  if (!sentry) {
    return;
  }

  const finalSentryOption = getSentryConfig(options);
  sentry.init(finalSentryOption);

  sentryInitialised = true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Context = any & {
  /**
   * Used to indicate if this is associated with the Security page or not,
   * to decide whether this should alert People & Teams.
   */
  securityPage?: boolean;
  /**
   * Any tags to be added to the event.
   */
  tags?: SentryTags;
};

function decorateScope(scope: Scope, context: Context): Scope {
  const { securityPage, tags, ...otherContext } = context;

  if (tags) {
    scope.setTags(tags);
  }

  if (securityPage) {
    scope.setTag('page', 'security');
  }

  if (Object.keys(otherContext).length) {
    scope.setExtras(otherContext);
  }

  return scope;
}

export async function logException(
  ex: Error,
  name: string,
  context: Context = {},
) {
  if (console && console.error && process.env.NODE_ENV !== 'test') {
    console.error('Logged with Sentry:', ex);
  }

  const sentry = await requireSentryLib();
  if (!sentry || typeof sentry.captureException !== 'function') {
    return;
  }

  sentry.withScope(scope => {
    decorateScope(scope, context);

    scope.setTag('name', name);

    sentry.captureException(ex, undefined, scope);
  });
}

async function logMessage(
  severity: Severity,
  message: string,
  context: Context = {},
) {
  if (console && console.error && process.env.NODE_ENV !== 'test') {
    console.error('Logged with Sentry:', message);
  }

  const sentry = await requireSentryLib();
  if (!sentry || typeof sentry.captureException !== 'function') {
    return;
  }

  sentry.withScope(scope => {
    decorateScope(scope, context);
    sentry.captureMessage(message, severity, undefined, scope);
  });
}

export async function logErrorMessage(message: string, context: Context = {}) {
  await logMessage(Severity.Error, message, context);
}

export async function logInfoMessage(message: string, context: Context = {}) {
  await logMessage(Severity.Info, message, context);
}
