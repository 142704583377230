import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { ProfileAvatar, InlineHelpInfoIcon } from '../../../../../common';

import PrivacyLevelDropdown from '../PrivacyLevelDropdown';
import { PrivacyLevel } from '../PrivacyLevelDropdown/types';
import { AvatarAndHeaderImageSectionProps as Props } from './types';
import * as Styled from './AvatarAndHeaderImageSection.styled';
import { ProfileHeader } from '../../../../../common/components/ProfileHeader';
import { AvatarSize } from '../../../../../common/components/ProfileAvatar/types';
import { useMyAvatar } from '../../../../../common/components/ProfileAvatar/hooks/useMyAvatar';

export const AvatarSection = ({
  addFlag,
  onPrivacyLevelChange,
  privacyLevel,
  privacyLevelOptions,
  userId,
}: Props) => {
  const {
    myAvatar,
    isLoading: myAvatarIsLoading,
    isError: myAvatarIsError,
  } = useMyAvatar(userId);

  const definitelyNoPicture = myAvatar?.uploaded === false;
  const disabled =
    !myAvatarIsLoading && !myAvatarIsError && definitelyNoPicture;

  return (
    <Styled.AvatarSectionCard>
      <Styled.HeaderImageWrapper>
        <ProfileHeader
          userId={userId}
          height={112}
          addFlag={addFlag}
          hasRoundCorners
          isEditable
        />
      </Styled.HeaderImageWrapper>

      <Styled.AvatarWrapper>
        <ProfileAvatar
          size={AvatarSize.xlarge}
          userId={userId}
          addFlag={addFlag}
        />
      </Styled.AvatarWrapper>

      <Styled.ContentWrapper>
        <Styled.PrivacyControlsWrapper>
          <Styled.PrivacyControlsLabel>
            <FormattedMessage
              id="ptc-directory.profile-and-privacy-settings.avatar-and-header-image.who-can-see-your-profile-photo"
              defaultMessage="Who can see your profile photo?"
              description="A description for the dropdown that controls the privacy level of the userʼs avatar"
            />
            <InlineHelpInfoIcon
              appearance="dark"
              message={
                <FormattedMessage
                  id="ptc-directory.profile-and-privacy-settings.avatar-and-header-image.help-info"
                  defaultMessage="Your visibility setting only applies to your profile photo. Your header image is always visible to anyone."
                  description="Pop up information to ensure the user is aware that the privacy level does not affect their header image"
                />
              }
              position="bottom-end"
            />
          </Styled.PrivacyControlsLabel>

          <PrivacyLevelDropdown
            disabledMessage={
              disabled && (
                <FormattedMessage
                  id="ptc-directory.profile-and-privacy-settings.avatar-and-header-image.disabled"
                  defaultMessage="If you upload a profile picture, you’ll be able to select who can see it."
                  description="Pop up information to inform user that they cannot change the visibility of their profile picture as they do not have one uploaded"
                />
              )
            }
            options={privacyLevelOptions}
            privacyLevel={disabled ? PrivacyLevel.PUBLIC : privacyLevel}
            onChange={onPrivacyLevelChange}
          />
        </Styled.PrivacyControlsWrapper>
      </Styled.ContentWrapper>
    </Styled.AvatarSectionCard>
  );
};

export default AvatarSection;
