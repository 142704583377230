import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import {
  PrimaryButton,
  PrimaryDropdownButton,
  ProductHome,
} from '@atlaskit/atlassian-navigation';
import { token } from '@atlaskit/tokens';

export const profileGridSizeTimes = (n: number): number => 80 * n;

export const halfGutterSize = 20 as const;

export const Page = styled.div`
  max-height: 100vh;
  min-height: 100%;
`;

export const StyledProductHome = styled(ProductHome)`
  &:hover {
    background-color: ${token('color.background.neutral', colors.N30)};
  }
`;

export const MainSettings = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  min-height: 100%;
  max-width: ${profileGridSizeTimes(7.8)}px;

  margin: 0 auto;

  /**
 * Set a 20px horizontal padding to produce a full 40px between a
 * column's content and the edge of the page when added to the column's padding
 */
  padding: 0 ${halfGutterSize}px;

  padding-bottom: ${token('space.400', '32px')};
`;

export const StyledButton = styled(PrimaryButton)<{
  isHighlighted: boolean;
}>`
  color: ${props =>
    props.isHighlighted
      ? token('color.text.selected', colors.B400)
      : token(
          'color.text.subtle',
          colors.N800,
        )} !important; /* need important here to override :link and :visited styles from @atlaskit/button */

  &:hover {
    background-color: ${props =>
      props.isHighlighted
        ? token('color.background.selected', colors.B50)
        : token('color.background.neutral', colors.N30)}!important;
    color: ${props =>
      props.isHighlighted
        ? token('color.text.selected', colors.B400)
        : token('color.text.subtle', colors.N800)} !important;
  }
`;

export const TabWithChildrenButtonsContainer = styled.div<{
  isOpen: boolean;
  isHighlighted: boolean;
}>`
  display: flex;
  gap: 1px;
  position: relative;

  a,
  button {
    color: ${props =>
      props.isHighlighted || props.isOpen
        ? token('color.text.selected', colors.B400)
        : token(
            'color.text.subtle',
            colors.N800,
          )} !important; /* need important here to override :link and :visited styles from @atlaskit/button */
  }
  
  a:hover {
    text-decoration: none;
  }

  &:hover a,
  &:hover button {
    background-color: ${props =>
      props.isHighlighted || props.isOpen
        ? token('color.background.selected', colors.B50)
        : token('color.background.neutral', colors.N30)};
    color: ${props =>
      props.isHighlighted || props.isOpen
        ? token('color.text.selected', colors.B400)
        : token(
            'color.text.subtle',
            colors.N800,
          )} !important; /* need important here to override :link and :visited styles from @atlaskit/button */
  &:hover {
    background-color: ${props =>
      props.isHighlighted || props.isOpen
        ? token('color.background.selected.hovered', colors.B75)
        : token('color.background.neutral.hovered', colors.N40)};
    color: ${props =>
      props.isHighlighted || props.isOpen
        ? token('color.text.selected', colors.B400)
        : token(
            'color.text.subtle',
            colors.N800,
          )} !important; /* need important here to override :link and :visited styles from @atlaskit/button */

    &:active {
      background-color: ${props =>
        props.isHighlighted || props.isOpen
          ? token('color.background.selected.pressed', colors.B75)
          : token(
              'color.background.neutral.pressed',
              colors.N40,
            )} !important; /* need important here to override :link and :visited styles from @atlaskit/button */
      color: ${props =>
        props.isHighlighted || props.isOpen
          ? token('color.text.selected', colors.B400)
          : token(
              'color.text.subtle',
              colors.N800,
            )} !important; /* need important here to override :link and :visited styles from @atlaskit/button */
    }
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
`;

export const StyledPrimaryDropdownButton = styled(PrimaryDropdownButton)`
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 0 6px;

  span {
    opacity: 1;
  }
`;

export const SubMenuPrimaryDropdownButton = styled(PrimaryDropdownButton)`
  display: flex;
  right: 20px;

  span {
    span {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
`;

export const SubMenuDropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const IsHighlightedBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 4px;
  right: 4px;
  height: 3px;
  background-color: ${token('color.border.selected', colors.B400)};
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`;
