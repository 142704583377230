/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';

import { jsx } from '@emotion/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useCallbackWithAnalytics } from '@atlaskit/analytics-next';
import { Profile, ProfileProps } from '@atlaskit/atlassian-navigation';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';
import Popup, { PopupProps, TriggerProps } from '@atlaskit/popup';
import { gridSize } from '@atlaskit/theme/constants';

import { NAVIGATION_CHANNEL } from '../../analytics';
import { useImageState } from '../../use-image-state';
import type { AccountMenuProps } from '../account-menu';

export type AccountMenuPopupProps = Pick<
  AccountMenuProps,
  'testId' | 'picture' | 'email'
> &
  Pick<PopupProps, 'content'>;

export const AccountMenuPopup = ({
  testId,
  picture,
  email,
  content,
}: AccountMenuPopupProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallbackWithAnalytics(
    () => setIsOpen(false),
    {
      eventType: UI_EVENT_TYPE,
      action: 'closed',
      actionSubject: 'popup',
      actionSubjectId: 'accountMenu',
    },
    NAVIGATION_CHANNEL,
  );
  const open = useCallbackWithAnalytics(
    () => setIsOpen(true),
    {
      eventType: UI_EVENT_TYPE,
      action: 'opened',
      actionSubject: 'popup',
      actionSubjectId: 'accountMenu',
    },
    NAVIGATION_CHANNEL,
  );

  return (
    <Popup
      isOpen={isOpen}
      onClose={close}
      testId={testId}
      placement="bottom"
      trigger={triggerProps => (
        <AccountMenuTrigger
          onClick={isOpen ? close : open}
          testId={testId && `${testId}--trigger`}
          picture={picture}
          email={email}
          {...triggerProps}
        />
      )}
      content={content}
    />
  );
};

type AccountMenuTriggerProps = 
  Pick<ProfileProps, 'onClick' | 'testId'> & 
  TriggerProps & 
  {
    picture: string | undefined;
    email: string | undefined;
  }

const AccountMenuTrigger = React.forwardRef<HTMLElement, AccountMenuTriggerProps>(({ picture, email, ...props }, ref) => {
  const { formatMessage } = useIntl();
  const state = useImageState(picture || '');

  const tooltip = formatMessage(messages.triggerTooltip);

  return (
    <Profile
      {...props}
      ref={ref}
      tooltip={email || tooltip}
      icon={
        picture && state === 'loaded' ? (
          <img
            css={{
              borderRadius: '50%',
              width: gridSize() * 3,
              height: gridSize() * 3,
            }}
            src={picture}
            alt={tooltip}
          />
        ) : (
          <UserAvatarCircleIcon label={tooltip} size="medium" />
        )
      }
    />
  );
});

const messages = defineMessages({
  triggerTooltip: {
    id: 'identity.account-menu.account-menu-popup.skeleton.tooltip',
    defaultMessage: 'Account',
    description: 'Tooltip and alt-text for the account menu item',
  },
});
