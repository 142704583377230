import React, { useCallback, useRef, useState } from 'react';
import InlineDialog from '@atlaskit/inline-dialog';
import InlineEdit from '@atlaskit/inline-edit';
import Tooltip from '@atlaskit/tooltip';

import InlineHelpInfoIcon from '../InlineHelpInfoIcon';

import { ProfileDetailsInlineEditProps } from './types';
import { TextEditView } from './EditViewTypes';

import * as Styled from './ProfileDetailsInlineEdit.styled';

export const hasValueChanged = (previousValue, currentValue) => {
  if (previousValue === null && currentValue === '') {
    // Empty fields are coming in as `null` from GraphQl but are
    // empty strings when returned from the inline edit fields.
    // They're considered unchanged.
    return false;
  }
  return previousValue !== currentValue;
};

const ProfileDetailsInlineEdit = ({
  icon,
  infoHelperMessage,
  inlineDialogContent = null,
  inlineDialogContentPosition,
  isReadOnly,
  isRequired,
  label,
  onSubmit = () => null,
  placeholder,
  readStyle = {},
  readValue,
  requiredInvalidMessage,
  toolTipContent = null,
  value,
}: ProfileDetailsInlineEditProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const leavingInlineDialogTimeout = useRef<number>();

  /* Select all text in the field on focus */
  const handleEditFocus = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const inputRef = e.currentTarget;
      inputRef.setSelectionRange(0, inputRef.value.length);
    },
    [],
  );

  const handleConfirm = useCallback(
    (newValue: string) => {
      // FIXME: Value is still null sometimes
      const trimmedValue = (newValue || '').trim();

      if (isRequired && !trimmedValue) {
        return;
      }
      if (hasValueChanged(value, trimmedValue)) {
        onSubmit(trimmedValue);
      }
    },
    [isRequired, onSubmit, value],
  );

  const handleReadViewMouseEnter = () => {
    clearTimeout(leavingInlineDialogTimeout.current);
    setIsHovering(true);
  };

  const handleReadViewMouseLeave = () => {
    leavingInlineDialogTimeout.current = window.setTimeout(() => {
      setIsHovering(false);
    }, 150);
  };

  const interactionProps = {
    onMouseEnter: handleReadViewMouseEnter,
    onMouseLeave: handleReadViewMouseLeave,
    onFocus: handleReadViewMouseEnter,
    onBlur: handleReadViewMouseLeave,
  };

  const getEditView = fieldProps => {
    return (
      <TextEditView
        placeholder={placeholder || ''}
        {...fieldProps}
        onFocus={handleEditFocus}
      />
    );
  };

  const getReadOnlyView = () => {
    return (
      <Styled.ReadOnlyViewWrapper data-testid="read-only-view" isEmpty={!value}>
        {readValue || value || placeholder}
      </Styled.ReadOnlyViewWrapper>
    );
  };

  const getReadView = () => {
    return (
      <Styled.ReadViewWrapper
        isEmpty={!value}
        style={readStyle}
        data-test-selector="profile-about-item-read-view"
      >
        {readValue || value || placeholder}
      </Styled.ReadViewWrapper>
    );
  };

  const renderFieldLabel = () => {
    return (
      <Styled.Label>
        <Styled.LabelText>{label}</Styled.LabelText>
        {infoHelperMessage && (
          <InlineHelpInfoIcon appearance="dark" message={infoHelperMessage} />
        )}
      </Styled.Label>
    );
  };

  const renderInlineEdit = (hasToolTip = false) => {
    const fieldName = (label || '').toLowerCase().replace(/ /g, '-');

    return (
      <Styled.InlineEditWrapper
        data-test-selector={`field-wrapper-${fieldName}`}
      >
        {icon && <Styled.IconWrapper>{icon}</Styled.IconWrapper>}
        <div data-test-selector={`field-edit-${fieldName}`}>
          {renderEditor(hasToolTip)}
        </div>
      </Styled.InlineEditWrapper>
    );
  };

  const renderEditor = (hasToolTip = false) => {
    if (isReadOnly) {
      return (
        <Styled.InlineEditInnerWrapper>
          {label && renderFieldLabel()}
          {getReadOnlyView()}
        </Styled.InlineEditInnerWrapper>
      );
    }

    const tooltip = hasToolTip ? toolTipContent : null;

    return (
      <Tooltip position="right" content={tooltip}>
        <InlineEdit
          label={renderFieldLabel()}
          readViewFitContainerWidth
          defaultValue={value || ''}
          editView={getEditView}
          readView={getReadView}
          onConfirm={handleConfirm}
          validate={validateInput}
        />
      </Tooltip>
    );
  };

  const validateInput = (value: string): string | undefined => {
    if (!value && isRequired) {
      return requiredInvalidMessage;
    }
  };

  if (inlineDialogContent) {
    // when user is hovering out of current field to hover into its associated inline dialog,
    // we want the inline-dialog still appears
    const content = <div {...interactionProps}>{inlineDialogContent}</div>;

    return (
      <InlineDialog
        content={content}
        placement={inlineDialogContentPosition || 'right'}
        isOpen={isHovering}
      >
        <Styled.InlineDialogDisabledTrigger
          data-test-selector="interaction-wrapper"
          {...interactionProps}
        >
          {renderInlineEdit()}
        </Styled.InlineDialogDisabledTrigger>
      </InlineDialog>
    );
  } else {
    if (isReadOnly || !toolTipContent) {
      return renderInlineEdit();
    }

    return renderInlineEdit(true);
  }
};

export default ProfileDetailsInlineEdit;
