import React from 'react';

import Drawer from '@atlaskit/drawer';
import { AtlassianIcon } from '@atlaskit/logo/atlassian-icon';

import * as Styled from './FocusedTask.styled';

export type Props = {
  onClose?: () => void;
  isOpen: boolean;
  children: React.ReactNode;
};

// Make icon non-interactive
const focusedTaskOverrides = {
  Sidebar: {
    cssFn: defaultStyles => ({
      ...defaultStyles,
      '& > button': {
        pointerEvents: 'none',
      },
    }),
  },
};

const FocusedTask: React.ComponentType<Props> = ({
  onClose,
  isOpen,
  children,
}) => (
  <Drawer
    onClose={onClose}
    isOpen={isOpen}
    icon={AtlassianIcon}
    width="full"
    overrides={focusedTaskOverrides}
  >
    <Styled.FocusedTaskContent>{children}</Styled.FocusedTaskContent>
  </Drawer>
);

export default FocusedTask;
