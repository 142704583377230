// Extending new custom Error types with `Object.setPrototypeOf`
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/setPrototypeOf

class CommonError extends Error {
  message: string;
  name: string;
  stack: string;
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.stack = new Error(message).stack || '';
  }
}

export class DefaultError extends CommonError {
  constructor({ message }) {
    super(message || 'UnknownError');
    Object.setPrototypeOf(this, DefaultError.prototype);
  }
}

export class SentryIgnoreError extends CommonError {
  constructor({ message }) {
    super(`SentryIgnore: ${message || 'UnknownError'}`);
    Object.setPrototypeOf(this, SentryIgnoreError.prototype);
  }
}

// Http Errors
export class HttpError extends CommonError {
  status: number;

  constructor({ message, status }) {
    super(message);
    Object.setPrototypeOf(this, HttpError.prototype);

    this.status = status;
  }
}

// Graphql Errors
interface ResultErrorData {
  category: string;
  message: string;
  fields?: object;
}

interface FieldError {
  message: string;
  field: string;
}
interface ErrorData {
  category: string;
  message: string;
  fields?: FieldError[];
}

export class GraphQLError extends CommonError {
  fields?: any; // tslint:disable-line no-any
  category?: string;

  constructor({
    message,
    category = 'default',
    fields,
  }: Partial<ResultErrorData>) {
    super(message);
    Object.setPrototypeOf(this, GraphQLError.prototype);

    this.category = category;

    if (fields) {
      this.fields = fields;
    }
  }

  static from = (rawErrors: ErrorData[]) => {
    const firstError = rawErrors[0];

    const errorData: ResultErrorData = {
      category: firstError.category,
      message: firstError.message,
    };

    if (firstError.fields) {
      errorData.fields = firstError.fields.reduce((obj, item) => {
        obj[item.field] = item.message;
        return obj;
      }, {});
    }

    return new GraphQLError(errorData);
  };
}
