import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

export type Timezone = {
  label: string;
  value: {
    offset: string;
    zone: string;
  };
};

const reformatTimezoneToOption = (zone: string): Timezone => {
  const momentOffset = moment.tz(new Date(), zone).format('Z') as string;
  return {
    label: zone.replace(/_/g, ' ').replace(/\bKiev\b/g, 'Kyiv'),
    value: {
      offset: momentOffset,
      zone: zone,
    },
  };
};

export const timezoneOptions: Timezone[] = (moment.tz.names() as string[])
  .filter(zone => {
    const lowerZone = zone.toLowerCase();
    return !(
      ['est', 'mst', 'hst', 'roc'].includes(lowerZone) ||
      // We replace Europe/Kiev with Europe/Kyiv in the UI because backends don't support Europ/Kyiv yet
      lowerZone === 'europe/kyiv' ||
      lowerZone.startsWith('etc/') ||
      lowerZone.startsWith('gmt')
    );
  })
  .map(zone => reformatTimezoneToOption(zone));
