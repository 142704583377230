import { Flag } from '../../../../common';
export { FieldType } from '../../../../common';

export type ConnectedProps = {
  userId: string;
  isManaged: boolean;
};

export type ConnectedDispatchProps = {
  addFlag: (flag: Flag) => void;
};
