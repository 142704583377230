import React from 'react';
type FlagType = 'error' | 'info' | 'normal' | 'success' | 'warning';

interface FlagLink {
  text: string;
  href?: string;
  to?: string;
  target?: string;
}

export interface Flag {
  id: number | string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  type?: FlagType;
  links?: FlagLink[];
  appearance?: string;
  actions?: {
    content: React.ReactNode;
    onClick?: () => void;
    href?: string;
  }[];
}
