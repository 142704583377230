import { connect } from 'react-redux';
import UserSettingsLayout from './UserSettingsLayout';
import { getCurrentUser, RootState } from '../../../../common';

const mapDispatchToProps = {
  getCurrentUser,
};

const mapStateToProps = ({ user }: RootState) => {
  return {
    userId: user.data.accountId,
    name: user.data.fullName,
    email: user.data.email,
    picture: user.data.picture,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsLayout);
