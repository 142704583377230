import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var getIconButtonTheme = function getIconButtonTheme(_ref) {
  var iconButton = _ref.mode.iconButton;
  return function (current, props) {
    var _current = current(props),
      buttonStyles = _current.buttonStyles,
      spinnerStyles = _current.spinnerStyles;
    return {
      buttonStyles: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, buttonStyles), {}, {
        borderRadius: "var(--ds-border-radius-circle, 100%)",
        display: 'flex',
        margin: "0 ".concat("var(--ds-space-025, 2px)"),
        // TODO Delete this comment after verifying space token -> previous value `4`
        padding: "var(--ds-space-050, 4px)",
        height: 'auto',
        fontWeight: 500
      }, iconButton.default), {}, {
        ':hover': iconButton.hover,
        ':focus': iconButton.focus,
        // :active doesn't work in FF, because we do a
        // e.preventDefault() on mouse down in Button.
        // '&&' is required to add more CSS specificity
        // && it not a valid CSSObject property
        // @ts-ignore
        '&&': _objectSpread({}, props.state === 'active' && iconButton.active)
      }, props.state === 'selected' && iconButton.selected), {}, {
        '> span': {
          margin: 0
        }
      }),
      spinnerStyles: spinnerStyles
    };
  };
};