import { Flag } from './flags-types';

export const FEEDBACK_COLLECTOR_SUCCESS_FLAG_ID = 'feedbackSent';

interface AddFlagAction {
  type: 'ADD_FLAG';
  payload: Flag;
}

export interface FlagsState {
  flags: Flag[];
}

// ------------------------------------
// Reducer
// ------------------------------------

const REMOVE_FLAG = 'REMOVE_FLAG';
export const ADD_FLAG = 'ADD_FLAG';

const initialState: FlagsState = {
  flags: [],
};

export function addFlag(flag: Flag): AddFlagAction {
  return {
    type: ADD_FLAG,
    payload: flag,
  };
}

export function removeFlag(flagId) {
  return {
    type: REMOVE_FLAG,
    payload: {
      flagId,
    },
  };
}

export default function flagsReducer(state: FlagsState = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REMOVE_FLAG: {
      const newFlags = state.flags.filter(
        flag => flag && flag.id !== payload.flagId,
      );

      return {
        ...state,
        flags: newFlags,
      };
    }
    case ADD_FLAG: {
      let flagId = payload.id;

      // do not auto add random id to Feedback Flag
      if (flagId !== FEEDBACK_COLLECTOR_SUCCESS_FLAG_ID) {
        flagId = payload.id ? `${payload.id}.${Date.now()}` : Date.now();
      }

      const newFlag = {
        ...payload,
        id: flagId,
      };

      return {
        ...state,
        flags: [newFlag, ...state.flags],
      };
    }
    default:
      return state;
  }
}
