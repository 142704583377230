import React from 'react';
import {
  identityUserClient,
  identityAvailableProductsClient,
} from '../../../../common';
import Request from '../Request';
import CloseAccountFlowTrigger from './CloseAccountFlowTrigger';

export default () => (
  <Request request={identityUserClient.deleteUser}>
    {({ loading: isOnCompleteLoading, error }, deleteUser) => (
      <Request request={identityAvailableProductsClient.getAvailableSites}>
        {({ data: sitesData }, getAvailableSites) => (
          <CloseAccountFlowTrigger
            getAccessibleSites={getAvailableSites}
            accessibleSites={sitesData}
            isOnDeleteUserLoading={isOnCompleteLoading}
            deleteUser={deleteUser}
            onDeleteError={error}
          />
        )}
      </Request>
    )}
  </Request>
);
