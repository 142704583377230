import { AbstractAccountClient } from './AbstractAccountClient';
import type { Config, User, UserListener } from './types';

export class PreloadedAccountClient extends AbstractAccountClient {
  /** @override */
  readonly currentUser: User | null | undefined;

  constructor(config: Config, user: User | null) {
    super(config);
    this.currentUser = user;
  }

  /** @override */
  addUserListener(this: this, callback: UserListener): () => void {
    // Doesn't do anything yet
    return () => {};
  }

  /** @override */
  async switchAccount(): Promise<void> {}

  /** @override */
  async getRecentAssociatedSessions() {
    return [];
  }
}
