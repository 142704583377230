/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Japanese
export default {
  'focused-task-close-account.cancel': 'キャンセル',
  'focused-task-close-account.deactivate-account': 'アカウントの無効化',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': '次のユーザー アカウントを無効化しようとしています:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': '次のユーザー アカウントを無効化しようとしています:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'アカウントの無効化',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'アカウントの無効化',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'アカウントはいつでも再有効化できます。',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'アカウントはいつでも再有効化できます。',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'アカウントを無効化すると:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'アカウントを無効化すると:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'このユーザーに対する請求は発生しなくなります。',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'このユーザーに対する請求は発生しなくなります。',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} さんはアトラシアンのすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。現在アクセス権限のあるサービス:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} さんはアトラシアンのすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。現在、コミュニティや Marketplace などのサービスを除いて、アクセス権限はありません。',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'アトラシアン コミュニティや Marketplace など、その他の Atlassian アカウント サービス。',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Atlassian のすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。現在アクセス権限のあるサービス:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Atlassian のすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。現在、コミュニティや Marketplace などのサービスを除いて、アクセス権限はありません。',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': '氏名やメール アドレスなどの個人情報および作成したコンテンツは Atlassian アカウントのサービスから削除されません。',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': '氏名やメール アドレスなどの個人情報および作成したコンテンツは Atlassian アカウントのサービスから削除されません。',
  'focused-task-close-account.delete-account': 'アカウントの削除',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': '注: このアンケートへの回答は、すべてのユーザーの体験を向上するために役立ちます。アカウントを削除した後、ユーザーは「旧ユーザー」として表示されます。',
  'focused-task-close-account.delete-account.content-preview.footnote.self': '注: このアンケートへの回答は、アカウントを削除しようとしているユーザーを含むすべてのユーザー体験の向上に役立ちます。アカウントを削除した後、あなたは「旧ユーザー」として表示されます。',
  'focused-task-close-account.delete-account.content-preview.formerUser': '旧ユーザー',
  'focused-task-close-account.delete-account.content-preview.heading.admin': '削除されたユーザーはどのように表示されたらいいと思いますか?',
  'focused-task-close-account.delete-account.content-preview.heading.self': '削除されたアカウントはどのように表示されたらいいと思いますか?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': '選択肢があるとしたら、削除されたユーザーが他のユーザーに対してどのように表示されることを希望しますか?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': '選択肢があるとしたら、他のユーザーにどのように表示されることを希望しますか?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'アカウントが削除されると、そのユーザーは他のユーザーの画面で「旧ユーザー」として表示されます。この点について、以下のアンケートにお答えください。',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'アカウントが削除されると、あなたは他のユーザーの画面で「旧ユーザー」として表示されます。この点について、以下のアンケートにお答えください。',
  'focused-task-close-account.delete-account.drop-down-collapse-button': '省略表示',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'あと {num} 個',
  'focused-task-close-account.delete-account.overview.first.line.admin': '次のアカウントを削除しようとしています:',
  'focused-task-close-account.delete-account.overview.first.line.self': '次のアカウントを削除しようとしています:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'アカウントの削除',
  'focused-task-close-account.delete-account.overview.heading.self': 'アカウントの削除',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': '製品で作成したページ、課題、コメントなど。',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': '製品で作成したページ、課題、コメントなど。',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'あなた、または他のユーザーがアトラシアン製品に機能を追加するアプリケーションをインストールしている可能性があります。これらのアプリはユーザーのプロファイル情報を保存している可能性があります。',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'あなた、または他のユーザーがアトラシアン製品に機能を追加するアプリケーションをインストールしている可能性があります。これらのアプリはプロファイル情報を保存している可能性があります。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': '財務報告のために保管する必要がある購入に関する情報。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': '財務報告のために保管する必要がある購入に関する情報。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'アカウントの削除を示す記録。規制当局へ提出する必要がある場合があります。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'アカウントの削除を示す記録。規制当局へ提出する必要がある場合があります。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': '進行中の訴訟の一部であり、法律上、保存する必要のあるデータ。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': '進行中の訴訟の一部であり、法律上、保存する必要のあるデータ。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': '正当な業務上または法律上の目的で、個人データを限られた期間保管します。例:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': '正当な業務上または法律上の目的で、個人情報を限られた期間保管します。例:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'ページや課題に入力された名前やメール アドレスなど、ユーザーが作成したコンテンツから個人情報が削除されることはありません。これらの個人情報は製品管理者が手動で削除する必要があります。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'ページや課題に入力された名前やメール アドレスなど、あなたや他の人が作成したコンテンツから個人情報が削除されることはありません。これらの個人情報は製品管理者が手動で削除する必要があります。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'ユーザーは自国の監督当局に苦情を提起する権利を有しています。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'お客様はお住まいの地域の監督当局に苦情を提起する権利を有しています。',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'アカウントを削除すると:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'アカウントを削除すると:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': '作成したコンテンツは Atlassian アカウント サービスから削除されません。',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': '作成したコンテンツは Atlassian アカウント サービスから削除されません。',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': '14 日間の猶予期間後は、アカウントの削除を取り消すことはできません。',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': '個人情報を保存している可能性のあるアプリの一覧をメールでお知らせします。',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': '個人情報を保存している可能性のあるアプリの一覧をメールでお知らせします。',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} さんは Atlassian のすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。現在アクセス権限のあるサービス:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} さんは Atlassian のすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。現在、コミュニティや Marketplace などのサービスを除いて、アクセス権限はありません。',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'アトラシアン コミュニティや Marketplace など、その他の Atlassian アカウント サービス。',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'アトラシアンのすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。 現在アクセス権限のあるサービス:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'アトラシアンのすべてのアカウント サービスへの<b>アクセス権限を直ちに失います</b>。現在、コミュニティや Marketplace などのサービスを除いて、アクセス権限はありません。',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': '正当な業務上または法律上の目的で必要とされる場合を除き、30 日以内に Atlassian アカウント サービスから氏名やメール アドレスなどの<b>個人情報は削除</b>されます。',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': '正当な業務上または法律上の目的で必要とされる場合を除き、30 日以内に Atlassian アカウント サービスから氏名やメール アドレスなどの<b>個人情報は削除</b>されます。',
  'focused-task-close-account.delete-account.overview.warning-section.body': '14 日間の猶予期間後は、アカウントの削除を取り消すことはできません。後でアカウントが必要になると思われる場合は、代わりにアカウントを無効にしてください。',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': '14 日間の猶予期間後は、アカウントの削除を取り消すことはできません。',
  'focused-task-close-account.learnMore': '詳細',
  'focused-task-close-account.next': '次へ',
  'focused-task-close-account.previous': '戻る'
};