/**
 * Use this component whenever you would need <a> or <Link>.
 * Use with `href` property to create an <a> tag and with `to` property
 * to create a <Link> tag.
 *
 * With using this component for all link elements we make sure links have
 * the proper hover and focus styles. Atlaskit/button with link appearance
 * does not have a correct focus style and is harder to deal with as it might
 * need additional props like spacing depending on where it's used.
 */

import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { StyledProps } from './Anchor.types';

const getColor = (props: StyledProps) =>
  props.appearance === 'subtle'
    ? token('color.text.subtlest', colors.N200)
    : token('color.link', colors.B400);

const getHoverColor = (props: StyledProps) =>
  props.appearance === 'subtle'
    ? token('color.text.subtle', colors.N90)
    : token('color.link', colors.B300);

export const getStyledAnchor = component => styled(component)`
  color: ${(props: StyledProps) => getColor(props)};

  &&:hover {
    color: ${(props: StyledProps) => getHoverColor(props)};
    text-decoration: underline;
  }

  &&:focus {
    outline: 2px solid ${token('color.border.focused', colors.B100)};
    outline-offset: 2px;
  }

  &&:active {
    outline: none;
    text-decoration: none;
  }
`;
