import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../../../utils/fetchApi';
import {
  fromManageAPIResponse,
  IdentityProfileResponse,
} from '../../../utils/user';
import { getConfig } from '../../../common';

export const ManageProfileUserQueryKey = 'ManageProfileUser';

type ManageProfileUserQueryFnProps = {
  userId: string;
  stargateRoot: string;
};

const manageProfileUserQueryFn = async ({
  userId,
  stargateRoot,
}: ManageProfileUserQueryFnProps) => {
  const url = `${stargateRoot}/users/${userId}/manage/profile`;
  const response = await fetchApi(url);
  const data = (await response.json()) as IdentityProfileResponse;
  return fromManageAPIResponse(data);
};

export const useManageProfileUser = (userId: string) => {
  const { stargateRoot } = getConfig();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [ManageProfileUserQueryKey, userId],
    queryFn: () => manageProfileUserQueryFn({ userId, stargateRoot }),
    enabled: !!userId,
  });

  return {
    user: data,
    isLoading,
    isError,
    error,
  };
};
