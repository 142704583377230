import window from 'window-or-global';
import { EnvType, getEnv } from './env';

interface Config {
  stargateRoot: string;
  mediaApiClientId: string;
  analytics: {
    scheme: string;
    server: string;
  };
  identityProxyUrl: string;
  feedbackIssueCollectorId: string;
  emailPreferencesUrl: string;
  atlassianAccountUrl: string;
  permsServiceUrl: string;
  mpacRoot: string;
  haveISeenItUrl: string;
  featureGateInitCode: string;
}

// default configs is also config for production env
const defaultConfig: Config = {
  stargateRoot: '/gateway/api',
  mediaApiClientId: 'fad17e2e-9fbd-427c-b7eb-acabf78c258f',
  analytics: {
    scheme: 'https',
    server: 'analytics.atlassian.com',
  },
  identityProxyUrl: '/manage/rest',
  feedbackIssueCollectorId: '908df207',
  emailPreferencesUrl: '/gateway/api/marketing/email-preferences',
  atlassianAccountUrl: 'https://id.atlassian.com',
  permsServiceUrl: '/gateway/api/permissions',
  mpacRoot: 'https://marketplace.atlassian.com',
  haveISeenItUrl: '/gateway/api/flag/my',
  featureGateInitCode: '85b8609a-422f-45b6-9f02-e143340a831e',
};

const stagingOverrides: Partial<Config> = {
  mediaApiClientId: '9650cd51-5e82-48e6-b5bd-e9ee499f3b80',
  feedbackIssueCollectorId: 'a0d6de4d', // david test collector
  atlassianAccountUrl: 'https://id.stg.internal.atlassian.com',
  mpacRoot: 'https://marketplace.stg.internal.atlassian.com',
  featureGateInitCode: 'fafc04ef-8b74-40f6-8049-dcc52b765447',
};

const localAndTestOverrides = {
  stargateRoot: 'https://id.stg.internal.atlassian.com/gateway/api',
  emailPreferencesUrl:
    'https://id.stg.internal.atlassian.com/gateway/api/marketing/email-preferences',
  permsServiceUrl:
    'https://id.stg.internal.atlassian.com/gateway/api/permissions',
  atlassianAccountUrl: 'https://id.stg.internal.atlassian.com',
  mpacRoot: 'https://marketplace.stg.internal.atlassian.com',
  // use same mock data as siteDirectory app
  identityProxyUrl: '/profile-rest',
  featureGateInitCode: 'ff4cf059-97be-4751-beb1-da318e29cbcc',
};

const environmentOverrides: Record<
  Exclude<EnvType, 'prod'>,
  Partial<Config>
> = {
  local: {
    ...localAndTestOverrides,
    mediaApiClientId: '9650cd51-5e82-48e6-b5bd-e9ee499f3b80',
    analytics: {
      scheme: 'http',
      server: `${window.location.hostname}:3000`,
    },
  },
  test: {
    ...localAndTestOverrides,
    analytics: {
      scheme: 'http',
      server: 'localhost:3000',
    },
  },
  ddev: {
    ...stagingOverrides,
    atlassianAccountUrl: 'https://id.dev.internal.atlassian.com',
  },
  fedex: {
    ...stagingOverrides,
    atlassianAccountUrl: 'https://id.atlassian-fex.com',
  },
  stg: stagingOverrides,
  'stg-fedm': {
    ...stagingOverrides,
    atlassianAccountUrl: 'https://id.stg.atlassian-us-gov-mod.com',
    featureGateInitCode: 'bcb0e982-1aa9-46ec-ba11-12a80bbf3d00',
  },
  'prod-fedm': {
    ...defaultConfig,
    atlassianAccountUrl: 'https://id.atlassian-us-gov-mod.com',
    featureGateInitCode: '9cd2f9f5-8320-4305-83ea-9e6deee60795',
  },
};

const cachedConfig = {};
export function getConfig(): Config {
  const env = getEnv();

  if (!cachedConfig[env]) {
    cachedConfig[env] = {
      ...defaultConfig,
      ...environmentOverrides[env],
    };
  }
  return cachedConfig[env];
}
