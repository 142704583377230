import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../../../../../utils/fetchApi';
import { getConfig } from '../../../../../common/config/envConfig';
import { transformPrivacySettingsFormatFromApiToSchema } from './helpers/manage-user-privacy-settings';
import { UserProfilePrivacy } from '../../../model/userProfilePrivacy';

export const UserManagePrivacySettingsQueryKey = 'UserManagePrivacySettings';

export type PrivacySettingsTransformed = UserProfilePrivacy & {
  userId: string;
};

const userManagePrivacySettingsQueryFn = async (
  userId: string,
): Promise<PrivacySettingsTransformed> => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/users/${userId}/manage/privacy-settings`;
  const response = await fetchApi(url);
  const data = await response.json();

  const transformedData = transformPrivacySettingsFormatFromApiToSchema(data);

  return {
    ...transformedData,
    userId: userId,
  };
};

export const useUserManagePrivacySettings = (userId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [UserManagePrivacySettingsQueryKey, userId],
    queryFn: () => userManagePrivacySettingsQueryFn(userId),
    enabled: !!userId,
  });
  return {
    userPrivacySettings: data,
    isLoading,
    isError,
  };
};
