import { UPDATE_PASSWORD } from './password-actions';
import { PasswordChangeErrors, ProfileChangePasswordState } from './types';

const intialState: ProfileChangePasswordState = {
  isSaving: false,
  errors: {},
};

function extractFieldErrorsFromMessage(message): PasswordChangeErrors {
  const errors: PasswordChangeErrors = {};
  Object.keys(message.fieldErrors).forEach(key => {
    errors[key] = message.fieldErrors[key][0];
  });
  if (message.formErrors) {
    errors.form = message.formErrors[0];
  }

  return errors;
}

export default (state = intialState, action) => {
  switch (action.type) {
    case `${UPDATE_PASSWORD}_PENDING`:
      return {
        ...state,
        isSaving: true,
        errors: {},
      };
    case `${UPDATE_PASSWORD}_FULFILLED`:
      return {
        ...state,
        isSaving: false,
      };
    case `${UPDATE_PASSWORD}_REJECTED`:
      return {
        ...state,
        isSaving: false,
        errors: extractFieldErrorsFromMessage(action.payload),
      };
    default:
      return state;
  }
};
