/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { N200 } from '@atlaskit/theme/colors';
var fieldsetLabelStyles = css({
  display: 'inline-block',
  font: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
  marginBlockEnd: "var(--ds-space-050, 4px)",
  marginBlockStart: "var(--ds-space-0, 0px)"
});
var oldFieldsetLabelStyles = css({
  color: "var(--ds-text-subtle, ".concat(N200, ")"),
  fontWeight: "var(--ds-font-weight-semibold, 600)"
});
var newFieldsetLabelStyles = css({
  color: "var(--ds-text-subtle, #44546F)",
  fontWeight: "var(--ds-font-weight-bold, 700)"
});

/**
 * __Label__
 *
 * A label represents a caption for an item in a user interface.
 *
 * It's recommended that a label has a `space.050` spacing above its associated
 * control element.
 */
export var Label = function Label(_ref) {
  var children = _ref.children,
    htmlFor = _ref.htmlFor,
    id = _ref.id,
    testId = _ref.testId;
  return jsx("label", {
    css: [fieldsetLabelStyles, getBooleanFF('platform.design-system-team.form-label-typography-updates') ? newFieldsetLabelStyles : oldFieldsetLabelStyles],
    id: id,
    htmlFor: htmlFor,
    "data-testid": testId
  }, children);
};

/**
 * __Legend__
 *
 * A Legend represents a caption for a fieldset in a user interface.
 */
export var Legend = function Legend(_ref2) {
  var children = _ref2.children;
  return jsx("legend", {
    css: [fieldsetLabelStyles, getBooleanFF('platform.design-system-team.form-label-typography-updates') ? newFieldsetLabelStyles : oldFieldsetLabelStyles]
  }, children);
};
export default Label;