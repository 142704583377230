import React, { ComponentProps, useEffect } from 'react';
import { PageLayout, usePageLayoutResize } from '@atlaskit/page-layout';

export type ResponsivePageLayoutProps = ComponentProps<typeof PageLayout> & {
  collapseSize: number;
  expandSize: number;
};

const ResponsivePageLayout: React.ComponentType<ResponsivePageLayoutProps> = props => {
  const { children } = props;
  return (
    <PageLayout>
      <PageLayoutWindowSizeHandler
        collapseSize={props.collapseSize}
        expandSize={props.expandSize}
      />
      {children}
    </PageLayout>
  );
};

function PageLayoutWindowSizeHandler({
  collapseSize = 700,
  expandSize = 1000,
}) {
  const {
    isLeftSidebarCollapsed,
    expandLeftSidebar,
    collapseLeftSidebar,
  } = usePageLayoutResize();
  useEffect(() => {
    function handleUpdate() {
      if (!isLeftSidebarCollapsed && window.innerWidth <= collapseSize) {
        collapseLeftSidebar();
      }
      if (isLeftSidebarCollapsed && window.innerWidth >= expandSize) {
        expandLeftSidebar();
      }
    }
    window.addEventListener('resize', handleUpdate);
    return () => window.removeEventListener('resize', handleUpdate);
  });
  return null;
}

export default ResponsivePageLayout;
