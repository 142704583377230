/**
 * ShortProductKey is used in code. It should not have a special characters or empty space
 */
export enum ShortProductKey {
  CONFLUENCE = 'confluence',
  JIRA = 'jira',
  JIRA_CORE = 'jira_core',
  JIRA_SOFTWARE = 'jira_software',
  JIRA_SERVICE_DESK = 'jira_service_key',
  BITBUCKET = 'bitbucket',
  TRELLO = 'trello',
  // The following is commented out because of https://sdog.jira-dev.com/browse/KT-1836
  // JIRA_INCIDENT_MANAGER = 'jira-incident-manager.ondemand',
}

/**
 * ProductName is used to render in UI.
 * This page (https://www.atlassian.com/software) lists some Atlassian product names.
 */
export enum ProductName {
  CONFLUENCE = 'Confluence',
  JIRA = 'Jira',
  JIRA_CORE = 'Jira Core',
  JIRA_SOFTWARE = 'Jira Software',
  JIRA_SERVICE_DESK = 'Jira Service Desk',
  BITBUCKET = 'Bitbucket',
  TRELLO = 'Trello',
  // The following is commented out because of https://sdog.jira-dev.com/browse/KT-1836
  // JIRA_INCIDENT_MANAGER = 'Jira Incident Manager',
}

// when querying Sites from some services, these are product keys the back-end returns
export enum LongProductKey {
  JIRA_CORE = 'jira-core.ondemand',
  JIRA_SOFTWARE = 'jira-software.ondemand',
  CONFLUENCE = 'confluence.ondemand',
  JIRA_SERVICE_DESK = 'jira-servicedesk.ondemand',
  BITBUCKET = 'bitbucket',
  TRELLO = 'trello',
  // The following is commented out because of https://sdog.jira-dev.com/browse/KT-1836
  // JIRA_INCIDENT_MANAGER = 'jira-incident-manager.ondemand',
}

// There is no user custom product icon or product display name, so
// product icon and product name is fixed
export interface ProductObject {
  cloudId?: string;
  cloudUrl?: string;
  key: ShortProductKey;
  productName: ProductName;
  settingsPath: string;
}

export interface Workspace {
  orgId: string;
  workspaceAri: string;
  workspaceUrl: string;
  workspacePermissionIds: string[];
  workspaceDisplayName: string;
  workspaceAvatarUrl: string;
  vortexMode?: 'ENABLED' | 'DISABLED';
}

export interface ProductData {
  productDisplayName: string;
  productId: LongProductKey;
  workspaces: Workspace[];
}

export interface Site {
  // Bitbucket is included in Site[] but does not have a real cloud-id
  // our client assigns `null` as Bitbuckets cloud-id
  cloudId: string | null;
  url: string;
  products?: string[];
  avatarUrl?: string;
  // is a name configured by user
  displayName?: string;
}
