// This is the entry point for time-critical scripts that we want run as soon as possible during app load,
// even before our mega-size bundles (which include React, Atlaskit...) are downloaded and run.
//
// One example is the code to fetch logged-in user information (locale, account ID). It is part of this entry
// point because we want the fetch request to happen in parallel with the main app download and execution, not
// after. That way, components in the main app do typically not have to wait for the data, and improves initial page load.
//
// Be mindful when adding more code/dependencies to this entry point, because once it gets larger,
// it will take longer till the time-critical piece of code is executed, and thus cancel the possible performance gain.

// Warning: `import { ... } from './common';` will increase time taken by early-init.ts significantly
// so we should instead import directly like below
import { withDefaultExponentialBackoff } from './common/utils/http';
import { userInfoProvider } from './common/clients/user-info-provider';

void userInfoProvider.get(withDefaultExponentialBackoff(fetch));
