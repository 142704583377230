import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const SectionMessageContainer = styled.div`
  background-color: ${token('color.background.discovery', colors.P50)};
  border-radius: 3px;
  width: 100%;
  padding: ${token('space.200', '16px')};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;

  & > button {
    padding: 7.25px;
    height: ${token('space.300', '24px')};
    width: ${token('space.300', '24px')};
    align-items: center;
    justify-content: center;
    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      & path {
        transform: scale(calc(9.5 / 5.54));
        transform-origin: center;
      }
    }
  }
`;

export const IconWrapper = styled.span`
  padding-right: ${token('space.200', '16px')};
`;

export const MessageWrapper = styled.span<{ hasIcon: boolean }>`
  flex: 1;
  padding-top: ${({ hasIcon }) =>
    hasIcon ? `${token('space.025', '2px')}` : 0};
`;

export const ActionsWrapper = styled.ul`
  display: flex;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  padding-top: ${token('space.100', '8px')};
`;

export const Action = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  & + &::before {
    color: ${token('color.text.subtle', colors.N500)};
    content: '·';
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: ${token('space.200', '16px')};
  }
`;

export const CloseButtonWrapper = styled.div`
  width: ${token('space.300', '24px')};
  height: ${token('space.300', '24px')};
  margin-left: ${token('space.200', '16px')};
`;
