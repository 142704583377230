import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _excluded = ["icon", "logo", "siteTitle", "onClick", "href", "onMouseDown", "testId", "logoMaxWidth"];
var _css;
/** @jsx jsx */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { PRODUCT_HOME_BREAKPOINT } from '../../common/constants';
import { useTheme } from '../../theme';
import { getTag } from './utils';
var VAR_LOGO_MAX_WIDTH = '--logo-max-width';
var VAR_PRODUCT_HOME_COLOR_ACTIVE = '--product-home-color-active';
var VAR_PRODUCT_HOME_BACKGROUND_COLOR_ACTIVE = '--product-home-bg-color-active';
var VAR_PRODUCT_HOME_BOX_SHADOW_ACTIVE = '--product-home-box-shadow-active';
var VAR_PRODUCT_HOME_COLOR_FOCUS = '--product-home-color-focus';
var VAR_PRODUCT_HOME_BACKGROUND_COLOR_FOCUS = '--product-home-bg-color-focus';
var VAR_PRODUCT_HOME_BOX_SHADOW_FOCUS = '--product-home-box-shadow-focus';
var VAR_PRODUCT_HOME_COLOR_HOVER = '--product-home-color-hover';
var VAR_PRODUCT_HOME_BACKGROUND_COLOR_HOVER = '--product-home-bg-color-hover';
var VAR_PRODUCT_HOME_BOX_SHADOW_HOVER = '--product-home-box-shadow-hover';
var productHomeButtonStyles = css((_css = {
  display: 'flex',
  padding: "var(--ds-space-050, 4px)",
  alignItems: 'center',
  background: 'none',
  border: 0,
  borderRadius: "var(--ds-border-radius, 3px)",
  color: 'inherit',
  cursor: 'pointer',
  '&::-moz-focus-inner': {
    border: 0
  },
  '&:first-of-type': {
    marginLeft: 0
  },
  '&:hover': {
    backgroundColor: "var(".concat(VAR_PRODUCT_HOME_BACKGROUND_COLOR_HOVER, ")"),
    boxShadow: "var(".concat(VAR_PRODUCT_HOME_BOX_SHADOW_HOVER, ")"),
    color: "var(".concat(VAR_PRODUCT_HOME_COLOR_HOVER, ")")
  },
  '&:active': {
    backgroundColor: "var(".concat(VAR_PRODUCT_HOME_BACKGROUND_COLOR_ACTIVE, ")"),
    boxShadow: "var(".concat(VAR_PRODUCT_HOME_BOX_SHADOW_ACTIVE, ")"),
    color: "var(".concat(VAR_PRODUCT_HOME_COLOR_ACTIVE, ")")
  },
  '&:focus-visible': {
    backgroundColor: "var(".concat(VAR_PRODUCT_HOME_BACKGROUND_COLOR_FOCUS, ")"),
    color: "var(".concat(VAR_PRODUCT_HOME_COLOR_FOCUS, ")"),
    outline: "var(--ds-border-width-outline, 2px)".concat(" solid ", "var(--ds-border-focused, #4C9AFF)")
  },
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  'div&': {
    pointerEvents: 'none'
  }
}, _defineProperty(_css, "@media (max-width: ".concat(PRODUCT_HOME_BREAKPOINT - 0.1, "px)"), {
  margin: "0 ".concat("var(--ds-space-100, 8px)")
}), _defineProperty(_css, "@media (min-width: ".concat(PRODUCT_HOME_BREAKPOINT, "px)"), {
  margin: "0 ".concat("var(--ds-space-200, 16px)")
}), _css));
var productLogoStyles = css(_defineProperty({
  // Ensure anything passed into
  // productHome is aligned correctly
  display: 'flex',
  maxWidth: "var(".concat(VAR_LOGO_MAX_WIDTH, ")"),
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > *': {
    maxHeight: 24
  },
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > span > svg': {
    maxWidth: "var(".concat(VAR_LOGO_MAX_WIDTH, ")")
  }
}, "@media (max-width: ".concat(PRODUCT_HOME_BREAKPOINT - 0.1, "px)"), {
  display: 'none'
}));
var customMaxHeightStyles = css({
  maxHeight: 28
});
var productIconStyles = css(_defineProperty({
  // Ensure anything passed into
  // productHome is aligned correctly
  display: 'flex',
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > *': {
    maxHeight: 24
  }
}, "@media (min-width: ".concat(PRODUCT_HOME_BREAKPOINT, "px)"), {
  display: 'none'
}));
var siteTitleStyles = css({
  display: 'flex',
  // Overridden in PFF styles applied below; consolidate in FF cleanup
  marginRight: "var(--ds-space-050, 4px)",
  marginLeft: "var(--ds-space-050, 4px)",
  paddingRight: "var(--ds-space-200, 16px)",
  alignItems: 'center'
});

// When cleaning up feature flag, these styles can be moved into the above block
var featureFlaggedSiteTitleStyles = css(_defineProperty({
  // Under the FF, we've made the site title handle it's spacing, with the
  // PrimaryItemsContainer not needing to worry about its paddingInlineStart.
  // This makes it cleaner for the title styles to appear and disappear with mq's
  marginRight: "var(--ds-space-100, 8px)"
}, "@media (max-width: ".concat(PRODUCT_HOME_BREAKPOINT - 0.1, "px)"), {
  display: 'none'
}));

/**
 * __Product home__
 *
 * The ProductHome component displays the product visual identity composed of:
 * logo, icon, and optional text. Values for logo and icon are never displayed
 * at the same time, where icon is used only when space is restricted. Should be
 * passed into `AtlassianNavigation`'s `renderProductHome` prop.
 *
 * - [Examples](https://atlassian.design/components/atlassian-navigation/examples#product-home)
 * - [Code](https://atlassian.design/components/atlassian-navigation/code)
 */
var ProductHome = function ProductHome(_ref) {
  var _productHomeButtonDyn;
  var Icon = _ref.icon,
    Logo = _ref.logo,
    siteTitle = _ref.siteTitle,
    onClick = _ref.onClick,
    href = _ref.href,
    onMouseDown = _ref.onMouseDown,
    testId = _ref.testId,
    _ref$logoMaxWidth = _ref.logoMaxWidth,
    logoMaxWidth = _ref$logoMaxWidth === void 0 ? 260 : _ref$logoMaxWidth,
    rest = _objectWithoutProperties(_ref, _excluded);
  var theme = useTheme();
  var primaryButton = theme.mode.primaryButton;
  var _theme$mode$productHo = theme.mode.productHome,
    _theme$mode$productHo2 = _theme$mode$productHo.iconColor,
    iconColor = _theme$mode$productHo2 === void 0 ? 'inherit' : _theme$mode$productHo2,
    _theme$mode$productHo3 = _theme$mode$productHo.iconGradientStart,
    iconGradientStart = _theme$mode$productHo3 === void 0 ? 'inherit' : _theme$mode$productHo3,
    _theme$mode$productHo4 = _theme$mode$productHo.iconGradientStop,
    iconGradientStop = _theme$mode$productHo4 === void 0 ? 'inherit' : _theme$mode$productHo4,
    _theme$mode$productHo5 = _theme$mode$productHo.textColor,
    textColor = _theme$mode$productHo5 === void 0 ? theme.mode.productHome.color : _theme$mode$productHo5;
  var Tag = getTag(onClick, href);
  var preventFocusRing = function preventFocusRing(e) {
    e.preventDefault();
    onMouseDown && onMouseDown(e);
  };
  var productHomeButtonDynamicStyles = (_productHomeButtonDyn = {}, _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_COLOR_ACTIVE, primaryButton.active.color), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_BACKGROUND_COLOR_ACTIVE, primaryButton.active.backgroundColor), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_BOX_SHADOW_ACTIVE, primaryButton.active.boxShadow), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_COLOR_FOCUS, primaryButton.focus.color), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_BACKGROUND_COLOR_FOCUS, primaryButton.focus.backgroundColor), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_BOX_SHADOW_FOCUS, primaryButton.focus.boxShadow), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_COLOR_HOVER, primaryButton.hover.color), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_BACKGROUND_COLOR_HOVER, primaryButton.hover.backgroundColor), _defineProperty(_productHomeButtonDyn, VAR_PRODUCT_HOME_BOX_SHADOW_HOVER, primaryButton.hover.boxShadow), _defineProperty(_productHomeButtonDyn, VAR_LOGO_MAX_WIDTH, "".concat(logoMaxWidth, "px")), _productHomeButtonDyn);
  return jsx(Fragment, null, jsx(Tag, _extends({
    style: productHomeButtonDynamicStyles,
    css: productHomeButtonStyles,
    href: href,
    onClick: onClick,
    onMouseDown: preventFocusRing,
    "data-testid": testId && "".concat(testId, "-container")
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, rest), jsx("div", {
    css: [customMaxHeightStyles, productLogoStyles],
    "data-testid": testId && "".concat(testId, "-logo")
  }, jsx(Logo, {
    iconGradientStart: iconGradientStart,
    iconGradientStop: iconGradientStop,
    iconColor: iconColor,
    textColor: textColor
  })), jsx("div", {
    css: [customMaxHeightStyles, productIconStyles],
    "data-testid": testId && "".concat(testId, "-icon")
  }, jsx(Icon, {
    iconGradientStart: iconGradientStart,
    iconGradientStop: iconGradientStop,
    iconColor: iconColor
  }))), siteTitle && jsx("div", {
    style: {
      borderRight: theme.mode.productHome.borderRight
    },
    css: [siteTitleStyles, getBooleanFF('platform.design-system-team.navigation-v2-no-jank_5yhbd') && featureFlaggedSiteTitleStyles],
    "data-testid": testId && "".concat(testId, "-site-title")
  }, siteTitle));
};
export default ProductHome;