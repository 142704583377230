import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState, Spinner } from '../../../../common';

function mapStateToProps(
  rootState: RootState,
  ownProps: { params?: { userId: string } },
): PropsFromState {
  const { user } = rootState;
  const { params } = ownProps;

  return {
    userId:
      params && params.userId
        ? params.userId
        : user.data && user.data.accountId,
  };
}

type PropsFromState = {
  userId?: string | null;
};

export type WithUserIdProps = {
  userId: string;
};

type WithUserIdOptions = {
  loading?: ReactNode;
};

/**
 * This higher component makes sure that wrapped component always has userId prop
 */
export default function withUserId<P extends object>(
  WrappedComponent: React.ComponentType<P & WithUserIdProps>,
  options: WithUserIdOptions = {},
) {
  const WithUserIdWrapper = (props: P & PropsFromState) => {
    const { userId } = props;

    if (!userId) {
      if (options.loading === undefined) {
        return <Spinner size="medium" data-testid="loading-spinner" />;
      } else {
        return <>{options.loading}</>;
      }
    }

    return <WrappedComponent {...props} userId={userId} />;
  };

  // @ts-expect-error i give up
  return connect(mapStateToProps)(WithUserIdWrapper);
}
