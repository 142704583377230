import { AbstractAccountClient } from './AbstractAccountClient';
import type { Config, User, UserListener } from './types';

export const mockUser: User = {
  account_id: '000000000000000000000000',
  email: 'user@email.com',
  name: 'User Name',
  picture:
    'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/AA-0.png',
  nickname: 'Nickname',
  zoneinfo: 'UTC',
  locale: 'en-GB',
};

export class MockAccountClient extends AbstractAccountClient {
  private _listeners: UserListener[] = [];

  /** @override */
  currentUser: User | null | undefined;

  constructor(config: Config, user?: User | null) {
    super(config);
    this.currentUser = user;
  }

  /** @override */
  addUserListener(this: this, callback: UserListener): () => void {
    this._listeners.push(callback);

    return () => {
      this._listeners = this._listeners.filter(
        listener => listener !== callback,
      );
    };
  }

  /** @override */
  async switchAccount(): Promise<void> {}

  /** @override */
  async getRecentAssociatedSessions() {
    return [];
  }

  setUser(this: this, user: User | null): void {
    this.currentUser = user;
    this._listeners.forEach(listener => listener(user));
  }
}
