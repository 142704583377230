import styled from '@emotion/styled';

export const MenuBlanket = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const DropdownWrapper = styled.div`
  cursor: pointer;
`;
