export enum UserPrivacyLevel {
  private = 'private',
  domain = 'domain',
  public = 'public',
}

export enum UserStatus {
  active = 'active',
  inactive = 'inactive',
  closed = 'closed',
}

export interface UserPrivacy {
  level: UserPrivacyLevel;
  domain: string;
  isBlacklistedDomain: boolean;
}

export interface User {
  id: string;
  isCurrentUser?: boolean;
  fullName: string;
  nickname?: string;
  email?: string;
  title?: string;
  timezone?: string;
  localTime?: string;
  locale?: string;
  preferredLanguage?: string;
  location?: string;
  companyName?: string;
  department?: string;
  position?: string;
  avatarUrl?: string;
  headerImageUrl?: string;
  privacy?: UserPrivacy;
  status?: UserStatus;
  statusModifiedDate?: number;
  isBot?: boolean;
  isNotMentionable?: boolean;
  phoneNumber?: string;
}

// Profile details fields
export enum FieldType {
  FullName = 'fullName',
  Nickname = 'nickname', // aka `Public name`
  Title = 'title',
  Department = 'department',
  CompanyName = 'companyName',
  Location = 'location',
  Timezone = 'timezone',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Avatar = 'picture',
  Language = 'language',
  LocalTime = 'localTime',
}

// The following field names are based on the corresponding fields
// defined in https://hello.atlassian.net/wiki/spaces/I/pages/163851429/Proposal+-+v2+User+JSON+schema
export enum IdentityFieldName {
  PHONE_NUMBER = 'phone_number',
  JOB_TITLE = 'job_title',
  DEPARTMENT = 'department',
  COMPANY_NAME = 'organization',
  LOCATION = 'location',
  ZONEINFO = 'zoneinfo',
  NAME = 'name',
  EMAIL = 'email',
}
