/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 * @codegen <<SignedSource::b84f3ab5de0b07fa5b6f8c5356895432>>
 * @codegenCommand yarn build tokens
 */
var tokens = {
  'color.text': '--ds-text',
  'color.text.accent.lime': '--ds-text-accent-lime',
  'color.text.accent.lime.bolder': '--ds-text-accent-lime-bolder',
  'color.text.accent.red': '--ds-text-accent-red',
  'color.text.accent.red.bolder': '--ds-text-accent-red-bolder',
  'color.text.accent.orange': '--ds-text-accent-orange',
  'color.text.accent.orange.bolder': '--ds-text-accent-orange-bolder',
  'color.text.accent.yellow': '--ds-text-accent-yellow',
  'color.text.accent.yellow.bolder': '--ds-text-accent-yellow-bolder',
  'color.text.accent.green': '--ds-text-accent-green',
  'color.text.accent.green.bolder': '--ds-text-accent-green-bolder',
  'color.text.accent.teal': '--ds-text-accent-teal',
  'color.text.accent.teal.bolder': '--ds-text-accent-teal-bolder',
  'color.text.accent.blue': '--ds-text-accent-blue',
  'color.text.accent.blue.bolder': '--ds-text-accent-blue-bolder',
  'color.text.accent.purple': '--ds-text-accent-purple',
  'color.text.accent.purple.bolder': '--ds-text-accent-purple-bolder',
  'color.text.accent.magenta': '--ds-text-accent-magenta',
  'color.text.accent.magenta.bolder': '--ds-text-accent-magenta-bolder',
  'color.text.accent.gray': '--ds-text-accent-gray',
  'color.text.accent.gray.bolder': '--ds-text-accent-gray-bolder',
  'color.text.disabled': '--ds-text-disabled',
  'color.text.inverse': '--ds-text-inverse',
  'color.text.selected': '--ds-text-selected',
  'color.text.brand': '--ds-text-brand',
  'color.text.danger': '--ds-text-danger',
  'color.text.warning': '--ds-text-warning',
  'color.text.warning.inverse': '--ds-text-warning-inverse',
  'color.text.success': '--ds-text-success',
  'color.text.discovery': '--ds-text-discovery',
  'color.text.information': '--ds-text-information',
  'color.text.subtlest': '--ds-text-subtlest',
  'color.text.subtle': '--ds-text-subtle',
  'color.link': '--ds-link',
  'color.link.pressed': '--ds-link-pressed',
  'color.link.visited': '--ds-link-visited',
  'color.link.visited.pressed': '--ds-link-visited-pressed',
  'color.icon': '--ds-icon',
  'color.icon.accent.lime': '--ds-icon-accent-lime',
  'color.icon.accent.red': '--ds-icon-accent-red',
  'color.icon.accent.orange': '--ds-icon-accent-orange',
  'color.icon.accent.yellow': '--ds-icon-accent-yellow',
  'color.icon.accent.green': '--ds-icon-accent-green',
  'color.icon.accent.teal': '--ds-icon-accent-teal',
  'color.icon.accent.blue': '--ds-icon-accent-blue',
  'color.icon.accent.purple': '--ds-icon-accent-purple',
  'color.icon.accent.magenta': '--ds-icon-accent-magenta',
  'color.icon.accent.gray': '--ds-icon-accent-gray',
  'color.icon.disabled': '--ds-icon-disabled',
  'color.icon.inverse': '--ds-icon-inverse',
  'color.icon.selected': '--ds-icon-selected',
  'color.icon.brand': '--ds-icon-brand',
  'color.icon.danger': '--ds-icon-danger',
  'color.icon.warning': '--ds-icon-warning',
  'color.icon.warning.inverse': '--ds-icon-warning-inverse',
  'color.icon.success': '--ds-icon-success',
  'color.icon.discovery': '--ds-icon-discovery',
  'color.icon.information': '--ds-icon-information',
  'color.icon.subtle': '--ds-icon-subtle',
  'color.border': '--ds-border',
  'color.border.accent.lime': '--ds-border-accent-lime',
  'color.border.accent.red': '--ds-border-accent-red',
  'color.border.accent.orange': '--ds-border-accent-orange',
  'color.border.accent.yellow': '--ds-border-accent-yellow',
  'color.border.accent.green': '--ds-border-accent-green',
  'color.border.accent.teal': '--ds-border-accent-teal',
  'color.border.accent.blue': '--ds-border-accent-blue',
  'color.border.accent.purple': '--ds-border-accent-purple',
  'color.border.accent.magenta': '--ds-border-accent-magenta',
  'color.border.accent.gray': '--ds-border-accent-gray',
  'color.border.disabled': '--ds-border-disabled',
  'color.border.focused': '--ds-border-focused',
  'color.border.input': '--ds-border-input',
  'color.border.inverse': '--ds-border-inverse',
  'color.border.selected': '--ds-border-selected',
  'color.border.brand': '--ds-border-brand',
  'color.border.danger': '--ds-border-danger',
  'color.border.warning': '--ds-border-warning',
  'color.border.success': '--ds-border-success',
  'color.border.discovery': '--ds-border-discovery',
  'color.border.information': '--ds-border-information',
  'color.border.bold': '--ds-border-bold',
  'color.background.accent.lime.subtlest': '--ds-background-accent-lime-subtlest',
  'color.background.accent.lime.subtlest.hovered': '--ds-background-accent-lime-subtlest-hovered',
  'color.background.accent.lime.subtlest.pressed': '--ds-background-accent-lime-subtlest-pressed',
  'color.background.accent.lime.subtler': '--ds-background-accent-lime-subtler',
  'color.background.accent.lime.subtler.hovered': '--ds-background-accent-lime-subtler-hovered',
  'color.background.accent.lime.subtler.pressed': '--ds-background-accent-lime-subtler-pressed',
  'color.background.accent.lime.subtle': '--ds-background-accent-lime-subtle',
  'color.background.accent.lime.subtle.hovered': '--ds-background-accent-lime-subtle-hovered',
  'color.background.accent.lime.subtle.pressed': '--ds-background-accent-lime-subtle-pressed',
  'color.background.accent.lime.bolder': '--ds-background-accent-lime-bolder',
  'color.background.accent.lime.bolder.hovered': '--ds-background-accent-lime-bolder-hovered',
  'color.background.accent.lime.bolder.pressed': '--ds-background-accent-lime-bolder-pressed',
  'color.background.accent.red.subtlest': '--ds-background-accent-red-subtlest',
  'color.background.accent.red.subtlest.hovered': '--ds-background-accent-red-subtlest-hovered',
  'color.background.accent.red.subtlest.pressed': '--ds-background-accent-red-subtlest-pressed',
  'color.background.accent.red.subtler': '--ds-background-accent-red-subtler',
  'color.background.accent.red.subtler.hovered': '--ds-background-accent-red-subtler-hovered',
  'color.background.accent.red.subtler.pressed': '--ds-background-accent-red-subtler-pressed',
  'color.background.accent.red.subtle': '--ds-background-accent-red-subtle',
  'color.background.accent.red.subtle.hovered': '--ds-background-accent-red-subtle-hovered',
  'color.background.accent.red.subtle.pressed': '--ds-background-accent-red-subtle-pressed',
  'color.background.accent.red.bolder': '--ds-background-accent-red-bolder',
  'color.background.accent.red.bolder.hovered': '--ds-background-accent-red-bolder-hovered',
  'color.background.accent.red.bolder.pressed': '--ds-background-accent-red-bolder-pressed',
  'color.background.accent.orange.subtlest': '--ds-background-accent-orange-subtlest',
  'color.background.accent.orange.subtlest.hovered': '--ds-background-accent-orange-subtlest-hovered',
  'color.background.accent.orange.subtlest.pressed': '--ds-background-accent-orange-subtlest-pressed',
  'color.background.accent.orange.subtler': '--ds-background-accent-orange-subtler',
  'color.background.accent.orange.subtler.hovered': '--ds-background-accent-orange-subtler-hovered',
  'color.background.accent.orange.subtler.pressed': '--ds-background-accent-orange-subtler-pressed',
  'color.background.accent.orange.subtle': '--ds-background-accent-orange-subtle',
  'color.background.accent.orange.subtle.hovered': '--ds-background-accent-orange-subtle-hovered',
  'color.background.accent.orange.subtle.pressed': '--ds-background-accent-orange-subtle-pressed',
  'color.background.accent.orange.bolder': '--ds-background-accent-orange-bolder',
  'color.background.accent.orange.bolder.hovered': '--ds-background-accent-orange-bolder-hovered',
  'color.background.accent.orange.bolder.pressed': '--ds-background-accent-orange-bolder-pressed',
  'color.background.accent.yellow.subtlest': '--ds-background-accent-yellow-subtlest',
  'color.background.accent.yellow.subtlest.hovered': '--ds-background-accent-yellow-subtlest-hovered',
  'color.background.accent.yellow.subtlest.pressed': '--ds-background-accent-yellow-subtlest-pressed',
  'color.background.accent.yellow.subtler': '--ds-background-accent-yellow-subtler',
  'color.background.accent.yellow.subtler.hovered': '--ds-background-accent-yellow-subtler-hovered',
  'color.background.accent.yellow.subtler.pressed': '--ds-background-accent-yellow-subtler-pressed',
  'color.background.accent.yellow.subtle': '--ds-background-accent-yellow-subtle',
  'color.background.accent.yellow.subtle.hovered': '--ds-background-accent-yellow-subtle-hovered',
  'color.background.accent.yellow.subtle.pressed': '--ds-background-accent-yellow-subtle-pressed',
  'color.background.accent.yellow.bolder': '--ds-background-accent-yellow-bolder',
  'color.background.accent.yellow.bolder.hovered': '--ds-background-accent-yellow-bolder-hovered',
  'color.background.accent.yellow.bolder.pressed': '--ds-background-accent-yellow-bolder-pressed',
  'color.background.accent.green.subtlest': '--ds-background-accent-green-subtlest',
  'color.background.accent.green.subtlest.hovered': '--ds-background-accent-green-subtlest-hovered',
  'color.background.accent.green.subtlest.pressed': '--ds-background-accent-green-subtlest-pressed',
  'color.background.accent.green.subtler': '--ds-background-accent-green-subtler',
  'color.background.accent.green.subtler.hovered': '--ds-background-accent-green-subtler-hovered',
  'color.background.accent.green.subtler.pressed': '--ds-background-accent-green-subtler-pressed',
  'color.background.accent.green.subtle': '--ds-background-accent-green-subtle',
  'color.background.accent.green.subtle.hovered': '--ds-background-accent-green-subtle-hovered',
  'color.background.accent.green.subtle.pressed': '--ds-background-accent-green-subtle-pressed',
  'color.background.accent.green.bolder': '--ds-background-accent-green-bolder',
  'color.background.accent.green.bolder.hovered': '--ds-background-accent-green-bolder-hovered',
  'color.background.accent.green.bolder.pressed': '--ds-background-accent-green-bolder-pressed',
  'color.background.accent.teal.subtlest': '--ds-background-accent-teal-subtlest',
  'color.background.accent.teal.subtlest.hovered': '--ds-background-accent-teal-subtlest-hovered',
  'color.background.accent.teal.subtlest.pressed': '--ds-background-accent-teal-subtlest-pressed',
  'color.background.accent.teal.subtler': '--ds-background-accent-teal-subtler',
  'color.background.accent.teal.subtler.hovered': '--ds-background-accent-teal-subtler-hovered',
  'color.background.accent.teal.subtler.pressed': '--ds-background-accent-teal-subtler-pressed',
  'color.background.accent.teal.subtle': '--ds-background-accent-teal-subtle',
  'color.background.accent.teal.subtle.hovered': '--ds-background-accent-teal-subtle-hovered',
  'color.background.accent.teal.subtle.pressed': '--ds-background-accent-teal-subtle-pressed',
  'color.background.accent.teal.bolder': '--ds-background-accent-teal-bolder',
  'color.background.accent.teal.bolder.hovered': '--ds-background-accent-teal-bolder-hovered',
  'color.background.accent.teal.bolder.pressed': '--ds-background-accent-teal-bolder-pressed',
  'color.background.accent.blue.subtlest': '--ds-background-accent-blue-subtlest',
  'color.background.accent.blue.subtlest.hovered': '--ds-background-accent-blue-subtlest-hovered',
  'color.background.accent.blue.subtlest.pressed': '--ds-background-accent-blue-subtlest-pressed',
  'color.background.accent.blue.subtler': '--ds-background-accent-blue-subtler',
  'color.background.accent.blue.subtler.hovered': '--ds-background-accent-blue-subtler-hovered',
  'color.background.accent.blue.subtler.pressed': '--ds-background-accent-blue-subtler-pressed',
  'color.background.accent.blue.subtle': '--ds-background-accent-blue-subtle',
  'color.background.accent.blue.subtle.hovered': '--ds-background-accent-blue-subtle-hovered',
  'color.background.accent.blue.subtle.pressed': '--ds-background-accent-blue-subtle-pressed',
  'color.background.accent.blue.bolder': '--ds-background-accent-blue-bolder',
  'color.background.accent.blue.bolder.hovered': '--ds-background-accent-blue-bolder-hovered',
  'color.background.accent.blue.bolder.pressed': '--ds-background-accent-blue-bolder-pressed',
  'color.background.accent.purple.subtlest': '--ds-background-accent-purple-subtlest',
  'color.background.accent.purple.subtlest.hovered': '--ds-background-accent-purple-subtlest-hovered',
  'color.background.accent.purple.subtlest.pressed': '--ds-background-accent-purple-subtlest-pressed',
  'color.background.accent.purple.subtler': '--ds-background-accent-purple-subtler',
  'color.background.accent.purple.subtler.hovered': '--ds-background-accent-purple-subtler-hovered',
  'color.background.accent.purple.subtler.pressed': '--ds-background-accent-purple-subtler-pressed',
  'color.background.accent.purple.subtle': '--ds-background-accent-purple-subtle',
  'color.background.accent.purple.subtle.hovered': '--ds-background-accent-purple-subtle-hovered',
  'color.background.accent.purple.subtle.pressed': '--ds-background-accent-purple-subtle-pressed',
  'color.background.accent.purple.bolder': '--ds-background-accent-purple-bolder',
  'color.background.accent.purple.bolder.hovered': '--ds-background-accent-purple-bolder-hovered',
  'color.background.accent.purple.bolder.pressed': '--ds-background-accent-purple-bolder-pressed',
  'color.background.accent.magenta.subtlest': '--ds-background-accent-magenta-subtlest',
  'color.background.accent.magenta.subtlest.hovered': '--ds-background-accent-magenta-subtlest-hovered',
  'color.background.accent.magenta.subtlest.pressed': '--ds-background-accent-magenta-subtlest-pressed',
  'color.background.accent.magenta.subtler': '--ds-background-accent-magenta-subtler',
  'color.background.accent.magenta.subtler.hovered': '--ds-background-accent-magenta-subtler-hovered',
  'color.background.accent.magenta.subtler.pressed': '--ds-background-accent-magenta-subtler-pressed',
  'color.background.accent.magenta.subtle': '--ds-background-accent-magenta-subtle',
  'color.background.accent.magenta.subtle.hovered': '--ds-background-accent-magenta-subtle-hovered',
  'color.background.accent.magenta.subtle.pressed': '--ds-background-accent-magenta-subtle-pressed',
  'color.background.accent.magenta.bolder': '--ds-background-accent-magenta-bolder',
  'color.background.accent.magenta.bolder.hovered': '--ds-background-accent-magenta-bolder-hovered',
  'color.background.accent.magenta.bolder.pressed': '--ds-background-accent-magenta-bolder-pressed',
  'color.background.accent.gray.subtlest': '--ds-background-accent-gray-subtlest',
  'color.background.accent.gray.subtlest.hovered': '--ds-background-accent-gray-subtlest-hovered',
  'color.background.accent.gray.subtlest.pressed': '--ds-background-accent-gray-subtlest-pressed',
  'color.background.accent.gray.subtler': '--ds-background-accent-gray-subtler',
  'color.background.accent.gray.subtler.hovered': '--ds-background-accent-gray-subtler-hovered',
  'color.background.accent.gray.subtler.pressed': '--ds-background-accent-gray-subtler-pressed',
  'color.background.accent.gray.subtle': '--ds-background-accent-gray-subtle',
  'color.background.accent.gray.subtle.hovered': '--ds-background-accent-gray-subtle-hovered',
  'color.background.accent.gray.subtle.pressed': '--ds-background-accent-gray-subtle-pressed',
  'color.background.accent.gray.bolder': '--ds-background-accent-gray-bolder',
  'color.background.accent.gray.bolder.hovered': '--ds-background-accent-gray-bolder-hovered',
  'color.background.accent.gray.bolder.pressed': '--ds-background-accent-gray-bolder-pressed',
  'color.background.disabled': '--ds-background-disabled',
  'color.background.input': '--ds-background-input',
  'color.background.input.hovered': '--ds-background-input-hovered',
  'color.background.input.pressed': '--ds-background-input-pressed',
  'color.background.inverse.subtle': '--ds-background-inverse-subtle',
  'color.background.inverse.subtle.hovered': '--ds-background-inverse-subtle-hovered',
  'color.background.inverse.subtle.pressed': '--ds-background-inverse-subtle-pressed',
  'color.background.neutral': '--ds-background-neutral',
  'color.background.neutral.hovered': '--ds-background-neutral-hovered',
  'color.background.neutral.pressed': '--ds-background-neutral-pressed',
  'color.background.neutral.subtle': '--ds-background-neutral-subtle',
  'color.background.neutral.subtle.hovered': '--ds-background-neutral-subtle-hovered',
  'color.background.neutral.subtle.pressed': '--ds-background-neutral-subtle-pressed',
  'color.background.neutral.bold': '--ds-background-neutral-bold',
  'color.background.neutral.bold.hovered': '--ds-background-neutral-bold-hovered',
  'color.background.neutral.bold.pressed': '--ds-background-neutral-bold-pressed',
  'color.background.selected': '--ds-background-selected',
  'color.background.selected.hovered': '--ds-background-selected-hovered',
  'color.background.selected.pressed': '--ds-background-selected-pressed',
  'color.background.selected.bold': '--ds-background-selected-bold',
  'color.background.selected.bold.hovered': '--ds-background-selected-bold-hovered',
  'color.background.selected.bold.pressed': '--ds-background-selected-bold-pressed',
  'color.background.brand.subtlest': '--ds-background-brand-subtlest',
  'color.background.brand.subtlest.hovered': '--ds-background-brand-subtlest-hovered',
  'color.background.brand.subtlest.pressed': '--ds-background-brand-subtlest-pressed',
  'color.background.brand.bold': '--ds-background-brand-bold',
  'color.background.brand.bold.hovered': '--ds-background-brand-bold-hovered',
  'color.background.brand.bold.pressed': '--ds-background-brand-bold-pressed',
  'color.background.brand.boldest': '--ds-background-brand-boldest',
  'color.background.brand.boldest.hovered': '--ds-background-brand-boldest-hovered',
  'color.background.brand.boldest.pressed': '--ds-background-brand-boldest-pressed',
  'color.background.danger': '--ds-background-danger',
  'color.background.danger.hovered': '--ds-background-danger-hovered',
  'color.background.danger.pressed': '--ds-background-danger-pressed',
  'color.background.danger.bold': '--ds-background-danger-bold',
  'color.background.danger.bold.hovered': '--ds-background-danger-bold-hovered',
  'color.background.danger.bold.pressed': '--ds-background-danger-bold-pressed',
  'color.background.warning': '--ds-background-warning',
  'color.background.warning.hovered': '--ds-background-warning-hovered',
  'color.background.warning.pressed': '--ds-background-warning-pressed',
  'color.background.warning.bold': '--ds-background-warning-bold',
  'color.background.warning.bold.hovered': '--ds-background-warning-bold-hovered',
  'color.background.warning.bold.pressed': '--ds-background-warning-bold-pressed',
  'color.background.success': '--ds-background-success',
  'color.background.success.hovered': '--ds-background-success-hovered',
  'color.background.success.pressed': '--ds-background-success-pressed',
  'color.background.success.bold': '--ds-background-success-bold',
  'color.background.success.bold.hovered': '--ds-background-success-bold-hovered',
  'color.background.success.bold.pressed': '--ds-background-success-bold-pressed',
  'color.background.discovery': '--ds-background-discovery',
  'color.background.discovery.hovered': '--ds-background-discovery-hovered',
  'color.background.discovery.pressed': '--ds-background-discovery-pressed',
  'color.background.discovery.bold': '--ds-background-discovery-bold',
  'color.background.discovery.bold.hovered': '--ds-background-discovery-bold-hovered',
  'color.background.discovery.bold.pressed': '--ds-background-discovery-bold-pressed',
  'color.background.information': '--ds-background-information',
  'color.background.information.hovered': '--ds-background-information-hovered',
  'color.background.information.pressed': '--ds-background-information-pressed',
  'color.background.information.bold': '--ds-background-information-bold',
  'color.background.information.bold.hovered': '--ds-background-information-bold-hovered',
  'color.background.information.bold.pressed': '--ds-background-information-bold-pressed',
  'color.blanket': '--ds-blanket',
  'color.blanket.selected': '--ds-blanket-selected',
  'color.blanket.danger': '--ds-blanket-danger',
  'color.interaction.hovered': '--ds-interaction-hovered',
  'color.interaction.pressed': '--ds-interaction-pressed',
  'color.skeleton': '--ds-skeleton',
  'color.skeleton.subtle': '--ds-skeleton-subtle',
  'color.chart.categorical.1': '--ds-chart-categorical-1',
  'color.chart.categorical.1.hovered': '--ds-chart-categorical-1-hovered',
  'color.chart.categorical.2': '--ds-chart-categorical-2',
  'color.chart.categorical.2.hovered': '--ds-chart-categorical-2-hovered',
  'color.chart.categorical.3': '--ds-chart-categorical-3',
  'color.chart.categorical.3.hovered': '--ds-chart-categorical-3-hovered',
  'color.chart.categorical.4': '--ds-chart-categorical-4',
  'color.chart.categorical.4.hovered': '--ds-chart-categorical-4-hovered',
  'color.chart.categorical.5': '--ds-chart-categorical-5',
  'color.chart.categorical.5.hovered': '--ds-chart-categorical-5-hovered',
  'color.chart.categorical.6': '--ds-chart-categorical-6',
  'color.chart.categorical.6.hovered': '--ds-chart-categorical-6-hovered',
  'color.chart.categorical.7': '--ds-chart-categorical-7',
  'color.chart.categorical.7.hovered': '--ds-chart-categorical-7-hovered',
  'color.chart.categorical.8': '--ds-chart-categorical-8',
  'color.chart.categorical.8.hovered': '--ds-chart-categorical-8-hovered',
  'color.chart.lime.bold': '--ds-chart-lime-bold',
  'color.chart.lime.bold.hovered': '--ds-chart-lime-bold-hovered',
  'color.chart.lime.bolder': '--ds-chart-lime-bolder',
  'color.chart.lime.bolder.hovered': '--ds-chart-lime-bolder-hovered',
  'color.chart.lime.boldest': '--ds-chart-lime-boldest',
  'color.chart.lime.boldest.hovered': '--ds-chart-lime-boldest-hovered',
  'color.chart.neutral': '--ds-chart-neutral',
  'color.chart.neutral.hovered': '--ds-chart-neutral-hovered',
  'color.chart.red.bold': '--ds-chart-red-bold',
  'color.chart.red.bold.hovered': '--ds-chart-red-bold-hovered',
  'color.chart.red.bolder': '--ds-chart-red-bolder',
  'color.chart.red.bolder.hovered': '--ds-chart-red-bolder-hovered',
  'color.chart.red.boldest': '--ds-chart-red-boldest',
  'color.chart.red.boldest.hovered': '--ds-chart-red-boldest-hovered',
  'color.chart.orange.bold': '--ds-chart-orange-bold',
  'color.chart.orange.bold.hovered': '--ds-chart-orange-bold-hovered',
  'color.chart.orange.bolder': '--ds-chart-orange-bolder',
  'color.chart.orange.bolder.hovered': '--ds-chart-orange-bolder-hovered',
  'color.chart.orange.boldest': '--ds-chart-orange-boldest',
  'color.chart.orange.boldest.hovered': '--ds-chart-orange-boldest-hovered',
  'color.chart.yellow.bold': '--ds-chart-yellow-bold',
  'color.chart.yellow.bold.hovered': '--ds-chart-yellow-bold-hovered',
  'color.chart.yellow.bolder': '--ds-chart-yellow-bolder',
  'color.chart.yellow.bolder.hovered': '--ds-chart-yellow-bolder-hovered',
  'color.chart.yellow.boldest': '--ds-chart-yellow-boldest',
  'color.chart.yellow.boldest.hovered': '--ds-chart-yellow-boldest-hovered',
  'color.chart.green.bold': '--ds-chart-green-bold',
  'color.chart.green.bold.hovered': '--ds-chart-green-bold-hovered',
  'color.chart.green.bolder': '--ds-chart-green-bolder',
  'color.chart.green.bolder.hovered': '--ds-chart-green-bolder-hovered',
  'color.chart.green.boldest': '--ds-chart-green-boldest',
  'color.chart.green.boldest.hovered': '--ds-chart-green-boldest-hovered',
  'color.chart.teal.bold': '--ds-chart-teal-bold',
  'color.chart.teal.bold.hovered': '--ds-chart-teal-bold-hovered',
  'color.chart.teal.bolder': '--ds-chart-teal-bolder',
  'color.chart.teal.bolder.hovered': '--ds-chart-teal-bolder-hovered',
  'color.chart.teal.boldest': '--ds-chart-teal-boldest',
  'color.chart.teal.boldest.hovered': '--ds-chart-teal-boldest-hovered',
  'color.chart.blue.bold': '--ds-chart-blue-bold',
  'color.chart.blue.bold.hovered': '--ds-chart-blue-bold-hovered',
  'color.chart.blue.bolder': '--ds-chart-blue-bolder',
  'color.chart.blue.bolder.hovered': '--ds-chart-blue-bolder-hovered',
  'color.chart.blue.boldest': '--ds-chart-blue-boldest',
  'color.chart.blue.boldest.hovered': '--ds-chart-blue-boldest-hovered',
  'color.chart.purple.bold': '--ds-chart-purple-bold',
  'color.chart.purple.bold.hovered': '--ds-chart-purple-bold-hovered',
  'color.chart.purple.bolder': '--ds-chart-purple-bolder',
  'color.chart.purple.bolder.hovered': '--ds-chart-purple-bolder-hovered',
  'color.chart.purple.boldest': '--ds-chart-purple-boldest',
  'color.chart.purple.boldest.hovered': '--ds-chart-purple-boldest-hovered',
  'color.chart.magenta.bold': '--ds-chart-magenta-bold',
  'color.chart.magenta.bold.hovered': '--ds-chart-magenta-bold-hovered',
  'color.chart.magenta.bolder': '--ds-chart-magenta-bolder',
  'color.chart.magenta.bolder.hovered': '--ds-chart-magenta-bolder-hovered',
  'color.chart.magenta.boldest': '--ds-chart-magenta-boldest',
  'color.chart.magenta.boldest.hovered': '--ds-chart-magenta-boldest-hovered',
  'color.chart.gray.bold': '--ds-chart-gray-bold',
  'color.chart.gray.bold.hovered': '--ds-chart-gray-bold-hovered',
  'color.chart.gray.bolder': '--ds-chart-gray-bolder',
  'color.chart.gray.bolder.hovered': '--ds-chart-gray-bolder-hovered',
  'color.chart.gray.boldest': '--ds-chart-gray-boldest',
  'color.chart.gray.boldest.hovered': '--ds-chart-gray-boldest-hovered',
  'color.chart.brand': '--ds-chart-brand',
  'color.chart.brand.hovered': '--ds-chart-brand-hovered',
  'color.chart.danger': '--ds-chart-danger',
  'color.chart.danger.hovered': '--ds-chart-danger-hovered',
  'color.chart.danger.bold': '--ds-chart-danger-bold',
  'color.chart.danger.bold.hovered': '--ds-chart-danger-bold-hovered',
  'color.chart.warning': '--ds-chart-warning',
  'color.chart.warning.hovered': '--ds-chart-warning-hovered',
  'color.chart.warning.bold': '--ds-chart-warning-bold',
  'color.chart.warning.bold.hovered': '--ds-chart-warning-bold-hovered',
  'color.chart.success': '--ds-chart-success',
  'color.chart.success.hovered': '--ds-chart-success-hovered',
  'color.chart.success.bold': '--ds-chart-success-bold',
  'color.chart.success.bold.hovered': '--ds-chart-success-bold-hovered',
  'color.chart.discovery': '--ds-chart-discovery',
  'color.chart.discovery.hovered': '--ds-chart-discovery-hovered',
  'color.chart.discovery.bold': '--ds-chart-discovery-bold',
  'color.chart.discovery.bold.hovered': '--ds-chart-discovery-bold-hovered',
  'color.chart.information': '--ds-chart-information',
  'color.chart.information.hovered': '--ds-chart-information-hovered',
  'color.chart.information.bold': '--ds-chart-information-bold',
  'color.chart.information.bold.hovered': '--ds-chart-information-bold-hovered',
  'elevation.surface': '--ds-surface',
  'elevation.surface.hovered': '--ds-surface-hovered',
  'elevation.surface.pressed': '--ds-surface-pressed',
  'elevation.surface.overlay': '--ds-surface-overlay',
  'elevation.surface.overlay.hovered': '--ds-surface-overlay-hovered',
  'elevation.surface.overlay.pressed': '--ds-surface-overlay-pressed',
  'elevation.surface.raised': '--ds-surface-raised',
  'elevation.surface.raised.hovered': '--ds-surface-raised-hovered',
  'elevation.surface.raised.pressed': '--ds-surface-raised-pressed',
  'elevation.surface.sunken': '--ds-surface-sunken',
  'elevation.shadow.overflow': '--ds-shadow-overflow',
  'elevation.shadow.overflow.perimeter': '--ds-shadow-overflow-perimeter',
  'elevation.shadow.overflow.spread': '--ds-shadow-overflow-spread',
  'elevation.shadow.overlay': '--ds-shadow-overlay',
  'elevation.shadow.raised': '--ds-shadow-raised',
  'opacity.disabled': '--ds-opacity-disabled',
  'opacity.loading': '--ds-opacity-loading',
  'utility.UNSAFE.textTransformUppercase': '--ds-UNSAFE-textTransformUppercase',
  'utility.UNSAFE.transparent': '--ds-UNSAFE-transparent',
  'utility.elevation.surface.current': '--ds-elevation-surface-current',
  'border.radius.050': '--ds-border-radius-050',
  'border.radius': '--ds-border-radius',
  'border.radius.100': '--ds-border-radius-100',
  'border.radius.200': '--ds-border-radius-200',
  'border.radius.300': '--ds-border-radius-300',
  'border.radius.400': '--ds-border-radius-400',
  'border.radius.circle': '--ds-border-radius-circle',
  'border.width': '--ds-border-width',
  'border.width.0': '--ds-border-width-0',
  'border.width.indicator': '--ds-border-width-indicator',
  'border.width.outline': '--ds-border-width-outline',
  'space.0': '--ds-space-0',
  'space.025': '--ds-space-025',
  'space.050': '--ds-space-050',
  'space.075': '--ds-space-075',
  'space.100': '--ds-space-100',
  'space.150': '--ds-space-150',
  'space.200': '--ds-space-200',
  'space.250': '--ds-space-250',
  'space.300': '--ds-space-300',
  'space.400': '--ds-space-400',
  'space.500': '--ds-space-500',
  'space.600': '--ds-space-600',
  'space.800': '--ds-space-800',
  'space.1000': '--ds-space-1000',
  'space.negative.025': '--ds-space-negative-025',
  'space.negative.050': '--ds-space-negative-050',
  'space.negative.075': '--ds-space-negative-075',
  'space.negative.100': '--ds-space-negative-100',
  'space.negative.150': '--ds-space-negative-150',
  'space.negative.200': '--ds-space-negative-200',
  'space.negative.250': '--ds-space-negative-250',
  'space.negative.300': '--ds-space-negative-300',
  'space.negative.400': '--ds-space-negative-400',
  'font.letterSpacing.0': '--ds-font-letterSpacing-0',
  'font.letterSpacing.100': '--ds-font-letterSpacing-100',
  'font.letterSpacing.200': '--ds-font-letterSpacing-200',
  'font.letterSpacing.300': '--ds-font-letterSpacing-300',
  'font.letterSpacing.400': '--ds-font-letterSpacing-400',
  'font.heading.xxlarge': '--ds-font-heading-xxlarge',
  'font.heading.xlarge': '--ds-font-heading-xlarge',
  'font.heading.large': '--ds-font-heading-large',
  'font.heading.medium': '--ds-font-heading-medium',
  'font.heading.small': '--ds-font-heading-small',
  'font.heading.xsmall': '--ds-font-heading-xsmall',
  'font.heading.xxsmall': '--ds-font-heading-xxsmall',
  'font.body.large': '--ds-font-body-large',
  'font.body': '--ds-font-body',
  'font.body.small': '--ds-font-body-small',
  'font.body.UNSAFE_small': '--ds-font-body-UNSAFE_small',
  'font.code': '--ds-font-code',
  'font.weight.regular': '--ds-font-weight-regular',
  'font.weight.medium': '--ds-font-weight-medium',
  'font.weight.semibold': '--ds-font-weight-semibold',
  'font.weight.bold': '--ds-font-weight-bold',
  'font.family.heading': '--ds-font-family-heading',
  'font.family.body': '--ds-font-family-body',
  'font.family.code': '--ds-font-family-code',
  'font.family.monospace': '--ds-font-family-monospace',
  'font.family.sans': '--ds-font-family-sans',
  'font.family.brand.heading': '--ds-font-family-brand-heading',
  'font.family.brand.body': '--ds-font-family-brand-body',
  'font.size.050': '--ds-font-size-050',
  'font.size.075': '--ds-font-size-075',
  'font.size.100': '--ds-font-size-100',
  'font.size.200': '--ds-font-size-200',
  'font.size.300': '--ds-font-size-300',
  'font.size.400': '--ds-font-size-400',
  'font.size.500': '--ds-font-size-500',
  'font.size.600': '--ds-font-size-600',
  'font.lineHeight.1': '--ds-font-lineHeight-1',
  'font.lineHeight.100': '--ds-font-lineHeight-100',
  'font.lineHeight.200': '--ds-font-lineHeight-200',
  'font.lineHeight.300': '--ds-font-lineHeight-300',
  'font.lineHeight.400': '--ds-font-lineHeight-400',
  'font.lineHeight.500': '--ds-font-lineHeight-500',
  'font.lineHeight.600': '--ds-font-lineHeight-600'
};
export default tokens;