/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Portuguese (Brazil)
export default {
  'focused-task-close-account.cancel': 'Cancelar',
  'focused-task-close-account.deactivate-account': 'Desativar conta',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Você está prestes a desativar a conta de usuário de:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Você está prestes a desativar a conta de usuário de:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Desativar conta',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Desativar conta',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Você pode reativar a conta a qualquer momento.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Você pode reativar a conta a qualquer momento.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Quando você desativar a conta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Quando você desativar a conta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Não vamos mais cobrar você por esse usuário.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Não vamos mais cobrar você por esse usuário.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente ele(a) possui acesso a:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente ele(a) não possui acesso a nenhum serviço, exceto serviços como Community e Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Outros serviços de conta da Atlassian, como a Atlassian Community e o Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Você <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente você possui acesso a:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Você <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente você não possui acesso a nenhum serviço, exceto serviços como Community e Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Seus dados pessoais, como nome completo e endereço de e-mail, e o conteúdo criado permanecerão nos serviços de conta da Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Seus dados pessoais, como nome completo e endereço de e-mail, e o conteúdo criado permanecerão nos serviços de conta da Atlassian.',
  'focused-task-close-account.delete-account': 'Excluir conta',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Observação: responder a esta pesquisa nos ajudará a melhorar a experiência para todos os usuários. O usuário continuará sendo exibido como "Ex-usuário" depois que você excluir a conta dele.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Observação: responder a esta pesquisa nos ajudará a melhorar a experiência para todos os usuários, inclusive para quem excluir a própria conta. Você continuará sendo exibido(a) como "Ex-usuário" depois de excluir sua conta.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Ex-usuário',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Como você prefere que o usuário excluído seja exibido?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Como você prefere que sua conta excluída seja exibida?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Se você pudesse escolher, como preferiria que o usuário excluído fosse exibido aos outros usuários?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Se você pudesse escolher, como preferiria ser exibido(a) aos outros usuários?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Depois que a conta do usuário for excluída, ele será exibido como "Ex-usuário" para os outros usuários. Reserve um momento para responder à pergunta da nossa pesquisa.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Depois que sua conta for excluída, você será exibido(a) como "Ex-usuário" para os outros usuários. Reserve um momento para responder à pergunta da nossa pesquisa.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Mostrar menos',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} mais',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Você está prestes a excluir a conta de:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Você está prestes a excluir sua conta:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Excluir conta',
  'focused-task-close-account.delete-account.overview.heading.self': 'Excluir sua conta',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Por exemplo, páginas, itens e comentários que ele(a) criou em produtos.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Por exemplo, páginas, itens e comentários que você criou em produtos.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Você ou outros usuários podem ter instalado aplicativos que adicionam funções aos produtos da Atlassian. Esses aplicativos podem ter armazenado as informações de perfil do usuário.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Você ou outros usuários podem ter instalado aplicativos que adicionam funções aos produtos da Atlassian. Esses aplicativos podem ter armazenado suas informações de perfil.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Informações relacionadas a compras, que precisamos manter para relatórios financeiros.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Informações relacionadas a compras, que precisamos manter para relatórios financeiros.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Registros que mostram que excluímos a conta de alguém, que podemos precisar fornecer a órgãos reguladores.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Registros que mostram que excluímos a conta de alguém, que podemos precisar fornecer a órgãos reguladores.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Dados que fazem parte de uma ação judicial em atividade que, por lei, somos obrigados a manter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Dados que fazem parte de uma ação judicial em atividade que, por lei, somos obrigados a manter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Mantemos dados pessoais por períodos limitados quando temos fins comerciais ou legais legítimos. Alguns exemplos incluem:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Mantemos dados pessoais por períodos limitados quando temos fins comerciais ou legais legítimos. Alguns exemplos incluem:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Não excluímos dados pessoais de conteúdos criados por usuários, como nomes e endereços de e-mail digitados em uma página ou item. Os administradores do produto precisam excluir esses dados manualmente.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Não excluímos dados pessoais de conteúdos criados por você ou outras pessoas, como nomes e endereços de e-mail digitados em uma página ou item. Seus administradores do produto precisam excluir esses dados manualmente.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Usuários têm o direito de enviar queixas à autoridade fiscalizadora do seu país.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Você tem o direito de enviar queixas à autoridade fiscalizadora do seu país.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Quando você excluir a conta:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Quando você excluir sua conta:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'O conteúdo criado permanecerá nos serviços de conta da Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'O conteúdo criado permanecerá nos serviços de conta da Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Depois de um período de carência de 14 dias, você não poderá cancelar a exclusão da conta.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Vamos enviar um e-mail para você com uma lista de aplicativos que podem ter armazenado seus dados pessoais.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Vamos enviar um e-mail para você com uma lista de aplicativos que podem ter armazenado seus dados pessoais.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente ele(a) possui acesso a:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente ele(a) não possui acesso a nenhum serviço, exceto serviços como Community e Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Outros serviços de conta da Atlassian, como a Atlassian Community e o Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Você <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente você possui acesso a:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Você <b>perderá o acesso imediatamente</b> a todos os serviços de conta da Atlassian. Atualmente você não possui acesso a nenhum serviço, exceto serviços como Community e Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Vamos <b>excluir seus dados pessoais</b>, tais como nome completo e endereço de e-mail, dos serviços de conta da Atlassian dentro de 30 dias, exceto em alguns casos obrigatórios para fins comerciais ou legais legítimos.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Vamos <b>excluir seus dados pessoais</b>, tais como nome completo e endereço de e-mail, dos serviços de conta da Atlassian dentro de 30 dias, exceto em alguns casos obrigatórios para fins comerciais ou legais legítimos.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Depois de um período de carência de 14 dias, você não poderá cancelar a exclusão da conta. Se você achar que vai precisar da conta depois, desative-a.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Depois de um período de carência de 14 dias, você não poderá cancelar a exclusão da conta.',
  'focused-task-close-account.learnMore': 'Saiba mais',
  'focused-task-close-account.next': 'Próximo',
  'focused-task-close-account.previous': 'Anterior'
};