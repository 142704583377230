/**
 * Abstractions over functions of the window object to facilitate mocking in tests.
 */
export function redirect(hostname: string) {
  window.location.assign(hostname);
}

export function reload() {
  window.location.reload();
}
