import { Placement } from '@atlaskit/inline-dialog';

export type ValueType = string;

export interface Option {
  label: React.ReactNode;
  value?: ValueType | null;
  icon?: JSX.Element;
  description?: React.ReactNode;
}

export interface Options {
  [k: string]: Option | undefined;
}

export interface SubtleDropdownProps {
  customComponents?: {
    Item?: React.ComponentType<Option>;
  };
  appearance?: 'default' | 'tall';
  options: Options;
  value?: ValueType;
  // when `value` is not in `options`, we render `placeHolderOption` as the dropdown trigger
  placeHolderOption?: Option;
  isLoading?: boolean;
  errorMessage?: React.ReactNode | string;
  disabledMessage?: React.ReactNode | string;
  position?: Placement;
  onChange: (value: ValueType) => void;
}

export interface SubtleDropdownState {
  isHovered: boolean;
  isMenuOpen: boolean;
  isInlineDialogOpen: boolean;
}
