import styled from '@emotion/styled';
import { typography } from '@atlaskit/theme';

export const SettingsDescription = styled.div`
  h3 {
    ${typography.h500 as any};
  }

  p {
    margin: 12px 0;
  }
`;

export const LoadingState = styled.div`
  width: 100%;
  height: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SettingsSection = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const SectionMessageWrapper = styled.div`
  margin: 1em 0;
`;
