import { useCallback } from 'react';

import { useAccountClient } from '../../common/context';

export const useSwitchAccount = () => {
  const client = useAccountClient();
  return useCallback(
    (accountId: string) => {
      void client.switchAccount(accountId);
    },
    [client],
  );
};
