import '../../../../styled/core';

import { useEffect } from 'react';
import window from 'window-or-global';
import { useIntl, MessageDescriptor, defineMessages } from 'react-intl-next';

import { Flag, getHistory, addFlag } from '../../../../common';
import { Omit } from '../../../../tstypes';
import { FlagHashes } from './types';
import { connect } from 'react-redux';

type HashFlag = Omit<Flag, 'title' | 'description'> & {
  title?: MessageDescriptor;
  description?: MessageDescriptor;
};

type DispatchToProps = {
  dispatchAddFlag: typeof addFlag;
};

type UserSettingsFlagByHashProps = DispatchToProps;

export const UserSettingsFlagByHash = ({
  dispatchAddFlag,
}: UserSettingsFlagByHashProps) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    const hashLocation = window.location.hash || '';

    const clearHash = () => {
      const { pathname, search } = window.location;
      getHistory().replace(`${pathname}${search}`);
    };

    const showFlagsIfHave = () => {
      const message: HashFlag | undefined = HASH_TO_FLAG_MESSAGE[hashLocation];

      if (message) {
        const { title, description } = message;

        dispatchAddFlag({
          ...message,
          title: title && formatMessage(title),
          description: description && formatMessage(description),
        });

        clearHash();
      }
    };

    showFlagsIfHave();
  }, [dispatchAddFlag, formatMessage]);

  return null;
};

export const messages = defineMessages({
  flagAccountRecoverTitle: {
    id: 'manage.profile.flag.account.recover.title',
    defaultMessage: 'Account recovery request cancelled',
    description:
      'Title of a success flag to let a user know that they have cancelled their request to recover their account because they have lost their MFA device',
  },
  flagAccountRecoverMsg: {
    id: 'manage.profile.flag.account.recover.message',
    defaultMessage: "We've cancelled your account recovery request.",
    description:
      'Message of a success flag to let a user know that they have cancelled their request to recover their account because they have lost their MFA device',
  },
  flagVerifiedAccountTitle: {
    id: 'manage.profile.flag.verified.account.title',
    defaultMessage: 'Welcome to your Atlassian account',
    description:
      'Title of a success flag to tell a user they have successfully verified their email address',
  },
  flagVerifiedAccountMsg: {
    id: 'manage.profile.flag.verified.account.message',
    defaultMessage: "We've verified your email address.",
    description:
      'Message of a success flag to tell a user they have successfully verified their email address',
  },
  // TODO: Get updated copy
  flagRegenerateRecoveryCodeSuccessTitle: {
    id: 'manage.profile.flag.regenerate-recovery-code.success.title',
    defaultMessage: 'You created a new recovery key',
    description:
      'Title of flag shown after the user regenerates their TSV recovery code',
  },
  flagRegenerateRecoveryCodeSuccessMsg: {
    id: 'manage.profile.flag.regenerate-recovery-code.success.message',
    defaultMessage: 'Your old recovery key no longer works.',
    description:
      'Message of flag shown after the user regenerates their TSV recovery code',
  },
  flagRegenerateRecoveryCodeUnauthorizedTitle: {
    id: 'manage.profile.flag.regenerate-recovery-code.unauthorized.title',
    defaultMessage: "We've locked your two-step verification settings.",
    description:
      "Title of flag shown after the user fails to regenerate their TSV recovery code because they weren't authorized",
  },
  flagTsvEnrollmentSuccessTitle: {
    id: 'manage.profile.flag.flag.tsv.enrollment.successful.title',
    defaultMessage: "You're all set!",
    description:
      'Title of the flag shown after user successfully finishes two step verification enrollment flow',
  },
  flagTsvEnrollmentSuccessMessage: {
    id: 'manage.profile.flag.flag.tsv.enrollment.successful.message',
    defaultMessage:
      "The next time you log in, you'll need to enter a 6-digit verification code.",
    description:
      'Description of the flag shown after user successfully finishes two step verification enrollment flow',
  },
  flagChangePasswordSuccessFlagMessage: {
    id: 'manage.profile.flag.flag.change.password.successful.message',
    defaultMessage: 'Your password was successfully changed',
    description:
      'Message body of flag informing the user that their password change was successful.',
  },
  flagChangePasswordSuccessFlagTitle: {
    id: 'manage.profile.flag.flag.change.password.successful.title',
    defaultMessage: 'Success',
    description:
      'Title of flag informing the user that their password change was successful.',
  },
});

const HASH_TO_FLAG_MESSAGE: Record<FlagHashes, HashFlag> = {
  [FlagHashes.TSV_RECOVERY_CANCELED]: {
    type: 'success',
    title: messages.flagAccountRecoverTitle,
    description: messages.flagAccountRecoverMsg,
    id: 'manage.profile.flag.account.recover',
  },
  [FlagHashes.VERIFIED]: {
    type: 'success',
    title: messages.flagVerifiedAccountTitle,
    description: messages.flagVerifiedAccountMsg,
    id: 'manage.profile.flag.verified.account',
  },
  [FlagHashes.TSV_REGENERATE_RECOVERY_CODE_SUCCESS]: {
    type: 'success',
    title: messages.flagRegenerateRecoveryCodeSuccessTitle,
    description: messages.flagRegenerateRecoveryCodeSuccessMsg,
    id: 'manage.profile.flag.regenerate-recover-code.success',
  },
  [FlagHashes.TSV_REGENERATE_RECOVERY_CODE_UNAUTHORIZED]: {
    type: 'error',
    title: messages.flagRegenerateRecoveryCodeUnauthorizedTitle,
    id: 'manage.profile.flag.regenerate-recover-code.unauthorized',
  },
  [FlagHashes.TSV_ENROLLMENT_SUCCESS]: {
    type: 'success',
    title: messages.flagTsvEnrollmentSuccessTitle,
    description: messages.flagTsvEnrollmentSuccessMessage,
    id: 'manage.profile.flag.tsv.enrollment.success',
  },
  [FlagHashes.CHANGE_PASSWORD_SUCCESS]: {
    type: 'success',
    title: messages.flagChangePasswordSuccessFlagTitle,
    description: messages.flagChangePasswordSuccessFlagMessage,
    id: 'manage.profile.flag.change.password.success',
  },
};

const mapDispatchToProps: DispatchToProps = { dispatchAddFlag: addFlag };

export default connect(undefined, mapDispatchToProps)(UserSettingsFlagByHash);
