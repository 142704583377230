/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Polish (Poland)
export default {
  'focused-task-close-account.cancel': 'Anuluj',
  'focused-task-close-account.deactivate-account': 'Dezaktywuj konto',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Zamierzasz dezaktywować konto użytkownika:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Zamierzasz dezaktywować konto użytkownika:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Dezaktywuj konto',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Dezaktywuj konto',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'W każdej chwili możesz ponownie aktywować konto.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'W każdej chwili możesz ponownie aktywować konto.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Gdy dezaktywujesz konto:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Gdy dezaktywujesz konto:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'W związku z tym nie będziemy już naliczać Ci opłat za tych użytkowników.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'W związku z tym nie będziemy już naliczać Ci opłat za tych użytkowników.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>natychmiast utraci dostęp</b> do wszystkich usług kont Atlassian. Obecnie ma dostęp do:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} natychmiast <b>utraci dostęp</b> do wszystkich usług kont Atlassian. Obecnie nie ma dostępu do żadnych innych usług z wyjątkiem Community i Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Inne usługi konta Atlassian, takie jak społeczność Atlassian Community i sklep Atlassian Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': '<b>Natychmiast utracisz dostęp</b> do wszystkich usług kont Atlassian. Obecnie masz dostęp do:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Natychmiast <b>utracisz dostęp</b> do wszystkich usług kont Atlassian. Obecnie nie masz dostępu do żadnych innych usług z wyjątkiem Community i Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Dane osobowe, takie jak imię i nazwisko i adres e-mail oraz utworzona treść pozostaną w usługach konta Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Dane osobowe, takie jak imię i nazwisko i adres e-mail oraz utworzona treść pozostaną w usługach konta Atlassian.',
  'focused-task-close-account.delete-account': 'Usuń konto',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Uwaga: odpowiedzi udzielone w tej ankiecie pomogą nam zapewnić lepszą obsługę wszystkim użytkownikom. Po usunięciu konta użytkownik nadal będzie wyświetlany jako „Były użytkownik”.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Uwaga: odpowiedzi udzielone w tej ankiecie pomogą nam zapewnić lepszą obsługę wszystkim użytkownikom, również tym, którzy usunęli swoje konta. Po usunięciu konta użytkownik nadal będzie wyświetlany jako „Były użytkownik”.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Były użytkownik',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Jak Twoim zdaniem usunięty użytkownik powinien być widoczny?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Jak Twoim zdaniem powinno być widoczne usunięte konto?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Jak Twoim zdaniem usunięty użytkownik powinien być widoczny dla innych użytkowników?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Jak po usunięciu Twojego konta powinno ono być widoczne dla innych użytkowników?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Po usunięciu konta użytkownika będzie on widoczny jako „Były użytkownik” dla innych użytkowników. Poświęć chwilę, aby odpowiedzieć na nasze pytanie w ankiecie.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Po usunięciu konta będziesz widoczny dla innych użytkowników jako „Były użytkownik”. Poświęć chwilę, aby odpowiedzieć na nasze pytanie w ankiecie.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Pokaż mniej',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'jeszcze {num}',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Zamierzasz usunąć konto:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Zamierzasz usunąć swoje konto:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Usuń konto',
  'focused-task-close-account.delete-account.overview.heading.self': 'Usuń konto',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Na przykład strony, zgłoszenia i komentarze, które zostały utworzone w produktach.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Na przykład strony, zgłoszenia i komentarze utworzone w produktach.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Ty lub inni użytkownicy mogą mieć zainstalowane aplikacje, które dodają funkcje do produktów Atlassian. Aplikacje te mogły przechowywać informacje o profilu użytkownika.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Ty lub inni użytkownicy mogą mieć zainstalowane aplikacje, które dodają funkcje do produktów Atlassian. Aplikacje te mogły przechowywać informacje o Twoim profilu.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Informacje związane z zakupami, które jesteśmy zobowiązani przechowywać do celów sprawozdawczości finansowej.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Informacje związane z zakupami, które jesteśmy zobowiązani przechowywać do celów sprawozdawczości finansowej.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Zapisy wskazujące, że usunęliśmy czyjeś konto, które być może będziemy musieli przekazać organom regulacyjnym.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Zapisy wskazujące, że usunęliśmy czyjeś konto, które być może będziemy musieli przekazać organom regulacyjnym.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Dane, które są częścią aktywnego procesu sądowego, do przechowywania których jesteśmy zobowiązani na mocy prawa.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Dane, które są częścią aktywnego procesu sądowego, do przechowywania których jesteśmy zobowiązani na mocy prawa.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Dane osobowe przechowujemy przez ograniczony czas, gdy mamy uzasadnione cele biznesowe lub prawne. Oto kilka przykładów:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Dane osobowe przechowujemy przez ograniczony czas, gdy mamy uzasadnione cele biznesowe lub prawne. Oto kilka przykładów:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Nie usuwamy żadnych danych osobowych z treści tworzonych przez użytkowników, takich jak imiona i nazwiska lub adresy e-mail, które wpisali na stronie lub w zgłoszeniu. Administratorzy produktów muszą usunąć te dane ręcznie.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Nie usuwamy żadnych danych osobowych z treści stworzonych przez Ciebie lub inne osoby, takich jak imiona i nazwiska lub adresy e-mail wpisane na stronie lub w zgłoszeniu. Administratorzy produktów muszą usunąć te dane ręcznie.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Użytkownicy mają prawo do składania skarg do organu nadzorczego w swoim kraju.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Masz prawo do składania skarg do organu nadzorczego w swoim kraju.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Gdy usuniesz konto:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Gdy usuniesz swoje konto:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Utworzone przez nich treści pozostaną w usługach kont Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Utworzone przez Ciebie treści pozostaną w usługach kont Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Po 14-dniowym okresie anulowanie usunięcia konta nie będzie możliwe.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Wyślemy Ci e-mail z listą aplikacji, które mogły przechowywać dane osobowe.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Wyślemy Ci e-mail z listą aplikacji, które mogły przechowywać dane osobowe.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>natychmiast utraci dostęp</b> do wszystkich usług kont Atlassian. Obecnie ma dostęp do:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>natychmiast utraci dostęp</b> do wszystkich usług kont Atlassian. Obecnie nie ma dostępu do żadnych innych usług z wyjątkiem Community i Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Inne usługi konta Atlassian, takie jak społeczność Atlassian Community i sklep Atlassian Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': '<b>Natychmiast utracisz dostęp</b> do wszystkich usług kont Atlassian. Obecnie masz dostęp do:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': '<b>Natychmiast utracisz dostęp</b> do wszystkich usług kont Atlassian. Obecnie nie masz dostępu do żadnych innych usług z wyjątkiem Community i Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'W ciągu 30 dni <b>usuniemy ich dane osobowe</b>, takie jak imię i nazwisko oraz adres e-mail, z usług kont Atlassian, z wyjątkiem kilku przypadków, gdy jest to wymagane w uzasadnionych celach biznesowych lub prawnych.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'W ciągu 30 dni <b>usuniemy Twoje dane osobowe</b>, takie jak imię i nazwisko oraz adres e-mail, z usług kont Atlassian, z wyjątkiem kilku przypadków, gdy jest to wymagane w uzasadnionych celach biznesowych lub prawnych.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Po 14-dniowym okresie anulowanie usunięcia konta nie będzie możliwe. Jeśli uważasz, że konto może być Ci potrzebne później, dezaktywuj je.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Po 14-dniowym okresie anulowanie usunięcia konta nie będzie możliwe.',
  'focused-task-close-account.learnMore': 'Dowiedz się więcej',
  'focused-task-close-account.next': 'Dalej',
  'focused-task-close-account.previous': 'Wstecz'
};