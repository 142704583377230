import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback, useContext, useEffect, useState } from 'react';
import { RadioGroupContext } from '../../radio/dropdown-item-radio-group';
import { SelectionStoreContext } from '../context/selection-store';
import resetOptionsInGroup from '../utils/reset-options-in-group';
function useRadioState(_ref) {
  var id = _ref.id,
    isSelected = _ref.isSelected,
    defaultSelected = _ref.defaultSelected;
  var _useContext = useContext(SelectionStoreContext),
    setGroupState = _useContext.setGroupState,
    getGroupState = _useContext.getGroupState;
  var _useContext2 = useContext(RadioGroupContext),
    group = _useContext2.id,
    radioGroupState = _useContext2.radioGroupState,
    selectRadioItem = _useContext2.selectRadioItem;
  var persistedIsSelected = radioGroupState[id];
  var _useState = useState(function () {
      return persistedIsSelected !== undefined ? persistedIsSelected : defaultSelected || false;
    }),
    _useState2 = _slicedToArray(_useState, 2),
    localIsSelected = _useState2[0],
    setLocalIsSelected = _useState2[1];
  var setLocalState = useCallback(function (newValue) {
    if (!persistedIsSelected) {
      var nextValue = newValue(persistedIsSelected);
      selectRadioItem(id, nextValue);
      setLocalIsSelected(nextValue);
    }
  }, [persistedIsSelected, id, selectRadioItem]);

  /**
   * - radio state changes any time a radio child is changed
   * - when it changes we want all radio buttons to update their local state
   * - it takes the value from radio group state and applies it locally if it exists which it will only exist, if it is selected
   */
  useEffect(function () {
    setLocalIsSelected(function () {
      var existing = radioGroupState[id];
      return existing !== undefined ? existing : defaultSelected || false;
    });
  }, [radioGroupState, group, id, defaultSelected]);
  if (typeof isSelected === 'boolean') {
    return [isSelected, function () {
      return false;
    }];
  }
  if (persistedIsSelected === undefined) {
    var existing = getGroupState(group);
    setGroupState(group, _objectSpread(_objectSpread({}, resetOptionsInGroup(existing || {})), {}, _defineProperty({}, id, defaultSelected || false)));
  }
  return [localIsSelected, setLocalState];
}
export default useRadioState;