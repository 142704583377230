import React from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';

import { DialogEntry } from './types';
import useDialogs from './useDialogs';

/**
 * DialogRenderer is used to render actual Dialog inside AK ModalTransition.
 */
export default function DialogRenderer(props: DialogEntry) {
  const { DialogComponent, isOpen, onClose, dialogProps } = props;
  const { closeDialog } = useDialogs();

  if (!DialogComponent) {
    return null;
  }

  const handleCloseDialog = () => {
    closeDialog();
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <DialogComponent {...dialogProps} onClose={handleCloseDialog} />
      )}
    </ModalTransition>
  );
}
