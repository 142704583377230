import { useCallback } from 'react';
import { useAnalyticsEvents } from './useAnalyticsEvents';
import { useTrackedRef } from './useTrackedRef';
export var useCallbackWithAnalytics = function useCallbackWithAnalytics(method, payload, channel) {
  var _useAnalyticsEvents = useAnalyticsEvents(),
    createAnalyticsEvent = _useAnalyticsEvents.createAnalyticsEvent;
  var methodRef = useTrackedRef(method);
  var payloadRef = useTrackedRef(payload);
  return useCallback(function () {
    var pload = typeof payloadRef.current === 'function' ? payloadRef.current.apply(payloadRef, arguments) : payloadRef.current;
    createAnalyticsEvent(pload).fire(channel);
    methodRef.current.apply(methodRef, arguments);
  }, [createAnalyticsEvent, methodRef, payloadRef, channel]);
};