import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { AvatarSize } from './types';

const avatarSizeTimes = (multiplier: number) => multiplier * 8; // 8px is the design system base unit
export const AVATAR_SIZES: {
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
  xxlarge: number;
} = {
  xsmall: avatarSizeTimes(2),
  small: avatarSizeTimes(3),
  medium: avatarSizeTimes(4),
  large: avatarSizeTimes(5),
  xlarge: avatarSizeTimes(12),
  xxlarge: avatarSizeTimes(16),
};

const avatarBorder = 2;

export const AvatarWrapper = styled.div`
  position: relative;
  display: block;

  && * {
    box-sizing: content-box;
  }

  &:hover,
  &:focus {
    outline: none;
  }
`;

interface SizeProp {
  size: AvatarSize;
}

export const AvatarHoverInfo = styled.div`
  opacity: 0;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ size }: SizeProp) => AVATAR_SIZES[size]}px;
  height: ${({ size }: SizeProp) => AVATAR_SIZES[size]}px;
  border-radius: 50%;
  margin: ${avatarBorder}px;

  & > * {
    display: none;
  }

  /* In order to simulate hover event in Cypress */
  &.hover,
  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.25s ease;
    color: ${token('color.text.inverse', colors.N0)};
    background: ${token('color.background.neutral.hovered', colors.N70A)};
    & > * {
      display: block;
    }
  }
`;

export const AvatarOpenPickerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${token('space.050', '4px')};
`;
