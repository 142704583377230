import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../../../../../utils/fetchApi';
import { getConfig } from '../../../../../common';
import { haveISeenItKey } from '../constants';

const dismissedAdminManagersAccountBannerKey =
  'dismissedAdminManagersAccountBannerKey';

export type DismissedAdminManagesAccountBannerResponse = {
  status: boolean;
  lastSeenDate: string;
};

const dismissedAdminManagesAccountBannerQueryFn = async () => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/flag/my?flagKey=manage.profile.have-i-seen-it.${encodeURIComponent(
    haveISeenItKey,
  )}`;
  try {
    const response = await fetchApi(url);
    const data: DismissedAdminManagesAccountBannerResponse = await response.json();
    return {
      id: haveISeenItKey,
      ...data,
    };
  } catch (error) {
    return {
      id: haveISeenItKey,
      lastSeenDate: null,
      status: true,
    };
  }
};

export const useDismissedAdminManagesAccountBannerQuery = (enabled = false) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [dismissedAdminManagersAccountBannerKey],
    queryFn: () => dismissedAdminManagesAccountBannerQueryFn(),
    enabled,
  });

  return { data, isLoading, isError };
};
