import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { IS_SIDEBAR_DRAGGING } from '../constants';
var getIsDragging = function getIsDragging() {
  // SSR bail-out because document is undefined on the server
  if (typeof document === 'undefined') {
    return false;
  }
  return document.documentElement.getAttribute(IS_SIDEBAR_DRAGGING) === 'true';
};

// TODO: I think this should be derived from the sidebar state,
// and not indirectly from observing an attribute change
var useIsSidebarDragging = function useIsSidebarDragging() {
  var _useState = useState(getIsDragging),
    _useState2 = _slicedToArray(_useState, 2),
    isDragging = _useState2[0],
    setIsDragging = _useState2[1];
  useEffect(function () {
    var observer = new MutationObserver(function () {
      setIsDragging(getIsDragging);
    });
    observer.observe(document.documentElement, {
      attributeFilter: [IS_SIDEBAR_DRAGGING]
    });
    return function () {
      observer.disconnect();
    };
  }, []);
  return isDragging;
};
export default useIsSidebarDragging;