import RestClient from './rest-client';
import { getConfig } from '../config/envConfig';
import { CanCloseAccountResponse } from '../../apps/id-a-c/components/CloseAccountFlowTrigger/types';

class IdentityUserClient extends RestClient {
  constructor() {
    super({
      serviceUrl: `${getConfig().stargateRoot}/users`,
    });
  }

  deleteUser = (userId: string): Promise<void> => {
    const resourcePath = `/${userId}`;

    return this.deleteResource(resourcePath);
  };

  checkCloseAccount = (userId: string): Promise<CanCloseAccountResponse> => {
    return this.getResource(`/${userId}/can-close`);
  };
}

export default new IdentityUserClient();
