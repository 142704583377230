/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Danish (Denmark)
export default {
  'focused-task-close-account.cancel': 'Annullér',
  'focused-task-close-account.deactivate-account': 'Deaktivér konto',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Du er ved at deaktivere brugerkontoen for:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Du er ved at deaktivere brugerkontoen for:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Deaktivér konto',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Deaktivér konto',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Du kan genaktivere kontoen når som helst.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Du kan genaktivere kontoen når som helst.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Når du deaktiverer kontoen:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Når du deaktiverer kontoen:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Fakturerer vi dig ikke længere for vedkommende.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Fakturerer vi dig ikke længere for vedkommende.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>mister øjeblikkeligt adgang</b> til alle Atlassian-kontotjenester. Personen har p.t. adgang til:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>mister øjeblikkeligt adgang</b> til alle Atlassian-kontotjenester. Personen har p.t. ikke adgang til nogen bortset fra tjenester som Community og Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Andre Atlassian-kontotjenester såsom Atlassian Community og Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Du <b>mister øjeblikkeligt adgang</b> til alle Atlassian-kontotjenester. Du har p.t. adgang til:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Du <b>mister øjeblikkeligt adgang</b> til alle Atlassian-kontotjenester. Du har p.t. ikke adgang til nogen bortset fra tjenester som Community og Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Vedkommendes personoplysninger såsom fulde navn og e-mailadresse samt indhold, vedkommende har oprettet, vil blive i Atlassian-kontotjenesterne.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Vedkommendes personoplysninger såsom fulde navn og e-mailadresse samt indhold, vedkommende har oprettet, vil blive i Atlassian-kontotjenesterne.',
  'focused-task-close-account.delete-account': 'Slet konto',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Bemærk: Du hjælper os med at sikre alle brugere en bedre oplevelse ved at svare på denne undersøgelse. Brugeren fremstår stadig som en "Tidligere bruger", efter kontoen slettes.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Bemærk: Du hjælper os med at sikre alle brugere en bedre oplevelse, ved at svare på denne undersøgelse, herunder også de der sletter deres kontoer. Du bliver stadig vist som "Tidligere bruger", efter din konto slettes.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Tidligere bruger',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Hvordan foretrækker du, at den slettede bruger bliver vist?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Hvordan foretrækker du, at din slettede konto bliver vist?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Hvis du fik valget, hvordan ville du så foretrække, at den slettede bruger blev vist overfor andre brugere?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Hvis du fik valget, hvordan ville du så foretrække at blive vist overfor andre brugere?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Når din brugers konto slettes, bliver de vist som "Tidligere bruger" overfor andre brugere. Brug et øjeblik på at besvare vores undersøgelsesspørgsmål.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Når din konto slettes, bliver du vist som "Tidligere bruger" overfor andre brugere. Brug et øjeblik på at besvare vores undersøgelsesspørgsmål.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Vis mindre',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} mere',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Du er ved at slette kontoen for:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Du er ved at slette din konto:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Slet konto',
  'focused-task-close-account.delete-account.overview.heading.self': 'Slet din konto',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'For eksempel sider, opgaver og kommentarer, personen har tilføjet til produkter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'For eksempel sider, opgaver og kommentarer, du har tilføjet til produkter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Du og andre brugere kan have installeret apps, der tilføjer funktioner til Atlassian-produkter. Disse apps kan indeholde brugerens profiloplysninger.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Du og andre brugere kan have installeret apps, der tilføjer funktioner til Atlassian-produkter. Disse apps kan indeholde brugerens profiloplysninger.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Information vedrørende køb, som vi er forpligtede til at beholde af hensyn til finansiel rapportering.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Information vedrørende køb, som vi er forpligtede til at beholde af hensyn til finansiel rapportering.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Fortegnelser, der viser, at vi slettet en persons konto, som kan være forpligtede til at videregive til lovgivere.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Fortegnelser, der viser, at vi slettet en persons konto, som kan være forpligtede til at videregive til lovgivere.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Data, som indgår i en aktiv retssag, og som loven påbyder os at beholde.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Data, som indgår i en aktiv retssag, og som loven påbyder os at beholde.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Vi beholder private oplysninger i begrænsede tidsrum, når de er påkrævede til drift af lovlig virksomhed eller juridiske formål. Eksempler omfatter:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Vi beholder private oplysninger i begrænsede tidsrum, når de er påkrævede til drift af lovlig virksomhed eller juridiske formål. Eksempler omfatter:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Vi sletter ikke private oplysninger fra indhold, brugere har oprettet, såsom navne og e-mailadresser, de har indtastet på en side eller i en opgave. Disse oplysninger skal slettes manuelt af produktadministratorerne.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Vi sletter ikke private oplysninger fra indhold, brugere har oprettet, såsom navne og e-mailadresser, de har indtastet på en side eller i en opgave. Disse oplysninger skal slettes manuelt af produktadministratorerne.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Brugere har ret til at indsende klager til den relevante myndighed i deres land.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Du har ret til at indsende klager til den relevante myndighed i dit land.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Når du sletter kontoen:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Når du sletter din konto:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Indholdet, personen har oprettet, vil blive i Atlassian-kontotjenesterne.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Indholdet, du har oprettet, vil blive i Atlassian-kontotjenesterne.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Efter en henstandsperiode på 14 dage vil du ikke kunne annullere sletningen af din konto.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Vi sender dig en e-mail med en liste over apps, der kan indeholde personens private oplysninger.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Vi sender dig en e-mail med en liste over apps, der kan indeholde dine private oplysninger.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} vil <b>øjeblikkeligt miste adgang</b> til alle Atlassian-kontotjenester. Vedkommende har p.t. adgang til:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} vil <b>øjeblikkeligt miste adgang</b> til alle Atlassian-kontotjenester. Personen har p.t. ikke adgang til nogen, bortset fra tjenester som Fællesskab og Markedsplads.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Andre Atlassian-kontotjenester, såsom Atlassian Fællesskab og Markedsplads.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Du vil <b>øjeblikkeligt miste adgang</b> til alle Atlassian-kontotjenester. Du har p.t. adgang til:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Du vil <b>øjeblikkeligt miste adgang</b> til alle Atlassian-kontotjenester. Du har p.t. ikke adgang til nogen, bortset fra tjenester som Fællesskab og Markedsplads.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Vi vil<b>slette personens private oplysninger</b>, såsom vedkommendes fulde navn og e-mailadresse, fra Atlassian-kontotjenester inden for 30 dage, bortset fra nogle få tilfælde, hvor de er påkrævede til drift af lovlig virksomhed eller juridiske formål.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Vi vil<b>slette dine private oplysninger</b>, såsom dit fulde navn og e-mailadresse, fra Atlassian-kontotjenester inden for 30 dage, bortset fra nogle få tilfælde, hvor de er påkrævede til drift af lovlig virksomhed eller juridiske formål.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Efter en henstandsperiode på 14 dage vil du ikke kunne annullere sletningen af kontoen. Hvis du tror, at du får brug for kontoen senere, skal du deaktivere den i stedet.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Efter en henstandsperiode på 14 dage vil du ikke kunne annullere sletningen af kontoen.',
  'focused-task-close-account.learnMore': 'Læs mere',
  'focused-task-close-account.next': 'Næste',
  'focused-task-close-account.previous': 'Seneste'
};