import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { B200, N0, N10, N100, N200, N30, N40, N70, N900, R400 } from '@atlaskit/theme/colors';
import { codeFontFamily, fontFamily, fontSize as getFontSize,
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
gridSize as getGridSize } from '@atlaskit/theme/constants';
var fontSize = getFontSize();
var gridSize = getGridSize();
var backgroundColor = {
  standard: "var(--ds-background-input, ".concat(N10, ")"),
  subtle: 'transparent',
  none: 'transparent'
};
var backgroundColorFocus = {
  standard: "var(--ds-background-input-pressed, ".concat(N0, ")"),
  subtle: "var(--ds-background-input-pressed, ".concat(N0, ")"),
  none: 'transparent'
};
var backgroundColorHover = {
  standard: "var(--ds-background-input-hovered, ".concat(N30, ")"),
  subtle: "var(--ds-background-input-hovered, ".concat(N30, ")"),
  none: 'transparent'
};
var borderColor = {
  standard: "var(--ds-border-input, ".concat(getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? N100 : N40, ")"),
  subtle: 'transparent',
  none: 'transparent'
};
var borderColorFocus = {
  standard: "var(--ds-border-focused, ".concat(B200, ")"),
  subtle: "var(--ds-border-focused, ".concat(B200, ")"),
  none: 'transparent'
};
var borderColorHover = {
  standard: "var(--ds-border-input, ".concat(getBooleanFF('platform.design-system-team.border-checkbox_nyoiu') ? N100 : N40, ")"),
  subtle: "var(--ds-border-input, transparent)",
  none: 'transparent'
};
var getContainerTextBgAndBorderColor = function getContainerTextBgAndBorderColor(appearance) {
  return {
    backgroundColor: backgroundColor[appearance],
    borderColor: borderColor[appearance],
    color: "var(--ds-text, ".concat(N900, ")"),
    cursor: 'text',
    '&:hover:not([data-disabled])': {
      backgroundColor: backgroundColorHover[appearance],
      borderColor: borderColorHover[appearance]
    },
    '&:focus-within:not([data-disabled])': {
      backgroundColor: backgroundColorFocus[appearance],
      borderColor: borderColorFocus[appearance],
      boxShadow: getBooleanFF('platform.design-system-team.update-input-border-wdith_5abwv') ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", borderColorFocus[appearance]) : undefined
    },
    '&[data-disabled]': _objectSpread({
      color: "var(--ds-text-disabled, ".concat(N70, ")"),
      cursor: 'not-allowed'
    }, appearance === 'standard' && {
      backgroundColor: "var(--ds-background-disabled, ".concat(N10, ")"),
      borderColor: "var(--ds-background-disabled, ".concat(N10, ")")
    }),
    '&[data-invalid], &[data-invalid]:hover': {
      borderColor: "var(--ds-border-danger, ".concat(R400, ")"),
      boxShadow: getBooleanFF('platform.design-system-team.update-input-border-wdith_5abwv') ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", "var(--ds-border-danger, ".concat(R400, ")")) : undefined
    },
    '&[data-invalid]:focus-within': {
      backgroundColor: "var(--ds-background-input-pressed, ".concat(N0, ")"),
      borderColor: "var(--ds-border-focused, ".concat(B200, ")"),
      boxShadow: getBooleanFF('platform.design-system-team.update-input-border-wdith_5abwv') ? "inset 0 0 0 ".concat("var(--ds-border-width, 1px)", " ", "var(--ds-border-focused, ".concat(B200, ")")) : undefined
    },
    '@media screen and (-ms-high-contrast: active)': {
      '&[data-invalid]:focus-within': {
        borderColor: 'Highlight'
      },
      '&:focus-within': {
        borderColor: 'Highlight'
      },
      '&[data-disabled]': {
        borderColor: 'GrayText'
      }
    }
  };
};
var widthMap = {
  xsmall: 80,
  small: 160,
  medium: 240,
  large: 320,
  xlarge: 480
};
var getMaxWidth = function getMaxWidth(width) {
  return !width ? "100%" : width in widthMap ? widthMap[width] : +width;
};
export var containerStyles = function containerStyles(appearance, width) {
  return _objectSpread(_objectSpread(_objectSpread({
    alignItems: 'center'
  }, getContainerTextBgAndBorderColor(appearance)), {}, {
    borderRadius: 3,
    borderWidth: getBooleanFF('platform.design-system-team.update-input-border-wdith_5abwv') ? "var(--ds-border-width, 1px)" : 2
  }, getBooleanFF('platform.design-system-team.update-input-border-wdith_5abwv') && appearance !== 'none' ? {
    padding: "var(--ds-border-width, 1px)".concat(" 0")
  } : {}), {}, {
    borderStyle: appearance === 'none' ? 'none' : 'solid',
    boxSizing: 'border-box',
    display: 'flex',
    flex: '1 1 100%',
    fontSize: fontSize,
    justifyContent: 'space-between',
    maxWidth: getMaxWidth(width),
    overflow: 'hidden',
    transition: "background-color 0.2s ease-in-out, border-color 0.2s ease-in-out",
    wordWrap: 'break-word',
    verticalAlign: 'top',
    pointerEvents: 'auto'
  });
};
export var inputStyles = function inputStyles() {
  return {
    backgroundColor: 'transparent',
    border: 0,
    boxSizing: 'border-box',
    color: 'inherit',
    cursor: 'inherit',
    fontSize: fontSize,
    minWidth: '0',
    outline: 'none',
    width: '100%',
    lineHeight: gridSize * 2.5 / fontSize,
    fontFamily: fontFamily(),
    '&[data-monospaced]': {
      fontFamily: codeFontFamily()
    },
    '&[data-compact]': {
      padding: "var(--ds-space-050, 4px)".concat(" ", "var(--ds-space-075, 6px)"),
      height: "".concat((gridSize * 3.5 / fontSize).toFixed(2), "em")
    },
    '&:not([data-compact])': {
      padding: "var(--ds-space-100, 8px)".concat(" ", "var(--ds-space-075, 6px)"),
      height: "".concat((gridSize * 4.5 / fontSize).toFixed(2), "em")
    },
    '&[disabled]': {
      // Safari (WebKit) adds a -webkit-text-fill-color style to disabled inputs
      // which takes priority over color and makes the text unreadable. Need to
      // override it with the color we want.
      WebkitTextFillColor: "var(--ds-text-disabled, ".concat(N70, ")")
    },
    // Hide the clear indicator on Edge (Windows only)
    '&::-ms-clear': {
      display: 'none'
    },
    '&:invalid': {
      boxShadow: 'none'
    },
    '&:placeholder-shown': {
      textOverflow: 'ellipsis'
    },
    '&::placeholder': {
      color: "var(--ds-text-subtlest, ".concat(N200, ")"),
      '&:disabled': {
        color: "var(--ds-text-disabled, ".concat(N70, ")")
      }
    },
    '@media screen and (-ms-high-contrast: active)': {
      '&[disabled]': {
        color: 'GrayText'
      }
    }
  };
};