import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl-next';
import { logException } from '../../utils/sentry';
import { I18nProviderProps } from './type';

import {
  DEFAULT_LOCALE,
  getEffectiveLocale,
  loadIntlData,
} from './i18n-helper';

/**
 * Sets up the react-intl IntlProvider for its children, blocking until translations are loaded
 */
const NO_MESSAGES = {};

const I18nProvider = ({
  children,
  messagesConfig,
  locale: predefinedLocale,
}: I18nProviderProps) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [messages, setMessages] = useState<
    Record<string, string> | undefined
  >();

  useEffect(() => {
    const loadData = async () => {
      const locale = await getEffectiveLocale(predefinedLocale);
      if (locale === DEFAULT_LOCALE) {
        // Everything is already loaded for English
        setLocale(DEFAULT_LOCALE);
        setMessages(NO_MESSAGES);
        return;
      }
      try {
        const messages = await loadIntlData(messagesConfig, locale);

        setLocale(locale);
        setMessages(messages || NO_MESSAGES);
      } catch (error) {
        void logException(error, 'Error loading translations.', { locale });
        // show english on error
        setLocale(DEFAULT_LOCALE);
        setMessages(NO_MESSAGES);
      }
    };

    void loadData();
  }, [messagesConfig, predefinedLocale]);

  if (!messages) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
