import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  profileHeaderUserQueryKey,
  useProfileHeaderUserQuery,
} from './useProfileHeaderUserQuery';
import updateHeaderImageWithUserPrefCall from '../utils/updateHeaderImageWithUserPrefCall';
import updateUserHeaderImage from '../utils/updateUserHeaderImage';
import { checkFeatureGate } from '../../Statsig/utils';

type UpdateUserProfileHeaderInput = {
  headerImageId: string | null;
};

const updateUserProfileHeaderMutationFn = async (
  userId: string,
  fields: UpdateUserProfileHeaderInput,
) => {
  if (checkFeatureGate('migrate_media_token_to_user_preferences_service')) {
    return updateHeaderImageWithUserPrefCall({
      userId,
      fields,
    });
  }
  return updateUserHeaderImage({
    userId,
    fields,
  });
};

type ProfileHeaderUser = ReturnType<typeof useProfileHeaderUserQuery>['data'];

export const useUpdateUserProfileHeaderMutation = (userId: string) => {
  const queryClient = useQueryClient();
  const queryKey = [profileHeaderUserQueryKey, userId];

  const { isError, isLoading, mutateAsync } = useMutation({
    onSuccess: async data => {
      await queryClient.cancelQueries({ queryKey });

      // Optimistically update to the new state
      if (data) {
        queryClient.setQueryData(queryKey, (old?: ProfileHeaderUser) => ({
          ...old,
          headerImageUrl: data.data.User.headerImageUrl,
        }));
      }
    },
    mutationFn: async (input: UpdateUserProfileHeaderInput) =>
      updateUserProfileHeaderMutationFn(userId, input),
  });

  return { isError, isLoading, mutateAsync };
};
