import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import FeatureFlagWebClient, {
  ClientOptions,
  EnvironmentType,
  Identifiers,
  PerimeterType,
} from '@atlassiansox/feature-flag-web-client';
import { currentPerimeter, EnvType, getEnv } from '../config/env';
import { effectiveFlags, FeatureFlag } from './featureFlags';
import {
  DEFAULT_PRODUCT_NAME,
  getAnalyticsClient,
} from '../clients/analytics-client';
import { FeatureFlagUser } from '@atlassiansox/feature-flag-web-client/src/api/types';

let ffClient: FeatureFlagWebClient;

const FF_ENV_MAP: Record<EnvType, EnvironmentType> = {
  local: EnvironmentType.LOCAL,
  test: EnvironmentType.LOCAL,
  ddev: EnvironmentType.DEV,
  fedex: EnvironmentType.STAGING,
  stg: EnvironmentType.STAGING,
  'stg-fedm': EnvironmentType.STAGING,
  prod: EnvironmentType.PROD,
  'prod-fedm': EnvironmentType.PROD,
};

// Corresponding Launch Darkly Instance: https://app.launchdarkly.com/identity/production/features
// API keys are found at https://developer.atlassian.com/platform/frontend-feature-flags/resources/api-keys/
const LD_API_KEYS: Record<EnvironmentType, string> = {
  [EnvironmentType.LOCAL]: 'e91005d2-b812-461e-b15d-d564d1006cf2',
  [EnvironmentType.DEV]: 'e91005d2-b812-461e-b15d-d564d1006cf2',
  [EnvironmentType.STAGING]: 'c1b4f462-6a1b-4805-9b2a-3ebd77b6d0ac',
  [EnvironmentType.PROD]: '41ebe9bd-4502-4a8d-b018-9de8cd0aabc7',
};

/**
 * Initialise the feature flag client.
 * @param {object} userDetails - this is a required prop to initialise feature flag client
 * @return {Promise} - If flag values are loaded from local storage, the promise from ready is resolved before the constructor has finished. The promise maybe rejected if:
 * - The apiKey provided is invalid, or
 * - The feature flag user object is malformed.
 */
export const initializeFeatureFlagClient = async ({
  userId,
  userEmail,
}: {
  userId: string;
  userEmail: string;
}) => {
  if (ffClient) {
    return ffClient;
  }
  const environmentType = FF_ENV_MAP[getEnv()];
  const apiKey = LD_API_KEYS[environmentType];

  // AnalyticClient instance is initialised in entry-point 'src/apps/id-a-c/index.tsx'
  const analyticsWebClient = getAnalyticsClient({});
  if (!analyticsWebClient) {
    return Promise.reject();
  }

  const featureFlagUser: FeatureFlagUser = {
    identifier: {
      type: Identifiers.ATLASSIAN_ACCOUNT_ID,
      value: userId,
    },
    isAnonymous: !userId,
    custom: {
      emailDomain: userEmail.split('@')[1],
    },
  };
  const options: ClientOptions = {
    productKey: DEFAULT_PRODUCT_NAME,
    environment: environmentType,
    ...(currentPerimeter === 'fedramp-moderate' && {
      perimeter: PerimeterType.FEDRAMP_MODERATE, // perimeter should be undefined for commercial env
    }),
    // In local, we may want to set pollingInterval to 10 seconds so that it's easier to test feature flag change in LaunchDarkly.
    // pollingInterval: 10000,
  };

  ffClient = new FeatureFlagWebClient(
    apiKey,
    analyticsWebClient.analyticsWebClient,
    featureFlagUser,
    options,
  );

  const readyResponse = await ffClient.ready();

  try {
    /**
     * Platform Feature flags (PFF): https://hello.atlassian.net/wiki/spaces/AF/pages/2634731342/Platform+Feature+Flags
     *
     * This creates a PFF resolver that allows calls to `getBooleanFF` inside of Platform components to use your feature flag client
     * and LD project, rather than installing a feature flag client of their own, or passing those flags in via props.
     */
    setBooleanFeatureFlagResolver(flagKey =>
      Boolean(ffClient.getFlagValue(flagKey, false)),
    );
  } catch (error) {
    console.error('Could not initialize Platform Feature Flags', error);
  }

  return readyResponse;
};

export const getFeatureFlagValue = (
  flagKey: FeatureFlag,
  shouldSendExposureEvent = true,
): boolean => {
  const defaultValue = effectiveFlags[flagKey];

  // when LaunchDarkly client is not initialised yet, default value of feature flag is returned.
  if (!ffClient) {
    return defaultValue;
  }

  return ffClient.getFlagValue(flagKey, defaultValue, {
    shouldSendExposureEvent,
  });
};

export const getFeatureFlagValueWhenReady = async (
  flagKey: FeatureFlag,
  shouldSendExposureEvent = true,
): Promise<boolean> => {
  const defaultValue = effectiveFlags[flagKey];

  // when LaunchDarkly client is not initialised yet, default value of feature flag is returned.
  if (!ffClient) {
    return defaultValue;
  }

  await ffClient.ready();
  return ffClient.getFlagValue(flagKey, defaultValue, {
    shouldSendExposureEvent,
  });
};
