import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { History } from 'history';

import {
  Analytics,
  AnalyticsProvider,
  DialogProvider as LegacyDialogProvider,
  DialogsProvider,
  I18nProvider,
  getEnv,
  FeatureFlag,
  getFeatureFlagValue,
} from '../../../../common';
import { useLocalStorageTheme } from '../../../../components/Theme';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import { DeviceContextProvider } from '../../../../components/DeviceContext';
import ManageProfileRoutes from '../../routes/ManageProfileRoute';
import messagesConfig from '../../../../i18n/messagesConfig';
import { DefaultAccountClient } from '../atlassian-frontend-packages/account-client';
import {
  AccountClientProvider,
  AccountSwitchDetectorUI,
} from '../atlassian-frontend-packages/account';
import { BrowserMetricsBoundary } from '../../../../common/browser-metrics/BrowserMetricsBoundary';
import StatSigInit from '../../../../common/components/Statsig';
import QueryClientProviderInit from '../../../../common/components/QueryClientProviderInit';

interface Props {
  history: History;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: any;
  analytics: Analytics;
}

const identityEnv = () => {
  const env = getEnv();
  switch (env) {
    case 'ddev':
      return 'dev';
    case 'stg':
      return 'stg';
    case 'prod':
      return 'prod';
    default:
      return 'dev';
  }
};

const accountClient = new DefaultAccountClient({
  identityEnvironment: identityEnv(),
  defaultContinueUrl: location.href,
  application: 'manage-profile',
});

/**
 * This is a rendering entry point for siteless manage-profile app (aka new user settings).
 */
const UserSettingsAppContainer = (props: Props) => {
  useLocalStorageTheme();

  const { analytics, store, history } = props;
  return (
    <I18nProvider messagesConfig={messagesConfig}>
      <ErrorBoundary analytics={analytics}>
        <AnalyticsProvider analytics={analytics}>
          <Provider store={store}>
            <StatSigInit>
              <QueryClientProviderInit>
                <DialogsProvider>
                  <LegacyDialogProvider>
                    <DeviceContextProvider>
                      <NewAccountSwitchingExperienceProvider>
                        <BrowserRouter>
                          <Switch>
                            <BrowserMetricsBoundary history={history}>
                              <Route
                                path="/manage-profile"
                                component={ManageProfileRoutes}
                              />
                            </BrowserMetricsBoundary>
                          </Switch>
                        </BrowserRouter>
                      </NewAccountSwitchingExperienceProvider>
                    </DeviceContextProvider>
                  </LegacyDialogProvider>
                </DialogsProvider>
              </QueryClientProviderInit>
            </StatSigInit>
          </Provider>
        </AnalyticsProvider>
      </ErrorBoundary>
    </I18nProvider>
  );
};

const NewAccountSwitchingExperienceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isJustInTimeSwitchingEnabled = getFeatureFlagValue(
    FeatureFlag.ENABLE_JUST_IN_TIME_ACCOUNT_SWITCHER,
  );
  return (
    <AccountClientProvider client={accountClient}>
      {isJustInTimeSwitchingEnabled && <AccountSwitchDetectorUI />}
      {children}
    </AccountClientProvider>
  );
};

export default UserSettingsAppContainer;
