import { OutboundAuthAccountsPayload } from '../clients/outbound-auth-client';
import {
  ConnectedAppsAction,
  GetConnectedAppsAction,
  RemoveConnectedAppAction,
  RemoveThirdPartyConnectionAction,
} from './connected-apps-actions';
import {
  ConnectedApp,
  ConnectedAppsDictionary,
  ConnectedAppsState,
  OAuthClientsAccountGrant,
  OutboundAuthConnection,
  ScopeDetails,
  UserGrant,
} from './connected-apps-types';

export const GET_CONNECTED_APPS = 'GET_CONNECTED_APPS';
export const REMOVE_CONNECTED_APP = 'REMOVE_CONNECTED_APP';
export const REMOVE_THIRD_PARTY_CONNECTION = 'REMOVE_THIRD_PARTY_CONNECTION';

export const scopeDescriptionByKey = (
  scopeKey: string,
  scopeDetails: ScopeDetails[],
): string => {
  const foundDetails = scopeDetails.find(scope => scope.key === scopeKey);
  // in case no description is available we return just the scope key
  // as we must not hide an app's scope from the user
  return foundDetails?.description ? foundDetails.description : scopeKey;
};

export const getAppId = (ari: string): string => {
  // extracting resource ids (uuid) from app ari
  const matches = ari.match(
    /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
  );

  return !matches ? ari : matches[0].slice(1);
};

export const transformAccountGrantToUserGrant = (
  accountGrant: OAuthClientsAccountGrant,
): UserGrant | null => {
  const app = accountGrant.appEnvironment?.app;

  if (!app) {
    return null;
  }

  const userGrant: UserGrant = {
    accountId: accountGrant.accountId,
    appId: getAppId(app.id),
    scopes: accountGrant.scopeDetails,
    oauthClientId: accountGrant.clientId,
    appDetails: {
      name: app.name,
      description: app.description,
      vendorName: app.vendorName,
      contactLink: app.contactLink,
      avatarUrl: '',
    },
  };
  return userGrant;
};

export const transformConnectedAppsResponse = (
  connectedApps: ConnectedApp[],
  scopeDetails: ScopeDetails[],
): ConnectedApp[] =>
  connectedApps.map(app => ({
    ...app,
    scopes: !scopeDetails
      ? []
      : app.scopes.map(scope => scopeDescriptionByKey(scope, scopeDetails)),
  }));

export const transformNewConnectedAppsResponse = (
  userGrants: UserGrant[],
  outboundAuthConnections: OutboundAuthConnection[],
) => {
  const appDictionary = userGrants.reduce<ConnectedAppsDictionary>(
    (connectedApps, userGrant) => {
      if (connectedApps[userGrant.oauthClientId]) {
        const existingGrant = connectedApps[userGrant.oauthClientId];
        userGrant.scopes
          .map(scope => scope.description || scope.key)
          .filter(
            scope => !existingGrant.scopes.find(existing => existing === scope),
          )
          .forEach(scope => existingGrant.scopes.push(scope));
      } else {
        const appConnections = outboundAuthConnections.filter(
          connection =>
            userGrant.appId && connection.containerId.includes(userGrant.appId),
        );

        // Remove connections that belongs to the app from the list
        outboundAuthConnections = outboundAuthConnections.filter(
          connection =>
            !userGrant.appId ||
            !connection.containerId.includes(userGrant.appId),
        );

        connectedApps[userGrant.oauthClientId] = {
          ...userGrant,
          scopes: userGrant.scopes.map(scope => scope.description || scope.key),
          outboundAuthConnections: appConnections,
        };
      }
      return connectedApps;
    },
    {},
  );

  return {
    apps: Object.values(appDictionary),
    filteredConnections: outboundAuthConnections,
  };
};

export const transformOutboundAuthConnectionsResponse = (
  response: OutboundAuthAccountsPayload,
): OutboundAuthConnection[] =>
  response.containers.reduce(
    (containerConns, containerWrapper) =>
      containerWrapper.services.reduce(
        (serviceConns, serviceWrapper) =>
          serviceWrapper.accounts.reduce(
            (connections, account) =>
              connections.concat([
                {
                  accountId: account.id,
                  displayName: account.displayName,
                  containerId: containerWrapper.container.containerId,
                  containerName: containerWrapper.container.displayName,
                  serviceKey: serviceWrapper.service.serviceKey,
                  serviceName: serviceWrapper.service.displayName,
                  addedDate: account.connectedAt,
                  scopes: account.scopes,
                  contextAris: account.contextAris,
                },
              ]),
            serviceConns,
          ),
        containerConns,
      ),
    [] as OutboundAuthConnection[],
  );

export const removeOutboundAuthConnectionsFromApp = (
  apps: ConnectedApp[],
  accountId: string,
  oauthClientId: string,
) => {
  return apps.map(app => {
    if (app.oauthClientId === oauthClientId) {
      app.outboundAuthConnections = app.outboundAuthConnections?.filter(
        conn => conn.accountId !== accountId,
      );
    }
    return app;
  });
};

export const intialState: ConnectedAppsState = {
  isLoading: true,
  hasLoaded: false,
  hasError: false,
  apps: [],
  outboundAuthConnections: [],
};

export default (
  state = intialState,
  action: ConnectedAppsAction,
): ConnectedAppsState => {
  switch (action.type) {
    case `${GET_CONNECTED_APPS}_PENDING`:
      return {
        ...state,
        isLoading: true,
        hasLoaded: false,
        hasError: false,
        apps: [],
        outboundAuthConnections: [],
      };
    case `${GET_CONNECTED_APPS}_FULFILLED`:
      const [
        appsPayload,
        outboundAuthPayload,
        accountGrantsPayload,
      ] = (action as GetConnectedAppsAction).payload;

      let outboundAuthConnections = transformOutboundAuthConnectionsResponse(
        outboundAuthPayload,
      );

      let apps: ConnectedApp[] = [];
      let userGrantsFromOauthClients: UserGrant[] = [];
      let userGrantsFromEcosystem: UserGrant[] = [];

      if (accountGrantsPayload.oauthClients) {
        userGrantsFromOauthClients = accountGrantsPayload.oauthClients.allAccountGrantsForUser.nodes
          .map(accountGrant => transformAccountGrantToUserGrant(accountGrant))
          .filter((grant): grant is UserGrant => grant !== null);
      }

      if (appsPayload.ecosystem) {
        userGrantsFromEcosystem = appsPayload.ecosystem.userGrants.nodes;
      }

      const combinedGrants: UserGrant[] = [
        ...userGrantsFromOauthClients,
        ...userGrantsFromEcosystem,
      ];
      if (combinedGrants.length > 0) {
        const transformedResponse = transformNewConnectedAppsResponse(
          combinedGrants,
          outboundAuthConnections,
        );

        apps = transformedResponse.apps;
        outboundAuthConnections = transformedResponse.filteredConnections;
      } else if (appsPayload.UserGrants) {
        apps = transformConnectedAppsResponse(
          appsPayload.UserGrants.grants,
          appsPayload.UserGrants.grantedScopeDetails,
        );
      }
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasError: false,
        apps,
        outboundAuthConnections,
      };
    case `${GET_CONNECTED_APPS}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasError: true,
        apps: [],
      };
    case `${REMOVE_CONNECTED_APP}_FULFILLED`:
      const oauthClientId = (action as RemoveConnectedAppAction).meta!
        .oauthClientId;
      return {
        ...state,
        apps: state.apps.filter(
          (app: ConnectedApp) => app.oauthClientId !== oauthClientId,
        ),
      };
    case `${REMOVE_THIRD_PARTY_CONNECTION}_FULFILLED`:
      const {
        accountId,
        oauthClientId: clientId,
      } = (action as RemoveThirdPartyConnectionAction).meta!;
      return {
        ...state,
        apps: clientId
          ? removeOutboundAuthConnectionsFromApp(
              state.apps,
              accountId,
              clientId,
            )
          : state.apps,
        outboundAuthConnections: state.outboundAuthConnections.filter(
          conn => conn.accountId !== accountId,
        ),
      };
    default:
      return state;
  }
};
