import React from 'react';
import CloseAccountSettings from '../../components/CloseAccountSettingsTab/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const SettingsCloseAccountRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_CLOSE_ACCOUNT_SETTINGS} />
    <RecordTransition />
    <CloseAccountSettings />
  </>
);

export default SettingsCloseAccountRoute;
