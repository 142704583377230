import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../../../../utils/fetchApi';
import { getConfig } from '../../../../common';

export const UserOrganizationQueryKey = 'UserOrganization';

interface UserOrganizationResponse {
  cloudId: string | null;
  name: string | null;
}

const userOrganizationQueryFn = async ({
  stargateRoot,
}): Promise<UserOrganizationResponse> => {
  const url = `${stargateRoot}/my-orgs`;

  const response = await fetchApi(url);
  const data = await response.json();

  return {
    cloudId: (data.managedBy && data.managedBy.id) || null,
    name: (data.managedBy && data.managedBy.name) || null,
  };
};

export const useUserOrganization = () => {
  const { stargateRoot } = getConfig();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: [UserOrganizationQueryKey],
    queryFn: () =>
      userOrganizationQueryFn({
        stargateRoot,
      }),
  });

  return {
    usersOrg: data,
    isLoading,
    isError,
    error,
  };
};
