/** @jsx jsx */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl-next';
import { B50, N20A, N800 } from '@atlaskit/theme/colors';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { borderRadius, gridSize } from '@atlaskit/theme/constants';
import { PersonIcon } from '../icons/person';
import { ProfilePicture } from '../profile-picture';
import { SkeletonText } from '../skeleton-text';
export var AccountDetails = function AccountDetails(_ref) {
  var testId = _ref.testId,
    name = _ref.name,
    picture = _ref.picture,
    email = _ref.email,
    managedBy = _ref.managedBy;
  return jsx(Container, {
    "data-testid": testId
  }, managedBy && jsx(ManagedBy, null, jsx(PersonIconWrapper, null, jsx(PersonIcon, {
    label: "",
    size: "small"
  })), jsx(FormattedMessage, {
    id: "identity.account-menu.account-menu-details.managed-account",
    defaultMessage: "This account is managed by <b>{managedBy}</b>",
    description: "Label showing the user which organisation manages their Atlassian account",
    values: {
      b: function b(text) {
        return jsx("strong", null, text);
      },
      managedBy: managedBy
    }
  })), jsx(Content, {
    hasHeader: !!managedBy
  }, jsx(Profile, {
    testId: testId,
    name: name,
    picture: picture,
    email: email
  })));
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var Container = styled.section({
  display: 'flex',
  flexDirection: 'column',
  margin: "0 ".concat("var(--ds-space-100, 8px)")
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var ManagedBy = styled.small({
  display: 'box',
  margin: '0px',
  padding: "var(--ds-space-025, 2px)".concat(" ", "var(--ds-space-100, 8px)"),
  textAlign: 'center',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: "".concat(borderRadius(), "px ").concat(borderRadius(), "px 0 0"),
  background: "var(--ds-background-information, ".concat(B50, ")"),
  color: "var(--ds-text-subtle, #44546F)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var PersonIconWrapper = styled.span({
  marginRight: "var(--ds-space-050, 4px)",
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '& > span': {
    width: '12px',
    height: '12px'
  }
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  background: "var(--ds-surface-sunken, ".concat(N20A, ")")
},
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
function (_ref2) {
  var hasHeader = _ref2.hasHeader;
  return {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderRadius: hasHeader ? "0 0 ".concat(borderRadius(), "px ").concat(borderRadius(), "px") : "".concat(borderRadius(), "px")
  };
});
var Profile = function Profile(_ref3) {
  var name = _ref3.name,
    email = _ref3.email,
    picture = _ref3.picture,
    testId = _ref3.testId;
  return jsx(ProfileWrapper, null, jsx(ProfilePicture, {
    src: picture,
    size: gridSize() * 8,
    testId: testId && "".concat(testId, "--picture")
  }), jsx(NameAndEmail, null, jsx(DisplayName, {
    "data-testid": testId && "".concat(testId, "--name")
  }, name || jsx(SkeletonText, {
    length: "6em"
  })), jsx(EmailAddress, {
    "data-testid": testId && "".concat(testId, "--email")
  }, email || jsx(SkeletonText, {
    length: "10em"
  }))));
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var ProfileWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  margin: "var(--ds-space-200, 16px)".concat(" ", "var(--ds-space-200, 16px)")
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var NameAndEmail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: "auto 0 auto ".concat("var(--ds-space-200, 16px)")
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var DisplayName = styled.h3({
  color: "var(--ds-text, ".concat(N800, ")"),
  margin: 0
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
var EmailAddress = styled.p({
  marginTop: "var(--ds-space-025, 2px)",
  color: "var(--ds-text-subtlest, #626F86)",
  fontWeight: 400
});