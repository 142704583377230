import React from 'react';
import AnalyticsContext from './AnalyticsContext';
import { InjectedAnalyticsProps } from './types';

const withAnalyticsContext = <P extends object>(
  WrappedComponent: React.ComponentType<P & InjectedAnalyticsProps>,
): React.ComponentType<P> => {
  const name =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithAnalyticsContext = (props: P) => {
    return (
      <AnalyticsContext.Consumer>
        {({ analytics }) => (
          <WrappedComponent {...props} analytics={analytics} />
        )}
      </AnalyticsContext.Consumer>
    );
  };
  WithAnalyticsContext.displayName = `withAnalytics(${name})`;

  return WithAnalyticsContext;
};

export default withAnalyticsContext;
