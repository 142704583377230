import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getConfig } from '../../../config/envConfig';
import { checkFeatureGate } from '../../Statsig/utils';
import queryUserFromAGG from '../../../utils/queryUserFromAGG';

export const avatarByAccountIdQueryKey = 'avatarByAccountIdQueryKey';

type CloudUserData = {
  id: string;
  fullName: string;
  avatarUrl: string;
};

export type AvatarByAccountIdQueryData = {
  data: {
    CloudUser: CloudUserData;
  };
};

const getAvatarAndFullNameByAccountIdQueryFn = async (
  userId: string,
  size: number,
): Promise<{ CloudUser: CloudUserData }> => {
  const { stargateRoot } = getConfig();
  if (checkFeatureGate('migrate_cloud_user_to_agg_user_query') && userId) {
    const aggUrl = `${stargateRoot}/graphql?q=AvatarByAccountIdQuery`;
    const { id, name: fullName, picture: avatarUrl } = await queryUserFromAGG({
      userId,
      url: aggUrl,
    });
    // TODO: PTC-9458 clear Object property CloudUser once the feature gate is enabled.
    return {
      CloudUser: {
        id,
        fullName,
        avatarUrl,
      },
    };
  }

  // TODO: PTC-9458 This block of code is temporary and can be cleaned up once the feature gate is enabled.
  const url = `${stargateRoot}/directory/graphql?q=AvatarByAccountIdQuery`;
  const query = gql`
    query AvatarByAccountIdQuery($userId: String!, $size: Int) {
      CloudUser(userId: $userId) {
        id
        fullName
        avatarUrl(size: $size)
      }
    }
  `;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      query,
      variables: {
        userId,
        size,
      },
    }),
  });

  const { data } = (await response.json()) as AvatarByAccountIdQueryData;

  return data;
};

export const useAvatarByAccountIdQuery = (userId: string, size: number) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [avatarByAccountIdQueryKey, userId],
    queryFn: () => getAvatarAndFullNameByAccountIdQueryFn(userId, size),
  });

  return { avatarByAccountIdData: data, isLoading, isError };
};
