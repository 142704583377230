import { connect } from 'react-redux';
import AccountPreferencesSettings from './AccountPreferencesSettings';
import { addFlag } from '../../../../common';

const mapStateToProps = ({ user }) => ({
  isManaged: user.data.isManaged,
});

const mapDispatchToProps = { addFlag };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPreferencesSettings);
