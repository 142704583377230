import type { Config, EnvironmentConfig, IdentityEnvironment } from './types';

export interface ResolvedConfig extends Config {
  /** @override */
  identityEnvironment: EnvironmentConfig;
  /** @override */
  defaultLogoutContinueUrl: string;
}

export function resolveConfig(config: Config): ResolvedConfig {
  return {
    ...config,
    identityEnvironment:
      typeof config.identityEnvironment === 'string'
        ? environments[config.identityEnvironment]
        : { ...config.identityEnvironment }, // copy to prevent modification
    defaultLogoutContinueUrl:
      config.defaultLogoutContinueUrl || config.defaultContinueUrl,
  };
}

const environments: Record<IdentityEnvironment, EnvironmentConfig> = {
  dev: {
    baseUrl: 'https://id.dev.internal.atlassian.com',
  },
  stg: {
    baseUrl: 'https://id.stg.internal.atlassian.com',
  },
  prod: {
    baseUrl: 'https://id.atlassian.com',
  },
};
