import type { MediaClientConfig } from '@atlaskit/media-core';
import { useCallback, useRef, useState } from 'react';

import { fetchApi } from '../../../utils/fetchApi';
import { directoryClient } from '../../clients/directory-client';
import { getConfig } from '../../config/envConfig';
import { checkFeatureGate } from '../Statsig/utils';
import { isFedRamp } from '../../config/env';

type DataWriteToken = {
  MediaToken: {
    token: string;
    baseUrl: string;
  };
}

interface Result {
  mediaClientConfig: MediaClientConfig;
  // `headerImageId` can be a real uploaded header image or a new headerImageId
  // which is generated from User/Team Write Token query and is used to prepare to upload a new image.
  headerImageId: string;
  // A function is used to call to start getting the Write Token.
  startGetWriteToken: () => Promise<DataWriteToken>;
}

const getWriteTokenResultPlaceholder = Symbol('getWriteTokenResultPlaceholder');

export default function useUserWriteMediaClientConfig(): Result {
  const [state, setState] = useState({
    headerImageId: '',
  });
  const refQueryPromise = useRef<Promise<symbol | DataWriteToken>>(
    Promise.resolve(getWriteTokenResultPlaceholder),
  );

  // we want to start to fetch Write Token when File Dialog open, instead of on page loading.
  const startGetWriteToken: () => Promise<DataWriteToken> = useCallback(async () => {
    let data;
    if (
      checkFeatureGate('migrate_media_token_to_user_preferences_service')
    ) {
      // MediaApi is disabled in FedRamp
      if (isFedRamp()) {
        return new Promise(resolve =>
          resolve({ MediaToken: { token: '', baseUrl: '' } }),
        );
      }
      const { stargateRoot } = getConfig();
      const url = `${stargateRoot}/api/user-image/header-image/write`;
      data = await fetchApi(url, { method: 'GET' })
        .then(response => response.json())
        .then(data => ({ MediaToken: { ...data } }));
      refQueryPromise.current = data;
    } else {
      const promise = directoryClient.queryWriteMediaToken();
      refQueryPromise.current = promise;
      data = await promise;
    }

    setState({
      headerImageId: data?.MediaToken?.headerImageId,
    });

    return data;
  }, []);

  return {
    mediaClientConfig: {
      authProvider: async () => {
        // there is `headerImageId` in the new query
        let data = await refQueryPromise.current;

        // prevent race-condition
        if (!(data as DataWriteToken).MediaToken ||
          (data as symbol) === getWriteTokenResultPlaceholder) {
          data = await startGetWriteToken();
        }

        return {
          token: (data as DataWriteToken).MediaToken?.token,
          baseUrl: (data as DataWriteToken)?.MediaToken?.baseUrl,
          clientId: getConfig().mediaApiClientId,
        };
      },
    },
    headerImageId: state.headerImageId,
    startGetWriteToken,
  };
}
