/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Estonian
export default {
  'focused-task-close-account.cancel': 'Tühista',
  'focused-task-close-account.deactivate-account': 'Konto deaktiveerimine',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Oled keelamas kontot, mis kuulub kasutajale:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Oled keelamas kontot, mis kuulub kasutajale:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Keela konto',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Keela konto',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Võid kontot igal ajal lubada.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Võid kontot igal ajal lubada.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Konto keelamisel:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Konto keelamisel:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Me ei esita nende eest enam arvet',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Me ei esita nende eest enam arvet',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>kaotab ligipääsu kohe</b> kõikidele Atlassiani konto teenustele. Hetkel on tal ligipääs:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': "{fullName} <b>kaotab ligipääsu kohe</b> kõikidele Atlassiani konto teenustele. Hetkel on neil ligipääs ainult Community-le ja Marketplace'le.",
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Muud Atlassiani konto teenused nagu Atlassian Community ja Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': '<b>kaotad ligipääsu kohe</b> kõikidele Atlassiani teenustele. Hetkel pääsed ligi:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': '<b>kaotad ligipääsu kohe</b> kõikidele Atlassiani teenustele. Hetkel pääsed ligi ainult Community-le ja Marketplace-le.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Nende isiklikud andmed nagu täisnimi ja e-posti aadress ning nende loodud sisu jäävad Atlassiani konto teenustesse.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Nende isiklikud andmed nagu täisnimi ja e-posti aadress ning nende loodud sisu jäävad Atlassiani konto teenustesse.',
  'focused-task-close-account.delete-account': 'Kustuta konto',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Märkus: sellele küsimustikule vastamine aitab meil parandada kõigi kasutajate kogemust. Pärast kasutaja konto kustutamist esitletakse teda ikka endise kasutajana.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Märkus: sellele küsimustikule vastamine aitab meil parandada kõigi, ka oma konto kustutanud, kasutajate kogemust. Pärast oma konto kustutamist esitletakse sind ikka endise kasutajana.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Endine kasutaja',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Kuidas eelistad esitleda kustutatud kasutajat?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Kuidas eelistad esitleda oma kustutatud kontot?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Kui annaksime valida, siis kuidas soovite esitleda kustutatud kasutajat teistele kasutajatele?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Kui annaksime valida, siis kuidas soovid end esitleda teistele kasutajatele?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Pärast kasutaja konto kustutamist esitletakse teda teistele kasutajatele endise kasutajana. Palun leia hetk vastata meie küsimustiku küsimusele.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Pärast sinu konto kustutamist esitletakse sind teistele kasutajatele endise kasutajana. Palun leia hetk vastata meie küsimustiku küsimusele.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Näita vähem',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'veel {num}',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Oled kustutamas kontot, mis kuulub:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Oled kustutamas oma kontot:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Kustuta konto',
  'focused-task-close-account.delete-account.overview.heading.self': 'Kustuta oma konto',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Näiteks toodetes loodud lehed, probleemid ja arvustused.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Näiteks toodetes loodud lehed, probleemid ja arvustused.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Sina või muud kasutajad võivad olla paigaldanud rakendusi, mis lisavad Atlassiani toodetele funktsioone. Need rakendused võisid säilitada kasutajate profiiliteavet.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Sina või muud kasutajad võivad olla paigaldanud rakendusi, mis lisavad Atlassiani toodetele funktsioone. Need rakendused võisid säilitada sinu profiiliteavet.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Ostudega seotud teave, mida oleme kohustatud säilitama finantsaruandluse jaoks.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Ostudega seotud teave, mida oleme kohustatud säilitama finantsaruandluse jaoks.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Andmed, mis näitavad, et oleme kustutanud kellegi konto, mille peame esitama ametiasutustele.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Andmed, mis näitavad, et oleme kustutanud kellegi konto, mille peame esitama ametiasutustele.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Käimasoleva juurdluse andmed, mida oleme kohustatud säilitama vastavalt seadusele.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Käimasoleva juurdluse andmed, mida oleme kohustatud säilitama vastavalt seadusele.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Säilitame isiklikud andmed teatud ajaks, kui seda nõuab seaduslik äri või seadus. Sellisteks juhtudeks võib olla:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Säilitame isiklikud andmed teatud ajaks, kui seda nõuab seaduslik äri või seadus. Sellisteks juhtudeks võib olla:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Me ei kustuta kasutajate loodud sisust mistahes isiklikke andmeid nagu nimed või e-posti aadressid, mida nad kirjutasid lehele või probleemi. Toote adminid peavad kustutama need andmed käsitsi.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Me ei kustuta kasutajate loodud sisust mistahes isiklikke andmeid nagu nimed või e-posti aadressid, mida nad kirjutasid lehele või probleemi. Toote adminid peavad kustutama need andmed käsitsi.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Kasutajatel on õigus esitada oma sünniriigi järelevalveasutusele kaebuseid.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Sul on õigus esitada oma sünniriigi järelevalveasutusele kaebuseid.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Konto kustutamisel:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Oma konto kustutamisel:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Nende loodud sisu säilib Atlassiani konto teenustes.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'SInu loodud sisu säilib Atlassiani konto teenustes.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Pärast 14 päevast lisaaega ei saa konto kustutamist tühistada.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Saadame e-kirja, kus loendame rakendusi, mis võivad hoiustada nende isiklikke andmeid.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Saadame e-kirja, kus loendame rakendusi, mis võivad hoiustada sinu isiklikke andmeid.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>kaotab ligipääsu kohe</b> kõikidele Atlassiani konto teenustele. Hetkel on tal ligipääs:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': "{fullName} <b>kaotab ligipääsu kohe</b> kõikidele Atlassiani konto teenustele. Hetkel on neil ligipääs ainult Community-le ja Marketplace'le.",
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Muud Atlassiani konto teenused nagu Atlassian Community ja Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': '<b>Kaotad ligipääsu kohe</b> kõikidele Atlassiani teenustele. Hetkel pääsed ligi:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': '<b>Kaotad ligipääsu kohe</b> kõikidele Atlassiani teenustele. Hetkel pääsed ligi ainult Community-le ja Marketplace-le.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': '<b>Kustutame nende isiklikud andmed</b> nagu täisnimi ja e-posti aadressi Atlassiani konto teenustest 30 päeva jooksul, va paaril juhul, kui seda nõuab seaduslik äritegevus või seadus.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': '<b>Kustutame sinu isiklikud andmed</b> nagu täisnimi ja e-posti aadressi Atlassiani konto teenustest 30 päeva jooksul, va paaril juhul, kui seda nõuab seaduslik äritegevus või seadus.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Pärast 14 päevast lisaaaega ei saa konto kustutamist enam tühistada. Kui arvad, et kontot läheb hiljem vaja, siis hoopis deaktiveeri see.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Pärast 14 päevast lisaaega ei saa konto kustutamist tühistada.',
  'focused-task-close-account.learnMore': 'Lisateave',
  'focused-task-close-account.next': 'Edasi',
  'focused-task-close-account.previous': 'Tagasi'
};