import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../../../../../utils/fetchApi';
import { getConfig } from '../../../../../common/config/envConfig';
import { PRIVACY_SETTINGS_SCHEMA_TO_API_MAP } from './helpers/manage-user-privacy-settings';
import { UserProfilePrivacyOptions } from '../../../model/userProfilePrivacy';
import { PrivacyLevel } from '../../ProfileAndPrivacySettings/PrivacyLevelDropdown/types';

export const UserAllowedPrivacySettingsQueryKey = 'UserAllowedPrivacySettings';

type AllowedApiPrivacySetting = 'public' | 'private' | 'collaborator';

type AllowedPrivacySettingsApiResponse = {
  nickname?: AllowedApiPrivacySetting[];
  name?: AllowedApiPrivacySetting[];
  picture?: AllowedApiPrivacySetting[];
  email?: AllowedApiPrivacySetting[];
  zoneinfo?: AllowedApiPrivacySetting[];
  'extended_profile.location'?: AllowedApiPrivacySetting[];
  'extended_profile.organization'?: AllowedApiPrivacySetting[];
  'extended_profile.department'?: AllowedApiPrivacySetting[];
  'extended_profile.job_title'?: AllowedApiPrivacySetting[];
  'extended_profile.phone_number'?: AllowedApiPrivacySetting[];
};

const getAllowedPrivacySettingsFor = (
  apiData: AllowedPrivacySettingsApiResponse,
  apiResponseKey: string,
): PrivacyLevel[] | null => {
  const allowedPrivacySettings: AllowedApiPrivacySetting[] | undefined =
    apiData[apiResponseKey];
  if (!allowedPrivacySettings || !allowedPrivacySettings.length) {
    return null;
  }

  return allowedPrivacySettings
    .map(privacyLevel => {
      switch (privacyLevel) {
        case 'public':
          return PrivacyLevel.PUBLIC;
        case 'private':
          return PrivacyLevel.PRIVATE;
        case 'collaborator':
          return PrivacyLevel.ORGANISATION;
        default:
          return null;
      }
    })
    .filter((entry): entry is PrivacyLevel => entry !== null);
};

const transformUserManagePrivacySettingsAllowed = (
  data: AllowedPrivacySettingsApiResponse,
): UserProfilePrivacyOptions => {
  // warning: this reducer is NOT type safe
  return Object.entries(PRIVACY_SETTINGS_SCHEMA_TO_API_MAP).reduce(
    (memo, [schemaKey, apiResponseKey]) => {
      memo[schemaKey] = getAllowedPrivacySettingsFor(data, apiResponseKey);
      return memo;
    },
    {},
  ) as UserProfilePrivacyOptions;
};

const userAllowedPrivacySettingsQueryFn = async (userId: string) => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/users/${userId}/manage/privacy-settings/allowed`;
  const response = await fetchApi(url);
  const data = (await response.json()) as AllowedPrivacySettingsApiResponse;

  return transformUserManagePrivacySettingsAllowed(data);
};

export const useUserAllowedPrivacySettingsQuery = (userId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [UserAllowedPrivacySettingsQueryKey, userId],
    queryFn: () => userAllowedPrivacySettingsQueryFn(userId),
    enabled: !!userId,
  });
  return {
    userAllowedPrivacySettings: data,
    isLoading,
    isError,
  };
};
