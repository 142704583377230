import { gql } from 'graphql-request';
import { fetchApi } from '../../../../utils/fetchApi';
import { UpdateUserHeaderImageProps, UpdateUserHeaderOutput } from '../types';
import { getConfig } from '../../../config/envConfig';
import { isFedRamp } from '../../../config/env';
import { checkFeatureGate } from '../../Statsig/utils';
import buildHeaderImageUrl from './buildHeaderImageUrl';

const updateHeaderImageWithUserPrefCall = async ({
  userId,
  fields,
}: UpdateUserHeaderImageProps): Promise<UpdateUserHeaderOutput | undefined> => {
  // MediaApi is disabled in FedRamp
  if (isFedRamp()) {
    return undefined;
  }
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/directory/graphql?q=UpdateProfileHeaderUser`;
  const updateUserHeaderImageUrl = `${stargateRoot}/api/user-image/self/header-image`;
  await fetchApi(updateUserHeaderImageUrl, {
    method: 'PUT',
    body: JSON.stringify({
      headerImageId: fields.headerImageId,
    }),
  });

  if (checkFeatureGate('migrate_cloud_user_to_agg_user_query')) {
    const headerImageUrl = await buildHeaderImageUrl(userId);
    return {
      data: {
        User: {
          id: userId,
          headerImageUrl,
        },
      },
    };
  }
  // TODO: PTC-9458 This block of code is temporary and can be cleaned up once the feature gate is enabled.
  const query = gql`
    query ProfileHeaderUser($userId: String!) {
      User: CloudUser(userId: $userId) {
        id
        headerImageUrl(width: 1500)
      }
    }
  `;
  const response = await fetchApi(url, {
    method: 'POST',
    body: JSON.stringify({
      query: query,
      variables: {
        userId,
        fields,
      },
    }),
  });

  const data = (await response.json()) as UpdateUserHeaderOutput;
  return data;
};

export default updateHeaderImageWithUserPrefCall;
