/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Norwegian (Bokmål)
export default {
  'focused-task-close-account.cancel': 'Avbryt',
  'focused-task-close-account.deactivate-account': 'Deaktiver konto',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Du er i ferd med å slette brukerkolonnen til',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Du er i ferd med å slette brukerkolonnen til',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Deaktiver konto',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Deaktiver konto',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Du kan når som helst aktivere kontoen igjen.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Du kan når som helst aktivere kontoen igjen.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Når du deaktiverer kolonnen:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Når du deaktiverer kontoen:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Vi vil ikke lenger fakturere deg for dem.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Vi vil ikke lenger fakturere deg for dem.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester. For øyeblikket har de tilgang til:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester.  For øyeblikket har de ikke tilgang til noen, bortsett fra tjenester som Community og Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'andre Atlassian-kontotjenester, som Atlassian Community og Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Du <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester. For øyeblikket har du tilgang til:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Du <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester.  For øyeblikket har du ikke tilgang til noen, bortsett fra tjenester som Community og Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Personopplysningene deres, som fullt navn og e-postadresse, og innhold de har opprettet, forblir i Atlassian-kontotjenester.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Personopplysningene deres, som fullt navn og e-postadresse, og innhold de har opprettet, forblir i Atlassian-kontotjenester.',
  'focused-task-close-account.delete-account': 'Slett konto',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Merknad: Når du svarer på denne spørreundersøkelsen, hjelper du oss til å skape en bedre opplevelse for alle brukere. Brukeren vil fortsatt vises som «Tidligere bruker» etter at kontoen er slettet.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Merknad: Når du svarer på denne spørreundersøkelsen, hjelper du oss med å skape en bedre opplevelse for alle brukere, også dem som sletter kontoen sin. Du vil fortsatt vises som «Tidligere bruker» etter at du har slettet kontoen.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Tidligere bruker',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Hvordan ville du foretrekke at den slettede brukeren ble vist?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Hvordan ville du foretrekke at den slettede kontoen din ble vist?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Hvordan ville du foretrekke at den slettede brukeren ble vist til andre brukere hvis du kunne velge?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Hvordan ville du foretrekke å bli vist for andre brukere hvis du kunne velge?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Når konto er slettet, blir brukeren vist som «Tidligere bruker» for andre brukere. Vi vil sette pris på om du setter av et øyeblikk til å svare på spørreundersøkelsen vår.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Når kontoen er slettet, vises du som «Tidligere bruker» for andre brukere. Vi vil sette pris på om du setter av et øyeblikk på å svare på spørreundersøkelsen vår.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Vis mindre',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} mer',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Du er i ferd med å slette kolonnen til',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Du er i ferd med å slette kontoen din',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Slett konto',
  'focused-task-close-account.delete-account.overview.heading.self': 'Slett kontoen',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'For eksempel sider, saker og kommentarer de har opprettet i produkter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'For eksempel sider, saker og kommentarer du har opprettet i produkter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Du eller andre brukere kan ha installert apper som legger til funksjoner i Atlassian-produkter. Disse appene kan ha lagret brukerens profilinformasjon.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Du eller andre brukere kan ha installert apper som legger til funksjoner i Atlassian-produkter. Disse appene kan ha lagret profilinformasjonen din.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'informasjon knyttet til kjøp, som vi må oppbevare av hensyn til økonomisk rapportering',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'informasjon knyttet til kjøp, som vi må oppbevare av hensyn til økonomisk rapportering',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'registreringer som viser at vi har slettet en persons konto, som vi kanskje må gi til tilsynsorganer',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'registreringer som viser at vi har slettet en persons konto, som vi kanskje må gi til tilsynsorganer',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'data som brukes i et aktiv søksmål, som vi er pålagt ved lov å lagre',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'data som brukes i et aktiv søksmål, som vi er pålagt ved lov å lagre',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Vi tar vare på personopplysninger i en begrenset tid når vi har legitime grunner forbundet med lov eller forretningsdriften. Noen eksempler inkluderer',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Vi tar vare på personopplysninger i en begrenset tid når vi har legitime grunner forbundet med lov eller forretningsdriften. Noen eksempler inkluderer',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Vi sletter ikke personopplysninger fra innhold opprettet av brukere, som navn eller e-postadresser de har skrevet inn på en side eller en sak. Produktadministratorene må slette informasjonen manuelt.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Vi sletter ikke personopplysninger fra innhold opprettet av deg eller andre personer, som navn eller e-postadresser dere har skrevet inn på en side eller en sak. Produktadministratorene må slette informasjonen manuelt.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Brukerne har rett til å sende inn klager til tilsynsmyndigheten i landet der de bor.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Du har rett til å sende inn klager til tilsynsmyndigheten i landet der du bor.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Når du sletter kolonnen',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Når du sletter kolonnen din:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Innholdet de har opprettet, forblir i Atlassian-kontotjenestene.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Innholdet du har opprettet, forblir i Atlassian-kontotjenestene.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Etter en 14-dagers skånefrist kan du ikke kansellere slettingen av kontoen.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Vi vil sende deg en liste over apper som kan ha lagret personopplysningene deres.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Vi sender deg en liste over apper som kan ha lagret personopplysningene dine.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester. For øyeblikket har de tilgang til:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester.  For øyeblikket har vedkommende ikke tilgang til noen tjenester, bortsett fra tjenester som Community og Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Andre Atlassian-kontotjenester, som Atlassian Community og Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Du <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester. For øyeblikket har du tilgang til:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Du <b>mister tilgangen øyeblikkelig</b> til alle Atlassian-kontotjenester.  For øyeblikket har du ikke tilgang til noen tjenester, bortsett fra tjenester som Community og Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Vi sletter <b>personopplysningene deres</b>, som fullt navn og e-postadresse, fra Atlassian-kontotjenester innen 30 dager, unntatt i noen tilfeller der det kreves for legitime forretningsformål eller juridiske formål.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Vi sletter <b>personopplysningene dine</b>, som fullt navn og e-postadresse, fra Atlassian-kontotjenester innen 30 dager, unntatt i noen tilfeller der det kreves for legitime forretningsformål eller juridiske formål.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Etter en 14-dagers skånefrist kan du ikke kansellere slettingen av kontoen. Hvis du tror at du trenger kontoen senere, må du deaktivere den i stedet.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Etter en 14-dagers skånefrist kan du ikke kansellere slettingen av kontoen.',
  'focused-task-close-account.learnMore': 'Les mer',
  'focused-task-close-account.next': 'Neste',
  'focused-task-close-account.previous': 'Forrige'
};