import React, { useCallback, useState } from 'react';

import { useAccountSwitchDetector, useUser } from '../../hooks';
import { AccountSwitchFlag } from '../custom-flag/AccountSwitchFlag';

export const AccountSwitchDetectorUI = () => {
  const [showFlag, setShowFlag] = useState(false);
  const user = useUser();

  const onAccountChange = useCallback(() => {
    setShowFlag(true);
    setTimeout(() => setShowFlag(false), 3000);
  }, []);

  useAccountSwitchDetector({ onAccountChange });

  if (!user) return null;

  // We can replace AccountSwitchFlag with AccountSwitchProfileTracker when we are ready to move to the new top-right flag.

  return (
    <AccountSwitchFlag
      showFlag={showFlag}
      setShowFlag={setShowFlag}
      user={user}
    />
  );
};
