import React, { useContext } from 'react';

import { AccountClient } from '../../account-client/types';

const AccountClientContext = React.createContext<AccountClient | undefined>(
  undefined,
);

type AccountClientProviderProps = {
  client: AccountClient;
  children: React.ReactNode;
};

export const AccountClientProvider = ({
  client,
  children,
}: AccountClientProviderProps) => (
  <AccountClientContext.Provider value={client}>
    {children}
  </AccountClientContext.Provider>
);

export const useAccountClient = (): AccountClient => {
  const client = useContext(AccountClientContext);

  if (!client) {
    throw new Error(
      'Must provide an AccountClient with <AccountClientProvider>',
    );
  }

  return client;
};
