import React, { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import { Props } from './Anchor.types';
import { getStyledAnchor } from './Anchor.styled';

const getStyledLink = (LinkComponent: any) => getStyledAnchor(LinkComponent);

const StyledAnchor = getStyledLink('a');

const Anchor = ({
  LinkComponent = Link,
  children,
  to,
  appearance = 'default',
  onMouseDown = () => null,
  ...props
}: Props & HTMLAttributes<HTMLAnchorElement>) => {
  const StyledLink = getStyledLink(LinkComponent);

  const handleInternalMouseDown = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    onMouseDown(event);
  };

  const { href, target, ...linkProps } = props;

  if (to) {
    return (
      <StyledLink
        {...linkProps}
        onMouseDown={handleInternalMouseDown}
        to={to}
        appearance={appearance}
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledAnchor
      rel="noopener noreferrer"
      href={href}
      target={target}
      {...linkProps}
      appearance={appearance}
      onMouseDown={handleInternalMouseDown}
    >
      {children}
    </StyledAnchor>
  );
};

export default Anchor;
