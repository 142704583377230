// Reliably test against conditions like
// localStorage being full or private mode in Safari being a .
// https://gist.github.com/paulirish/5558557
var isLocalStorageSupported = function isLocalStorageSupported() {
  try {
    // use an random key to test
    // eslint-disable-next-line @repo/internal/react/disallow-unstable-values
    var testKey = "__test_".concat(Date.now(), "__");
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};
var safeLocalStorage = function safeLocalStorage() {
  if (isLocalStorageSupported()) {
    return localStorage;
  }

  // Returning a mock object here in the case that this is run in SSR mode
  if (typeof window === 'undefined') {
    return {
      getItem: function getItem(_key) {
        return null;
      },
      setItem: function setItem(_key, _value) {
        return null;
      },
      removeItem: function removeItem(_key) {
        return null;
      },
      clear: function clear() {
        return null;
      },
      key: function key(_index) {
        return null;
      },
      get length() {
        return 0;
      }
    };
  }
  if (window.__localStorageFallback && Object.keys(window.__localStorageFallback).length !== 0) {
    return window.__localStorageFallback;
  }
  var storageAPI = {
    getItem: function getItem(key) {
      if (window.__localStorageFallback.hasOwnProperty(key)) {
        return window.__localStorageFallback[key];
      }
      return null;
    },
    setItem: function setItem(key, value) {
      window.__localStorageFallback[key] = String(value);
    },
    removeItem: function removeItem(key) {
      delete window.__localStorageFallback[key];
    },
    clear: function clear() {
      for (var key in window.__localStorageFallback) {
        if (window.__localStorageFallback.hasOwnProperty(key)) {
          delete window.__localStorageFallback[key];
        }
      }
    },
    get length() {
      if (!window.__localStorageFallback) {
        return 0;
      }
      return Object.keys(window.__localStorageFallback).length;
    }
  };
  window.__localStorageFallback = Object.create(storageAPI);
  return window.__localStorageFallback;
};
export default safeLocalStorage;