/** @jsx jsx */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, keyframes, jsx } from '@emotion/react';
var fadeIn = keyframes({
  to: {
    opacity: 1
  }
});
var fadeInStyles = css({
  animation: "".concat(fadeIn, " 500ms ease forwards"),
  opacity: 0,
  margin: 0,
  padding: 0
});
export var FadeIn = function FadeIn(_ref) {
  var children = _ref.children,
    className = _ref.className,
    _ref$tag = _ref.tag,
    Tag = _ref$tag === void 0 ? 'div' : _ref$tag;
  return (
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
    jsx(Tag, {
      css: fadeInStyles,
      className: className
    }, children)
  );
};