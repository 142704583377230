/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'focused-task-close-account.cancel': 'Cancel',
  'focused-task-close-account.deactivate-account': 'Deactivate account',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': "You're about to deactivate the user account of:",
  'focused-task-close-account.deactivate-account.overview.first.line.self': "You're about to deactivate the user account of:",
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Deactivate account',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Deactivate account',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'You can reactivate the account at any time.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'You can reactivate the account at any time.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'When you deactivate the account:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'When you deactivate the account:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': "We'll no longer bill you for them.",
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': "We'll no longer bill you for them.",
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} will <b>immediately lose access</b> to all Atlassian account services. They currently have access to:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} will <b>immediately lose access</b> to all Atlassian account services. Currently, they don’t have access to any, except services like Community and Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Other Atlassian account services, such as Atlassian Community and Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': "You'll <b>immediately lose access</b> to all Atlassian account services. You currently have access to:",
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': "You'll <b>immediately lose access</b> to all Atlassian account services. Currently, you don’t have access to any, except services like Community and Marketplace.",
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': "Their personal data, such as their full name and email address, and content they've created will remain in Atlassian account services.",
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': "Their personal data, such as their full name and email address, and content they've created will remain in Atlassian account services.",
  'focused-task-close-account.delete-account': 'Delete account',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Note: Answering this survey will help us make a better experience for all users. The user will still appear as “Former user” after you delete their account.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Note: Answering this survey will help us make a better experience for all users, including those deleting their accounts. You’ll still appear as “Former user” after you delete your account.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Former user',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'How would you prefer the deleted user to appear?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'How would you prefer your deleted account to appear?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'If we gave you a choice, how would you like the deleted user to appear to other users?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'If we gave you a choice, how would you like to appear to other users?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'After the user’s account is deleted, they’ll appear as “Former user” to other users. Please take a moment to answer our survey question.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'After your account is deleted, you’ll appear as “Former user” to other users. Please take a moment to answer our survey question.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Show less',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} more',
  'focused-task-close-account.delete-account.overview.first.line.admin': "You're about to delete the account of:",
  'focused-task-close-account.delete-account.overview.first.line.self': "You're about to delete your account:",
  'focused-task-close-account.delete-account.overview.heading.admin': 'Delete account',
  'focused-task-close-account.delete-account.overview.heading.self': 'Delete your account',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': "For example, pages, issues, and comments they've created in products.",
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': "For example, pages, issues, and comments you've created in products.",
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'You or other users may have installed apps that add features to Atlassian products. These apps may have stored the user’s profile information.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'You or other users may have installed apps that add features to Atlassian products. These apps may have stored your profile information.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': "Information related to purchases, which we're required to keep for financial reporting.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': "Information related to purchases, which we're required to keep for financial reporting.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': "Records showing that we deleted someone's account, which we may need to provide to regulators.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': "Records showing that we deleted someone's account, which we may need to provide to regulators.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': "Data that's part of an active lawsuit, which we're required to keep by law.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': "Data that's part of an active lawsuit, which we're required to keep by law.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'We keep personal data for limited periods when we have legitimate business or legal purposes. Some examples include:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'We keep personal data for limited periods when we have legitimate business or legal purposes. Some examples include:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'We don’t delete any personal data from content created by users, such as names or email addresses they typed in a page or issue. The product admins need to delete that data manually.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': "We don't delete any personal data from content created by you or other people, such as names or email addresses typed into a page or issue. Your product admins need to delete that data manually.",
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Users have the right to submit complaints to the supervisory authority in their country.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'You have the right to submit complaints to the supervisory authority in your country.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'When you delete the account:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'When you delete your account:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': "The content they've created will remain in Atlassian account services.",
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': "The content you've created will remain in Atlassian account services.",
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'After a 14-day grace period, you won’t be able to cancel the deletion of the account.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'We’ll email you a list of apps that may have stored their personal data.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'We’ll email you a list of apps that may have stored your personal data.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} will <b>immediately lose access</b> to all Atlassian account services. They currently have access to:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} will <b>immediately lose access</b> to all Atlassian account services. Currently, they don’t have access to any, except services like Community and Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Other Atlassian account services, such as Atlassian Community and Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': "You'll <b>immediately lose access</b> to all Atlassian account services. You currently have access to:",
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': "You'll <b>immediately lose access</b> to all Atlassian account services. Currently, you don’t have access to any, except services like Community and Marketplace.",
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': "We'll <b>delete their personal data</b>, such as their full name and email address, from Atlassian account services within 30 days, except in a few cases where required for legitimate business or legal purposes.",
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': "We'll <b>delete your personal data</b>, such as your full name and email address, from Atlassian account services within 30 days, except in a few cases where required for legitimate business or legal purposes.",
  'focused-task-close-account.delete-account.overview.warning-section.body': 'After a 14-day grace period, you won’t be able to cancel the deletion of the account. If you think you’ll need the account later, deactivate it instead.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'After a 14-day grace period, you won’t be able to cancel the deletion of the account.',
  'focused-task-close-account.learnMore': 'Learn more',
  'focused-task-close-account.next': 'Next',
  'focused-task-close-account.previous': 'Previous'
};