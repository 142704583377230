import get from 'lodash/get';

import identityClient, { UserInfo } from '../clients/identity-client';
import { userInfoProvider } from '../clients/user-info-provider';
import { REVERT_USER_EMAIL, UPDATE_USER_EMAIL } from './profile-email';

export enum ExternalUserManagers {
  GOOGLE = 'google',
  SAML = 'saml',
}

export interface UserData {
  accountId: string | null;
  fullName: string | null;
  email: string | null;
  isEmailVerified: boolean | null;
  pendingEmail: string | null;
  isManaged: boolean | null;
  managedBy: string | null;
  managedById?: string;
  manageStartedTimestamp?: string;
  tsvEnabled: boolean | null;
  picture: string | null;
  mfaEnforcedByAuthPolicy: boolean | null;
}

interface StateData extends UserData {
  isSiteAdmin: boolean | null;
  isSiteAdminLoading: boolean | null;
}

export interface UserState {
  data: StateData;
  isLoading: null | boolean;
  isError: boolean;
}

// ------------------------------------
// Constants
// ------------------------------------
export const GET_USER = 'GET_USER';
export const USER_CORE_READY = 'USER_CORE_READY';
export const GET_IS_SITE_ADMIN = 'GET_IS_SITE_ADMIN';
export const SET_TSV_DISABLED = 'SET_TSV_DISABLED';

// ------------------------------------
// Actions
// ------------------------------------
export function getCurrentUser() {
  return async dispatch => {
    const {
      userId: accountId,
      isEmailVerified,
      fullName,
      email,
      picture,
    } = await userInfoProvider.get();

    // dispatch this action so that we can have current user-id in Redux store as soon as possible
    // it will increase render performance of other components which needs current user-id
    dispatch({
      type: USER_CORE_READY,
      payload: {
        accountId,
        isEmailVerified,
        fullName,
        email,
        picture,
      },
    });

    const userInfoPromise = identityClient.getUserInfo();

    dispatch({
      type: GET_USER,
      payload: userInfoPromise,
    });
  };
}

export function getIsSiteAdmin() {
  return async dispatch => {
    dispatch({
      type: GET_IS_SITE_ADMIN,
      payload: Promise.resolve(false),
    });
  };
}

export function setTsvDisabled() {
  return {
    type: SET_TSV_DISABLED,
  };
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState: UserState = {
  data: {
    isSiteAdmin: false,
    isSiteAdminLoading: null,
    accountId: null,
    fullName: null,
    email: null,
    isEmailVerified: null,
    pendingEmail: null,
    isManaged: null,
    managedBy: null,
    managedById: undefined,
    manageStartedTimestamp: undefined,
    tsvEnabled: null,
    picture: null,
    mfaEnforcedByAuthPolicy: null,
  },
  isLoading: null,
  isError: false,
};

const getSafeIsManagedState = (data: UserInfo): boolean =>
  (data.userManagedStatus && data.userManagedStatus.managed) || false;

const getSafeManagedOwner = (data: UserInfo): string | null => {
  const ownerType =
    data.authProvider || get(data, 'userManagedStatus.owner.ownerType');
  return ownerType ? ownerType.toLowerCase() : null;
};

const getSafeManagedOwnerId = (data: UserInfo): string | undefined =>
  data.userManagedStatus?.owner?.ownerId;

const getSafeManageStartedTimestamp = (data: UserInfo): string | undefined =>
  data.userManagedStatus?.owner?.timestamp;

const isTsvEnabled = (data: UserInfo): boolean => {
  return data.useMfa || false;
};

const isMfaEnforcedByAuthPolicy = (data: UserInfo): boolean => {
  return data.mfaEnforcedByAuthPolicy || false;
};

// Map REST payload to domain model
const dataFromPayload = (payload): Partial<UserData> => {
  // don't need `accountId` prop because `accountId` is already set by USER_CORE_READY action
  return {
    email: payload.email,
    fullName: payload.fullName,
    pendingEmail: payload.pendingEmail,
    isManaged: getSafeIsManagedState(payload),
    managedBy: getSafeManagedOwner(payload),
    managedById: getSafeManagedOwnerId(payload),
    manageStartedTimestamp: getSafeManageStartedTimestamp(payload),
    tsvEnabled: isTsvEnabled(payload),
    mfaEnforcedByAuthPolicy: isMfaEnforcedByAuthPolicy(payload),
  };
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_USER}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_USER}_FULFILLED`:
      return {
        ...state,
        data: {
          ...state.data,
          ...dataFromPayload(action.payload),
        },
        isLoading: false,
        isError: false,
      };
    case `${GET_USER}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case `${GET_IS_SITE_ADMIN}_PENDING`:
      return {
        ...state,
        data: {
          ...state.data,
          isSiteAdminLoading: true,
        },
      };
    case `${GET_IS_SITE_ADMIN}_FULFILLED`:
      return {
        ...state,
        data: {
          ...state.data,
          isSiteAdmin: action.payload as boolean,
          isSiteAdminLoading: false,
        },
      };
    case `${GET_IS_SITE_ADMIN}_REJECTED`:
      return {
        ...state,
        data: {
          ...state.data,
          isSiteAdminLoading: false,
        },
      };
    case `${UPDATE_USER_EMAIL}_FULFILLED`:
      return {
        ...state,
        data: {
          ...state.data,
          pendingEmail: action.payload.newEmail,
        },
      };
    case `${REVERT_USER_EMAIL}_FULFILLED`:
      return {
        ...state,
        data: {
          ...state.data,
          pendingEmail: null,
        },
      };
    case `${USER_CORE_READY}`:
      return {
        ...state,
        isLoading: true,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case SET_TSV_DISABLED:
      return {
        ...state,
        data: {
          ...state.data,
          tsvEnabled: false,
        },
      };
    default:
      return state;
  }
}
