import { useEffect, useState } from 'react';

import { AssociatedSession } from '../../../account-client/types';

import { useAccountClient } from '../../common/context';

export function useRecentAsscociatedSessions(): AssociatedSession[] {
  const client = useAccountClient();
  const [asscociatedSessions, setAsscociatedSessions] = useState<
    AssociatedSession[]
  >([]);
  useEffect(() => {
    void client.getRecentAssociatedSessions().then(setAsscociatedSessions);
  }, [client]);
  return asscociatedSessions;
}
