import { useEffect, useRef, useState } from 'react';

import { useAccountClient } from '../../common/context';
import { useUser } from '../use-user';

export type AccountSwitchDetectorParams = {
  onAccountChange: ({
    prevAaid,
    prevEmail,
  }: {
    prevAaid: string;
    prevEmail: string;
  }) => void;
  onError?: (e: Error) => void;
};

const useDocumentVisibility = () => {
  const [documentVisibility, setDocumentVisibility] = useState<
    DocumentVisibilityState
  >(document.visibilityState);

  useEffect(() => {
    const onVisibilityChange = () => {
      setDocumentVisibility(document.visibilityState);
    };
    document.addEventListener('visibilitychange', onVisibilityChange);

    return function cleanup() {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return documentVisibility;
};

export const useAccountSwitchDetector = ({
  onAccountChange,
  onError,
}: AccountSwitchDetectorParams) => {
  const accountClient = useAccountClient();
  const user = useUser();
  const previousUser = usePrevious(user);
  const documentVisibility = useDocumentVisibility();
  const [hasAccountChanged, setHasAccountChanged] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }
    window.localStorage.setItem('aaid', user.account_id);
  }, [user]);

  useEffect(() => {
    const onStorageChange = () => {
      const newAaid = window.localStorage.getItem('aaid');
      if (previousUser && newAaid !== previousUser.account_id) {
        setHasAccountChanged(true);
      }
    };
    window.addEventListener('storage', onStorageChange);
    return function cleanup() {
      window.removeEventListener('storage', onStorageChange);
    };
  }, [previousUser]);

  useEffect(() => {
    if (hasAccountChanged && previousUser && documentVisibility === 'visible') {
      accountClient
        .switchAccount(previousUser.account_id)
        .then(() => {
          window.localStorage.setItem('aaid', previousUser.account_id);
          onAccountChange({
            prevAaid: previousUser.account_id,
            prevEmail: previousUser.email,
          });
          setHasAccountChanged(false);
        })
        .catch(error => {
          onError?.(error);
          const previousUserSessionSyncUrl = accountClient.getSessionSyncLink(
            window.location.href,
            previousUser.email,
          );
          window.location.replace(previousUserSessionSyncUrl);
        });
    }
  }, [
    hasAccountChanged,
    previousUser,
    documentVisibility,
    accountClient,
    onAccountChange,
    onError,
  ]);
};

function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
