import { getEnv } from '../config/env';

export enum FeatureFlag {
  EXAMPLE_FF = 'example.ff.id',
  // https://app.launchdarkly.com/identity/staging/features/identity.mange-profile-ui.color-theme-menu.enabled/targeting
  ENABLE_SETTINGS_MENU_AND_COLOR_THEMES = 'identity.mange-profile-ui.color-theme-menu.enabled', //spelling error 'mange' matches LD-key
  // https://app.launchdarkly.com/identity/staging/features/allow-users-to-remove-themselves-from-site-org/targeting
  ALLOW_USERS_TO_REMOVE_THEMSELVES_FROM_SITES = 'allow-users-to-remove-themselves-from-site-org',
  // https://app.launchdarkly.com/identity/staging/features/identity.manage-profile-ui.in-product-account-switcher.enabled/targeting
  ENABLE_INPRODUCT_SWITCHER = 'identity.manage-profile-ui.in-product-account-switcher.enabled',
  // https://app.launchdarkly.com/identity/staging/features/identity.manage-profile.ui.just-in-time-account-switcher-detector.enabled/targeting
  ENABLE_JUST_IN_TIME_ACCOUNT_SWITCHER = 'identity.manage-profile.ui.just-in-time-account-switcher-detector.enabled',
}

// All feature flags must be listed here so that they can be selectively fetched from LaunchDarkly
// For real execution environments, configure flags in https://app.launchdarkly.com/identity/test/features
// The default values here are used as the fallback when everything else (LD, local storage cache, local overrides) fails
export const DEFAULT_FEATURE_FLAGS: FeatureFlagResult = {
  [FeatureFlag.EXAMPLE_FF]: false,
  [FeatureFlag.ENABLE_SETTINGS_MENU_AND_COLOR_THEMES]: false,
  [FeatureFlag.ALLOW_USERS_TO_REMOVE_THEMSELVES_FROM_SITES]: false,
  [FeatureFlag.ENABLE_INPRODUCT_SWITCHER]: false,
  [FeatureFlag.ENABLE_JUST_IN_TIME_ACCOUNT_SWITCHER]: false,
};

// Overrides only for local/CI environments
const LOCAL_OVERRIDE_FEATURE_FLAGS: Partial<FeatureFlagResult> = {
  [FeatureFlag.EXAMPLE_FF]: true,
  [FeatureFlag.ENABLE_SETTINGS_MENU_AND_COLOR_THEMES]: true,
  [FeatureFlag.ALLOW_USERS_TO_REMOVE_THEMSELVES_FROM_SITES]: false,
  [FeatureFlag.ENABLE_INPRODUCT_SWITCHER]: true,
  [FeatureFlag.ENABLE_JUST_IN_TIME_ACCOUNT_SWITCHER]: true,
};

export type FeatureFlagResult = { [k in FeatureFlag]: boolean };

export const effectiveFlags: FeatureFlagResult = {
  ...DEFAULT_FEATURE_FLAGS,
  ...(getEnv() === 'local' ? LOCAL_OVERRIDE_FEATURE_FLAGS : {}),
};
