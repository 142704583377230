// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N20A } from '@atlaskit/theme/colors';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var SkeletonText = styled.span({
  display: 'inline-block',
  height: '1em',
  borderRadius: '0.5em',
  backgroundColor: "var(--ds-background-neutral, ".concat(N20A, ")")
},
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
function (_ref) {
  var length = _ref.length;
  return {
    width: length
  };
});