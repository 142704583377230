import asDataProvider from './as-data-provider';
import { fetchJson } from '../utils/fetch';
import { withCached } from '../utils/with-cached';

/**
 * Some items might be using the type `ExportedDataProvider` instead due to errors with
 * the generated documentation
 */

/**
 * `WithCached` within `DataProvider` breaks the documentation with error:
 * `Error: Missing converter for: TSMappedType` due to usage of Proxy function
 * so we are exporting a simpler type here just for the docs. There has been reported
 * on their repo already: https://github.com/atlassian/extract-react-types/issues/75
 */

export var createProvider = function createProvider(name, experienceMark, url) {
  var fetchMethod = withCached(function (param) {
    return fetchJson(url);
  });
  return {
    fetchMethod: fetchMethod,
    ProviderComponent: asDataProvider(name, experienceMark, fetchMethod, fetchMethod.cached,
    // fetch will only throw on a network exception,
    // so we can safely retry on all exceptions
    {
      shouldRetryOnException: function shouldRetryOnException() {
        return true;
      },
      intervalsMS: [50, 200, 1000]
    })
  };
};
export var createProviderWithCustomFetchData = function createProviderWithCustomFetchData(name, experienceMark, fetchData, retryConfig) {
  var fetchMethod = withCached(function (param) {
    return fetchData(param);
  });
  return {
    fetchMethod: fetchMethod,
    ProviderComponent: asDataProvider(name, experienceMark, fetchMethod, fetchMethod.cached, retryConfig)
  };
};