import { LPUserPreferencesState } from './lp-user-preferences-types';
import {
  GetMyPreferencesAction,
  LPUserPreferencesAction,
  UpdateMyPreferencesAction,
} from './lp-user-preferences-actions';

export const GET_MY_PREFERENCES = 'GET_MY_PREFERENCES';
export const UPDATE_MY_PREFERENCES = 'UPDATE_MY_PREFERENCES';

export const initialState: LPUserPreferencesState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  userPreferences: {
    defaultAppearance: 'inline',
    appearances: [],
  },
};

export default (
  state = initialState,
  action: LPUserPreferencesAction,
): LPUserPreferencesState => {
  switch (action.type) {
    case `${GET_MY_PREFERENCES}_PENDING`:
      return {
        ...state,
        isLoading: true,
      };
    case `${GET_MY_PREFERENCES}_FULFILLED`:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasError: false,
        userPreferences: (action as GetMyPreferencesAction).payload,
      };
    case `${GET_MY_PREFERENCES}_REJECTED`:
      if ((action as GetMyPreferencesAction).payload.status === 404) {
        return {
          ...state,
          isLoading: false,
          hasLoaded: true,
          hasError: false,
          userPreferences: {
            defaultAppearance: 'inline',
            appearances: [],
          },
        };
      }
      return {
        ...state,
        hasError: true,
      };
    case `${UPDATE_MY_PREFERENCES}_PENDING`:
      return {
        ...state,
        userPreferences:
          (action as UpdateMyPreferencesAction).meta || state.userPreferences,
      };
    case `${UPDATE_MY_PREFERENCES}_FULFILLED`:
      return {
        ...state,
        userPreferences: (action as UpdateMyPreferencesAction).payload,
      };
    default:
      return state;
  }
};
