import styled from '@emotion/styled';
import { token } from '@atlaskit/tokens';
import { colors } from '@atlaskit/theme';

export const AvatarSectionCard = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  border-radius: ${token('border.radius.100', '3px')};
  box-shadow: ${token(
    'elevation.shadow.raised',
    '0px 1px 1px #091E4240, 0px 0px 1px #091E424F',
  )};
  background: ${token('elevation.surface.raised', colors.N0)};
`;

export const HeaderImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 112px;
  border-radius: ${token('border.radius.100', '3px')}
    ${token('border.radius.100', '3px')} 0 0;
`;

export const AvatarWrapper = styled.div`
  margin-top: 38px;
  margin-left: ${token('space.600', '48px')};
`;

export const ContentWrapper = styled.div`
  margin-top: 112px;
  padding: 0 ${token('space.200', '16px')} ${token('space.200', '16px')};
`;

export const PrivacyControlsLabel = styled.small`
  font-size: 11px;
  line-height: ${token('space.200', '16px')};
  margin-bottom: ${token('space.100', '8px')};
  padding-left: ${token('space.100', '8px')};
  width: 100%;
`;

export const PrivacyControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 200px;
`;
