import { useQuery } from '@tanstack/react-query';
import { getConfig } from '../../../config/envConfig';
import { fetchApi } from '../../../../utils/fetchApi';
import { gql } from 'graphql-request';
import { checkFeatureGate } from '../../Statsig/utils';
import buildHeaderImageUrl from '../utils/buildHeaderImageUrl';

export const profileHeaderUserQueryKey = 'profileHeaderUserQueryKey';

export type ProfileHeaderApiResponse = {
  data: {
    User: {
      id: string;
      headerImageUrl: string;
    };
  };
  extensions: {
    errorNumber: number;
  };
};

type ProfileHeaderUserQueryData = {
  id: string;
  headerImageUrl: string;
};

const profileHeaderUserQuery = async (
  userId: string,
): Promise<ProfileHeaderUserQueryData> => {
  if (
    checkFeatureGate('migrate_cloud_user_to_agg_user_query') &&
    checkFeatureGate('migrate_media_token_to_user_preferences_service')
  ) {
    const headerImageUrl = await buildHeaderImageUrl(userId);
    return {
      id: userId,
      headerImageUrl,
    };
  }

  // TODO: PTC-9458 clear Object property CloudUser once the feature gate is enabled.
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/directory/graphql?q=ProfileHeaderUser`;
  const query = gql`
    query ProfileHeaderUser($userId: String!) {
      User: CloudUser(userId: $userId) {
        id
        headerImageUrl(width: 1500)
      }
    }
  `;
  const response = await fetchApi(url, {
    method: 'POST',
    body: JSON.stringify({
      query: query,
      variables: {
        userId,
      },
    }),
  });
  const { data } = (await response.json()) as ProfileHeaderApiResponse;
  return data.User;
};
export const useProfileHeaderUserQuery = (userId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [profileHeaderUserQueryKey, userId],
    queryFn: () => profileHeaderUserQuery(userId),
  });

  return { data, isLoading, isError };
};
