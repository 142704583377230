import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from '@emotion/styled';
import { fontSize } from '@atlaskit/theme/constants';
import { h500 } from '@atlaskit/theme/typography';
import { N200 } from '@atlaskit/theme/colors';
import gridSizeTimes from '../../util/gridSizeTimes';
var baseHeading = function baseHeading(size, lineHeight) {
  return "\n  font-size: ".concat(size / fontSize(), "em;\n  font-style: inherit;\n  line-height: ").concat(lineHeight / size, ";\n");
};
export var UserInfoOuter = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin-bottom: ", "px;\n"])), gridSizeTimes(2));
export var Avatar = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", "px;\n  margin-right: ", "px;\n"])), gridSizeTimes(2.5), gridSizeTimes(1));
export var UserDetails = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", "px;\n"])), gridSizeTimes(0.5));
export var UserName = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n  margin-top: 0;\n"])), h500);
export var UserEmail = styled.span(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", " color: ", ";\n  font-weight: 300;\n  margin-top: ", "px;\n"])), baseHeading(11, 16), "var(--ds-text-subtlest, ".concat(N200, ")"), gridSizeTimes(0.5));