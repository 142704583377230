import React from 'react';
import Textfield from '@atlaskit/textfield';
import * as Styled from './TextEditView.styled';

const TextEditView = fieldProps => (
  <Styled.Wrapper isEmpty={!fieldProps.value}>
    <Textfield autoFocus {...fieldProps} />
  </Styled.Wrapper>
);

export default TextEditView;
