// we want to import this before anything else
import '../../early-init';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import '@atlaskit/css-reset/dist/bundle.css';

import {
  getAnalyticsClient,
  GenericErrorPage,
  getEnv,
  installSentry,
  logException,
  requestLoginCheckOrRedirect,
  userInfoProvider,
} from '../../common';

import { startBrowserMetrics } from '../../common/browser-metrics/startBrowserMetrics';

import UserSettingsAppContainer from './components/UserSettingsAppContainer';
import createStore from './store/createStore';
import { initializeFeatureFlagClient } from '../../common/modules/featureFlagClient';
import { isFedRamp } from '../../common/config/env';

const env = getEnv();

if (env === 'prod' || env === 'stg') {
  const SENTRY_PROJECT_KEY = '0f1ab734a2224e33bd8d1710e9eb5aaa';
  const SENTRY_PROJECT_ID = '6717816';
  void installSentry({
    dsn: `https://${SENTRY_PROJECT_KEY}@o55978.ingest.sentry.io/${SENTRY_PROJECT_ID}`,
    version: process.env.VERSION,
    env,
  });
} else if (isFedRamp()) {
  const SENTRY_PROJECT_KEY = 'e9f88d5249c97aadd98a08a48a7fa50b';
  const SENTRY_PROJECT_ID = '21';
  void installSentry({
    // https://hello.atlassian.net/wiki/spaces/OBSERVABILITY/pages/3370131692/Sentry+FedRAMP+-+Getting+Started#Sentry-DSN-in-FedRAMP
    dsn: `https://${SENTRY_PROJECT_KEY}@sentry.atlassian-us-gov-mod.com/${SENTRY_PROJECT_ID}`,
    version: process.env.VERSION,
    env,
  });
}

const history = createBrowserHistory();
const store = createStore();
const analytics = getAnalyticsClient(store);

const MOUNT_NODE = document.getElementById('root');

if (MOUNT_NODE === null) {
  throw new Error('Could not find HTMLElement to mount react app');
}
const root = createRoot(MOUNT_NODE);

const render = (App = UserSettingsAppContainer) => {
  root.render(<App store={store} analytics={analytics} history={history} />);
  startBrowserMetrics(analytics);
};

const onLoginCheckOrRedirectSuccess = ({ redirectToLogin }) => {
  if (redirectToLogin) {
    redirectToLogin();
  } else {
    render();
  }
};

const renderErrorPage = error => {
  if (error.response && error.response.status !== 401) {
    void logException(error, 'Error fetching user info early (IDAC).');
  }
  root.render(<GenericErrorPage />);
};

requestLoginCheckOrRedirect(userInfoProvider.get())
  .then(onLoginCheckOrRedirectSuccess)
  .catch(error => renderErrorPage(error));

void userInfoProvider.get().then(userInfo => {
  const userId = userInfo.userId;
  const userEmail = userInfo.email;
  void initializeFeatureFlagClient({ userId, userEmail });
});

render();
