import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const Headline = styled.h5`
  margin: 0;
`;

export const Message = styled.p`
  margin-top: ${token('space.150', '12px')};
`;

export const Label = styled.label`
  font-size: 12px;
  color: ${token('color.text.subtle', colors.N200)};
  line-height: ${token('space.200', '16px')};
  vertical-align: middle;
  margin-bottom: ${token('space.050', '4px')};
`;

export const LabelText = styled.span`
  font-weight: 600;
  padding: 0;
`;

export const InlineEditWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${token('color.text', colors.N800)};
`;

export const InlineEditInnerWrapper = styled.div`
  width: 100%;
  margin-right: -8px;
  display: flex;
  flex-direction: column;
`;

export const ReadViewWrapper = styled.div`
  /* Align with edit view */
  padding: 8px 6px 8px 8px;
  line-height: 20px;
  font-size: 14px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: nowrap;
  color: ${(props: { isEmpty: boolean }) =>
    props.isEmpty ? token('color.text.subtlest', colors.N100) : 'inherit'};
`;

export const ReadOnlyViewWrapper = styled(ReadViewWrapper)`
  margin-top: 10px;
  margin-left: 2px;
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  height: 40px;

  margin-right: ${token('space.200', '16px')};
  color: ${token('color.text.subtle', colors.N200)};
`;

export const InlineDialogDisabledTrigger = styled.div`
  cursor: not-allowed;
`;
