import React from 'react';

export enum ProfileAvatarDropdownMenuIconSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

export interface ProfileAvatarDropdownMenuProps {
  /** Should consist of DropdownItem components from @atlaskit/dropdown-menu */
  children?: React.ReactNode;
  iconSize: ProfileAvatarDropdownMenuIconSize;
}
