import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { FieldType, FieldsConfigMap } from './types';

import { FieldInputType } from '../../../../../common/components/ProfileDetailsInlineEdit/types';

import {
  getConfig,
  Anchor,
  GenericInlineDialogContent,
} from '../../../../../common';

const getAnchorProps = (endPoint: string, { isSPA } = { isSPA: true }) => {
  const url = isSPA
    ? endPoint
    : `${getConfig().atlassianAccountUrl}${endPoint}`;

  return isSPA ? { to: url } : { target: '_blank', href: url };
};

export const getDisabledLocalTimeInlineDialogContent = (
  formatMessage,
  { isSPA } = { isSPA: true },
) => {
  const anchorProps = getAnchorProps('/manage-profile/account-preferences', {
    isSPA,
  });

  return (
    <GenericInlineDialogContent
      message={
        <>
          <FormattedMessage
            id="ptc-directory.settings.profile-and-visibility.details.local.time"
            defaultMessage="Go to the Account preferences tab to manage your time zone."
            description="A message displayed when user is hovering a field to edit their local time value that can not be edited"
          />
          <p>
            <Anchor {...anchorProps}>
              <FormattedMessage
                id="ptc-directory.settings.profile-and-visibility.details.manage.time.zone"
                defaultMessage="Manage your time zone"
                description='A link name which points to Account preferences: "/manage-profile/account-preferences" to edit timezone'
              />
            </Anchor>
          </p>
        </>
      }
    />
  );
};

const isManagedNextMessage = () => {
  return (
    <FormattedMessage
      id="ptc-directory.settings.profile-and-visibility.details.managed-account.nickname.info.xml"
      defaultMessage="Your display name on Atlassian public forums, such as Atlassian Community. {br} Also used if you restrict the visibility of <strong>Full name</strong>."
      description="Detailed explanation to a managed user's public name field"
      tagName="span"
      values={{
        // eslint-disable-next-line react/display-name
        strong: ((chunks => (
          <strong>{chunks}</strong>
        )) as unknown) as React.ReactNode,
        br: <br />,
      }}
    />
  );
};

export const getFormFields = ({
  formatMessage,
  isManaged,
}): FieldsConfigMap => {
  return {
    [FieldType.FullName]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.fullName',
        defaultMessage: 'Full name',
        description: 'Label for the input field for the users full name',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.fullName',
        defaultMessage: 'Your name',
        description:
          'Placeholder text displayed in an empty input field for the users full name',
      }),
      disabled: (
        <GenericInlineDialogContent
          message={formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.details.full-name.disabled-eading',
            defaultMessage: 'Full name is set by your admin.',
            description:
              'A message displayed when user is hovering a field to edit full name value that can not be edited',
          })}
        />
      ),
      privacy: {
        key: 'name',
      },
      isRequired: true,
      requiredInvalidMessage: formatMessage({
        id: 'pf-home.profile-edit-form.required.invalid.fullName',
        defaultMessage: 'Full name is required',
        description: 'A message when full name field is empty',
      }),
    },
    [FieldType.Nickname]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.nickname',
        defaultMessage: 'Public name',
        description:
          'Label for the input field for the users nickname (now called Public name)',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.nickname',
        defaultMessage: 'Your public name',
        description:
          'Placeholder text displayed in an empty input field for the users nickname (now called Public name)',
      }),
      info: isManaged ? (
        isManagedNextMessage()
      ) : (
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.details.unmanaged-account.nickname.info"
          defaultMessage="Your display name on Atlassian public forums, such as Atlassian Community."
          description="Detailed explanation to a unmanaged user's public name field"
          tagName="span"
        />
      ),
      privacy: {
        key: 'nickname',
      },
      isRequired: true,
      requiredInvalidMessage: formatMessage({
        id: 'pf-home.profile-edit-form.required.invalid.nickname',
        defaultMessage: 'Public name is required',
        description: 'A message when public name field is empty',
      }),
    },
    [FieldType.Title]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.title',
        defaultMessage: 'Job title',
        description:
          'Label for the input field for the users job title/occupation',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.title',
        defaultMessage: 'Your job title',
        description:
          'Placeholder text displayed in an empty input field for the users job title/occupation',
      }),
      disabled: (
        <GenericInlineDialogContent
          message={formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.details.title.managed',
            defaultMessage: 'Job title is set by your admin.',
            description:
              'A message displayed when user is hovering a field to edit their job title value that can not be edited',
          })}
        />
      ),
      privacy: {
        key: 'jobTitle',
      },
    },
    [FieldType.Department]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.department',
        defaultMessage: 'Department',
        description:
          'Label for the input field describing the department/division a user is a member of',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.department',
        defaultMessage: 'Your department',
        description:
          'Placeholder text displayed in an empty input field for the users department/division',
      }),
      disabled: (
        <GenericInlineDialogContent
          message={formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.details.department.managed',
            defaultMessage: 'Department is set by your admin.',
            description:
              'A message displayed when user is hovering a field to edit the deparment value that can not be edited',
          })}
        />
      ),
      privacy: {
        key: 'department',
      },
    },
    [FieldType.CompanyName]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.companyName',
        defaultMessage: 'Organization',
        description:
          'Label for the input field describing the company/business a user is a member of',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.companyName',
        defaultMessage: 'Your organization',
        description:
          'Placeholder text displayed in an empty input field for the users company',
      }),
      disabled: (
        <GenericInlineDialogContent
          message={formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.details.organisation.managed',
            defaultMessage: 'Organization is set by your admin.',
            description:
              'A message displayed when user is hovering a field to edit their organisation value that can not be edited',
          })}
        />
      ),
      privacy: {
        key: 'organization',
      },
    },
    [FieldType.Location]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.location',
        defaultMessage: 'Based in',
        description: `Label name to for the field representing the user's domicile.
          The location can be both a city and/or country.
          E.g. "Based in Sydney, Australia" or "Based in Australia"`,
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.location',
        defaultMessage: 'Your location',
        description: `Placeholder text displayed in an empty input field for the users location.
        The location can be both a city and/or country.
        E.g. "Based in Sydney, Australia" or "Based in Australia"`,
      }),
      privacy: {
        key: 'location',
      },
    },
    [FieldType.Timezone]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.timezone',
        defaultMessage: 'Time zone',
        description: 'Label for the input field for the users time zone',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.timezone',
        defaultMessage: 'Your time zone',
        description:
          'Placeholder text displayed in an empty input field for the users time zone',
      }),
      inputType: FieldInputType.Timezone,
      privacy: {
        key: 'zoneinfo',
      },
    },
    [FieldType.LocalTime]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.localTime',
        defaultMessage: 'Local time',
        description: 'Label for the input field for the users local time',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.localTime',
        defaultMessage: 'You have not set your time zone yet',
        description: '',
      }),
      // this is read-ony field
      disabled: getDisabledLocalTimeInlineDialogContent(formatMessage),
      // this is same as above timezone field so that user still can change visibility of timezone here.
      privacy: {
        key: 'zoneinfo',
      },
    },
    [FieldType.Email]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.email',
        defaultMessage: 'Email address',
        description: 'Label for the input field for the users email address',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.email',
        defaultMessage: 'Your email address',
        description:
          'Placeholder text displayed in an empty input field for the users email address',
      }),
      managed: (
        <GenericInlineDialogContent
          message={formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.details.email.managed',
            defaultMessage: 'Email address is set by your admin',
            description:
              'Message displayed in the inline dialog in case users email is readonly as it is managed by administrator',
          })}
        />
      ),
      disabled: (
        <GenericInlineDialogContent
          message={
            <>
              <FormattedMessage
                id="ptc-directory.settings.profile-and-visibility.details.email.disabled-body"
                defaultMessage="Go to the Email tab to manage your email address."
                description="A message displayed when user is hovering a field to edit an email value that can not be edited"
              />
              <p>
                <Anchor {...getAnchorProps('/manage-profile/email')}>
                  <FormattedMessage
                    id="ptc-directory.settings.profile-and-visibility.details.manage.email"
                    defaultMessage="Manage your email address"
                    description='A link name which points to Email preferences: "/manage-profile/email" to edit the email address'
                  />
                </Anchor>
              </p>
            </>
          }
        />
      ),
      privacy: {
        key: 'email',
      },
    },
    [FieldType.PhoneNumber]: {
      label: formatMessage({
        id: 'pf-home.profile-edit-form.label.phone',
        defaultMessage: 'Phone number',
        description: 'Label for the input field for the users phone number',
      }),
      placeholder: formatMessage({
        id: 'pf-home.profile-edit-form.placeholder.phone',
        defaultMessage: 'Your phone number',
        description:
          'Placeholder text displayed in an empty input field for the users phone number',
      }),
      privacy: {
        key: 'phoneNumber',
      },
    },
  };
};
