import { CSSProperties } from 'react';
import { Placement } from '@atlaskit/inline-dialog';

// PrivacyLevel && FieldConfig are fully copied from PrivacyLevelDropdown and ProfileDetailsForm
enum PrivacyLevel {
  PUBLIC = 'public',
  ORGANISATION = 'organisation',
  PRIVATE = 'private',
}

export interface FieldConfig {
  label: string;
  placeholder?: string;
  inputType?: FieldInputType;
  info?: JSX.Element | string;
  disabled?: JSX.Element;
  managed?: JSX.Element;
  privacy: {
    key: string;
    managedLevels?: PrivacyLevel[];
    levels: PrivacyLevel[];
    disabled?: JSX.Element;
  };
  isRequired?: boolean;
  requiredInvalidMessage?: string;
}

export enum FieldInputType {
  Text,
  Timezone,
}

export type FieldProps = Pick<
  FieldConfig,
  | 'label'
  | 'inputType'
  | 'placeholder'
  | 'isRequired'
  | 'requiredInvalidMessage'
>;

export interface ProfileDetailsInlineEditProps extends FieldProps {
  icon?: JSX.Element;
  inlineDialogContent?: React.ReactNode;
  inlineDialogContentPosition?: Placement;
  infoHelperMessage?: React.ReactNode;
  isReadOnly: boolean;
  toolTipContent?: React.ReactNode;
  value: string;
  readValue: string;
  readStyle?: CSSProperties;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
}
