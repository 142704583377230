import { connect } from 'react-redux';

import { addFlag, RootState } from '../../../../common';

// Eventual Consistency HOCs
import UserProfileAndVisibilitySettings from './UserProfileAndVisibilitySettings';

import { ConnectedDispatchProps, ConnectedProps } from './types';

const mapStateToProps = ({ user }: RootState): ConnectedProps => ({
  userId: user.data.accountId!,
  isManaged: user.data.isManaged!,
});

const mapDispatchToProps = (dispatch): ConnectedDispatchProps => ({
  addFlag: flag => dispatch(addFlag(flag)),
});

const ProfileAndVisibilitySettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserProfileAndVisibilitySettings);

export default ProfileAndVisibilitySettings;
