import React from 'react';
import ProductsSettings from '../../components/ProductsSettings/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const SettingsProductsRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_PRODUCT_SETTINGS} />
    <RecordTransition />
    <ProductsSettings />
  </>
);

export default SettingsProductsRoute;
