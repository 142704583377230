import React, { useMemo, useReducer } from 'react';
import { DialogEntry, DialogType, DialogProps } from './types';
import { DialogsContext } from './DialogsContext';
import DialogRenderer from './DialogRenderer';

const initialStates: DialogEntry = {
  DialogComponent: null,
  dialogProps: null,
  isOpen: false,
};

interface Props {
  children: React.ReactNode;
}

interface Action {
  type: 'close' | 'open';
  payload?: Pick<DialogEntry, 'DialogComponent' | 'dialogProps'>;
}

function dialogReducer(state: DialogEntry, action: Action) {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        DialogComponent: action.payload?.DialogComponent || null,
        dialogProps: action.payload?.dialogProps || null,
        isOpen: true,
      };
    case 'close':
      return {
        ...state,
        DialogComponent: null,
        dialogProps: null,
        isOpen: false,
      };
    default:
      throw new Error();
  }
}

export default function DialogsProvider(props: Props) {
  const { children } = props;
  const [stateDialogProvider, dispatch] = useReducer(
    dialogReducer,
    initialStates,
  );

  const valueProvider = useMemo(
    () => ({
      openDialog: (
        DialogComponent: DialogType | null,
        dialogProps: DialogProps | null = null,
      ) =>
        dispatch({
          type: 'open',
          payload: {
            DialogComponent,
            dialogProps,
          },
        }),
      closeDialog: () => dispatch({ type: 'close' }),
    }),
    [],
  );

  return (
    <DialogsContext.Provider value={valueProvider}>
      {children}
      <DialogRenderer {...stateDialogProvider} />
    </DialogsContext.Provider>
  );
}
