/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useCallback } from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl-next';

import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Avatar, { AvatarItem } from '@atlaskit/avatar';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import type { IconProps } from '@atlaskit/icon/types';
import { LinkItem, LinkItemProps, MenuGroup, Section } from '@atlaskit/menu';
import type { ContentProps } from '@atlaskit/popup';
import { gridSize } from '@atlaskit/theme/constants';

import { NAVIGATION_CHANNEL } from '../../analytics';
import { AccountDetails } from '../account-details';
import type { AccountMenuProps } from '../account-menu';
import { PeopleIcon } from '../icons/people';
import { PersonIcon } from '../icons/person';
import { SignOutIcon } from '../icons/sign-out';
import { ThemeSwitcher } from '../theme-switcher';

export type AccountMenuContentProps = {
  closePopup: ContentProps['onClose'];
} & Pick<
  AccountMenuProps,
  | 'testId'
  | 'name'
  | 'picture'
  | 'email'
  | 'managedBy'
  | 'manageProfileLink'
  | 'viewProfileLink'
  | 'onViewProfileClick'
  | 'switchAccountLink'
  | 'getAccountSwitchLink'
  | 'logOutLink'
  | 'onThemeChange'
  | 'currentTheme'
  | 'associatedSessions'
>;

export const AccountMenuContent = ({
  testId,
  closePopup,
  name,
  picture,
  email,
  managedBy,
  manageProfileLink,
  viewProfileLink,
  onViewProfileClick,
  switchAccountLink,
  getAccountSwitchLink,
  logOutLink,
  onThemeChange,
  currentTheme,
  associatedSessions,
}: AccountMenuContentProps) => {
  const themeSwitcherProps = onThemeChange && currentTheme;
  const shouldRenderProfileThemeSwitcherSection = Boolean(
    viewProfileLink || manageProfileLink || themeSwitcherProps,
  );

  return (
  <Container data-testid={testId}>
    <MenuGroup>
      <Section>
        <AccountDetails
          testId={testId && `${testId}--details`}
          name={name}
          picture={picture}
          email={email}
          managedBy={managedBy}
        />
      </Section>
      {shouldRenderProfileThemeSwitcherSection && (
        <Section>
          {viewProfileLink && (
            <ViewProfileLink
              onClick={onViewProfileClick}
              testId={testId && `${testId}--view-profile`}
              href={viewProfileLink}
              closePopup={closePopup}
            />
          )}
          {manageProfileLink && (
            <ManageProfileLink
              testId={testId && `${testId}--manage-profile`}
              href={manageProfileLink}
              closePopup={closePopup}
            />
          )}
           {themeSwitcherProps && (
              <ThemeSwitcher
                testId={testId && `${testId}--theme-switcher`}
                onThemeChange={onThemeChange}
                currentTheme={currentTheme}
              />
            )}
        </Section>
      )}
      {associatedSessions && associatedSessions.length > 0 && getAccountSwitchLink && (
        <Section
          hasSeparator={Boolean(viewProfileLink || manageProfileLink)}
          title="Switch Account"
        >
          <InProductSwitchAccountLinks
            associatedSessions={associatedSessions}
            getAccountSwitchLink={getAccountSwitchLink}
          />
        </Section>
      )}
      <Section
        hasSeparator={Boolean(
          shouldRenderProfileThemeSwitcherSection ||
            (associatedSessions && associatedSessions.length > 0),
        )}
      >
        {switchAccountLink &&
          (associatedSessions && associatedSessions.length > 0 ? (
            <AddAccountLink
              testId={testId && `${testId}--add-account`}
              href={switchAccountLink}
              closePopup={closePopup}
            />
          ) : (
            <SwitchAccountLink
              testId={testId && `${testId}--switch-account`}
              href={switchAccountLink}
              closePopup={closePopup}
            />
          ))}
        <LogOutLink
          testId={testId && `${testId}--log-out`}
          href={logOutLink}
          closePopup={closePopup}
        />
      </Section>
    </MenuGroup>
  </Container>);
};

const InProductSwitchAccountLinks = ({
  associatedSessions,
  getAccountSwitchLink
}: {
  associatedSessions: NonNullable<AccountMenuProps['associatedSessions']>
  getAccountSwitchLink: NonNullable<AccountMenuProps['getAccountSwitchLink']>;
}) => {
  return (
    <Fragment>
      {associatedSessions.map(user => {
        const url = getAccountSwitchLink(window.location.href, user.email);
        return (
          <LinkItem href={url} key={user.displayName}>
            <AvatarItem
              avatar={<Avatar src={user.avatarUrl} size="medium" />}
              primaryText={user.displayName}
              secondaryText={user.email}
            />
          </LinkItem>
        );
      })}
    </Fragment>
  );
};

const AddAccountLink = ({
  testId,
  href,
  closePopup,
}: Pick<
  React.ComponentProps<typeof Link>,
  'testId' | 'href' | 'closePopup'
>) => (
  <Link
    testId={testId}
    icon={PeopleIcon}
    href={href}
    closePopup={closePopup}
    actionSubjectId="addAccountLink"
  >
    <FormattedMessage
      id="identity.account-menu.account-menu-content.add-account-button"
      defaultMessage="Add account"
      description="Label for button in account menu to switch to another another account"
    />
  </Link>
);

const Container = styled.div({
  boxSizing: 'border-box',
  minWidth: gridSize() * 38,
});

const ViewProfileLink = ({
  testId,
  href,
  onClick,
  closePopup,
}: Pick<
  React.ComponentProps<typeof Link>,
  'testId' | 'href' | 'onClick' | 'closePopup'
>) => (
  <Link
    testId={testId}
    href={href}
    onClick={onClick}
    icon={PersonIcon}
    closePopup={closePopup}
    actionSubjectId="viewProfileLink"
  >
    <FormattedMessage
      id="identity.account-menu.account-details.view-profile-tooltip"
      defaultMessage="Profile"
      description="Text shown when hovering over profile information"
    />
  </Link>
);

const ManageProfileLink = ({
  testId,
  href,
  closePopup,
}: Pick<
  React.ComponentProps<typeof Link>,
  'testId' | 'href' | 'closePopup'
>) => (
  <Link
    testId={testId}
    href={href}
    icon={SettingsIcon}
    closePopup={closePopup}
    actionSubjectId="manageProfileLink"
    target="_blank"
    rel="noopener"
  >
    <FormattedMessage
      id="identity.account-menu.account-details.manage-profile-link"
      defaultMessage="Account settings"
      description="Text for link to account management page"
    />
  </Link>
);

const SwitchAccountLink = ({
  testId,
  href,
  closePopup,
}: Pick<
  React.ComponentProps<typeof Link>,
  'testId' | 'href' | 'closePopup'
>) => (
  <Link
    testId={testId}
    icon={PeopleIcon}
    href={href}
    closePopup={closePopup}
    actionSubjectId="switchAccountLink"
  >
    <FormattedMessage
      id="identity.account-menu.account-menu-content.switch-account-button"
      defaultMessage="Switch account"
      description="Label for button in account menu to switch to another another account"
    />
  </Link>
);

const LogOutLink = ({
  testId,
  href,
  closePopup,
}: Pick<
  React.ComponentProps<typeof Link>,
  'testId' | 'href' | 'closePopup'
>) => (
  <Link
    testId={testId}
    icon={SignOutIcon}
    href={href}
    closePopup={closePopup}
    actionSubjectId="logOutLink"
  >
    <FormattedMessage
      id="identity.account-menu.account-menu-content.log-out-button"
      defaultMessage="Log out"
      description="Label for button in account menu to log out"
    />
  </Link>
);

const Link = ({
  testId,
  closePopup,
  icon: Icon,
  href,
  onClick,
  actionSubjectId,
  children,
  ...props
}: Omit<LinkItemProps, 'onClick'> & {
  closePopup: AccountMenuContentProps['closePopup'];
  icon: React.ComponentType<IconProps>;
  href: string;
  actionSubjectId: string;
  onClick?: AccountMenuContentProps['onViewProfileClick'];
  children: React.ReactChild;
}) => {
  const { createAnalyticsEvent } = useAnalyticsEvents();

  const handleClick: LinkItemProps['onClick'] = useCallback(
    event => {
      const analyticsEvent = createAnalyticsEvent({
        eventType: UI_EVENT_TYPE,
        action: 'clicked',
        actionSubject: 'menuLinkItem',
        actionSubjectId,
      });

      const clone = analyticsEvent.clone();
      if (clone) {
        clone.fire(NAVIGATION_CHANNEL);
      }

      onClick?.(event, analyticsEvent);

      closePopup?.(event);
    },
    [createAnalyticsEvent, onClick, closePopup, actionSubjectId],
  );

  return (
    <LinkItem
      testId={testId}
      iconBefore={<Icon label="" size="medium" />}
      href={href}
      onClick={handleClick}
      {...props}
    >
      {children}
    </LinkItem>
  );
};
