import React, { ReactNode, useState } from 'react';
import * as Styled from './InlineHelpInfoIcon.styled';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import InlineDialog, { Placement } from '@atlaskit/inline-dialog';

export type Appearance = 'light' | 'dark';

export interface Props {
  title?: string | ReactNode;
  message: string | ReactNode;
  position?: Placement;
  appearance?: Appearance;
}
const InlineHelpInfoIcon = ({
  message,
  position = 'bottom-start',
  appearance = 'light',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const showInlineDialog = () => {
    setIsOpen(true);
  };

  const hideInlineDialog = () => {
    setIsOpen(false);
  };

  const renderInlineDialogContent = () => {
    return (
      <Styled.DialogContent>
        <Styled.DialogMessage>{message}</Styled.DialogMessage>
      </Styled.DialogContent>
    );
  };

  return (
    <Styled.Root
      onMouseEnter={showInlineDialog}
      onMouseLeave={hideInlineDialog}
    >
      <InlineDialog
        content={renderInlineDialogContent()}
        isOpen={isOpen}
        placement={position}
      >
        <Styled.DisclaimerIconWrapper
          appearance={appearance}
          tabIndex={0}
          onFocus={showInlineDialog}
          onBlur={hideInlineDialog}
        >
          <EditorInfoIcon size="small" label="Editor info" />
        </Styled.DisclaimerIconWrapper>
      </InlineDialog>
    </Styled.Root>
  );
};

export default InlineHelpInfoIcon;
