import once from 'lodash/once';
import assign from 'lodash/assign';
import merge from 'lodash/merge';

import {
  clearCookie,
  getCookieAsInteger,
  REDIRECT_COUNT,
  setCookie,
} from '../utils/cookie';
import { withDefaultExponentialBackoff } from '../utils/http';

import { SentryIgnoreError, HttpError } from '../model/errors';

interface FetchOptions {
  headers?: {
    [key: string]: string;
  };
  ignoreResponse?: boolean;
  body?: any; // tslint:disable-line no-any
  method?: string;
}

const defaultFetchOptions = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
  },
  mode: 'cors',
};

const fetchWithExponentialBackoff = withDefaultExponentialBackoff(fetch);

// tslint:disable-next-line no-any
function parseResponse(response: Response): Promise<any> | null {
  const responseStatusCode = response.status;
  if (responseStatusCode === 204 || responseStatusCode === 202) {
    return null;
  }

  const contentType = response.headers.get('content-type');
  if (
    !contentType ||
    contentType.includes('text/html') ||
    contentType.includes('text/plain')
  ) {
    return response.text();
  }

  if (contentType.includes('application/json')) {
    return response.json();
  }

  return null;
}

// to dedupe sentry, scrub non-unique 'timestamp' field from the error body
function scrubTimestampFromErrorBody(text: string): string {
  let json;
  try {
    json = JSON.parse(text);
  } catch (err) {
    return text;
  }
  delete json.timestamp;
  return JSON.stringify(json);
}

let incrementRedirectCount;
let clearRedirectCount;
resetRedirectCountOnceFlag();

// Used by test to reset the once-ness of the functions above
export function resetRedirectCountOnceFlag() {
  incrementRedirectCount = once(() =>
    setCookie(REDIRECT_COUNT, getCookieAsInteger(REDIRECT_COUNT) + 1),
  );

  clearRedirectCount = once(() => clearCookie(REDIRECT_COUNT));
}

class RestClient {
  constructor({
    serviceUrl = '',
  }: {
    serviceUrl?: string;
  } = {}) {
    this.serviceUrl = serviceUrl;
  }

  serviceUrl: string;

  async getResource(path) {
    return this.makeRequest(path);
  }

  async postResource(
    path: string,
    data?: object,
    fetchOptions: FetchOptions = {},
  ) {
    return this.postResourceRaw(path, JSON.stringify(data), fetchOptions);
  }

  async postResourceRaw(
    path: string,
    data?: string,
    fetchOptions: FetchOptions = {},
  ) {
    fetchOptions = {
      method: 'post',
      headers: {},
      ...fetchOptions,
    };

    if (data) {
      fetchOptions = {
        body: data,
        ...fetchOptions,
        headers: {
          'Content-Type': 'application/json',
          ...fetchOptions.headers,
        },
      };
    }

    return this.makeRequest(path, fetchOptions);
  }

  async patchResource(path: string, data?: object) {
    let fetchOptions: FetchOptions = {
      /**
       * PATCH must be uppercase as per fetch spec only
       * DELETE, GET, HEAD, OPTIONS, POST, and PUT methods
       * are normalised to uppercase.
       *
       * https://github.com/github/fetch/issues/37
       * https://fetch.spec.whatwg.org/#methods
       */
      method: 'PATCH',
    };

    if (data) {
      fetchOptions = {
        ...fetchOptions,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      };
    }

    return this.makeRequest(path, fetchOptions);
  }

  async putResource(
    path: string,
    data?: object,
    additionalFetchOptions: FetchOptions = {},
  ) {
    let fetchOptions = {
      method: 'put',
    };

    if (data) {
      fetchOptions = assign({}, fetchOptions, {
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    return this.makeRequest(path, {
      ...fetchOptions,
      ...additionalFetchOptions,
    });
  }

  async deleteResource(path: string, options: FetchOptions = {}) {
    const fetchOptions = {
      ...options,
      method: 'delete',
    };

    return this.makeRequest(path, fetchOptions);
  }

  /**
   * This function is necessary for if `statusCodeHandler` prescribes a login
   * redirect upon a 401 response.
   * While the default redirect behaviour may be overriden, this function has
   * been kept separate to ensure it cannot be overriden, lest a host
   * application supply embeddable-directory with a custom handler that suffers
   * the infinite redirect bug.
   */
  preventInfiniteRedirectLoop = response => {
    if (!response.ok) {
      const responseStatusCode = response.status;
      if (responseStatusCode === 401) {
        incrementRedirectCount();
      }
    } else {
      clearRedirectCount();
    }
  };

  async makeRequest(path: string, additionalFetchOptions: FetchOptions = {}) {
    const url = this.serviceUrl + path;
    const options = merge({}, defaultFetchOptions, additionalFetchOptions);
    const response = await fetchWithExponentialBackoff(url, options);

    this.preventInfiniteRedirectLoop(response);

    const responseStatusCode = response.status;
    if (!response.ok) {
      if (responseStatusCode === 401) {
        throw new SentryIgnoreError({
          message: 'Unauthorized Request - incrementing redirect count',
        });
      } else {
        const text = scrubTimestampFromErrorBody(await response.text());
        throw new HttpError({ message: text, status: responseStatusCode });
      }
    }

    return additionalFetchOptions.ignoreResponse
      ? null
      : parseResponse(response);
  }
}

export default RestClient;
