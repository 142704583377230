import React from 'react';

import SubtleDropdown, { Option } from '../../SubtleDropdown';
import { PrivacyLevelDropdownProps as Props } from './types';
import * as Styled from './PrivacyLevelDropdown.styled';

export const PrivacyLevelDropdown = ({
  disabledMessage,
  onChange,
  options,
  privacyLevel,
}: Props) => {
  const Item = ({ description, icon, label }: Option) => (
    <Styled.Item>
      <Styled.ItemIcon>{icon}</Styled.ItemIcon>
      <Styled.ItemTextWrapper>
        <Styled.ItemLabel>{label}</Styled.ItemLabel>
        <Styled.ItemDescription>{description}</Styled.ItemDescription>
      </Styled.ItemTextWrapper>
    </Styled.Item>
  );

  if (!privacyLevel) {
    // Without a current privacy level we can't render anything
    // as there are no reasonable generic fall back / default values
    return null;
  }

  return (
    <Styled.DropdownContainer>
      <SubtleDropdown
        appearance="tall"
        // Destructuring here is a workaround for TS not recognising certain types (PrivacyLevelOptions) as specific cases of more general types (Options from SubtleDropdown)
        options={{ ...options }}
        value={privacyLevel}
        onChange={onChange}
        disabledMessage={disabledMessage}
        customComponents={{ Item: Item }}
      />
    </Styled.DropdownContainer>
  );
};

export default PrivacyLevelDropdown;
