import PrivacyLevelDropdown from './PrivacyLevelDropdown';
import { PrivacyLevel } from './types';

export default PrivacyLevelDropdown;

export { generatePrivacyOptions } from './helpers/generatePrivacyOptions';

export const PRIVACY_VALUE_MAP = {
  PUBLIC: PrivacyLevel.PUBLIC,
  COLLABORATOR: PrivacyLevel.ORGANISATION,
  PRIVATE: PrivacyLevel.PRIVATE,
};
