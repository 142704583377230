export const AnalyticsKeys = {
  ACTIVITY_ALL_VIEW: 'activity.all.view',
  ACTIVITY_WATCHING_VIEW: 'activity.watching.view',
  HISTORY_VIEW: 'history.view',
  PROFILE_SETTINGS_CLOSE_ACCOUNT_VIEW: 'profile-settings.close-account.view',
  PROFILE_SETTINGS_EMAIL_VIEW: 'profile-settings.email.view',
  PROFILE_SETTINGS_GENERAL_VIEW: 'profile-settings.general.view',
  PROFILE_SETTINGS_PROFILE_AND_VISIBILITY_VIEW:
    'profile-settings.profile-and-visibility.view',
  PROFILE_SETTINGS_PRIVACY_VIEW: 'profile-settings.privacy.view',
  PROFILE_SETTINGS_PRODUCT_VIEW: 'profile-settings.product.view',
  PROFILE_SETTINGS_SECURITY_VIEW: 'profile-settings.security.view',
  PROFILE_SETTINGS_TWO_STEP_VERIFICATION_VIEW:
    'profile-settings.security.two-step-verification.view',
  PROFILE_SETTINGS_API_TOKENS_VIEW: 'profile-settings.security.api-tokens.view',
  PROFILE_SETTINGS_RECENT_DEVICES_VIEW:
    'profile-settings.security.recent-devices.view',
  PROFILE_SETTINGS_CONNECTED_APPS_VIEW: 'profile-settings.connected-apps.view',

  // TODO: remove these once we move to a new analytics platform
  LEGACY_ACTIVITY_ALL_VIEW: 'newsfeed.view',
};

export enum AnalyticsEventSource {
  MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB = 'manageAccountProfileAndVisibilityTab',
  MANAGE_ACCOUNT_PREFERENCES_TAB = 'manageAccountPreferencesTab',
  MANAGE_ACCOUNT_EMAIL_TAB = 'manageAccountEmailTab',
  MANAGE_ACCOUNT_SECURITY_TAB = 'manageAccountSecurityTab',
  MANAGE_ACCOUNT_RECENT_DEVICES_TAB = 'manageAccountRecentDevicesTab',
  MANAGE_ACCOUNT_API_TOKENS_TAB = 'manageAccountApiTokensTab',
  MANAGE_ACCOUNT_TWO_STEP_VERIFICATION_TAB = 'manageAccount2svTab',
  MANAGE_ACCOUNT_PRIVACY_TAB = 'manageAccountPrivacyTab',
  MANAGE_ACCOUNT_PRODUCTS_TAB = 'manageAccountProductsTab',
  MANAGE_ACCOUNT_CONNECTED_APPS_TAB = 'manageAccountConnectedAppsTab',
  MANAGE_ACCOUNT_LINK_PREFERENCES_TAB = 'manageAccountSmartLinkPreferencesScreen',
  MANAGE_ACCOUNT_CLOSE_ACCOUNT_TAB = 'manageAccountCloseAccountTab',
  MANAGE_ACCOUNT_CHANGE_EMAIL_MODAL = 'manageAccountChangeEmailModal',
  MANAGE_ACCOUNT_CUSTOM_INITIAL_MODAL = 'avatarInitialsPicker',
  MANAGE_ACCOUNT_REGENERATE_RECOVERY_KEY_SCREEN = '2svCreateNewRecoveryKeyScreen',
  MANAGE_ACCOUNT_SAVE_RECOVERY_KEY_SCREEN = '2svSaveRecoveryKeyScreen',
  MANAGE_ACCOUNT_TWO_STEP_VERIFICATION_SECURITY_KEYS = '2svSecurityKeys',

  // inside avatar menu
  USER_MENU = 'userMenu',

  // close account flow
  DELETE_ACCOUNT_FOCUSED_TASK = 'deleteAccountFocusedTask',
  DELETE_ACCOUNT_WARNING_MODAL = 'deleteAccountWarningModal',

  // generic metrics
  CLIENT_METRICS = 'clientMetrics',

  // generic metrics
  DIRECTORY_APP = 'directoryApp',

  // events sent from Atlassian switcher
  SWITCHER = 'switcher',

  // events from switching theme
  THEME_SWITCHER = 'themeSwitcher',
}

export enum AnalyticsEventActions {
  ACCEPTED = 'accepted',
  CLICKED = 'clicked',
  CLOSED = 'closed',
  CREATED = 'created',
  DECLINED = 'declined',
  DELETED = 'deleted',
  DRAGGED = 'dragged',
  EDITED = 'edited',
  EXPOSED = 'exposed',
  FAILED = 'failed',
  JOINED = 'joined',
  LIMITED = 'limited',
  MOUNTED = 'mounted',
  MOVED = 'moved',
  OPENED = 'opened',
  REDIRECTED = 'redirected',
  REMOVED = 'removed',
  SENT = 'sent',
  SHOWN = 'shown',
  SORTED = 'sorted',
  STARTED = 'started',
  SUBMITTED = 'submitted',
  SUCCEEDED = 'succeeded',
  TRIGGERED = 'triggered',
  TYPED = 'typed',
  UPDATED = 'updated',
  VIEWED = 'viewed',
  CHANGED_THEME = 'changedTheme',
}

export enum AnalyticsEventSubjects {
  AVATAR_PICKER = 'avatarPicker',
  BUTTON = 'button',
  ERROR = 'error',
  FORM = 'form',
  LINK = 'link',
  MENU = 'menu',
  MODAL_DIALOG = 'modalDialog',
  NOTIFICATION = 'notification',
  SMART_LINK_DEFAULT_ALL = 'smartLinkDefaultAll',
  SMART_LINK_DEFAULT_EXCEPTION = 'smartLinkDefaultException',
  THEME_STATE = 'themeState',
}
