import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022';

const timeZones = new Set(moment.tz.names());

// Key (left-side) is field name which is used in our app.
// Value (right-side) is field name which is used by data in services
const PROFILE_PROPS_MAP: { [key in keyof UserDetails]?: string } = {
  id: 'account_id',
  fullName: 'name',
  nickname: 'nickname',
  timezone: 'zoneinfo',
  locale: 'locale',
  avatarUrl: 'picture',
  email: 'email',
} as const;

// Key (left-side) is field name which is used in our app.
// Value (right-side) is field name which is used by data in services
const EXTENDED_PROFILE_PROPS_MAP: { [key in keyof UserDetails]?: string } = {
  title: 'job_title',
  department: 'department',
  companyName: 'organization',
  location: 'location',
  phoneNumber: 'phone_number',
} as const;

interface IdentityProfileData {
  account_id: string;
  name: string;
  nickname: string;
  locale: string;
  picture: string;
  email: string;
  zoneinfo: string;

  extended_profile?: {
    job_title?: string;
    organization?: string;
    department?: string;
    location?: string;
    phone_number?: string;
  };
}

export interface IdentityProfileResponse {
  account: IdentityProfileData;
}

type LocalTimeProps = {
  locale: string;
  format: string;
};

export interface UserDetails {
  id: string;
  fullName?: string;
  nickname?: string;
  timezone?: string;
  locale?: string;
  avatarUrl?: string;
  email?: string;
  // extended profile
  title?: string;
  companyName?: string;
  department?: string;
  location?: string;
  phoneNumber?: string;
  remoteWeekdayIndex?: string;
  remoteWeekdayString?: string;
  remoteTimeString?: string;
  localTime: (props: LocalTimeProps) => string | undefined;
}

export const fromManageAPIResponse = (
  res: IdentityProfileResponse,
): UserDetails | null => {
  const data = res.account;
  const extendedData = (data && data.extended_profile) || {};

  if (!data) {
    return null;
  }

  const timeZone = data.zoneinfo;

  const localTime = ({ locale, format }: LocalTimeProps) => {
    if (!timeZone || !timeZones.has(timeZone)) {
      return undefined;
    }

    return moment(new Date())
      .locale(locale)
      .tz(timeZone)
      .format(format);
  };

  const profile = Object.entries(PROFILE_PROPS_MAP).reduce(
    (acc: typeof PROFILE_PROPS_MAP, [internalFieldName, apiFieldName]) => {
      acc[internalFieldName] = data[apiFieldName];
      return acc;
    },
    {},
  );

  const extendedProfile = Object.entries(EXTENDED_PROFILE_PROPS_MAP).reduce(
    (
      acc: typeof EXTENDED_PROFILE_PROPS_MAP,
      [internalFieldName, apiFieldName],
    ) => {
      acc[internalFieldName] = extendedData[apiFieldName];
      return acc;
    },
    {},
  );

  const result: UserDetails = {
    ...profile,
    ...extendedProfile,
    id: data.account_id,
    localTime,
    remoteWeekdayIndex: localTime({ format: 'd', locale: data.locale }),
    remoteWeekdayString: localTime({ format: 'ddd', locale: data.locale }),
    remoteTimeString: localTime({
      format: 'h:mma ([GMT]Z)',
      locale: data.locale,
    }),
  };

  return result;
};

export const toManageAPIInput = input => {
  const output = {
    extended_profile: {},
  };

  Object.keys(input).forEach(key => {
    if (PROFILE_PROPS_MAP[key]) {
      output[PROFILE_PROPS_MAP[key]] = input[key];
    }

    if (EXTENDED_PROFILE_PROPS_MAP[key]) {
      output.extended_profile[EXTENDED_PROFILE_PROPS_MAP[key]] = input[key];
    }
  });

  return output;
};
