/* eslint-disable no-console */
import { useExperienceTracker } from './context';
import { useLayoutEffect } from 'react';
import { useOnceImmediately } from '../hooks';
export function useExperienceStart(experienceName, customStartTime) {
  var experienceTracker = useExperienceTracker();
  useOnceImmediately(function () {
    return experienceTracker.start(experienceName, customStartTime);
  });
}
export function useExperienceSuccess(experience, attributes) {
  var experienceTracker = useExperienceTracker();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(function () {
    return experienceTracker.success(experience, attributes);
  }, []);
}
export function useExperienceRenderAndMountMark(mark) {
  var experienceTracker = useExperienceTracker();
  useOnceImmediately(function () {
    return experienceTracker.markStart(mark);
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(function () {
    return experienceTracker.markEnd(mark);
  }, []);
}