import { connect } from 'react-redux';
import ProfileDetailsForm from './ProfileDetailsForm';

import { addFlag, RootState } from '../../../../../common';

const mapStateToProps = ({ user }: RootState) => ({
  userId: user.data.accountId!,
  isManaged: user.data.isManaged,
  managedBy: user.data.managedBy,
});

const mapDispatchToProps = {
  addFlag,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailsForm);
