import { useMutation, useQueryClient } from '@tanstack/react-query';
import merge from 'lodash/merge';
import { getConfig } from '../../../config/envConfig';
import {
  ProfileAvatarQueryData,
  profileAvatarQueryKey,
} from './useProfileAvatar';
import { ManageAvatarApiResponse, MyAvatarQueryKey } from './useMyAvatar';
import {
  AvatarByAccountIdQueryData,
  avatarByAccountIdQueryKey,
} from '../../Avatar/hooks';
import { logException } from '../../../utils/sentry';

const deleteUserAvatarMutationFn = async (userId: string) => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/users/${userId}/manage/avatar`;

  await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
  });

  return true;
};

export const useDeleteUserAvatarMutation = (userId: string) => {
  const queryClient = useQueryClient();

  const { isError, isLoading, mutateAsync } = useMutation({
    onSuccess: () => {
      queryClient.setQueryData<ProfileAvatarQueryData | undefined>(
        [profileAvatarQueryKey, userId],
        old =>
          old &&
          merge({}, old, {
            CloudUser: {
              avatarUrl: '',
            },
          }),
      );

      queryClient.setQueryData<ManageAvatarApiResponse | undefined>(
        [MyAvatarQueryKey, userId],
        old =>
          old &&
          merge({}, old, {
            uploaded: false,
            url: '',
          }),
      );

      queryClient.setQueryData<AvatarByAccountIdQueryData['data'] | undefined>(
        [avatarByAccountIdQueryKey, userId],
        old =>
          old &&
          merge({}, old, {
            CloudUser: {
              avatarUrl: '',
            },
          }),
      );
    },
    onError: (error: Error) => {
      void logException(error, 'Could not delete avatar');
    },
    mutationFn: async () => deleteUserAvatarMutationFn(userId),
  });

  return { isError, isLoading, mutateAsync };
};
