import { defineMessages } from 'react-intl-next';

export const visibilityFlagMessages = defineMessages({
  /*
   * Field specific messages for success flag
   * key of the message equals = `PrivacyItemType-PrivacyLevel`
   * where:
   *   - PrivacyItemType = is user details privacy field type
   *   - PrivacyLevel = is user details visibility level
   */
  fullName_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.name-organisation-success',
    defaultMessage:
      'Your full name will be visible to people who belong to your organization.',
  },
  fullName_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.name-public-success',
    defaultMessage:
      'Your full name will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },

  title_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.jobTitle-organisation-success',
    defaultMessage:
      'Your job title will be visible to people who belong to your organization.',
  },
  title_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.jobTitle-public-success',
    defaultMessage:
      'Your job title will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },

  department_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.department-organisation-success',
    defaultMessage:
      'Your department will be visible to people who belong to your organization.',
  },
  department_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.department-public-success',
    defaultMessage:
      'Your department will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },
  department_private: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.department-private-success',
    defaultMessage: 'Your department will only be visible to you.',
  },

  companyName_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.organization-organisation-success',
    defaultMessage:
      'Your organization will be visible to people who belong to your organization.',
  },
  companyName_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.organization-public-success',
    defaultMessage:
      'Your organization will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },

  location_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.location-organisation-success',
    defaultMessage:
      'Your location will be visible to people who belong to your organization.',
  },
  location_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.location-public-success',
    defaultMessage:
      'Your location will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },
  location_private: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.location-private-success',
    defaultMessage: 'Your location will only be visible to you.',
  },

  timezone_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.timezone-organisation-success',
    defaultMessage:
      'Your time zone will be visible to people who belong to your organization.',
  },
  timezone_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.timezone-public-success',
    defaultMessage:
      'Your time zone will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },
  timezone_private: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.timezone-private-success',
    defaultMessage: 'Your time zone will only be visible to you.',
  },

  localTime_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.local-time-organisation-success',
    defaultMessage:
      'Your local time will be visible to people who belong to your organization.',
  },
  localTime_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.local-time-public-success',
    defaultMessage:
      'Your local time will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },
  localTime_private: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.local-time-private-success',
    defaultMessage: 'Your local time will only be visible to you.',
  },

  email_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.email-organisation-success',
    defaultMessage:
      'Your email will be visible to people who belong to your organization.',
  },
  email_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.email-public-success',
    defaultMessage:
      'Your email will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },
  email_private: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.email-private-success',
    defaultMessage: 'Your email will only be visible to you and admins.',
  },

  phone_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.phone-organisation-success',
    defaultMessage:
      'Your phone number will be visible to people who belong to your organization.',
  },
  phone_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.phone-public-success',
    defaultMessage:
      'Your phone number will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },
  phone_private: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.phone-private-success',
    defaultMessage: 'Your phone number will only be visible to you.',
  },

  picture_organisation: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.picture-organisation-success',
    defaultMessage:
      'Your profile picture will be visible to people who belong to your organization.',
  },
  picture_public: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.picture-public-success',
    defaultMessage:
      'Your profile picture will be visible to anyone who can view your content, including people outside of your organization. It’s also accessible by installed apps.',
  },
  picture_private: {
    id:
      'ptc-directory.settings.profile-and-visibility.privacy.picture-private-success',
    defaultMessage: 'Your profile picture will only be visible to you.',
  },
});
