/** @jsx jsx */
import { css, jsx } from '@emotion/react';
var badgeContainerStyles = css({
  position: 'absolute',
  insetBlockStart: "var(--ds-space-negative-050, -4px)",
  insetInlineEnd: "var(--ds-space-negative-025, -2px)",
  pointerEvents: 'none'
});
var containerStyles = css({
  position: 'relative'
});

// Not exported to consumers
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var BadgeContainer = function BadgeContainer(props) {
  var badgeId = props.id,
    Badge = props.badge,
    children = props.children,
    role = props.role;
  return jsx("div", {
    css: containerStyles,
    role: role
  }, children, jsx("div", {
    css: badgeContainerStyles,
    id: badgeId,
    "aria-hidden": true
  }, jsx(Badge, null)));
};