import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Appearance } from './InlineHelpInfoIcon';

export const Root = styled.span`
  cursor: pointer;
  display: inline-block;
  margin-left: ${token('space.050', '4px')};
  vertical-align: middle;
`;

interface Props {
  appearance: Appearance;
}

export const DisclaimerIconWrapper = styled.span`
  color: ${(props: Props) =>
    props.appearance === 'dark'
      ? token('color.icon', colors.N200)
      : token('color.icon.subtle', colors.N90)};

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${token('elevation.shadow.overlay', colors.B100)}
      inset;
  }
`;

// This also modifies with margin top and bottom of components since the inline-dialog already has a large margin
export const DialogContent = styled.div`
  font-size: 14px;
  font-weight: normal;
  width: 280px;
`;

export const DialogMessage = styled.p`
  margin-top: ${token('space.150', '12px')};
  line-height: ${token('space.250', '20px')};
`;
