import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { mergeGridStateIntoStorage, removeFromGridStateInStorage } from '../common/utils';
var publishGridState = function publishGridState(gridState) {
  Object.entries(gridState).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      slotName = _ref2[0],
      value = _ref2[1];
    if (!value) {
      document.documentElement.style.removeProperty("--".concat(slotName));
      removeFromGridStateInStorage('gridState', slotName);
      return;
    }

    //Update the css variable
    document.documentElement.style.setProperty("--".concat(slotName), "".concat(value, "px"));

    // also update state in local storage so that
    // it persists across page refresh, across tabs etc
    mergeGridStateIntoStorage('gridState', _defineProperty({}, slotName, value));
  });
};
export default publishGridState;