import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { Help } from '@atlaskit/atlassian-navigation';
import { ButtonItem, Section, MenuGroup, LinkItem } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';

import { getWACLinks } from '../../utils/routes';
import {
  AnalyticsEventActions,
  AnalyticsEventSource,
  AnalyticsEventSubjects,
  FeedbackTrigger,
  useAnalytics,
} from '../../common';

const HelpMenuPopup: React.ComponentType = props => {
  const { formatMessage } = useIntl();
  const analytics = useAnalytics();
  const [isHelpOpen, setHelpOpen] = useState(false);
  const closeHelpMenu = () => setHelpOpen(false);
  const toggleHelpOpen = () => {
    setHelpOpen(!isHelpOpen);
    if (!isHelpOpen) {
      analytics.pushTrackEvent({
        source: AnalyticsEventSource.USER_MENU,
        actionSubject: AnalyticsEventSubjects.MENU,
        action: AnalyticsEventActions.OPENED,
        actionSubjectId: 'helpMenu',
      });
    }
  };

  return (
    <Popup
      placement="bottom-end"
      rootBoundary="document"
      onClose={closeHelpMenu}
      trigger={triggerProps => (
        <Help
          tooltip={formatMessage({
            id: 'ptc-directory.help-menu.title.help',
            defaultMessage: 'Help',
            description:
              'Title for the HELP menu item group incl. link to help and support documentation',
          })}
          onClick={toggleHelpOpen}
          {...triggerProps}
        />
      )}
      content={() => <HelpMenuContent {...props} />}
      isOpen={isHelpOpen}
    />
  );
};

const HelpMenuContent: React.ComponentType = () => {
  const { formatMessage } = useIntl();
  return (
    <div data-testid="help-menu-content">
      <MenuGroup>
        <Section
          title={formatMessage({
            id: 'ptc-directory.help-menu.title.help',
            defaultMessage: 'Help',
            description:
              'Title for the HELP menu item group incl. link to help and support documentation',
          })}
        >
          <LinkItem
            href="https://support.atlassian.com"
            target="_blank"
            rel="noopener"
          >
            <FormattedMessage
              id="ptc-directory.help-menu.item.support.user.settings.app"
              defaultMessage="Support"
              description="Link to the support page"
            />
          </LinkItem>
        </Section>
        <Section
          title={formatMessage({
            id: 'ptc-directory.help-menu.title.legal',
            defaultMessage: 'Legal',
            description:
              'Title for the LEGAL menu item group incl. Terms of service and Privacy policy',
          })}
        >
          <Section>
            <LinkItem
              href={getWACLinks().cloudTermsOfService}
              target="_blank"
              rel="noopener"
            >
              <FormattedMessage
                id="ptc-directory.help-menu.item.terms-of-service"
                defaultMessage="Terms of service"
                description="Link that opens the Atlassian Customer Agreement"
              />
            </LinkItem>
            <LinkItem
              href={getWACLinks().privacyPolicy}
              target="_blank"
              rel="noopener"
            >
              <FormattedMessage
                id="ptc-directory.help-menu.item.privacy-policy"
                defaultMessage="Privacy policy"
                description="Link that opens the Atlassian Privacy Policy"
              />
            </LinkItem>
            <LinkItem
              href={getWACLinks().userNotice}
              target="_blank"
              rel="noopener"
            >
              <FormattedMessage
                id="ptc-directory.help-menu.item.user-notice"
                defaultMessage="User notice"
                description="Link that opens the Atlassian User Notice"
              />
            </LinkItem>
          </Section>
        </Section>
        <Section
          title={formatMessage({
            id: 'ptc-directory.help-menu.label.feedback',
            defaultMessage: 'Feedback',
            description: 'Label for the icon on the "Give feedback" menu item',
          })}
        >
          <FeedbackTrigger source={AnalyticsEventSource.USER_MENU}>
            {openFeedbackDialog => (
              <ButtonItem onClick={openFeedbackDialog}>
                <FormattedMessage
                  id="ptc-directory.help-menu.item.give-feedback"
                  defaultMessage="Give feedback"
                  description="Opens a dialog for the user to enter and send feedback"
                />
              </ButtonItem>
            )}
          </FeedbackTrigger>
        </Section>
      </MenuGroup>
    </div>
  );
};

export default HelpMenuPopup;
