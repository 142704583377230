import { useEffect } from 'react';
import { getHistory } from '../../store';

import referrerInfo from '../../utils/referrerInfo';

let initialLoadTracked = false;

type RecordTransitionProps = {
  replace?: (url: string) => void;
};

const RecordTransition = ({ replace }: RecordTransitionProps) => {
  const onInitialLoad = () => {
    initialLoadTracked = true;
    // stores referrer info
    recordPageVisitOrigin();
  };

  const onTransition = () => {
    // cleanup stored referrer info on transition
    referrerInfo.cleanup();
  };

  const recordPageVisitOrigin = () => {
    const url = new URL(window.location.href);
    // ref contains a product like confluence, jira etc.
    const ref = url.searchParams.get('ref') || '';
    // src contains a location like appswitcher, peopledrawer etc.
    const src = url.searchParams.get('src') || null;
    if (!ref && !src) {
      return;
    }
    // save referrer info for retrieving it in further screen events
    referrerInfo.set({ src, ref });
    url.searchParams.delete('ref');
    url.searchParams.delete('src');

    // replace location if it changed after removing ref and src query params
    const path = `${url.pathname}${url.search}`;

    if (replace) {
      replace(path);
    } else {
      getHistory().replace(path);
    }
  };

  useEffect(() => {
    if (!initialLoadTracked) {
      onInitialLoad();
    } else {
      onTransition();
    }
  });

  return null;
};

export default RecordTransition;
