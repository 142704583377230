import styled from '@emotion/styled';
import { typography } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export { SettingsDescription } from '../../../../styled/settings';

export const Page = styled.div`
  padding-bottom: ${token('space.1000', '80px')};
`;

export const AdminManagesAccountBannerWrapper = styled.div`
  margin-top: ${token('space.400', '32px')};
`;

export const Heading = styled.h1``;
export const SubHeading = styled.h3`
  ${typography.h500 as any};
  margin-top: ${token('space.400', '32px')};
  margin-bottom: ${token('space.200', '16px')};
`;
