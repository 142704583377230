import React, { useMemo, useEffect } from 'react';
import {
  Browser,
  UploadEndEventPayload,
  UploadErrorEventPayload,
  UploadsStartEventPayload,
} from '@atlaskit/media-picker';

import useUserWriteMediaClientConfig from './useUserWriteMediaClientConfig';

type Props = {
  isOpen: boolean;
  onStart: (event: UploadsStartEventPayload) => void;
  onEnd: (event: UploadEndEventPayload) => void;
  onClose: () => void;
  onError: (event: UploadErrorEventPayload) => void;
};

const browserConfig = {
  multiple: false,
  fileExtensions: ['.jpg', '.png'],
  uploadParams: {},
};

export default function UserHeaderImageBrowser(props: Props) {
  const { isOpen, onStart, onEnd, onClose, onError } = props;

  const {
    mediaClientConfig,
    headerImageId,
    startGetWriteToken,
  } = useUserWriteMediaClientConfig();

  const memoizedBrowserConfig = useMemo(() => {
    if (headerImageId) {
      return {
        ...browserConfig,
        replaceFileId: headerImageId,
      };
    }

    return browserConfig;
  }, [headerImageId]);

  // start fetching write token when the browser dialog opens.
  useEffect(() => {
    if (isOpen) {
      void startGetWriteToken();
    }
  }, [isOpen, startGetWriteToken]);

  return (
    <Browser
      mediaClientConfig={mediaClientConfig}
      config={memoizedBrowserConfig}
      onUploadsStart={onStart}
      onEnd={onEnd}
      onClose={onClose}
      onError={onError}
      isOpen={isOpen}
    />
  );
}
