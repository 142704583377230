import { useMutation, useQueryClient } from '@tanstack/react-query';
import merge from 'lodash/merge';
import { getConfig } from '../../../config/envConfig';
import {
  ProfileAvatarQueryData,
  profileAvatarQueryKey,
} from './useProfileAvatar';
import { dataURItoFile } from './helpers/avatar-util';
import { ManageAvatarApiResponse, MyAvatarQueryKey } from './useMyAvatar';
import {
  AvatarByAccountIdQueryData,
  avatarByAccountIdQueryKey,
} from '../../Avatar/hooks';
import { logException } from '../../../utils/sentry';

export type UpdateUserAvatarOutput = {
  url: string;
};

const updateUserAvatarMutationFn = async (userId: string, dataURI: string) => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/users/${userId}/manage/avatar`;

  const fileObject = dataURItoFile(dataURI);
  const formData = new FormData();
  formData.append('file', fileObject);

  const response = await fetch(url, {
    method: 'PUT',
    credentials: 'include',
    body: formData,
  });

  const data = (await response.json()) as UpdateUserAvatarOutput;
  return data;
};

export const useUpdateUserAvatarMutation = (userId: string) => {
  const queryClient = useQueryClient();

  const { isError, isLoading, mutateAsync, error } = useMutation({
    onSuccess: data => {
      const newAvatarUrl = data.url;

      queryClient.setQueryData<ProfileAvatarQueryData | undefined>(
        [profileAvatarQueryKey, userId],
        old =>
          old &&
          merge({}, old, {
            CloudUser: {
              avatarUrl: newAvatarUrl,
            },
          }),
      );

      queryClient.setQueryData<ManageAvatarApiResponse | undefined>(
        [MyAvatarQueryKey, userId],
        old =>
          old &&
          merge({}, old, {
            uploaded: true,
            url: newAvatarUrl,
          }),
      );

      queryClient.setQueryData<AvatarByAccountIdQueryData['data'] | undefined>(
        [avatarByAccountIdQueryKey, userId],
        old =>
          old &&
          merge({}, old, {
            CloudUser: {
              avatarUrl: newAvatarUrl,
            },
          }),
      );
    },
    onError: (error: Error) => {
      void logException(error, 'Could not update avatar');
    },
    mutationFn: async (dataURI: string) =>
      updateUserAvatarMutationFn(userId, dataURI),
  });

  return { isError, error, isLoading, mutateAsync };
};
