import Loadable from 'react-loadable';

const LazyLinkPreferencesSettings = Loadable({
  loader: () => import('.'),
  loading: () => null,
});

LazyLinkPreferencesSettings.displayName = 'lazy(LinkPreferencesSettings)';

export default LazyLinkPreferencesSettings;
