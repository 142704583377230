/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Korean
export default {
  'focused-task-close-account.cancel': '취소',
  'focused-task-close-account.deactivate-account': '계정 비활성화',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': '다음 사용자 계정을 비활성화하려고 합니다:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': '다음 사용자 계정을 비활성화하려고 합니다:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': '계정 비활성화',
  'focused-task-close-account.deactivate-account.overview.heading.self': '계정 비활성화',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': '언제든지 계정을 다시 활성화할 수 있습니다.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': '언제든지 계정을 다시 활성화할 수 있습니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': '계정을 비활성화할 때:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': '계정을 비활성화할 때:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': '이에 대해 더 이상 청구를 받지 않을 것입니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': '이에 대해 더 이상 청구를 받지 않을 것입니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName}은(는) 모든 Atlassian 계정 서비스에 대한 <b>액세스 권한을 즉시 상실</b>하게 됩니다. 현재 액세스할 수 있는 항목은 다음과 같습니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName}은(는) 모든 Atlassian 계정 서비스에 대한 <b>액세스 권한을 즉시 상실</b>하게 됩니다. 현재 사용자는 커뮤니티 및 Marketplace와 같은 서비스 외에 액세스 권한을 가지고 있지 않습니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Atlassian 커뮤니티 및 Marketplace와 같은 기타 Atlassian 계정 서비스.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': '사용자는 즉시 Atlassian 계정 서비스에 대한 <b>액세스 권한을 상실</b>하게 됩니다. 현재 액세스할 수 있는 항목은 다음과 같습니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': '사용자는 모든 Atlassian 계정 서비스에 대한 <b>액세스 권한을 즉시 상실</b>하게 됩니다. 현재 귀하는 커뮤니티 및 Marketplace와 같은 서비스 외에는 액세스 권한을 가지고 있지 않습니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': '사용자의 성명 및 이메일 주소와 같은 개인 데이터 및 사용자가 만든 콘텐츠는 Atlassian 계정 서비스 내에 남습니다.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': '사용자의 성명 및 이메일 주소와 같은 개인 데이터 및 사용자가 만든 콘텐츠는 Atlassian 계정 서비스 내에 남습니다.',
  'focused-task-close-account.delete-account': '계정 삭제',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': "참고: 이 설문조사의 질문에 답해주시면 Atlassian에서 모든 사용자에게 더 나은 경험을 제공하는 데 도움이 됩니다. 계정을 삭제한 후에 삭제된 사용자는 여전히 '이전 사용자'로 표시됩니다.",
  'focused-task-close-account.delete-account.content-preview.footnote.self': "참고: 이 설문조사의 질문에 답해주시면 계정을 삭제하는 사용자를 포함하여 Atlassian에서 모든 사용자에게 더 나은 경험을 제공하는 데 도움이 됩니다. 계정을 삭제한 후에 여전히 '이전 사용자'로 표시됩니다.",
  'focused-task-close-account.delete-account.content-preview.formerUser': '이전 사용자',
  'focused-task-close-account.delete-account.content-preview.heading.admin': '삭제된 사용자가 어떻게 표시되길 원하십니까?',
  'focused-task-close-account.delete-account.content-preview.heading.self': '삭제된 계정이 어떻게 표시되길 원하십니까?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': '선택할 수 있다면, 삭제된 사용자가 다른 사용자에게 어떻게 표시되기를 원하시나요?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': '선택할 수 있다면, 다른 사용자에게 어떻게 표시되기를 원하시나요?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': "사용자 계정이 삭제된 후에 이들은 다른 사용자에게 '이전 사용자'로 표시됩니다. 잠시 시간을 내어 설문조사 질문에 답해주세요.",
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': "사용자 계정이 삭제된 후에 다른 사용자에게 '이전 사용자'로 표시됩니다. 잠시 시간을 내어 설문조사 질문에 답해주세요.",
  'focused-task-close-account.delete-account.drop-down-collapse-button': '간략히 보기',
  'focused-task-close-account.delete-account.drop-down-expand-button': '외 {num}개',
  'focused-task-close-account.delete-account.overview.first.line.admin': '다음의 계정을 삭제하려고 합니다:',
  'focused-task-close-account.delete-account.overview.first.line.self': '본인의 다음 계정을 삭제하려고 합니다:',
  'focused-task-close-account.delete-account.overview.heading.admin': '계정 삭제',
  'focused-task-close-account.delete-account.overview.heading.self': '사용자 계정 삭제',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': '예를 들어 사용자가 제품 내에서 만든 페이지, 이슈 및 댓글입니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': '예를 들어 귀하가 제품 내에서 만든 페이지, 이슈 및 댓글입니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': '귀하 또는 다른 사용자가 Atlassian 제품에 기능을 추가하는 앱을 설치했을 수 있습니다. 이런 앱은 사용자의 프로필 정보를 저장했을 수 있습니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': '귀하 또는 다른 사용자가 Atlassian 제품에 기능을 추가하는 앱을 설치했을 수 있습니다. 이런 앱은 사용자의 프로필 정보를 저장했을 수 있습니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': '재정 보고서를 위해 보존하는 구매 관련 정보입니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': '재정 보고서를 위해 보존하는 구매 관련 정보입니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Atlassian이 삭제한 사용자 계정에 대한 기록으로 필요한 경우 규제 기관에 제공합니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Atlassian이 삭제한 사용자 계정에 대한 기록으로 필요한 경우 규제 기관에 제공합니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': '진행 중인 소송의 일부에 해당하는 데이터로 법에 따라 보존해야 합니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': '진행 중인 소송의 일부에 해당하는 데이터로 법에 따라 보존해야 합니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Atlassian은 합법적인 비즈니스 또는 법률상의 목적이 있으면 제한된 기간 동안 개인 데이터를 보존합니다. 일부 예는 다음과 같습니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Atlassian은 합법적인 비즈니스 또는 법률상의 목적이 있으면 제한된 기간 동안 개인 데이터를 보존합니다. 일부 예는 다음과 같습니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': '페이지 또는 이슈에 입력한 이름 또는 이메일 주소와 같이 사용자가 만든 콘텐츠 내의 개인적인 정보는 삭제되지 않습니다. 제품 관리자가 수동으로 이런 데이터를 삭제해야 합니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': '페이지 또는 이슈에 입력한 이름 또는 이메일 주소와 같이 사용자 또는 다른 사용자가 만든 콘텐츠 내의 개인적인 정보는 삭제되지 않습니다. 제품 관리자가 수동으로 이런 데이터를 삭제해야 합니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': '사용자의 자신의 국가의 감독 기관에 이의 사항을 제출할 권리가 있습니다.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': '사용자의 자신의 국가의 감독 기관에 이의 사항을 제출할 권리가 있습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': '계정을 삭제할 때:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': '계정을 삭제할 때:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': '사용자가 만든 콘텐츠는 Atlassian 계정 서비스 내에 남습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': '사용자가 만든 콘텐츠는 Atlassian 계정 서비스 내에 남습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': '14일의 유예 기간 이후에는 계정 삭제를 취소할 수 없습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': '사용자 개인 데이터를 저장하고 있을 수 있는 앱 목록을 이메일로 보내드리겠습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': '사용자 개인 데이터를 저장하고 있을 수 있는 앱 목록을 이메일로 보내드리겠습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName}은(는) 즉시 Atlassian 계정 서비스에 대한 <b>액세스 권한을 상실</b>하게 됩니다. 현재 액세스할 수 있는 항목은 다음과 같습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName}은(는) 모든 Atlassian 계정 서비스에 대한 <b>액세스 권한을 즉시 상실</b>하게 됩니다. 현재 사용자는 커뮤니티 및 Marketplace와 같은 서비스 외에 액세스 권한을 가지고 있지 않습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Atlassian 커뮤니티 및 Marketplace와 같은 기타 Atlassian 계정 서비스.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': '사용자는 즉시 Atlassian 계정 서비스에 대한 <b>액세스 권한을 상실</b>하게 됩니다. 현재 액세스할 수 있는 항목은 다음과 같습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': '사용자는 모든 Atlassian 계정 서비스에 대한 <b>액세스 권한을 즉시 상실</b>하게 됩니다. 현재 귀하는 커뮤니티 및 Marketplace와 같은 서비스 외에는 액세스 권한을 가지고 있지 않습니다.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Atlassian은 Atlassian 계정 서비스에서 30일 내에 합법적 비즈니스 또는 법률상의 목적으로 필요한 경우를 제외하고 사용자의 성명 및 이메일 주소와 같은 <b>개인 데이터를 삭제합니다</b>.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Atlassian은 Atlassian 계정 서비스에서 30일 내에 합법적 비즈니스 또는 법률상의 목적으로 필요한 경우를 제외하고 귀하의 성명 및 이메일 주소와 같은 <b>개인 데이터를 삭제합니다</b>.',
  'focused-task-close-account.delete-account.overview.warning-section.body': '14일의 유예 기간 이후에는 계정 삭제를 취소할 수 없습니다. 나중에 계정을 사용할 수도 있다면 대신에 비활성화를 하십시오.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': '14일의 유예 기간 이후에는 계정 삭제를 취소할 수 없습니다.',
  'focused-task-close-account.learnMore': '자세히 알아보기',
  'focused-task-close-account.next': '다음',
  'focused-task-close-account.previous': '이전'
};