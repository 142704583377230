import React, { useState } from 'react';

import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu';
import InlineDialog from '@atlaskit/inline-dialog';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

import { SubtleDropdownProps as Props, ValueType } from './types';

import * as Styled from './SubtleDropdown.styled';
import { LoadingPlaceHolder } from '../Loading';
import Button from '@atlaskit/button';

export const SubtleDropdown = ({
  appearance = 'default',
  customComponents = {},
  disabledMessage = null,
  errorMessage,
  isLoading,
  onChange,
  options,
  placeHolderOption,
  position = 'bottom-end',
  value,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInlineDialogOpen, setIsInlineDialogOpen] = useState(false);

  const handleShowInlineDialog = () => {
    setIsInlineDialogOpen(true);
  };

  const handleHideInlineDialog = () => {
    setIsInlineDialogOpen(false);
  };

  const handleDropdownItemClick = (value: ValueType) => () => onChange(value);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleOpenChange = ({ isOpen }) => {
    setIsMenuOpen(isOpen);
  };

  const preventFocusWhenClicked = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const getIconAfterElement = () => {
    const Icon =
      isHovered || isMenuOpen ? ChevronDownIcon : Styled.EmptyIconSpace;
    return (
      <Styled.TriggerIconAfterWrapper>
        <Icon label="" />
      </Styled.TriggerIconAfterWrapper>
    );
  };

  const renderWrapInlineDialog = (component: JSX.Element) => {
    if (!disabledMessage) {
      return component;
    }

    return (
      <InlineDialog
        content={disabledMessage}
        placement={position}
        isOpen={isInlineDialogOpen}
      >
        {component}
      </InlineDialog>
    );
  };

  const renderDisabledTrigger = () => {
    return renderWrapInlineDialog(
      <Styled.DisabledTrigger
        data-testid="disabled-trigger"
        tabIndex={0}
        onMouseDown={preventFocusWhenClicked}
        onMouseEnter={handleShowInlineDialog}
        onMouseLeave={handleHideInlineDialog}
        onFocus={handleShowInlineDialog}
        onBlur={handleHideInlineDialog}
      >
        {renderTrigger()}
      </Styled.DisabledTrigger>,
    );
  };

  const renderErrorMessage = () => {
    return <Styled.ErrorState>{errorMessage}</Styled.ErrorState>;
  };

  const renderTrigger = () => {
    let currentOption = value ? options[value] : null;

    if (!currentOption) {
      // use placeholderOption as current option
      if (placeHolderOption) {
        currentOption = placeHolderOption;
      } else {
        return null;
      }
    }

    return (
      <Styled.TriggerWrapper appearance={appearance}>
        {currentOption.icon}
        <Styled.Triggertext>{currentOption.label}</Styled.Triggertext>
      </Styled.TriggerWrapper>
    );
  };

  const renderItem = (value: ValueType) => {
    const { Item } = customComponents;
    const currentOption = options[value];

    if (!currentOption) {
      return null;
    }

    // Use custom Item view component if provided
    if (Item) {
      return (
        <DropdownItem key={value} onClick={handleDropdownItemClick(value)}>
          <Item {...currentOption} />
        </DropdownItem>
      );
    }

    return (
      <DropdownItem
        key={value}
        elemBefore={currentOption.icon}
        description={<>{currentOption.description}</>}
        onClick={handleDropdownItemClick(value)}
      >
        {currentOption.label}
      </DropdownItem>
    );
  };

  if (isLoading) {
    return (
      <LoadingPlaceHolder
        style={{ margin: '0 8px' }}
        data-testid="loading-placeholder"
      />
    );
  }

  if (disabledMessage) {
    return renderDisabledTrigger();
  }

  if (errorMessage) {
    return renderErrorMessage();
  }

  return (
    <Styled.Root
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      data-test-selector="subtle-dropdown"
    >
      <DropdownMenu
        onOpenChange={handleOpenChange}
        appearance={appearance}
        trigger={({ triggerRef, ...props }) => (
          <Button
            {...props}
            iconAfter={getIconAfterElement()}
            ref={triggerRef}
            appearance="subtle"
            spacing="none"
          >
            {renderTrigger()}
          </Button>
        )}
      >
        <div style={{ maxWidth: 300 }}>
          <DropdownItemGroup>
            {Object.keys(options).map((key: ValueType) => renderItem(key))}
          </DropdownItemGroup>
        </div>
      </DropdownMenu>
    </Styled.Root>
  );
};

export default SubtleDropdown;
