import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import SectionMessage from '@atlaskit/section-message';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/standard-button';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';

import * as Styled from './CloseAccountSettingsSection.styled';
import { CloseAccountSettingsTabProps } from './types';
import CloseAccountFlowTriggerContainer from '../CloseAccountFlowTrigger';
import { Anchor } from '../../../../common';

type Props = CloseAccountSettingsTabProps;

export const CloseAccountSettingsSection = ({ isManaged }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Styled.SettingsSection>
        <Styled.SettingsDescription>
          <FormattedMessage
            tagName="h3"
            id="ptc-directory.settings.close-account.heading"
            defaultMessage="Delete your account"
            description='Title for the "Delete account" settings screen'
          />
        </Styled.SettingsDescription>
      </Styled.SettingsSection>
      <Styled.SettingsSection>
        {isManaged ? (
          <SectionMessage appearance="warning">
            <Styled.SectionMessageTitle>
              <FormattedMessage
                id="ptc-directory.settings.section-message.title"
                defaultMessage="Your account is managed"
                description="Title for the section message for managed user"
              />
            </Styled.SectionMessageTitle>
            <FormattedMessage
              id="ptc-directory.settings.section-message.description"
              defaultMessage="You can't deactivate or delete your Atlassian account because it's owned and managed by an organization. Contact your organization's admin for assistance."
              description="Description for the section message for managed user"
              tagName="p"
            />
            <p>
              <Anchor
                target="_blank"
                href="https://confluence.atlassian.com/cloud/delete-your-account-976161188.html"
              >
                <FormattedMessage
                  id="ptc-directory.settings.close-account.learnmore"
                  defaultMessage="Learn more"
                  description="Hyperlink label for learn more"
                />
              </Anchor>
            </p>
          </SectionMessage>
        ) : (
          <>
            <FormattedMessage
              tagName="p"
              id="ptc-directory.settings.close-account.detailed.message"
              defaultMessage="When you delete your account, you lose access to Atlassian account services, and we permanently delete your personal data. You can cancel the deletion within 14 days."
              description='Detailed message on "Delete account" screen'
            />
            <br />
            <ButtonGroup>
              <CloseAccountFlowTriggerContainer />
              <Button
                appearance="link"
                target="_blank"
                href="https://confluence.atlassian.com/x/pAkvOg"
                iconAfter={
                  <ShortcutIcon
                    label={formatMessage({
                      id: 'ptc-directory.settings.close-account.learnmore',
                      defaultMessage: 'Learn more',
                      description: 'Hyperlink label for learn more',
                    })}
                    size="small"
                  />
                }
              >
                <FormattedMessage
                  id="ptc-directory.settings.close-account.learnmore"
                  defaultMessage="Learn more"
                  description="Hyperlink label for learn more"
                />
              </Button>
            </ButtonGroup>
          </>
        )}
      </Styled.SettingsSection>
    </>
  );
};

export default CloseAccountSettingsSection;
