import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { css } from '@emotion/react';

/*
 * Usage:
 *
 * const StyledDiv = styled.div`
 *   ${ ellipsis };
 * `;
 */
export const ellipsis = `
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ellipsisInFlexChild = css`
  min-width: 0;
  ${ellipsis};
`;

export const paragraphTypography = css`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.005em;
  color: ${token('color.text', colors.N800)};
  line-height: 20px;
`;

export const smallTextTypography = css`
  color: ${token('color.text.subtlest', colors.N200)};
  font-size: 12px;
  line-height: ${14 / 12};
`;
