import { defineMessages } from 'react-intl-next';
var messages = defineMessages({
  newLozenge: {
    id: 'fabric.atlassianSwitcher.newLozenge',
    defaultMessage: 'New',
    description: 'The lozenge text for a lozenge indicating a new switcher item'
  },
  sites: {
    id: 'fabric.atlassianSwitcher.sites',
    defaultMessage: '{productName} {numSites, plural, one {site} other {sites}}',
    description: 'The text of a toggle showing or hiding more site options'
  }
});
export default messages;