import React, { useCallback, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl-next';
import Loadable from 'react-loadable';
import window from 'window-or-global';
import Avatar from '@atlaskit/avatar';
import { DropdownItem } from '@atlaskit/dropdown-menu';

import {
  AnalyticsEventActions,
  AnalyticsEventSource,
  AnalyticsEventSubjects,
  InjectedAnalyticsProps,
  withAnalyticsContext,
} from '../AnalyticsContext';
import ProfileAvatarDropdownMenu, {
  ProfileAvatarDropdownMenuIconSize as IconSize,
} from '../ProfileAvatarDropdownMenu';
import { AvatarSize, ProfileAvatarProps, ReduxDispatchProps } from './types';
import * as Styled from './ProfileAvatar.styled';
import {
  useUpdateUserAvatarMutation,
  useDeleteUserAvatarMutation,
  useMyAvatar,
  useProfileAvatarQuery,
} from './hooks';

const LoadableProfileAvatarPicker = Loadable({
  loader: () => import('./ProfileAvatarPicker'),
  loading: () => null,
});

const LoadableInitialsEditorDialog = Loadable({
  loader: () => import('./InitialsEditorDialog'),
  loading: () => null,
});

export type Props = ProfileAvatarProps &
  ReduxDispatchProps &
  InjectedAnalyticsProps;

const EDIT_AVATAR_HASH = '#edit-avatar'; // https://product-fabric.atlassian.net/browse/PTC-1342

export const ProfileAvatar = ({
  addFlag,
  analytics,
  replace,
  size = AvatarSize.xxlarge,
  userId,
}: Props) => {
  const {
    mutateAsync: onAvatarPicked,
    isLoading: isAvatarUploading,
  } = useUpdateUserAvatarMutation(userId);

  const {
    mutateAsync: deleteAvatar,
    isLoading: isAvatarDeleting,
  } = useDeleteUserAvatarMutation(userId);

  const isLoading = isAvatarUploading || isAvatarDeleting;

  const { formatMessage } = useIntl();
  const {
    profileAvatar,
    isLoading: isProfileAvatarLoading,
    isError: isProfileAvatarError,
  } = useProfileAvatarQuery(userId);
  const {
    myAvatar,
    isLoading: myAvatarIsLoading,
    isError: myAvatarIsError,
  } = useMyAvatar(userId);

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isInitialsEditorOpen, setIsInitialsEditorOpen] = useState(false);

  const { avatarUrl = '', fullName = '' } = profileAvatar?.CloudUser || {};

  const { uploaded = false } = myAvatar || {};

  useEffect(() => {
    LoadableProfileAvatarPicker.preload();
    LoadableInitialsEditorDialog.preload();
  }, []);

  const closeAvatarPicker = useCallback(() => {
    if (isPickerOpen) {
      analytics.pushTrackEvent({
        source: AnalyticsEventSource.CLIENT_METRICS,
        actionSubject: 'avatarPicker',
        action: AnalyticsEventActions.CLOSED,
      });
    }

    setIsPickerOpen(false);
  }, [analytics, isPickerOpen]);

  const openAvatarPicker = useCallback(() => {
    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: AnalyticsEventSubjects.BUTTON,
      action: AnalyticsEventActions.CLICKED,
      actionSubjectId: 'changeProfilePhoto',
    });

    setIsPickerOpen(true);
    analytics.pushTrackEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: AnalyticsEventSubjects.AVATAR_PICKER,
      action: AnalyticsEventActions.OPENED,
    });
  }, [analytics]);

  useEffect(() => {
    if (window.location.hash === EDIT_AVATAR_HASH) {
      openAvatarPicker();
    }
  }, [openAvatarPicker]);

  useEffect(() => {
    if (!isPickerOpen) {
      if (window.location.hash === EDIT_AVATAR_HASH) {
        replace(window.location.pathname);
      }
    }

    if (isPickerOpen) {
      replace(`${window.location.pathname}${EDIT_AVATAR_HASH}`);
    }
  }, [isPickerOpen, replace]);

  const handleDeleteAvatarError = useCallback(() => {
    analytics.pushTrackEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: 'deleteAvatar',
      action: AnalyticsEventActions.FAILED,
    });

    addFlag({
      id: 'profile.avatar.delete.error',
      title: formatMessage({
        id: 'pf-home.profile.avatar.delete.failure.title',
        defaultMessage: 'Failed to remove profile photo',
        description:
          'The title of a slide-in error notification card informing the user that the attempt to remove their profile photo has failed',
      }),
      type: 'error',
    });
  }, [addFlag, analytics, formatMessage]);

  const handleUpdateAvatarError = useCallback(
    (error, errorSource: string) => {
      analytics.pushTrackEvent({
        source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
        actionSubject: errorSource,
        action: AnalyticsEventActions.FAILED,
      });
      if (error) {
        addFlag({
          id: 'profile.avatar.upload.error',
          title: formatMessage({
            id: 'pf-home.profile.avatar.upload.failure.title',
            defaultMessage: 'Failed to change profile photo',
            description:
              'The title of a slide-in error notification card informing the user that the attempt to change their profile photo has failed',
          }),
          type: 'error',
        });
      }
    },
    [addFlag, analytics, formatMessage],
  );

  const openInitialsPicker = useCallback(() => {
    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: AnalyticsEventSubjects.BUTTON,
      action: AnalyticsEventActions.CLICKED,
      actionSubjectId: 'createInitialsAvatar',
    });
    setIsInitialsEditorOpen(true);
    analytics.pushTrackEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: 'avatarInitialsPicker',
      action: AnalyticsEventActions.OPENED,
    });
  }, [analytics]);

  const closeInitialsPicker = useCallback(() => {
    setIsInitialsEditorOpen(false);
    analytics.pushTrackEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: 'avatarInitialsPicker',
      action: AnalyticsEventActions.CLOSED,
    });
  }, [analytics]);

  const handleAvatarPicked = useCallback(
    async (dataURI: string) => {
      try {
        await onAvatarPicked(dataURI);
        closeAvatarPicker();

        analytics.pushTrackEvent({
          source:
            AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
          actionSubject: 'uploadAvatar',
          action: AnalyticsEventActions.SUCCEEDED,
        });

        addFlag({
          id: 'profile.avatar.upload.success',
          title: formatMessage({
            id: 'pf-home.profile.avatar.upload.success.title',
            defaultMessage: 'Avatar added',
            description:
              'The title of a slide-in notification card informing the user that the attempt to change their profile photo has succeeded',
          }),
          description: formatMessage({
            id: 'pf-home.profile.avatar.upload.success.description',
            defaultMessage:
              "We've uploaded your new avatar. It may take a few minutes to display everywhere.",
            description:
              'The text of a slide-in notification card informing the user that the attempt to change their profile photo has succeeded',
          }),
          type: 'success',
        });
      } catch (error) {
        handleUpdateAvatarError(error, 'uploadAvatar');
      }
    },
    [
      addFlag,
      analytics,
      closeAvatarPicker,
      formatMessage,
      handleUpdateAvatarError,
      onAvatarPicked,
    ],
  );

  const handleCustomInitialsPicked = useCallback(
    async (
      dataURI: string,
      _avatarType: 'image' | 'initials',
      _text: string,
      color: string,
    ) => {
      try {
        closeInitialsPicker();

        await onAvatarPicked(dataURI);

        analytics.pushTrackEvent({
          source:
            AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
          actionSubject: 'updateAvatarInitials',
          action: AnalyticsEventActions.SUCCEEDED,
          attributes: {
            color: color,
          },
        });

        addFlag({
          id: 'profile.avatar.upload.success',
          title: formatMessage({
            id: 'pf-home.profile.avatar.upload.success.title',
            defaultMessage: 'Avatar added',
            description:
              'The title of a slide-in notification card informing the user that the attempt to change their profile photo has succeeded',
          }),
          description: formatMessage({
            id: 'pf-home.profile.avatar.upload.success.description',
            defaultMessage:
              "We've uploaded your new avatar. It may take a few minutes to display everywhere.",
            description:
              'The text of a slide-in notification card informing the user that the attempt to change their profile photo has succeeded',
          }),
          type: 'success',
        });
      } catch (error) {
        handleUpdateAvatarError(error, 'updateAvatarInitials');
      }
    },
    [
      addFlag,
      analytics,
      closeInitialsPicker,
      formatMessage,
      handleUpdateAvatarError,
      onAvatarPicked,
    ],
  );

  const handleDeleteAvatar = useCallback(async () => {
    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
      actionSubject: AnalyticsEventSubjects.BUTTON,
      action: AnalyticsEventActions.CLICKED,
      actionSubjectId: 'removeAvatar',
    });

    try {
      await deleteAvatar();

      analytics.pushTrackEvent({
        source: AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
        actionSubject: 'deleteAvatar',
        action: AnalyticsEventActions.SUCCEEDED,
      });

      addFlag({
        id: 'profile.avatar.delete.success',
        title: formatMessage({
          id: 'pf-home.profile.avatar.delete.success.title',
          defaultMessage: 'Avatar removed',
          description: `The title of a slide-in notification card informing the user that the
            attempt to delete their profile photo has succeeded`,
        }),
        description: formatMessage({
          id: 'pf-home.profile.avatar.delete.success.description',
          defaultMessage:
            "We've deleted your Atlassian avatar. If you have one, your Gravatar will display instead.",
          description:
            'The text of a slide-in notification card informing the user that the attempt to delete their profile photo has succeeded',
        }),
        type: 'success',
      });
    } catch (error) {
      handleDeleteAvatarError();
    }
  }, [
    addFlag,
    analytics,
    deleteAvatar,
    formatMessage,
    handleDeleteAvatarError,
  ]);

  const renderAvatarMenu = () => {
    const iconSize = [AvatarSize.xxlarge, AvatarSize.xlarge].includes(size)
      ? IconSize.medium
      : IconSize.small;

    if (uploaded) {
      return (
        <ProfileAvatarDropdownMenu iconSize={iconSize}>
          <DropdownItem onClick={openAvatarPicker} data-testid="change-avatar">
            <FormattedMessage
              id="pf-home.profile-avatar.change-profile-photo.text"
              defaultMessage="Change profile photo"
              description="Used as text for a button for beginning the workflow of changing your own profile picture"
            />
          </DropdownItem>
          <DropdownItem
            onClick={openInitialsPicker}
            data-testid="create-custom-initial-button"
          >
            <FormattedMessage
              id="pf-home.profile-avatar.create-custom-avatar.text"
              defaultMessage="Create initials avatar"
              description="Used as text for a button for beginning the workflow of creating a new profile picture showing their initials"
            />
          </DropdownItem>
          <DropdownItem
            onClick={handleDeleteAvatar}
            data-testid="remove-avatar"
          >
            <FormattedMessage
              id="pf-home.profile-avatar.remove-profile-photo.text"
              defaultMessage="Remove"
              description="Used as text for a button for deleting your Atlassian profile picture"
            />
          </DropdownItem>
        </ProfileAvatarDropdownMenu>
      );
    }
    return (
      <ProfileAvatarDropdownMenu iconSize={iconSize}>
        <DropdownItem onClick={openAvatarPicker}>
          <FormattedMessage
            id="pf-home.profile-avatar.add-profile-photo.dropdown.text"
            defaultMessage="Add profile photo"
            description="Used as text for a button for beginning the workflow of adding your own profile picture when the user has never uploaded an avatar yet"
          />
        </DropdownItem>
        <DropdownItem
          onClick={openInitialsPicker}
          data-testid="create-custom-initial-button"
        >
          <FormattedMessage
            id="pf-home.profile-avatar.create-custom-avatar.text"
            defaultMessage="Create initials avatar"
            description="Used as text for a button for beginning the workflow of creating a new profile picture showing their initials"
          />
        </DropdownItem>
      </ProfileAvatarDropdownMenu>
    );
  };

  if (
    isProfileAvatarLoading ||
    isProfileAvatarError ||
    myAvatarIsError ||
    myAvatarIsLoading
  ) {
    return null;
  }

  return (
    <Styled.AvatarWrapper
      data-test-selector="profile-avatar"
      data-testid="avatar-wrapper"
    >
      {/* Key added to force react to render when url changes */}
      <Avatar
        testId="avatar"
        name={fullName}
        src={avatarUrl}
        size={size}
        key={avatarUrl}
      />
      <Styled.AvatarHoverInfo
        size={size}
        data-test-selector="profile-hover-info"
        data-has-uploaded-avatar={`${uploaded}`}
      >
        {renderAvatarMenu()}
      </Styled.AvatarHoverInfo>

      {isPickerOpen && (
        <LoadableProfileAvatarPicker
          onCancel={closeAvatarPicker}
          onAvatarPicked={handleAvatarPicked}
          isLoading={isLoading}
          hasUploadedAvatar={uploaded && avatarUrl}
        />
      )}
      {isInitialsEditorOpen && (
        <LoadableInitialsEditorDialog
          fullName={fullName || ''}
          onSubmit={handleCustomInitialsPicked}
          onCancel={closeInitialsPicker}
        />
      )}
    </Styled.AvatarWrapper>
  );
};

export default withAnalyticsContext(ProfileAvatar);
