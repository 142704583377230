import { Flag } from '../../modules/flags-types';

export interface ReduxDispatchProps {
  replace: (url: string) => void;
}

export interface ProfileAvatarProps {
  userId: string;
  /** Avatar size */
  size?: AvatarSize;
  addFlag: (flag: Flag) => void;
}

export interface ProfileAvatarState {
  isPickerOpen: boolean;
  isInitialsEditorOpen: boolean;
}

export enum AvatarSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
}
