import 'es6-promise/auto'; // 'axios' needs a Promise polyfill
import axios from 'axios';
import { getConfig } from '../config/envConfig';
import {
  is5xx,
  withExponentialBackoff,
  EXPONENTIAL_BACKOFF_RETRY_POLICY,
} from '../utils/http';
import { DefaultError, GraphQLError, HttpError } from '../model/errors';

interface Options {
  operationName?: string;
  contentType?: string;

  // Identified werthere we should throw an error and ignore the data if they returned alongside in the request
  //
  // Re-used the same kind of config as for Apollo Client Query components:
  // https://www.apollographql.com/docs/react/features/error-handling
  errorPolicy?: 'none' | 'all' | 'ignore';
}

// tslint:disable-next-line no-any
type Body = any;

export class BaseGraphQlClient {
  constructor(servicePath: string) {
    this.serviceUrl = `${getConfig().stargateRoot}${servicePath}`;
  }

  serviceUrl: string;

  async makeGraphQLRequestWithoutRetries(body: Body, options: Options = {}) {
    const operationNameQuery = options.operationName
      ? `?q=${options.operationName}`
      : '';

    const errorPolicy = options.errorPolicy || 'none';

    const url = this.serviceUrl + operationNameQuery;

    const request = axios.post(
      url,
      !options.contentType || options.contentType === 'application/json'
        ? JSON.stringify(body)
        : body,
      {
        responseType: 'json',
        withCredentials: true,
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': options.contentType || 'application/json',
        },
        validateStatus: status => {
          return status < 300 || status === 400;
        },
      },
    );

    return request.then(
      response => {
        if (response.data.errors) {
          if (errorPolicy === 'none') {
            throw GraphQLError.from(response.data.errors);
          }
          if (errorPolicy === 'all') {
            // Set timeout will postpone error throwing and de-touch it to another event loop,
            // so we can return data along with throwing error
            setTimeout(() => {
              throw GraphQLError.from(response.data.errors);
            }, 0);
          }
        }

        return response.data.data;
      },
      error => {
        if (!axios.isCancel(error)) {
          if (!error.response) {
            if (error instanceof GraphQLError) {
              throw error;
            }

            throw new DefaultError({
              message: error.message,
            });
          }

          return error;
        }
      },
    );
  }

  async makeGraphQLRequest(...args): Promise<any> {
    const makeGraphQLRequestWithRetries = withExponentialBackoff(
      this.makeGraphQLRequestWithoutRetries.bind(this),
      {
        initial: EXPONENTIAL_BACKOFF_RETRY_POLICY.INITIAL_DELAY,
        jitter: EXPONENTIAL_BACKOFF_RETRY_POLICY.JITTER,
        max: EXPONENTIAL_BACKOFF_RETRY_POLICY.MAX_RETRIES,
        retryIf: (data: { response: Response }) => {
          return data && data.response && is5xx(data.response.status);
        },
      },
    );

    const result = await makeGraphQLRequestWithRetries(...args);
    const status = result && result.response && result.response.status;
    const statusText = result && result.response && result.response.statusText;

    if (status > 400 && status <= 599) {
      throw new HttpError({
        message: statusText,
        status,
      });
    }

    return result;
  }
}

export default BaseGraphQlClient;
