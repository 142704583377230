import RestClient from './rest-client';
import { LongProductKey, ProductObject } from '../model/site';
import { getConfig } from '../config/envConfig';

class IdentityAvailableProductsClient extends RestClient {
  constructor() {
    super({
      serviceUrl: getConfig().stargateRoot,
    });
  }
  getAvailableProducts = async (): Promise<ProductObject[]> => {
    const availableProducts = this.postResource('/accessible-products', {
      productIds: [
        LongProductKey.CONFLUENCE,
        LongProductKey.JIRA_CORE,
        LongProductKey.JIRA_SOFTWARE,
        LongProductKey.JIRA_SERVICE_DESK,
        LongProductKey.TRELLO,
        LongProductKey.BITBUCKET,
      ],
      permissionIds: ['write', 'write-portal', 'external-collaborator-write'],
    });
    return availableProducts;
  };

  getAvailableSites = async () => {
    const otherSitesCheck = this.postResource('/available-sites', {
      products: [
        LongProductKey.CONFLUENCE,
        LongProductKey.JIRA_CORE,
        LongProductKey.JIRA_SERVICE_DESK,
        LongProductKey.JIRA_SOFTWARE,
      ],
    });

    const bitbucketCheck = this.postResource('/permissions/permitted', {
      resourceId: 'ari:cloud:bitbucket::site/bitbucket',
      permissionId: 'write',
    });

    const trelloCheck = this.postResource('/permissions/permitted', {
      resourceId: 'ari:cloud:trello::site/trello',
      permissionId: 'write',
    });

    const [
      bitbucketResponse,
      trelloResponse,
      otherSitesResponse,
    ] = await Promise.all([bitbucketCheck, trelloCheck, otherSitesCheck]);
    const bitbucketValue = {
      cloudId: null,
      url: 'bitbucket.org',
    };
    const trelloValue = {
      cloudId: null,
      url: 'trello.com',
    };
    return {
      sites: otherSitesResponse.sites
        .concat(bitbucketResponse.permitted === true && bitbucketValue)
        .concat(trelloResponse.permitted === true && trelloValue)
        .filter(x => x !== false)
        .filter(x => x !== true),
    };
  };
}

export default new IdentityAvailableProductsClient();
