import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as transformUser from '../../../../../utils/user';
import { getConfig } from '../../../../../common';
import { LanguageTag } from '../../../constants/supported-languages';
import { ManageProfileUserQueryKey } from '../../../hooks/useManageProfileUser';
import {
  IdentityProfileResponse,
  UserDetails,
} from '../../../../../utils/user';
import { fetchApi } from '../../../../../utils/fetchApi';

type AccountPreferencesMutationProps = {
  userId: string;
  onMutationSuccess?: () => void;
  onMutationError?: (e: Error) => void;
};

export type UpdateUserInfo = {
  locale?: LanguageTag;
  timezone?: string;
  title?: string;
  fullName?: string;
  nickname?: string;
  department?: string;
  companyName?: string;
  location?: string;
  email?: string;
  phoneNumber?: string;
};

const updateUser = async (userId: string, newUserInfo: UpdateUserInfo) => {
  const { stargateRoot } = getConfig();

  const url = `${stargateRoot}/users/${userId}/manage/profile`;
  const body = JSON.stringify(transformUser.toManageAPIInput(newUserInfo));

  const response = await fetchApi(url, {
    method: 'PATCH',
    body,
  });

  const data = (await response.json()) as IdentityProfileResponse;
  return transformUser.fromManageAPIResponse(data);
};

export const useAccountPreferencesMutation = ({
  userId,
  onMutationError,
  onMutationSuccess,
}: AccountPreferencesMutationProps) => {
  const queryClient = useQueryClient();
  const queryKey = [ManageProfileUserQueryKey, userId];

  const { mutateAsync, mutate } = useMutation({
    onSuccess: onMutationSuccess,
    onError: onMutationError,
    mutationFn: async (newUserInfo: UpdateUserInfo) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Optimistically update to the new state
      queryClient.setQueryData(queryKey, (old?: UserDetails) => {
        return {
          ...old,
          ...newUserInfo,
        };
      });

      await updateUser(userId, newUserInfo);
    },
    onSettled: () => {
      // Always refetch after error or success
      void queryClient.invalidateQueries(queryKey);
    },
  });

  return { mutateAsync, mutate };
};
