import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../../../../utils/fetchApi';
import { getConfig } from '../../../config/envConfig';

export const MyAvatarQueryKey = 'MyAvatarQuerykey';

export type ManageAvatarApiResponse = {
  uploaded: boolean;
  url: string;
};

const myAvatarQueryFn = async (userId: string) => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/users/${userId}/manage/avatar`;
  const response = await fetchApi(url);
  const data: ManageAvatarApiResponse = await response.json();
  return data;
};

export const useMyAvatar = (userId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [MyAvatarQueryKey, userId],
    queryFn: () => myAvatarQueryFn(userId),
  });

  return {
    myAvatar: data,
    isLoading,
    isError,
  };
};
