import { connect } from 'react-redux';
import { getHistory } from '../../store';

import ProfileAvatar from './ProfileAvatar';
import { ReduxDispatchProps } from './types';

const mapDispatchToProps = (ownProps): ReduxDispatchProps => ({
  replace: ownProps.replace || getHistory().replace,
});

export default connect(undefined, mapDispatchToProps)(ProfileAvatar);
