import React, { useEffect, useMemo } from 'react';
import Select from '@atlaskit/select';
import { FormattedMessage } from 'react-intl-next';
import * as Styled from '../AccountPreferencesSettings.styled';
import { LoadingPlaceHolder } from '../../Loading';
import { Option } from '../../SubtleDropdown';
import {
  AnalyticsEventActions,
  AnalyticsEventSource,
  useAnalytics,
} from '../../../../../common';
import { useAccountPreferenceSettings } from '../hooks/useAccountPreferences';
import { useAccountPreferencesMutation } from '../hooks/useAccountPreferencesMutation';
import {
  LanguageTag,
  supportedLanguages,
} from '../../../constants/supported-languages';

type LanguageOption = {
  label: string;
  value: LanguageTag;
};

/**
 * Convert object format from format of `supportedLanguages` (i.e. { [tag]: nameOfLang }) to `Options`
 */
const languageOptions: LanguageOption[] = Object.keys(supportedLanguages).map(
  (languageTag: LanguageTag) => ({
    label: supportedLanguages[languageTag],
    value: languageTag,
  }),
);

export type LanguageDropdownProps = {
  userId: string;
  inputId: string;
  onMutationSuccess?: () => void;
  onMutationError?: (e: Error) => void;
};

export const LanguageDropdown = ({
  userId,
  inputId,
  onMutationSuccess,
  onMutationError,
}: LanguageDropdownProps) => {
  const analytics = useAnalytics();

  const { defaultValue, isError, isLoading } = useAccountPreferenceSettings(
    'locale',
    userId,
  );
  const { mutate: onChange } = useAccountPreferencesMutation({
    userId,
    onMutationSuccess,
    onMutationError,
  });

  const placeHolderOption: Option = {
    label: (
      <FormattedMessage
        id="ptc-directory-ui.account-settings.language.placeholder.label"
        defaultMessage="Set your language"
        description="A placeholder entry for when the user has not set their language"
      />
    ),

    value: '',
  };

  const defaultLanguage = useMemo(
    () => languageOptions.find(option => option.value === defaultValue),
    [defaultValue],
  );

  useEffect(() => {
    // failed to show language of current user because our UI does not support that user's locale
    if (defaultValue && !supportedLanguages[defaultValue]) {
      analytics.pushOperationalEvent({
        source: AnalyticsEventSource.MANAGE_ACCOUNT_CLOSE_ACCOUNT_TAB,
        actionSubject: 'languageDropDown',
        action: AnalyticsEventActions.FAILED,
        attributes: {
          language: defaultValue,
        },
      });
    }
  }, [analytics, defaultValue]);

  const handleChangeLanguage = (language: LanguageOption) => {
    analytics.pushTrackEvent({
      action: AnalyticsEventActions.EDITED,
      actionSubject: 'languageDropDown',
      source: AnalyticsEventSource.MANAGE_ACCOUNT_PREFERENCES_TAB,
      attributes: {
        language: language.value,
      },
    });

    onChange({ locale: language.value });
  };

  return (
    <Styled.DropdownWrapper>
      {isError && (
        <Styled.ErrorField>
          <FormattedMessage
            id="ptc-directory-ui.account-settings.language.error"
            defaultMessage="Unable to load your language."
            description="An error message is showing when cannot load languages to show in a language dropdown"
          />
        </Styled.ErrorField>
      )}

      {isLoading && <LoadingPlaceHolder data-testid="LanguageDropdownLoader" />}

      {!isError && !isLoading && (
        <Select
          inputId={inputId}
          placeHolder={placeHolderOption}
          options={languageOptions}
          onChange={lang => lang && handleChangeLanguage(lang)}
          defaultValue={defaultLanguage}
        />
      )}
    </Styled.DropdownWrapper>
  );
};

export default LanguageDropdown;
