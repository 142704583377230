import { useMutation } from '@tanstack/react-query';
import { fetchApi } from '../../../../../utils/fetchApi';
import { haveISeenItKey } from '../constants';
import { getConfig } from '../../../../../common/config/envConfig';

const dismissHaveSeenIt = async () => {
  const { stargateRoot } = getConfig();
  try {
    await fetchApi(`${stargateRoot}/flag/my`, {
      method: 'POST',
      body: JSON.stringify({
        flagKey: `manage.profile.have-i-seen-it.${haveISeenItKey}`,
      }),
    });
  } catch (e) {
    // nothing to do here, fail silently
  }
};

export const useDismissAdminManagesAccountBannerMutation = () => {
  const { isLoading, isError, mutate } = useMutation({
    mutationFn: () => dismissHaveSeenIt(),
  });
  return { isLoading, isError, mutate };
};
