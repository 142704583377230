import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from '@emotion/styled';
import { borderRadius } from '@atlaskit/theme/constants';
import { B50, B400, N50A, N60A } from '@atlaskit/theme/colors';
import { h700 } from '@atlaskit/theme/typography';
import gridSizeTimes from '../../util/gridSizeTimes';
var getSelectedCardColor = function getSelectedCardColor(props) {
  return props.isSelected && "".concat("var(--ds-background-selected, ".concat(B50, ")"));
};
export var Screen = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 640px;\n  margin-bottom: ", "px;\n"])), gridSizeTimes(4));
export var Title = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", ";\n  margin-bottom: ", "px;\n  margin-top: 0;\n"])), h700, gridSizeTimes(4));
export var SectionCard = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n  padding: ", "px;\n  width: 100%;\n  background-color: ", ";\n  border-radius: ", "px;\n  box-shadow: ", ";\n  margin-top: ", "px;\n"])), gridSizeTimes(2.5), function (props) {
  return getSelectedCardColor(props);
}, borderRadius(), "var(--ds-shadow-overlay, ".concat("0 4px 8px -2px ".concat(N50A, ", 0 0 1px ").concat(N60A), ")"), gridSizeTimes(2));
export var Avatar = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-left: ", "px;\n  margin-right: ", "px;\n"])), gridSizeTimes(2.5), gridSizeTimes(1));
export var UserDetails = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  margin-top: ", "px;\n  font-weight: 600;\n  color: ", ";\n"])), gridSizeTimes(1.5), "var(--ds-text-accent-blue, ".concat(B400, ")"));