import { getConfig } from '../config/envConfig';

// Needs to be declared here to avoid blowing up this earlyInit bundle
class FetchError extends Error {
  name: string;
  response: Response;
  constructor(response: Response) {
    super(`Request failed with status ${response.status}`);
    this.response = response;
    this.name = this.constructor.name;
  }
}

interface UserInfo {
  userId: string;
  fullName: string;
  isEmailVerified: boolean;
  locale: string;
  email: string;
  picture: string;
}

// export for testing
export class UserInfoProvider {
  private promise?: Promise<UserInfo>;

  get(fetchOp: typeof fetch = fetch): Promise<UserInfo> {
    if (this.promise) {
      return this.promise;
    }

    const getJson = (url: string) =>
      fetchOp(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (!response.ok) {
          throw new FetchError(response);
        }
        return response.json();
      });

    this.promise = getJson(`${getConfig().stargateRoot}/me`).then(me => {
      return getJson(
        `${getConfig().stargateRoot}/users/${me.account_id}/manage/profile`,
      ).then(profile => ({
        userId: profile.account.account_id,
        fullName: profile.account.name,
        email: profile.account.email,
        isEmailVerified: profile.account.email_verified,
        locale: profile.account.locale,
        picture: profile.account.picture,
      }));
    });

    return this.promise;
  }
}

export const userInfoProvider = new UserInfoProvider();
