import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useRef, useState } from 'react';
export var useImageState = function useImageState(src) {
  var _useState = useState('pending'),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  var imgRef = useRef();
  useEffect(function () {
    if (!imgRef.current) {
      imgRef.current = new Image();
      imgRef.current.onload = function () {
        return setState('loaded');
      };
      imgRef.current.onerror = function () {
        return setState('error');
      };
    }
    setState('pending');
    imgRef.current.src = src;
  }, [src]);
  return state;
};