import React from 'react';
import { FlagGroup } from '@atlaskit/flag';
import styled from '@emotion/styled';

import { User } from '../../../account-client';

type AccountSwitchFlagProps = {
  user: User;
  showFlag: boolean;
  setShowFlag: (showFlag: boolean) => void;
};

const Card = styled.div`
  display: relative;
  font-size: 1.4rem;
  display: flex;
  min-width: 400px;
  max-width: 400px;
  padding: 0.5rem;
  flex-direction: column;
  overflow: auto;
  display: block;
  box-sizing: border-box;
  z-index: 400;
  flex: 1 1 auto;
  background-color: var(--ds-surface-overlay, #ffffff);
  border-radius: var(--ds-border-radius, 3px);
  box-shadow: var(
    --ds-shadow-overlay,
    0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px rgba(9, 30, 66, 0.31)
  );
  --ds-elevation-surface-current: var(--ds-surface-overlay, #ffffff);
  overflow: auto;
}`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--ds-surface-sunken, rgba(9, 30, 66, 0.04));
  border-radius: 3px;
  padding: 1rem;
  overflow: hidden;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0.5rem;
  overflow: hidden;
  margin: auto 0 auto var(--ds-space-200, 8px);

  p {
    margin: 0;
    font-size: 0.8em;
    font-style: inherit;
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;

const Title = styled.p`
  font-weight: 500;
`;

const Subtitle = styled.p`
  margin-top: var(--ds-space-025, 2px);
  color: var(--ds-text-subtlest, #626f86);
  font-weight: 400;
  font-size: 0.7em;
`;

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: 'block';
`;

const Close = styled.div`
  font-size: 0.6em;
  z-index: 500;
  color: #626f86;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
   opacity: 1;
`;

export const AccountSwitchFlag = ({
  setShowFlag,
  user,
  showFlag,
}: AccountSwitchFlagProps) => {
  return (
    <FlagGroup>
      {showFlag && (
        <Card>
          <CardContent>
            <Avatar src={user.picture} />
            <CardText>
              <Title>{user.name}</Title>
              <Subtitle>{user.email}</Subtitle>
            </CardText>
          </CardContent>
          <Close onClick={() => setShowFlag(false)}>X</Close>
        </Card>
      )}
    </FlagGroup>
  );
};
