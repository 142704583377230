import styled from '@emotion/styled';

export const SectionMessageTitle = styled.div`
  font-weight: 600;
`;

export {
  SettingsDescription,
  SettingsSection,
} from '../../../../styled/settings';
