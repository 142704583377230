/** @jsx jsx */
// eslint-disable-next-line @repo/internal/fs/filename-pattern-match

import { css, jsx } from '@emotion/react';
import { N200, N900 } from '@atlaskit/theme/colors';
var baseStyles = css({
  display: 'block',
  margin: "var(--ds-space-0, 0px)",
  color: "var(--ds-text, ".concat(N900, ")")
});
var truncateStyles = css({
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});
var secondaryStyles = css({
  color: "var(--ds-text-subtlest, ".concat(N200, ")"),
  fontSize: '0.85em'
});

/**
 * __Text__
 *
 * Text is used internally only.
 */
var Text = function Text(_ref) {
  var isSecondary = _ref.isSecondary,
    children = _ref.children,
    shouldTruncate = _ref.shouldTruncate;
  return jsx("span", {
    css: [baseStyles, isSecondary && secondaryStyles, shouldTruncate && truncateStyles]
  }, children);
};
export default Text;