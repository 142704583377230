import { useEffect, useRef, useState } from 'react';

export type ImageState = 'pending' | 'loaded' | 'error';

export const useImageState = (src: string): ImageState => {
  const [state, setState] = useState<ImageState>('pending');
  const imgRef = useRef<HTMLImageElement>();

  useEffect(() => {
    if (!imgRef.current) {
      imgRef.current = new Image();
      imgRef.current.onload = () => setState('loaded');
      imgRef.current.onerror = () => setState('error');
    }

    setState('pending');
    imgRef.current.src = src;
  }, [src]);

  return state;
};
