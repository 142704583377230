import React, { useState } from 'react';
import { DialogOptions } from './types';
import DialogContext from './DialogContext';

const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [openDialogKey, setOpenDialogKey] = useState<string | null>(null);
  const [options, setOptions] = useState<DialogOptions | null>(null);

  const closeDialog = () => {
    setOpenDialogKey(null);
  };

  const openDialog = (openDialogKey: string, options?: DialogOptions) => {
    setOpenDialogKey(openDialogKey);
    setOptions(options || null);
  };

  return (
    <DialogContext.Provider
      value={{
        openDialogKey,
        openDialog,
        closeDialog,
        options,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
