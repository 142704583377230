// TODO: PTC-2088 - simplify list of locales
export const intlLocales = [
  ...['cs', 'cs-CZ'], // Czech
  ...['da', 'da-DK'], // Danish
  ...['de', 'de-DE'], // German
  ...['es', 'es-ES'], // Spain
  ...['et', 'et-EE'], // Estonian
  ...['fi', 'fi-FI'], // Finnish
  ...['fr', 'fr-FR'], // French
  ...['hu', 'hu-HU'], // Hungarian
  ...['is', 'is-IS'], // Icelandic
  ...['it', 'it-IT'], // Italian
  ...['ja', 'ja-JP'], // Japanese
  ...['ko', 'ko-KR'], // Korean
  ...['nl', 'nl-NL'], // Dutch
  ...['nb', 'nb-NO'], // Norwegian (Norway)
  ...['pl', 'pl-PL'], // Polish
  ...['pt', 'pt-PT', 'pt-BR'], // Portuguese
  ...['ro', 'ro-RO'], // Romanian
  ...['ru', 'ru-RU'], // Russian
  ...['sk', 'sk-SK'], // Slovakian
  ...['sv', 'sv-SE'], // Swedish
  ...['zh', 'zh-CN', 'zh-Hans'], // China uses Chinese (Simplified)
  ...['zh-TW', 'zh-HK', 'zh-Hant'], // Taiwan or Hong Kong uses Chinese (Traditional)
  ...['tr', 'tr-TR'], // Turkish
  ...['th', 'th-TH'], // Thai
  ...['uk', 'uk-UA'], // Ukrainian
  ...['vi', 'vi-VN'], // Vietnamese
  ...['en-GB'], // English (UK)
];
