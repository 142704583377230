import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { getConfig } from '../../../config/envConfig';
import queryUserFromAGG from '../../../utils/queryUserFromAGG';
import { checkFeatureGate } from '../../Statsig/utils';

export const profileAvatarQueryKey = 'useProfileAvatarQueryKey';

export type ProfileAvatarQueryResponse = {
  data: ProfileAvatarQueryData;
  extensions: {
    errorNumber: number;
  };
};

export type ProfileAvatarQueryData = {
  CloudUser: {
    avatarUrl: string;
    fullName: string;
    status: string;
    isCurrentUser?: boolean;
  };
};

const profileAvatarQueryQueryFn = async (
  userId: string,
): Promise<ProfileAvatarQueryData> => {
  const { stargateRoot } = getConfig();
  if (checkFeatureGate('migrate_cloud_user_to_agg_user_query')) {
    const aggUrl = `${stargateRoot}/graphql?q=ProfileAvatar`;
    const {
      name: fullName,
      picture: avatarUrl,
      accountStatus: status,
    } = await queryUserFromAGG({ userId, url: aggUrl });
    // TODO: PTC-9458 clear Object property CloudUser once the feature gate is enabled.
    return {
      CloudUser: {
        status,
        avatarUrl,
        fullName,
      },
    };
  }

  // TODO: PTC-9458 This block of code is temporary and can be cleaned up once the feature gate is enabled.
  const url = `${stargateRoot}/directory/graphql?q=ProfileAvatar`;
  const query = gql`
    query ProfileAvatar($userId: String!) {
      CloudUser(userId: $userId) {
        id
        isCurrentUser
        status
        avatarUrl
        fullName
      }
    }
  `;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      query,
      variables: {
        userId,
      },
    }),
  });

  const { data }: ProfileAvatarQueryResponse = await response.json();

  return data;
};

export const useProfileAvatarQuery = (userId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [profileAvatarQueryKey, userId],
    queryFn: () => profileAvatarQueryQueryFn(userId),
  });

  return { profileAvatar: data, isLoading, isError };
};
