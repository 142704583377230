import React, { ReactNode } from 'react';
import {
  ConfluenceIcon,
  JiraIcon,
  TrelloIcon,
  JiraSoftwareIcon,
  BitbucketIcon,
  JiraServiceManagementIcon,
  JiraWorkManagementIcon,
} from '@atlaskit/logo';

import { ShortProductKey } from '../../model/site';

interface Props {
  productKey?: ShortProductKey;
  productName?: string;
  size?: 'small' | 'xsmall' | 'medium' | 'large' | 'xlarge';
  defaultIcon?: ReactNode;
}

const ProductIcon = ({
  defaultIcon = null,
  productKey,
  productName,
  size = 'small',
}: Props) => {
  const mapProductKeyToIcon = {
    [ShortProductKey.JIRA]: (
      <JiraIcon size={size} label={productName || 'Jira'} />
    ),
    [ShortProductKey.CONFLUENCE]: (
      <ConfluenceIcon size={size} label={productName || 'Confluence'} />
    ),
    [ShortProductKey.JIRA_SOFTWARE]: (
      <JiraSoftwareIcon size={size} label={productName || 'Jira'} />
    ),
    [ShortProductKey.JIRA_SERVICE_DESK]: (
      <JiraServiceManagementIcon size={size} label={productName || 'Jira'} />
    ),
    [ShortProductKey.JIRA_CORE]: (
      <JiraWorkManagementIcon size={size} label={productName || 'Jira'} />
    ),
    [ShortProductKey.BITBUCKET]: (
      <BitbucketIcon size={size} label={productName || 'Bitbucket'} />
    ),
    [ShortProductKey.TRELLO]: (
      <TrelloIcon size={size} label={productName || 'Trello'} />
    ),
  };

  return productKey && mapProductKeyToIcon[productKey] ? (
    mapProductKeyToIcon[productKey]
  ) : (
    <>{defaultIcon}</>
  );
};

export default ProductIcon;
