import React from 'react';
import ConnectedAppSettings from '../../components/ConnectedAppsList/lazy';

import { ApdexStart, ApdexTasks, RecordTransition } from '../../../../common';

const SettingsConnectedAppsRoute = () => (
  <>
    <ApdexStart task={ApdexTasks.VIEW_APPS_SETTINGS} />
    <RecordTransition />
    <ConnectedAppSettings />
  </>
);

export default SettingsConnectedAppsRoute;
