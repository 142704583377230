import { browserMetrics } from '@atlassian/browser-metrics';
import {
  BMEventsType,
  BrowserMetricsConfig,
} from '@atlassian/browser-metrics/types';
import { version } from 'react';
import { getFeatureFlagValue } from '../modules/featureFlagClient';
import type { FeatureFlag } from '../modules/featureFlags';
import type { Analytics } from '../components/AnalyticsContext';
import {getEnv} from '../config/env';

export const startBrowserMetrics = (analyticsClient: Analytics) => {
  const info: BrowserMetricsConfig['info'] = {
    app: {
      version: { web: process.env.VERSION ?? 'no-version' },
      framework: { name: 'react', version },
    },
    // because this has already been defined as part of sending gasv3 events
    product: 'identity',
    // Region is used for "Split by" in Perf Portal.
    region: 'unknown',
  };

  const endpoints: BrowserMetricsConfig['endpoints'] = {
    eventPipelineClient: Promise.resolve(
      analyticsClient.getAnalyticsWebClient(),
    ),
  };

  const events: BrowserMetricsConfig['events'] = {
    [BMEventsType.WEB_VITALS]: {
      enabled: true,
    },
  };

  const plugins: BrowserMetricsConfig['plugins'] = {
    featureFlags: {
      client: Promise.resolve({
        getValue: (flag: FeatureFlag) => getFeatureFlagValue(flag),
      }),
    },
    resourceTimings: {
      /**
       * Browser Metrics track all the network calls and add them automatically to the performance events.
       * To ensure we're not recording any UGC / PII, we need to provide a sanitise method.
       */
      sanitiseEndpoints: url => {
        // Remove sensitive PII data (i.e. all URL params)
        return url.split('?')[0];
      },
      mapResources: url => {
        if (!url.includes('ptc-directory')) {
          // This is an external URL where we can't guarantee the URL structure, therefore cannot do any mapping.
          return url;
        }

        // hashedFileName = login.28848bff.chunk.js
        const hashedFileName = url.split('/').pop() ?? 'unknown';

        const parts = hashedFileName.split('.');
        const ext = parts.length > 1 ? parts.pop() : 'unknown';

        // Returning login.js
        return `${parts[0]}.${ext}`;
      },
      // If url supports TimingAllowOrigin header, then BM calculates cache status of static resources:
      hasTimingHeaders: () => false,
    },
  };

  browserMetrics.init({
    info,
    endpoints,
    plugins,
    events,
    debug: getEnv() !== 'prod',
  });

  browserMetrics.startPageLoad({ isInitial: true });
};
