import Loadable from 'react-loadable';

const LazyConnectedAppsSettings = Loadable({
  loader: () => import('./'),
  loading: () => null,
});

LazyConnectedAppsSettings.displayName = 'lazy(ConnectedAppsSettings)';

export default LazyConnectedAppsSettings;
