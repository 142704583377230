/**
 * Task names should start with a verb e.g. viewProfile, createTeam, makeMemes
 */
export enum ApdexTasks {
  VIEW_APPS_SETTINGS = 'viewAppsSettings',
  VIEW_LINK_PREFERENCES_SETTINGS = 'viewLinkPreferencesSettings',
  VIEW_CLOSE_ACCOUNT_SETTINGS = 'viewCloseAccountSettings',
  VIEW_EMAIL_SETTINGS = 'viewEmailSettings',
  VIEW_GENERAL_SETTINGS = 'viewGeneralSettings',
  VIEW_PROFILE_AND_VISIBILITY_SETTINGS = 'viewProfileAndVisibilitySettings',
  VIEW_PRIVACY_SETTINGS = 'viewPrivacySettings',
  VIEW_PRODUCT_SETTINGS = 'viewProductSettings',
  VIEW_SECURITY_SETTINGS = 'viewSecuritySettings',
  VIEW_TWO_STEP_VERIFICATION = 'viewTwoStepVerification',
  VIEW_API_TOKENS = 'viewApiTokens',
  VIEW_RECENT_DEVICES = 'viewRecentDevices',
  VIEW_ACCOUNT_PREFERENCES_SETTINGS = 'viewAccountPreferencesSettings',
  VIEW_UNIFIED_USER_PROFILE = 'viewUnifiedUserProfile',
  VIEW_USER_WORKED_ON_PAGE = 'viewUserWorkedOnPage',
  VIEW_TEAM_PROFILE = 'viewTeamProfile',
  VIEW_SEARCH_PAGE = 'viewSearchPage',
  VIEW_PROFILE_CARD = 'viewProfileCard',
}
