/** @jsx jsx */
import React from 'react';
import { fontFallback } from '@atlaskit/theme/typography';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { analyticsAttributes, getItemAnalyticsContext, NavigationAnalyticsContext, withAnalyticsContextData } from '../../common/utils/analytics';
import { FadeIn } from './fade-in';
import ItemLink from './item-link';
import { AnalyticsItemType } from '../../types';
var sectionContainerStyles = css({
  padding: "var(--ds-space-075, 6px)".concat(" 0")
});
var secondarySectionTitleBaseStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&&': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    marginTop: "var(--ds-space-200, 16px)",
    marginLeft: "var(--ds-space-100, 8px)"
  }
});
var secondarySectionTitleStandaloneStyles = css({
  marginBottom: "var(--ds-space-050, 4px)"
});
var secondarySectionTitleDrawerStyles = css({
  marginBottom: "var(--ds-space-100, 8px)"
});
var sectionTitleStyles = css({
  color: "var(--ds-text-subtle, #44546F)",
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
  font: "var(--ds-font-body-small, ".concat(fontFallback.body.small, ")"),
  fontWeight: "var(--ds-font-weight-bold, 700)"
});
var sectionChildrenWrapperStyles = css({
  listStyleType: 'none',
  padding: 0,
  margin: 0
});
export var SectionContainerTitle = function SectionContainerTitle(_ref) {
  var appearance = _ref.appearance,
    title = _ref.title;
  return jsx("h3", {
    css: [secondarySectionTitleBaseStyles, appearance === 'standalone' ? secondarySectionTitleStandaloneStyles : secondarySectionTitleDrawerStyles, sectionTitleStyles]
  }, title);
};
var SectionContainerComponent = function SectionContainerComponent(_ref2) {
  var title = _ref2.title,
    titleLink = _ref2.titleLink,
    children = _ref2.children,
    appearance = _ref2.appearance,
    actionSubject = _ref2.actionSubject,
    sectionId = _ref2.sectionId;
  return React.Children.toArray(children).some(Boolean) ? jsx("section", {
    css: sectionContainerStyles,
    "data-testid": "".concat(sectionId, "__section")
  }, jsx(FadeIn, null, title && jsx(SectionContainerTitle, {
    appearance: appearance,
    title: title
  }), titleLink && jsx(TitleLink, {
    titleLink: titleLink,
    actionSubject: actionSubject
  })), jsx("ul", {
    css: sectionChildrenWrapperStyles
  }, children)) : null;
};
var TitleLink = function TitleLink(_ref3) {
  var titleLink = _ref3.titleLink,
    actionSubject = _ref3.actionSubject;
  return jsx(NavigationAnalyticsContext, {
    key: titleLink.key,
    data: getItemAnalyticsContext(0, titleLink.key, AnalyticsItemType.PRODUCT, titleLink.productType)
  }, jsx(ItemLink, {
    href: titleLink.href,
    iconAfter: jsx(ShortcutIcon, {
      size: "small",
      label: ""
    }),
    label: titleLink.label,
    testId: "section-title__link",
    actionSubject: actionSubject,
    ariaLabel: titleLink.ariaLabel
  }));
};
export var SectionContainer = withAnalyticsContextData(function (props) {
  return analyticsAttributes({
    group: props.sectionId,
    groupItemsCount: React.Children.count(props.children)
  });
})(SectionContainerComponent);