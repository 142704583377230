import React, { useCallback, useEffect, useState } from 'react';
import { RequestProps, CallbackArguments, RequestData } from './types';
import { HttpError as RestClientHttpError } from '../../../../common/model/errors';

/**
 * Apollo-inspired render-prop component for wrapping requests/promises
 */
export const Request = ({
  children,
  fireOnMount,
  request: requestFn,
  variables,
}: RequestProps) => {
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<RestClientHttpError | null>(null);
  const [data, setData] = useState<RequestData>(undefined);

  /**
   * Send request on mount if props.fireOnMount is true
   */
  useEffect(() => {
    if (fireOnMount) {
      if (variables) {
        void sendRequest(...variables);
      } else {
        void sendRequest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStateToRejected = error => {
    setData(null);
    setError(error);
    setLoading(false);
  };

  const setStateToPending = () => {
    setError(null);
    setLoading(true);
  };

  const setStateToFulfilled = data => {
    setData(data);
    setError(null);
    setLoading(false);
  };

  /**
   * Makes the async request, keeping the child render function up-to-date with
   * the state of the request.
   */
  const sendRequest = useCallback(
    async (...args: CallbackArguments) => {
      const requestArguments = args.length ? args : variables;
      const request = requestArguments
        ? () => requestFn(...requestArguments)
        : () => requestFn();
      try {
        // Make the request, set state to pending
        setStateToPending();
        const resp = await request();
        // On success, set state to fulfilled
        setStateToFulfilled(resp);
      } catch (e) {
        // On error, set state to rejected
        setStateToRejected(e);
      }
    },
    [requestFn, variables],
  );

  return <>{children({ loading, error, data }, sendRequest)}</>;
};

export default Request;
