export function getManageProfileAppRootPath(): string {
  return '/manage-profile';
}

export function getWACLinks() {
  const wacHomePage = 'https://www.atlassian.com';

  return {
    homePage: wacHomePage,
    products: `${wacHomePage}/software`,
    privacyPolicy: `${wacHomePage}/legal/privacy-policy`,
    userAgreement: `${wacHomePage}/end-user-agreement`,
    userNotice: `${wacHomePage}/legal/user-notice`,
    cloudTermsOfService: `${wacHomePage}/legal/cloud-terms-of-service`,
  };
}
