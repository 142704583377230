import { BaseGraphQlClient } from './graphql-client';

class DirectoryClient extends BaseGraphQlClient {
  async queryWriteMediaToken() {
    const mediaTokenQuery = {
      query: `query UserMediaWriteToken {
        MediaToken: UserMediaWriteToken {
          token
          baseUrl
          headerImageId
        }
      }`,
    };

    return this.makeGraphQLRequest(mediaTokenQuery, {
      operationName: 'UserMediaWriteToken',
    });
  }
}

export const directoryClient = new DirectoryClient('/directory/graphql');
