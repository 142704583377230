import React from 'react';
import AkSpinner from '@atlaskit/spinner';
import { SpinnerProps } from './types';
import * as Styled from './Spinner.styled';

export interface SpinnerWrapperProps {
  wrapperHeight?: number | string;
}

const Spinner = ({
  wrapperHeight,
  ...props
}: SpinnerProps & SpinnerWrapperProps) => (
  <Styled.SpinnerWrapper
    height={wrapperHeight}
    data-test-selector="spinner"
    data-testid="spinner"
  >
    <AkSpinner {...props} />
  </Styled.SpinnerWrapper>
);
export default Spinner;
