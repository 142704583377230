import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { Profile } from '@atlaskit/atlassian-navigation';

import { NAVIGATION_CHANNEL } from '../../analytics';
import { AccountMenuProps } from '../account-menu';
import { SignInIcon } from '../icons/sign-in';

export type AccountMenuLoggedOutStateProps = Pick<
  AccountMenuProps,
  'testId' | 'logInLink'
>;

export const AccountMenuLoggedOutState = ({
  testId,
  logInLink,
}: AccountMenuLoggedOutStateProps) => (
  <Profile
    testId={testId}
    href={logInLink}
    onClick={(event, analytics) => {
      analytics
        .update({ actionSubjectId: 'logInLink' })
        .fire(NAVIGATION_CHANNEL);
    }}
    tooltip={
      <FormattedMessage
        id="identity.account-menu.account-menu-logged-out-state.log-in"
        defaultMessage="Log in"
        description="Tooltip for log in button when user is logged out"
      />
    }
    icon={<SignInIcon label="" size="medium" />}
  />
);
