import React from 'react';
import { colors } from '@atlaskit/theme';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { token } from '@atlaskit/tokens';

import { FlagType } from './types';

export const flagIconByType = {
  error: (
    <ErrorIcon
      label=""
      primaryColor={token('color.icon.danger', colors.R300)}
    />
  ),
  warning: (
    <WarningIcon
      label=""
      primaryColor={token('color.icon.warning', colors.Y300)}
    />
  ),
  default: (
    <SuccessIcon
      label=""
      primaryColor={token('color.icon.success', colors.G300)}
    />
  ),
};

export const getTypeIcon = (type: FlagType = 'normal') =>
  flagIconByType[type] || flagIconByType.default;
