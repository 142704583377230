/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Portuguese (Portugal)
export default {
  'focused-task-close-account.cancel': 'Cancelar',
  'focused-task-close-account.deactivate-account': 'Desativar conta',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Está prestes a desativar a conta de utilizador de:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Está prestes a desativar a conta de utilizador de:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Desativar conta',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Desativar conta',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Pode reativar a conta quando quiser.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Pode reativar a conta quando quiser.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Quando desativa a conta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Quando desativa a conta:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Já não será cobrado por estes utilizadores.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Já não será cobrado por estes utilizadores.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente tem acesso a:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente, não tem acesso a nenhum serviço, exceto à Comunidade e ao Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Outros serviços da conta Atlassian, tais como a Comunidade Atlassian e o Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente tem acesso a:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente, não tem acesso a nenhum serviço, exceto à Comunidade e ao Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Os dados pessoais, tais como o nome completo e endereço de e-mail, e o conteúdo criado pelos mesmos será mantido nos serviços da conta Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Os dados pessoais, tais como o nome completo e endereço de e-mail, e o conteúdo criado pelos mesmos será mantido nos serviços da conta Atlassian.',
  'focused-task-close-account.delete-account': 'Eliminar conta',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Nota: responder a este inquérito irá ajudar-nos a criar uma melhor experiência para todos os utilizadores. O utilizador continua a ser apresentado como “Utilizador antigo” após eliminar a conta.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Nota: responder a este inquérito irá ajudar-nos a criar uma melhor experiência para todos os utilizadores, incluindo os utilizadores que eliminaram as suas contas. O utilizador continua a ser apresentado como “Utilizador antigo” após eliminar a conta.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Utilizador antigo',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Como pretende que o utilizador eliminado seja apresentado?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Como pretende que a conta eliminada seja apresentado?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Se pudesse escolher, como gostaria que o utilizador eliminado fosse apresentado aos outros utilizadores?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Se pudesse escolher, como gostaria de ser apresentado aos outros utilizadores?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Após a conta do utilizador ser eliminada, o utilizador é apresentado aos outros utilizadores como "Utilizador antigo". Dedique algum tempo a responder à pergunta do nosso inquérito.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Após a sua conta ser eliminada, é apresentado aos outros utilizadores como "Utilizador antigo". Dedique algum tempo a responder à pergunta do nosso inquérito.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Mostrar menos',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'mais {num}',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Está prestes a eliminar a conta de:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Está prestes a eliminar a sua conta:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Eliminação da conta',
  'focused-task-close-account.delete-account.overview.heading.self': 'Eliminação da conta',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Por exemplo, páginas, problemas e comentários que criaram nos produtos.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Por exemplo, páginas, problemas e comentários que criou nos produtos.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'O utilizador ou outros poderão ter instalado aplicações que adicionam funcionalidades aos produtos Atlassian. Estas aplicações poderão ter armazenado as informações de perfil do utilizador.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'O utilizador ou outros poderão ter instalado aplicações que adicionam funcionalidades aos produtos Atlassian. Estas aplicações poderão ter armazenado as suas informações de perfil.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Informações relacionadas com compras, que têm de ser mantidas para relatórios financeiros.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Informações relacionadas com compras, as quais têm de ser mantidas para relatórios financeiros.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Registos que demonstração a eliminação da conta de um utilizador, que poderão ser necessários para apresentação a reguladores.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Registos que demonstração a eliminação da conta de um utilizador, que poderão ser necessários para apresentação a reguladores.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Dados que pertencem a um processo legal ativo e que  têm de ser mantidos, tal como requerido por lei.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Dados que pertencem a um processo legal ativo e que  têm de ser mantidos, tal como requerido por lei.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Mantemos os dados pessoais por períodos de tempo limitados para fins legais ou de negócio legítimos. Alguns exemplos incluem:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Mantemos os dados pessoais por períodos de tempo limitados para fins legais ou de negócio legítimos. Alguns exemplos incluem:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Não eliminamos quaisquer dados pessoais no conteúdo criado pelos utilizadores, tais como nomes ou endereços de e-mail introduzidos numa página ou num problema. Os administradores do produto terão de eliminar manualmente os respetivos dados.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Não eliminamos quaisquer dados pessoais no conteúdo criado por si ou por outros utilizadores, tais como nomes ou endereços de e-mail introduzidos numa página ou num problema. Os administradores do produto terão de eliminar manualmente os respetivos dados.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Os utilizadores têm o direito de enviar reclamações à autoridade de supervisão do seu país.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Tem o direito de enviar reclamações à autoridade de supervisão do seu país.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Quando elimina a conta:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Quando elimina a sua conta:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'O conteúdo criado pelos mesmos será mantido nos serviços da conta Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'O conteúdo criado por si será mantido nos serviços da conta Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Após uma prorrogação de 14 dias, não poderá cancelar o processo de eliminação da conta.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Será enviado um e-mail com a lista das aplicações que poderão ter armazenado os dados pessoais.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Será enviado um e-mail com a lista das aplicações que poderão ter armazenado os seus dados pessoais.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente tem acesso a:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente, não tem acesso a nenhum serviço, exceto à Comunidade e ao Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Outros serviços da conta Atlassian, tais como a Comunidade Atlassian e o Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente tem acesso a:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Irá <b>perder acesso imediatamente</b> a todos os serviços da conta Atlassian. Atualmente, não tem acesso a nenhum serviço, exceto à Comunidade e ao Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Iremos <b>eliminar os dados pessoais</b>, tais como o nome completo e endereço de e-mail, dos serviços da conta Atlassian dentro de 30 dias, exceto nos poucos casos onde têm de ser mantidos para fins legais ou legítimos de negócio.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Iremos <b>eliminar os seus dados pessoais</b>, tais como o seu nome completo e endereço de e-mail, dos serviços da conta Atlassian dentro de 30 dias, exceto nos poucos casos onde têm de ser mantidos para fins legais ou legítimos de negócio.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Após uma prorrogação de 14 dias, não poderá cancelar o processo de eliminação da conta. Se achar que poderá necessitar da conta mais tarde, opte por desativá-la.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Após uma prorrogação de 14 dias, não poderá cancelar o processo de eliminação da conta.',
  'focused-task-close-account.learnMore': 'Obter mais informações',
  'focused-task-close-account.next': 'Seguinte',
  'focused-task-close-account.previous': 'Anterior'
};