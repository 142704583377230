import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import styled from '@emotion/styled';
import { B500, B200 } from '@atlaskit/theme/colors';
import { h700 } from '@atlaskit/theme/typography';
import gridSizeTimes from '../../util/gridSizeTimes';
export var Screen = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  max-width: 640px;\n  margin-bottom: ", "px;\n  > p {\n    margin-top: ", "px;\n    margin-bottom: ", "px;\n  }\n"])), gridSizeTimes(2), gridSizeTimes(3), gridSizeTimes(2));
export var LoadingWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 500px;\n"])));
export var Title = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", ";\n  margin-bottom: ", "px;\n  margin-top: 0;\n"])), h700, gridSizeTimes(3));
export var SectionMessageOuter = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin: ", "px 0;\n"])), gridSizeTimes(3));
export var MainInformationList = styled.ul(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  > li b {\n    font-weight: 600;\n  }\n  p + ul {\n    margin-top: ", "px;\n  }\n"])), gridSizeTimes(1.5));
export var IconHoverWrapper = styled.span(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  color: ", ";\n  padding-left: ", "px;\n  &:hover {\n    color: ", ";\n  }\n"])), "var(--ds-background-information-bold, ".concat(B500, ")"), gridSizeTimes(0.5), "var(--ds-background-information-bold-hovered, ".concat(B200, ")"));
export var InlineDialogContent = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  li {\n    margin-left: ", "px;\n    margin-top: ", "px;\n    padding-left: ", "px;\n  }\n"])), gridSizeTimes(3), gridSizeTimes(1), gridSizeTimes(1));