// dimension vars
export var VAR_LEFT_PANEL_WIDTH = 'leftPanelWidth';
export var VAR_LEFT_SIDEBAR_WIDTH = 'leftSidebarWidth';
export var VAR_RIGHT_SIDEBAR_WIDTH = 'rightSidebarWidth';
export var VAR_RIGHT_PANEL_WIDTH = 'rightPanelWidth';
export var VAR_TOP_NAVIGATION_HEIGHT = 'topNavigationHeight';
export var VAR_BANNER_HEIGHT = 'bannerHeight';
export var VAR_LEFT_SIDEBAR_FLYOUT = 'leftSidebarFlyoutWidth';
export var DIMENSIONS = [VAR_LEFT_PANEL_WIDTH, VAR_RIGHT_PANEL_WIDTH, VAR_BANNER_HEIGHT, VAR_TOP_NAVIGATION_HEIGHT, VAR_LEFT_SIDEBAR_WIDTH, VAR_RIGHT_SIDEBAR_WIDTH];

// Grid area names
export var LEFT_PANEL = 'left-panel';
export var RIGHT_PANEL = 'right-panel';
export var BANNER = 'banner';
export var TOP_NAVIGATION = 'top-navigation';
export var CONTENT = 'content';
export var MAIN = 'main';
export var LEFT_SIDEBAR = 'left-sidebar';
export var RIGHT_SIDEBAR = 'right-sidebar';

// Default slot dimension values
export var DEFAULT_BANNER_HEIGHT = 56;
export var DEFAULT_TOP_NAVIGATION_HEIGHT = 56;
export var DEFAULT_LEFT_SIDEBAR_WIDTH = 240;
export var DEFAULT_RIGHT_SIDEBAR_WIDTH = 280;
export var DEFAULT_RIGHT_PANEL_WIDTH = 368;
export var DEFAULT_LEFT_PANEL_WIDTH = 368;

// Other constants
export var COLLAPSED_LEFT_SIDEBAR_WIDTH = 20;
export var MOBILE_COLLAPSED_LEFT_SIDEBAR_WIDTH = 16;
export var MIN_LEFT_SIDEBAR_WIDTH = 80;
export var DEFAULT_LEFT_SIDEBAR_FLYOUT_WIDTH = 240;
export var MIN_LEFT_SIDEBAR_DRAG_THRESHOLD = 200;
export var MAX_MOBILE_SIDEBAR_FLYOUT_WIDTH = 350;
export var TRANSITION_DURATION = 300;
export var FLYOUT_DELAY = 200;
export var LEFT_SIDEBAR_EXPANDED_WIDTH = 'expandedLeftSidebarWidth';
export var PAGE_LAYOUT_LS_KEY = 'DS_PAGE_LAYOUT_UI_STATE';

// Data attributes
export var IS_SIDEBAR_DRAGGING = 'data-is-sidebar-dragging';
export var IS_SIDEBAR_COLLAPSING = 'data-is-sidebar-collapsing';
export var IS_FLYOUT_OPEN = 'data-is-flyout-open';
export var GRAB_AREA_LINE_SELECTOR = 'data-grab-area-line';
export var GRAB_AREA_SELECTOR = 'data-grab-area';
export var RESIZE_BUTTON_SELECTOR = 'data-resize-button';
export var RESIZE_CONTROL_SELECTOR = 'data-resize-control';
export var PAGE_LAYOUT_SLOT_SELECTOR = 'data-ds--page-layout--slot';
export var DEFAULT_I18N_PROPS_SKIP_LINKS = 'Skip to:';
export var PAGE_LAYOUT_CONTAINER_SELECTOR = 'data-layout-container';
export var LEFT_PANEL_WIDTH = "var(--".concat(VAR_LEFT_PANEL_WIDTH, ", 0px)");
export var RIGHT_PANEL_WIDTH = "var(--".concat(VAR_RIGHT_PANEL_WIDTH, ", 0px)");
export var LEFT_SIDEBAR_WIDTH = "var(--".concat(VAR_LEFT_SIDEBAR_WIDTH, ", 0px)");
export var RIGHT_SIDEBAR_WIDTH = "var(--".concat(VAR_RIGHT_SIDEBAR_WIDTH, ", 0px)");
export var TOP_NAVIGATION_HEIGHT = "var(--".concat(VAR_TOP_NAVIGATION_HEIGHT, ", 0px)");
export var BANNER_HEIGHT = "var(--".concat(VAR_BANNER_HEIGHT, ", 0px)");
export var LEFT_SIDEBAR_FLYOUT_WIDTH = "var(--".concat(VAR_LEFT_SIDEBAR_FLYOUT, ", ").concat(DEFAULT_LEFT_SIDEBAR_FLYOUT_WIDTH, "px)");