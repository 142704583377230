/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl-next';

import { B50, N20A, N800 } from '@atlaskit/theme/colors';
import { borderRadius, gridSize } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

import type { AccountMenuProps } from '../account-menu';
import { PersonIcon } from '../icons/person';
import { ProfilePicture } from '../profile-picture';
import { SkeletonText } from '../skeleton-text';

export type AccountDetailsProps = Pick<
  AccountMenuProps,
  'testId' | 'name' | 'picture' | 'email' | 'managedBy'
>;

export const AccountDetails = ({
  testId,
  name,
  picture,
  email,
  managedBy,
}: AccountDetailsProps) => (
  <Container data-testid={testId}>
    {/* ManagedBy can't go on top of a background since it's background may not be opaque. */}
    {managedBy && (
      <ManagedBy>
        <PersonIconWrapper>
          <PersonIcon label="" size="small" />
        </PersonIconWrapper>
        <FormattedMessage
          id="identity.account-menu.account-menu-details.managed-account"
          defaultMessage="This account is managed by <b>{managedBy}</b>"
          description="Label showing the user which organisation manages their Atlassian account"
          values={{
            b: ((text) => <strong>{text}</strong>) as unknown as React.ReactNode,
            managedBy,
          }}
        />
      </ManagedBy>
    )}

    <Content hasHeader={!!managedBy}>
      <Profile testId={testId} name={name} picture={picture} email={email} />
    </Content>
  </Container>
);

const Container = styled.section({
  display: 'flex',
  flexDirection: 'column',
  margin: `0 ${gridSize()}px`,
});

const ManagedBy = styled.small({
  display: 'box',
  margin: '0px',
  padding: `${gridSize() / 4}px ${gridSize()}px`,
  textAlign: 'center',
  borderRadius: `${borderRadius()}px ${borderRadius()}px 0 0`,
  background: token('color.background.information', B50),
  color: token('color.text.subtle', '#44546F'),
});

const PersonIconWrapper = styled.span({
  marginRight: `${gridSize() / 2}px`,
  '& > span': {
    width: '12px',
    height: '12px',
  },
});

const Content = styled.div<{ hasHeader: boolean }>(
  {
    display: 'flex',
    flexDirection: 'column',
    background: token('elevation.surface.sunken', N20A),
  },
  ({ hasHeader }) => ({
    borderRadius: hasHeader
      ? `0 0 ${borderRadius()}px ${borderRadius()}px`
      : `${borderRadius()}px`,
  }),
);

const Profile: React.ComponentType<
  Pick<AccountDetailsProps, 'name' | 'email' | 'picture' | 'testId'>
> = ({ name, email, picture, testId }) => (
  <ProfileWrapper>
    <ProfilePicture
      src={picture}
      size={gridSize() * 8}
      testId={testId && `${testId}--picture`}
    />
    <NameAndEmail>
      <DisplayName data-testid={testId && `${testId}--name`}>
        {name || <SkeletonText length="6em" />}
      </DisplayName>
      <EmailAddress data-testid={testId && `${testId}--email`}>
        {email || <SkeletonText length="10em" />}
      </EmailAddress>
    </NameAndEmail>
  </ProfileWrapper>
);

const ProfileWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  margin: `${gridSize() * 2}px ${gridSize() * 2}px`,
});

const NameAndEmail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: `auto 0 auto ${gridSize() * 2}px`,
});

const DisplayName = styled.h3({
  color: token('color.text', N800),
  margin: 0,
});

const EmailAddress = styled.p({
  marginTop: `${gridSize() / 4}px`,
  color: token('color.text.subtlest', '#626F86'),
  fontWeight: 400,
});
