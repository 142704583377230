import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl-next';

import {
  AnalyticsEventActions,
  AnalyticsEventSource,
  InjectedAnalyticsProps,
  logException,
  withAnalyticsContext,
  ProfileDetailsInlineEdit,
  GenericInlineDialogContent,
} from '../../../../../common';

import PrivacyLevelDropdown, {
  generatePrivacyOptions,
  PRIVACY_VALUE_MAP,
} from '../PrivacyLevelDropdown';
import { PrivacyLevel } from '../PrivacyLevelDropdown/types';

import { getFormFields } from './form-fields-config';

import * as Styled from './ProfileDetailsForm.styled';
import { FieldType, ProfileDetailsFormProps as Props } from './types';
import { disabledPrivacy } from './messages';
import { useProfileDetailsMutabilityConstraints } from '../../../hooks/useProfileDetailsMutabilityConstraints';

const managedFields = [
  FieldType.Email,
  FieldType.FullName,
  FieldType.Title,
  FieldType.Department,
  FieldType.CompanyName,
];

export const allowedEmptyFields = [
  // users cannot update local time in UI
  FieldType.LocalTime,
];

export const immutableFields = [
  // users cannot update local time in UI
  FieldType.LocalTime,
  FieldType.Email,
];

const PROFILE_DETAILS_SUBMIT_ERROR_FLAG = 'PROFILE_DETAILS_SUBMIT_ERROR_FLAG';
const PROFILE_DETAILS_SUBMIT_SUCCESS_FLAG =
  'PROFILE_DETAILS_SUBMIT_SUCCESS_FLAG';

const ProfileDetailsForm = ({
  addFlag,
  analytics,
  fieldTypes,
  isManaged,
  onPrivacyLevelChange,
  onSubmit,
  privacyOptions,
  privacyValues,
  usersOrg,
  values,
  userId,
}: Props & InjectedAnalyticsProps) => {
  const { data, isError, isLoading } = useProfileDetailsMutabilityConstraints(
    userId,
  );
  const { formatMessage } = useIntl();

  const externallyManagedFields = useMemo(
    () => data?.map(constraint => constraint?.field),
    [data],
  );

  const formFields = useMemo(
    () =>
      getFormFields({
        formatMessage,
        isManaged,
      }),
    [formatMessage, isManaged],
  );

  const getPrivacyLevelChangeHandler = (fieldKey: FieldType) => (
    value: PrivacyLevel,
  ) => onPrivacyLevelChange(fieldKey, value);

  const getSubmitHandler = useCallback(
    (fieldKey: FieldType) => async (value: string) => {
      try {
        await onSubmit({ [fieldKey]: value });

        analytics.pushTrackEvent({
          source:
            AnalyticsEventSource.MANAGE_ACCOUNT_PROFILE_AND_VISIBILITY_TAB,
          actionSubject: 'profileAboutItem',
          action: AnalyticsEventActions.EDITED,
          attributes: {
            fieldKey,
          },
        });
        addFlag({
          type: 'success',
          title: formatMessage(successFlag[fieldKey]),
          description: formatMessage({
            id:
              'ptc-directory.user-profile-page.about.error-flag.change-propagation-takes-time',
            defaultMessage: 'Your change might take a while to show everywhere',
            description:
              'The error message description shown after a user info is edited, mostly about changes not being visible everywhere immediately',
          }),
          id: PROFILE_DETAILS_SUBMIT_SUCCESS_FLAG,
        });
      } catch (err) {
        void logException(err, 'Submission failed in ProfileDetailsForm');
        addFlag({
          type: 'error',
          title: formatMessage(errorFlag[fieldKey]),
          description: formatMessage({
            id:
              'ptc-directory.settings.profile-and-visibility.details.try-again-later',
            defaultMessage: 'Try again later',
            description:
              'The error message description for when a field edit fails. Appears under the title in a notification flag when a field edit fails.',
          }),
          id: PROFILE_DETAILS_SUBMIT_ERROR_FLAG,
        });
      }
    },
    [addFlag, analytics, formatMessage, onSubmit],
  );

  const renderPrivacyDropdown = (type: FieldType) => {
    // When rendering visibility dropdown for local time field,
    // we want to render visibility dropdown for time zone instead
    const fieldType = type === FieldType.LocalTime ? FieldType.Timezone : type;

    const fieldData = formFields[fieldType]!;

    const privacyLevels = privacyOptions[fieldData.privacy.key];

    const privacyLevelValue =
      PRIVACY_VALUE_MAP[privacyValues[fieldData.privacy.key]];

    const isDisabled = !privacyLevels || privacyLevels.length === 1;

    return (
      <PrivacyLevelDropdown
        disabledMessage={
          isDisabled ? renderDisabledMessageForPrivacyDropdown(fieldType) : null
        }
        options={generatePrivacyOptions(fieldType, privacyLevels || undefined, {
          orgName: usersOrg,
          isManaged,
        })}
        privacyLevel={privacyLevelValue}
        onChange={getPrivacyLevelChangeHandler(fieldType)}
      />
    );
  };

  const renderFormRow = (type: FieldType, idx: number) => {
    const value = values[type];
    const fieldData = formFields[type]!;

    const canBeEmpty = allowedEmptyFields.includes(type);
    const cantUpdateYet = immutableFields.includes(type);
    const isFieldManaged =
      (externallyManagedFields && externallyManagedFields.includes(type)) || // Based on actual data from Identity
      (isManaged && managedFields.includes(type)); // Based on our best guess during initial render

    const isReadOnly = isFieldManaged || cantUpdateYet || isLoading || isError;

    if (value && cantUpdateYet && !canBeEmpty && !value.edit) {
      return null;
    }

    return (
      <Styled.Row key={idx}>
        <Styled.Field>
          <ProfileDetailsInlineEdit
            data-testid={`testId_${type}`}
            isReadOnly={isReadOnly}
            label={fieldData.label}
            placeholder={fieldData.placeholder}
            value={value?.edit || ''}
            readValue={value?.read || ''}
            onSubmit={getSubmitHandler(type)}
            inputType={fieldData.inputType}
            infoHelperMessage={fieldData.info}
            inlineDialogContent={
              (isManaged && isFieldManaged && fieldData.managed) ||
              (isReadOnly && fieldData.disabled)
            }
            inlineDialogContentPosition="bottom-start"
            isRequired={fieldData.isRequired}
            requiredInvalidMessage={fieldData.requiredInvalidMessage}
          />
        </Styled.Field>

        <Styled.VisibilityDropdown
          data-test-selector={`visibility-wrapper-${type.toLowerCase()}`}
        >
          {renderPrivacyDropdown(type)}
        </Styled.VisibilityDropdown>
      </Styled.Row>
    );
  };

  const renderDisabledMessageForPrivacyDropdown = (fieldType: string) => {
    const message =
      disabledPrivacy[fieldType] ||
      disabledPrivacy[`${fieldType}_${isManaged ? 'managed' : 'unmanaged'}`];

    if (!message) {
      return null;
    }

    return (
      <GenericInlineDialogContent
        message={
          <FormattedMessage
            {...message}
            values={{
              br: <br />,
              strong: chunks => <strong>{chunks}</strong>,
            }}
          />
        }
      />
    );
  };

  return (
    <Styled.FormCard>
      <Styled.PrivacyLabel>
        <FormattedMessage
          id="ptc-directory.settings.profile-and-visibility.details.form-label"
          defaultMessage="Who can see this?"
          description="A text of a label above the visibility dropdowns"
        />
      </Styled.PrivacyLabel>
      <Styled.FormFields>
        {fieldTypes.map((type, idx) => renderFormRow(type, idx))}
      </Styled.FormFields>
    </Styled.FormCard>
  );
};

export default withAnalyticsContext(ProfileDetailsForm);

export const successFlag = defineMessages({
  nickname: {
    id: 'ptc-directory.user-profile-page.about.success-flag.nickname.title',
    defaultMessage: 'You’ve updated your public name',
    description:
      'The success message title for when editing the public name succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  fullName: {
    id: 'ptc-directory.user-profile-page.about.success-flag.fullName.title',
    defaultMessage: 'You’ve updated your full name',
    description:
      'The success message title for when editing the full name succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  title: {
    id: 'ptc-directory.user-profile-page.about.success-flag.title.title',
    defaultMessage: 'You’ve updated your job title',
    description:
      'The success message title for when editing the description succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  department: {
    id: 'ptc-directory.user-profile-page.about.success-flag.department.title',
    defaultMessage: 'You’ve updated your department',
    description:
      'The success message title for when editing the department succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  companyName: {
    id: 'ptc-directory.user-profile-page.about.success-flag.companyName.title',
    defaultMessage: 'You’ve updated your organization',
    description:
      'The success message title for when editing the organization succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  location: {
    id: 'ptc-directory.user-profile-page.about.success-flag.location.title',
    defaultMessage: 'You’ve updated your location',
    description:
      'The success message title for when editing the location succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  timezone: {
    id: 'ptc-directory.user-profile-page.about.success-flag.timezone.title',
    defaultMessage: 'You’ve updated your time zone',
    description:
      'The success message title for when editing the timezone succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  email: {
    id: 'ptc-directory.user-profile-page.about.success-flag.email.title',
    defaultMessage: 'You’ve updated your email address',
    description:
      'The success message title for when editing the email address succeeded. Appears at the title of the notification flag upon a successful save.',
  },
  phoneNumber: {
    id: 'ptc-directory.user-profile-page.about.success-flag.phone.title',
    defaultMessage: 'You’ve updated your phone number',
    description:
      'The success message title for when editing the phone number succeeded. Appears at the title of the notification flag upon a successful save.',
  },
});

export const errorFlag = defineMessages({
  nickname: {
    id: 'ptc-directory.user-profile-page.about.error-flag.nickname.title',
    defaultMessage: 'We couldn’t update your public name',
    description:
      'The error message title for when editing the public name fails. Appears in a notification flag upon failure to save new value.',
  },
  fullName: {
    id: 'ptc-directory.user-profile-page.about.error-flag.fullName.title',
    defaultMessage: 'We couldn’t update your full name',
    description:
      'The error message title for when editing the full name fails. Appears in a notification flag upon failure to save new value.',
  },
  title: {
    id: 'ptc-directory.user-profile-page.about.error-flag.title.title',
    defaultMessage: 'We couldn’t update your job title',
    description:
      'The error message title for when editing the job title fails. Appears in a notification flag upon failure to save new value.',
  },
  department: {
    id: 'ptc-directory.user-profile-page.about.error-flag.department.title',
    defaultMessage: 'We couldn’t update your department',
    description:
      'The error message title for when editing the department fails. Appears in a notification flag upon failure to save new value.',
  },
  companyName: {
    id: 'ptc-directory.user-profile-page.about.error-flag.companyName.title',
    defaultMessage: 'We couldn’t update your organization',
    description:
      'The error message title for when editing the organization fails. Appears in a notification flag upon failure to save new value.',
  },
  location: {
    id: 'ptc-directory.user-profile-page.about.error-flag.location.title',
    defaultMessage: 'We couldn’t update your location',
    description:
      'The error message title for when editing the location fails. Appears in a notification flag upon failure to save new value.',
  },
  timezone: {
    id: 'ptc-directory.user-profile-page.about.error-flag.timezone.title',
    defaultMessage: 'We couldn’t update your time zone',
    description:
      'The error message title for when editing the time zone fails. Appears in a notification flag upon failure to save new value.',
  },
  email: {
    id: 'ptc-directory.user-profile-page.about.error-flag.email.title',
    defaultMessage: 'We couldn’t update your email address',
    description:
      'The error message title for when editing the email address fails. Appears in a notification flag upon failure to save new value.',
  },
  phoneNumber: {
    id: 'ptc-directory.user-profile-page.about.error-flag.phone.title',
    defaultMessage: 'We couldn’t update your phone number',
    description:
      'The error message title for when editing the phone number fails. Appears in a notification flag upon failure to save new value.',
  },
});
