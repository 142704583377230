import window from 'window-or-global';
import { getConfig } from '../config/envConfig';
import {
  REDIRECT_COUNT,
  getCookieAsInteger,
  setCookie,
  clearCookie,
} from './cookie';

const redirect = (url: string) => window.location.assign(url);

const HALTING_REDIRECT_THRESHOLD = 10;

export function returnAfterLogin() {
  if (getCookieAsInteger(REDIRECT_COUNT) <= HALTING_REDIRECT_THRESHOLD) {
    redirect(
      `${getConfig().atlassianAccountUrl}/login?continue=${encodeURIComponent(
        window.location.href,
      )}`,
    );
  }
}

function redirectCentralLogin() {
  redirect(
    `${getConfig().atlassianAccountUrl}/login?continue=${encodeURIComponent(
      window.location.href,
    )}`,
  );
}

function redirectTenantLogin() {
  redirect(
    `${getConfig().atlassianAccountUrl}/login?continue=${encodeURIComponent(
      window.location.href,
    )}`,
  );
}

const redirectLimiter = {
  reset: () => {
    clearCookie(REDIRECT_COUNT);
  },
  try: (f: () => void) => {
    const attemptCount = getCookieAsInteger(REDIRECT_COUNT);
    if (attemptCount < HALTING_REDIRECT_THRESHOLD) {
      setCookie(REDIRECT_COUNT, attemptCount + 1);
      f();
    }
  },
};

function createFetchUnauthorisedInterceptor(onUnauthorised: () => void) {
  return request =>
    request.then(resp => {
      if (resp.ok) {
        redirectLimiter.reset();
      } else if (resp.status === 401) {
        redirectLimiter.try(onUnauthorised);
      }
      return resp;
    });
}

function createRequestUnauthorisedInterceptor<T>(onUnauthorised: () => void) {
  return (request: Promise<T>) =>
    request
      .then(result => {
        redirectLimiter.reset();
        return result;
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          redirectLimiter.try(onUnauthorised);
        }
        throw err;
      });
}

function createRequestLoginCheckOrRedirect<T>(onUnauthorised: () => void) {
  return (request: Promise<T>) =>
    request
      .then(result => {
        redirectLimiter.reset();
        return result;
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          return {
            redirectToLogin: () => {
              redirectLimiter.try(onUnauthorised);
            },
          };
        }

        throw err;
      });
}

export const fetchInterceptorCentralLoginOnUnauthorised = createFetchUnauthorisedInterceptor(
  redirectCentralLogin,
);

export const fetchInterceptorTenantLoginOnUnauthorised = createFetchUnauthorisedInterceptor(
  redirectTenantLogin,
);

export const requestInterceptorCentralLoginOnUnauthorised = createRequestUnauthorisedInterceptor(
  redirectCentralLogin,
);

export const requestLoginCheckOrRedirect = createRequestLoginCheckOrRedirect(
  redirectCentralLogin,
);
