import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from 'react';
export function useInView() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var ref = React.useRef();
  var observerInstance = React.useRef();
  var _React$useState = React.useState({
      inView: false,
      entry: undefined,
      target: undefined
    }),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    state = _React$useState2[0],
    setState = _React$useState2[1];
  var threshold = options.threshold,
    root = options.root,
    rootMargin = options.rootMargin;
  var setRef = React.useCallback(function (node) {
    observerInstance.current = new IntersectionObserver(function (entries) {
      if (entries && entries.length) {
        var intersection = entries[0];
        var isIntersecting = intersection.isIntersecting,
          intersectionRatio = intersection.intersectionRatio;
        if (intersectionRatio >= 0) {
          var inView = intersectionRatio >= (threshold || 0);
          if (isIntersecting !== undefined) {
            inView = inView && isIntersecting;
          }
          setState({
            inView: inView,
            entry: intersection,
            target: node
          });
        }
      }
    }, {
      threshold: threshold,
      root: root,
      rootMargin: rootMargin
    });
    if (ref.current) {
      observerInstance.current.unobserve(ref.current);
    }
    if (node) {
      observerInstance.current.observe(node);
    }

    // Store a reference to the node
    ref.current = node;
  }, [threshold, root, rootMargin]);

  /**
   * Unsubscribe IntersectionObserver before unmounting a component.
   */
  React.useEffect(function () {
    return function () {
      if (observerInstance.current && ref.current) {
        observerInstance.current.unobserve(ref.current);
      }
    };
  }, []);
  return [setRef, state.inView, state.target, state.entry];
}