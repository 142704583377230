import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const Wrapper = styled.div`
  /**
   * Grey out field if empty.
   */
  color: ${(props: { isEmpty: boolean }) =>
    props.isEmpty ? token('color.text.subtlest', colors.N90) : 'inherit'};
  & input {
    padding-left: ${token('space.100', '8px')};
  }
`;
