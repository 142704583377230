import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
/** @jsx jsx */
import { useCallback, useState } from 'react';
import { css, jsx } from '@emotion/react';
import Popup from '@atlaskit/popup';
import { WidthObserver } from '@atlaskit/width-detector';
import { OverflowProvider, useOverflowController } from '../../controllers/overflow';
import { PrimaryDropdownButton } from '../PrimaryDropdownButton';
var containerStyles = css({
  display: 'flex',
  height: '100%',
  position: 'relative',
  alignItems: 'stretch',
  flexBasis: 0,
  flexGrow: 1,
  flexShrink: 0,
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > *': {
    margin: "0 ".concat("var(--ds-space-050, 4px)"),
    flexShrink: 0
  }
});
var widthObserverContainerStyles = css({
  width: '100%',
  minWidth: '1px',
  margin: '0px',
  position: 'relative',
  flexShrink: 1
});

// Internal only
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var PrimaryItemsContainer = function PrimaryItemsContainer(_ref) {
  var moreLabel = _ref.moreLabel,
    items = _ref.items,
    Create = _ref.create,
    theme = _ref.theme,
    testId = _ref.testId;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isMoreOpen = _useState2[0],
    setIsMoreOpen = _useState2[1];
  var _useOverflowControlle = useOverflowController(items),
    updateWidth = _useOverflowControlle.updateWidth,
    visibleItems = _useOverflowControlle.visibleItems,
    overflowItems = _useOverflowControlle.overflowItems;
  var onMoreClick = useCallback(function () {
    setIsMoreOpen(!isMoreOpen);
  }, [isMoreOpen, setIsMoreOpen]);
  var onMoreClose = useCallback(function () {
    setIsMoreOpen(false);
  }, []);
  var openOverflowMenu = useCallback(function () {
    setIsMoreOpen(true);
  }, []);
  var trigger = useCallback(function (triggerProps) {
    return jsx(PrimaryDropdownButton, _extends({
      onClick: onMoreClick,
      isSelected: isMoreOpen,
      testId: testId ? "".concat(testId, "-overflow-menu-trigger") : 'overflow-menu-trigger'
    }, triggerProps), moreLabel);
  }, [moreLabel, onMoreClick, isMoreOpen, testId]);
  var content = useCallback(function () {
    return jsx(OverflowProvider, {
      isVisible: false,
      openOverflowMenu: openOverflowMenu,
      closeOverflowMenu: onMoreClose
    }, overflowItems);
  },
  // Overflow items has an unstable reference - so we will only recalculate
  // content if `overflowItems` length changes.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [overflowItems.length, openOverflowMenu, onMoreClose]);
  return jsx("div", {
    css: containerStyles,
    "data-testid": testId && "".concat(testId, "-primary-actions"),
    role: "list"
  }, jsx(OverflowProvider, {
    isVisible: true,
    openOverflowMenu: openOverflowMenu,
    closeOverflowMenu: onMoreClose
  }, visibleItems), overflowItems.length > 0 && jsx(Popup, {
    shouldRenderToParent: true,
    placement: "bottom-start",
    content: content,
    isOpen: isMoreOpen,
    onClose: onMoreClose,
    trigger: trigger,
    testId: testId ? "".concat(testId, "-overflow-menu-popup") : 'overflow-menu-popup'
  }), Create && jsx(Create, null), jsx("div", {
    css: widthObserverContainerStyles
  }, jsx(WidthObserver, {
    offscreen: true,
    setWidth: updateWidth
  })));
};