import { useQuery } from '@tanstack/react-query';
import { fetchApi } from '../../../utils/fetchApi';
import { getConfig } from '../../../common/config/envConfig';

const ProfileDetailsMutabilityConstraint = 'ProfileDetailsMutabilityConstraint';

type UserManageConfig = {
  userId: string;
};

export type UserManageConfigResponse = {
  profile: Profile;
  'profile.write': Profile;
  'profile.read': PermissionResult;
  'email.set': PermissionResult;
  'email.initiateChange': PermissionResult;
  'mfa.read': PermissionResult;
  'mfa.unenroll': PermissionResult;
  'password.set': PermissionResult;
  'password.reset': PermissionResult;
  'lifecycle.enablement': PermissionResult;
  'lifecycle.delete': PermissionResult;
  'linkedAccounts.read': PermissionResult;
  'apiToken.read': PermissionResult;
  'apiToken.delete': PermissionResult;
  'apiToken.create': PermissionResult;
  avatar: PermissionResult;
  'privacy.set': PermissionResult;
  'session.read': PermissionResult;
  'session.delete': PermissionResult;
  'refreshToken.delete': PermissionResult;
};

type PermissionResult = {
  allowed: boolean;
  reason?: { key: string };
};

type Profile = {
  name: PermissionResult;
  nickname: PermissionResult;
  zoneinfo: PermissionResult;
  locale: PermissionResult;
  'extended_profile.job_title': PermissionResult;
  'extended_profile.organization': PermissionResult;
  'extended_profile.department': PermissionResult;
  'extended_profile.location': PermissionResult;
};

const MUTABILITY_DETAILS_MAP = {
  fullName: 'name',
  nickname: 'nickname',
  timezone: 'zoneinfo',
  locale: 'locale',
  email: 'email',
  apiTokenCreate: 'apiToken.create',
  // extended profile
  title: 'extended_profile.job_title',
  phoneNumber: 'extended_profile.phone_number',
  companyName: 'extended_profile.organization',
  department: 'extended_profile.department',
  location: 'extended_profile.location',
};

const CUSTOM_FIELD_PLACE = {
  email: 'email.set',
  picture: 'avatar',
  apiTokenCreate: 'apiToken.create',
};

type MutabilityConstraint = {
  field: string;
  reason: string | null;
};

const getFieldConfig = (data, field) => {
  const fieldPath = CUSTOM_FIELD_PLACE[field];
  if (fieldPath) {
    return data[fieldPath];
  }
  const mappedFieldKey = MUTABILITY_DETAILS_MAP[field];
  return data['profile.write'][mappedFieldKey];
};

const getMutabilityConstraintFor = (
  data,
  field,
): MutabilityConstraint | null => {
  const fieldConfig = getFieldConfig(data, field) || {};
  if (fieldConfig.allowed) {
    return null;
  }

  const reason = fieldConfig.reason;
  return {
    field,
    reason: (reason && reason.key) || null,
  };
};

export const transformUserManageConfig = (data: UserManageConfigResponse) =>
  Object.keys(MUTABILITY_DETAILS_MAP)
    .map(field => getMutabilityConstraintFor(data, field))
    .filter(
      (constraint): constraint is MutabilityConstraint => constraint !== null,
    );

const userManageQueryFn = async ({
  userId,
}: UserManageConfig): Promise<MutabilityConstraint[]> => {
  const { stargateRoot } = getConfig();
  const url = `${stargateRoot}/users/${userId}/manage`;
  const response = await fetchApi(url);
  const data: UserManageConfigResponse = await response.json();
  return transformUserManageConfig(data);
};

export const useProfileDetailsMutabilityConstraints = (userId: string) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [ProfileDetailsMutabilityConstraint, userId],
    queryFn: () => userManageQueryFn({ userId }),
  });

  return {
    data,
    isLoading,
    isError,
  };
};
