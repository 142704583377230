import RestClient from './rest-client';
import { getConfig } from '../config/envConfig';
import { IDENTITY_CSRF_TOKEN, setCookie } from '../utils/cookie';

interface Owner {
  ownerId: string;
  ownerType: string;
  timestamp: string;
}
export interface UserInfo {
  id: string;
  email: string;
  pendingEmail: string | null;
  timezone: string | null;
  useMfa: boolean;
  authProvider?: string;
  userManagedStatus?: {
    managed: boolean;
    owner?: Owner;
  };
  mfaEnforcedByAuthPolicy: boolean;
}

export const IDENTITY_CSRF_HEADER = 'X-CSRF-TOKEN';

const commonHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
  // we don't want server returning a non-english error message to front-end.
  'Accept-Language': 'en-US,en;q=0.5',
};

const createRandomString = (n = 20) => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < n; i++) {
    result += charset.charAt(Math.floor(Math.random() * charset.length));
  }

  return result;
};

const formUrlEncode = (data: object) =>
  Object.keys(data)
    .reduce((a, b) => {
      return `${b}=${encodeURIComponent(data[b])}&${a}`;
    }, '')
    .slice(0, -1);

class IdentityClient extends RestClient {
  getUserInfo(): Promise<UserInfo> {
    return this.getResource('/user');
  }

  changePassword(currentPassword: string, newPassword: string) {
    const token = createRandomString();
    setCookie(IDENTITY_CSRF_TOKEN, token);

    const query = formUrlEncode({
      currentPassword,
      newPassword,
      confirmPassword: newPassword,
    });

    const options = {
      headers: {
        ...commonHeaders,
        [IDENTITY_CSRF_HEADER]: token,
        'X-REQUESTED-WITH': 'XMLHttpRequest',
      },
    };

    return this.postResourceRaw('/password', query, options);
  }

  changeEmail(newEmail: string) {
    const token = createRandomString();
    setCookie(IDENTITY_CSRF_TOKEN, token);

    const query = formUrlEncode({
      newEmail,
      csrfToken: token,
    });

    return this.postResourceRaw('/change-email', query, {
      headers: commonHeaders,
    });
  }

  revertEmail() {
    const token = createRandomString();
    setCookie(IDENTITY_CSRF_TOKEN, token);

    const query = formUrlEncode({
      csrfToken: token,
    });

    const fetchOptions = {
      body: query,
      ignoreResponse: true,
      headers: commonHeaders,
    };

    return this.deleteResource('/change-email', fetchOptions);
  }
}

export default new IdentityClient({
  serviceUrl: getConfig().identityProxyUrl,
});
