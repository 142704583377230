import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/custom-theme-button';
import {
  FocusedTaskCloseAccount,
  DeleteUserOverviewScreen,
} from '@atlaskit/focused-task-close-account';

import {
  AnalyticsEventActions,
  AnalyticsEventSource,
  InjectedAnalyticsProps,
  withAnalyticsContext,
  getConfig,
  addFlag,
  HttpError,
  identityUserClient,
} from '../../../../common';
import { redirect } from '../../../../facades/window';
import ErrorModalDialogLazy from './ErrorModalDialog/lazy';
import WarningModalDialogLazy from './WarningModalDialog/lazy';
import qs from 'query-string';
import { usePrevious } from '../../../../utils/hooks';
import useDialogs from '../../../../common/components/Dialog/useDialogs';
import { useAvatarByAccountIdQuery } from '../../../../common/components/Avatar/hooks';
import { connect } from 'react-redux';
import { AvailableSitesResponse } from './types';
import { useQueryClient } from '@tanstack/react-query';

const canCloseAccountQueryKey = 'canCloseAccountKey';

interface CloseAccountFlowTriggerProps {
  getAccessibleSites: () => Promise<void>;
  accessibleSites: AvailableSitesResponse;
  isOnDeleteUserLoading: boolean | undefined;
  deleteUser: (userId: string) => Promise<void>;
  onDeleteError: HttpError | null | undefined;
}

type Props = CloseAccountFlowTriggerProps &
  InjectedAnalyticsProps &
  StateProps &
  DispatchProps;

export function CloseAccountFlowTrigger({
  accessibleSites,
  addFlag,
  analytics,
  deleteUser,
  email,
  getAccessibleSites,
  isOnDeleteUserLoading,
  onDeleteError,
  userId,
}: Props) {
  const { formatMessage } = useIntl();
  const { openDialog } = useDialogs();
  const queryClient = useQueryClient();

  const [isFocusedTaskOpen, setIsFocusedTaskOpen] = useState(false);
  const [
    isCheckCanCloseAccountLoading,
    setIsCheckCanCloseAccountLoading,
  ] = useState(false);

  const previousOnDeleteError = usePrevious(onDeleteError);
  const previousIsOnDeleteUserLoading = usePrevious(isOnDeleteUserLoading);
  useEffect(() => {
    const hasOnDeleteError =
      onDeleteError && onDeleteError !== previousOnDeleteError;

    if (hasOnDeleteError) {
      analytics.pushTrackEvent({
        source: AnalyticsEventSource.DELETE_ACCOUNT_WARNING_MODAL,
        action: AnalyticsEventActions.FAILED,
        actionSubject: 'deleteAccount',
      });
      addFlag({
        type: 'error',
        title: formatMessage({
          id:
            'ptc-directory.settings.close-account.delete-account-error.title.text',
          defaultMessage: "We weren't able to delete your account.",
          description: 'Flag title when error is received',
        }),
        id: 'delete.account.error',
      });
    }

    const isFinishedDeletingUser =
      !hasOnDeleteError &&
      !isOnDeleteUserLoading &&
      isOnDeleteUserLoading !== previousIsOnDeleteUserLoading;

    if (isFinishedDeletingUser) {
      redirect(
        `${getConfig().atlassianAccountUrl}/logout?${qs.stringify({
          continue: `${
            getConfig().atlassianAccountUrl
          }/login/deletion-request-confirmation`,
          prompt: 'none',
        })}`,
      );
    }
  }, [
    formatMessage,
    previousIsOnDeleteUserLoading,
    previousOnDeleteError,
    analytics,
    addFlag,
    isOnDeleteUserLoading,
    onDeleteError,
  ]);

  const fetchCanCloseAccount = async (userId: string) =>
    await queryClient.fetchQuery({
      queryKey: [canCloseAccountQueryKey, userId],
      queryFn: async () => await identityUserClient.checkCloseAccount(userId),
      staleTime: 120000,
    });

  const handleDeleteAccountButtonInTabOnClick = async () => {
    analytics.pushUIEvent({
      source: AnalyticsEventSource.MANAGE_ACCOUNT_CLOSE_ACCOUNT_TAB,
      actionSubject: 'deleteAccountButton',
      action: AnalyticsEventActions.CLICKED,
    });

    try {
      setIsCheckCanCloseAccountLoading(true);
      const data = await fetchCanCloseAccount(userId);

      if (data.errors.length > 0) {
        openDialog(ErrorModalDialogLazy, {
          content: data.errors,
        });
      } else {
        await getAccessibleSites();
        setIsFocusedTaskOpen(true);
      }
    } catch (e) {
      addFlag({
        type: 'error',
        title: formatMessage({
          id:
            'ptc-directory.settings.close-account.check-for-warnings-error.title.text',
          defaultMessage:
            'Something went wrong trying to delete your account. Please try again later.',
          description:
            'Flag title when the user cannot start the Delete Account flow',
        }),
        id: 'delete.account.check.error',
      });
    } finally {
      setIsCheckCanCloseAccountLoading(false);
    }
  };

  const openWarningModal = async () => {
    const data = await fetchCanCloseAccount(userId);

    openDialog(WarningModalDialogLazy, {
      content: data?.warnings,
      onSubmit: handleDeleteOnClick,
      isLoading: isOnDeleteUserLoading,
      hasWarnings: data && data.warnings.length > 0,
      products: productsList(),
    });
  };

  const handleDeleteButtonInTaskOnClick = () => {
    analytics.pushUIEvent({
      source: AnalyticsEventSource.DELETE_ACCOUNT_FOCUSED_TASK,
      actionSubject: 'deleteAccountButton',
      action: AnalyticsEventActions.CLICKED,
    });

    void openWarningModal();
  };

  const handleFocusedTaskClose = () => {
    // push analytics on close of Delete Account focused task
    analytics.pushUIEvent({
      source: AnalyticsEventSource.DELETE_ACCOUNT_FOCUSED_TASK,
      actionSubject: 'deleteAccountFocusedTask',
      action: AnalyticsEventActions.CLOSED,
    });
    setIsFocusedTaskOpen(false);
  };

  const handleDeleteOnClick = () => {
    void deleteUser(userId);
  };

  const productsList = () => {
    const sites = !accessibleSites ? [] : accessibleSites.sites;
    const productsList = sites
      .map(({ products = [] }) => products)
      .reduce((a, b) => a.concat(b), []);

    return [...new Set(productsList)];
  };

  const renderFinalSubmitButton = () => (
    <Button appearance="primary" onClick={handleDeleteButtonInTaskOnClick}>
      <FormattedMessage
        id="ptc-directory.settings.close-account.delete-account.button.text"
        defaultMessage="Delete account"
        description="Text for the button that deletes the user's account"
      />
    </Button>
  );

  const { avatarByAccountIdData } = useAvatarByAccountIdQuery(userId, 128);

  const renderDeleteUserOverviewScreen = () => {
    const avatarUrl = avatarByAccountIdData?.CloudUser.avatarUrl || '';
    const fullName = avatarByAccountIdData?.CloudUser.fullName || '';
    const sites = !accessibleSites
      ? []
      : accessibleSites.sites.map(({ url }) => url.replace('https://', ''));
    return (
      <DeleteUserOverviewScreen
        accessibleSites={sites}
        isCurrentUser
        user={{
          avatarUrl,
          fullName,
          email,
        }}
        deactivateUserHandler={undefined}
        isUserDeactivated={false}
      />
    );
  };

  return (
    <>
      <Button
        appearance="default"
        isLoading={isCheckCanCloseAccountLoading}
        onClick={handleDeleteAccountButtonInTabOnClick}
      >
        <FormattedMessage
          id="ptc-directory.settings.close-account.button.text"
          defaultMessage="Delete account"
          description="Text for the button that begins deletion of the user's account"
        />
      </Button>

      {isFocusedTaskOpen && (
        <FocusedTaskCloseAccount
          onClose={handleFocusedTaskClose}
          isOpen
          screens={[renderDeleteUserOverviewScreen()]}
          submitButton={renderFinalSubmitButton()}
          learnMoreLink="https://confluence.atlassian.com/x/pAkvOg"
        />
      )}
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  addFlag: flag => dispatch(addFlag(flag)),
});

const mapStateToProps = ({ user }) => ({
  userId: user.data.accountId,
  email: user.data.email,
});

interface StateProps {
  userId: string;
  email: string;
}

interface DispatchProps {
  addFlag: (flag: any) => void;
}

export default connect<StateProps, DispatchProps, CloseAccountFlowTriggerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withAnalyticsContext(CloseAccountFlowTrigger));
