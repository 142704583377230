/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Chinese (Traditional)
export default {
  'focused-task-close-account.cancel': '取消',
  'focused-task-close-account.deactivate-account': '停用帳戶',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': '您即將停用以下的使用者帳戶：',
  'focused-task-close-account.deactivate-account.overview.first.line.self': '您即將停用以下的使用者帳戶：',
  'focused-task-close-account.deactivate-account.overview.heading.admin': '停用帳戶',
  'focused-task-close-account.deactivate-account.overview.heading.self': '停用帳戶',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': '您可以隨時重新啟用此帳戶。',
  'focused-task-close-account.deactivate-account.overview.last.line.self': '您可以隨時重新啟用此帳戶。',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': '當您停用此帳戶時：',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': '當您停用此帳戶時：',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': '我們不會再向您收取其費用。',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': '我們不會再向您收取其費用。',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} 會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。他們目前可存取以下項目：',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} 會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。他們目前無法存取任何項目，社群和市集之類的服務除外。',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': '其他 Atlassian 帳戶服務，例如 Atlassian Community 和 Marketplace。',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': '您會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。您目前可存取以下項目：',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': '您會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。您目前無法存取任何項目，社群和市集之類的服務除外。',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': '其個人資料 (例如其全名和電子郵件地址)，以及其建立的內容將保留在 Atlassian 帳戶服務中。',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': '其個人資料 (例如其全名和電子郵件地址)，以及其建立的內容將保留在 Atlassian 帳戶服務中。',
  'focused-task-close-account.delete-account': '刪除帳戶',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': '請留意：回答此調查，可協助我們為所有使用者提供更完善的體驗。在您刪除帳戶後，這些使用者仍會顯示為「舊使用者」。',
  'focused-task-close-account.delete-account.content-preview.footnote.self': '請留意：回答此調查，可協助我們為所有使用者 (包含刪除帳戶的使用者) 提供更完善的體驗。在刪除帳戶後，您仍會顯示為「舊使用者」。',
  'focused-task-close-account.delete-account.content-preview.formerUser': '舊使用者',
  'focused-task-close-account.delete-account.content-preview.heading.admin': '您希望以何種方式顯示已刪除的使用者？',
  'focused-task-close-account.delete-account.content-preview.heading.self': '您希望以何種方式顯示已刪除的帳戶？',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': '如果能夠選擇，您希望以何種方式對其他使用者顯示已刪除的使用者？',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': '如果能夠選擇，您希望以何種方式對其他使用者顯示您？',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': '在使用者帳戶遭刪除後，其他使用者會看到這些使用者顯示為「舊使用者」。請花幾分鐘來回答我們的調查問題。',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': '在您的帳戶遭刪除後，其他使用者會看到您顯示為「舊使用者」。請花幾分鐘來回答我們的調查問題。',
  'focused-task-close-account.delete-account.drop-down-collapse-button': '顯示較少',
  'focused-task-close-account.delete-account.drop-down-expand-button': '還有 {num} 個',
  'focused-task-close-account.delete-account.overview.first.line.admin': '您即將刪除以下帳戶：',
  'focused-task-close-account.delete-account.overview.first.line.self': '您即將刪除您的帳戶：',
  'focused-task-close-account.delete-account.overview.heading.admin': '刪除帳戶',
  'focused-task-close-account.delete-account.overview.heading.self': '刪除帳戶',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': '例如，使用者在產品中建立的頁面、問題和留言。',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': '例如，您在產品中建立的頁面、問題和留言。',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': '您或其他使用者可能已安裝應用程式，這些應用程式會在 Atlassian 產品中新增功能。這些應用程式可能已儲存使用者的設定檔資訊。',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': '您或其他使用者可能已安裝應用程式，這些應用程式會在 Atlassian 產品中新增功能。這些應用程式可能已儲存您的設定檔資訊。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': '我們需要保留與購買相關的資訊以製作財務報告。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': '我們需要保留與購買相關的資訊以製作財務報告。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': '我們可能需要向監管機構提供顯示我們已刪除某人帳戶的記錄。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': '我們可能需要向監管機構提供顯示我們已刪除某人帳戶的記錄。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': '我們需要依法保留正在進行訴訟的資料。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': '我們需要依法保留正在進行訴訟的資料。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': '我們會將個人資料保留一段時間，以供合法業務或法律目的使用。一些範例包含：',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': '我們會將個人資料保留一段時間，以供合法業務或法律目的使用。一些範例包含：',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': '我們不會從使用者建立的內容中刪除任何個人資料，例如他們在頁面或問題中輸入的名稱或電子郵件地址。產品管理員需要手動刪除該資料。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': '我們不會從您或其他使用者建立的內容中刪除任何個人資料，例如在頁面或問題中輸入的名稱或電子郵件地址。產品管理員需要手動刪除該資料。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': '使用者有權向其國家/地區的監察機關提請投訴。',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': '您有權向您所在國家/地區的監察機關提請投訴。',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': '刪除帳戶時：',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': '刪除帳戶時：',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': '他們建立的內容會保留在 Atlassian 帳戶服務中。',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': '您建立的內容會保留在 Atlassian 帳戶服務中。',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': '在 14 天寬限期後，您就無法取消帳戶的刪除。',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': '我們會寄給您一個清單，其中列出可能儲存個人資料的應用程式。',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': '我們會寄給您一個清單，其中列出可能儲存您個人資料的應用程式。',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} 會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。他們目前可存取以下項目：',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} 會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。他們目前無法存取任何項目，社群和市集之類的服務除外。',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': '其他 Atlassian 帳戶服務，例如 Atlassian Community 和 Marketplace。',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': '您會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。您目前可存取以下項目：',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': '您會<b>立即失去</b>所有 Atlassian 帳戶服務的存取權。您目前無法存取任何項目，社群和市集之類的服務除外。',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': '我們會在 30 天內從 Atlassian 帳戶服務中<b>刪除使用者的個人資料</b>，例如全名和電子郵件地址，少數幾個合法業務或法律目的需要的情況下除外。',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': '我們會在 30 天內從 Atlassian 帳戶服務中<b>刪除您的個人資料</b>，例如全名和電子郵件地址，少數幾個合法業務或法律目的需要的情況下除外。',
  'focused-task-close-account.delete-account.overview.warning-section.body': '在 14 天寬限期後，您就無法取消帳戶刪除。如果您認為之後會需要此帳戶，請改為將其停用。',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': '在 14 天寬限期後，您就無法取消帳戶刪除。',
  'focused-task-close-account.learnMore': '了解更多',
  'focused-task-close-account.next': '下一步',
  'focused-task-close-account.previous': '上一步'
};