import * as React from 'react';

type AtlassianAccountLogoProps = { textColor: string };

const AtlassianAccountLogo = ({ textColor }: AtlassianAccountLogoProps) => {
  return (
    <svg
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 315 32"
    >
      <path fill="#fff" fillOpacity={0} d="M0 0h315v32H0z" />
      <path
        d="M213.18 21.594c1.696 0 3.328-.192 5.216-.576l1.76 4.768h3.232L215.1 4.762h-3.648l-8.288 21.024h3.232l1.76-4.736c1.792.352 3.424.544 5.024.544Zm0-2.56c-1.28 0-2.592-.128-4.16-.384l4.256-11.552 4.256 11.52c-1.632.256-3.008.416-4.352.416ZM236.607 22.906c-.992.352-2.016.576-3.648.576-4.192 0-5.92-2.624-5.92-5.728 0-3.104 1.696-5.728 5.856-5.728 1.504 0 2.592.288 3.616.768v-2.56c-1.248-.576-2.368-.768-3.808-.768-5.696 0-8.352 3.456-8.352 8.288 0 4.896 2.656 8.352 8.352 8.352 1.472 0 2.944-.224 3.904-.736v-2.464ZM251.044 22.906c-.992.352-2.016.576-3.648.576-4.192 0-5.92-2.624-5.92-5.728 0-3.104 1.696-5.728 5.856-5.728 1.504 0 2.592.288 3.616.768v-2.56c-1.248-.576-2.368-.768-3.808-.768-5.696 0-8.352 3.456-8.352 8.288 0 4.896 2.656 8.352 8.352 8.352 1.472 0 2.944-.224 3.904-.736v-2.464ZM260.842 26.106c4.768 0 7.552-3.552 7.552-8.352 0-4.8-2.784-8.288-7.552-8.288-4.8 0-7.616 3.488-7.616 8.288 0 4.8 2.816 8.352 7.616 8.352Zm0-14.08c3.392 0 4.864 2.688 4.864 5.728 0 3.04-1.472 5.792-4.864 5.792-3.424 0-4.928-2.752-4.928-5.792s1.504-5.728 4.928-5.728ZM271.272 19.162c0 4.416 2.112 6.944 5.792 6.944 2.144 0 4.032-1.056 5.056-2.944v2.624h2.752v-16h-2.752v8.896c0 3.232-1.76 4.928-4.32 4.928-2.624 0-3.776-1.28-3.776-4.16V9.786h-2.752v9.376ZM302.629 16.41c0-4.416-2.112-6.944-5.792-6.944-2.144 0-4.032 1.056-5.056 2.944V9.786h-2.752v16h2.752V16.89c0-3.232 1.76-4.928 4.32-4.928 2.624 0 3.776 1.28 3.776 4.16v9.664h2.752V16.41ZM310.275 20.826v-8.48h4.256v-2.56h-4.256V6.394h-2.688v3.392h-2.592v2.56h2.592v8.544c0 2.976 1.664 4.992 5.088 4.992.832 0 1.376-.128 1.856-.256V22.97c-.48.096-1.088.224-1.728.224-1.696 0-2.528-.96-2.528-2.368Z"
        fill={textColor}
      />
      <path
        d="M106.571 11.723c0 2.888 1.34 5.18 6.578 6.19 3.125.653 3.779 1.16 3.779 2.202 0 1.013-.654 1.667-2.856 1.667-2.562 0-5.597-.863-7.591-2.055v4.703c1.577.772 3.661 1.638 7.53 1.638 5.479 0 7.652-2.44 7.652-6.072 0-3.423-1.814-5.03-6.937-6.132-2.828-.626-3.514-1.25-3.514-2.145 0-1.132 1.013-1.606 2.889-1.606 2.263 0 4.494.686 6.61 1.638V7.257c-1.487-.744-3.779-1.34-6.459-1.34-5.059 0-7.681 2.206-7.681 5.806ZM176.942 6.215v19.558h4.168V10.86l1.757 3.96 5.895 10.953H194V6.215h-4.167v12.624l-1.578-3.664-4.735-8.96h-6.578ZM150.436 6.215h-4.555v19.558h4.555V6.215ZM140.625 20c0-3.424-1.814-5.03-6.937-6.133-2.828-.625-3.514-1.25-3.514-2.145 0-1.131 1.013-1.605 2.888-1.605 2.264 0 4.495.686 6.611 1.638V7.257c-1.488-.744-3.78-1.34-6.46-1.34-5.062 0-7.681 2.202-7.681 5.806 0 2.888 1.34 5.18 6.578 6.19 3.126.653 3.78 1.16 3.78 2.202 0 1.013-.654 1.667-2.856 1.667-2.562 0-5.598-.863-7.591-2.055v4.702c1.577.773 3.66 1.639 7.53 1.639 5.478.003 7.652-2.436 7.652-6.068ZM71.19 6.215v19.558h9.363l1.473-4.225h-6.251V6.215H71.19ZM52.693 6.215v4.229h5.062v15.33h4.584v-15.33h5.417V6.215H52.693ZM46.046 6.215H40.04l-6.822 19.558h5.21l.966-3.294c1.157.337 2.382.524 3.646.524 1.268 0 2.49-.187 3.647-.524l.966 3.294h5.21L46.045 6.215Zm-3.003 12.74c-.87 0-1.71-.126-2.508-.36l2.508-8.54 2.507 8.54a8.908 8.908 0 0 1-2.507.36ZM96.691 6.215h-6.007l-6.819 19.558h5.21l.966-3.294c1.157.337 2.382.524 3.647.524 1.268 0 2.49-.187 3.646-.524l.966 3.294h5.21L96.691 6.215Zm-3.003 12.74c-.87 0-1.71-.126-2.508-.36l2.508-8.54 2.507 8.54a8.907 8.907 0 0 1-2.507.36ZM166.847 6.215h-6.007l-6.818 19.558h5.209l.966-3.294c1.157.337 2.382.524 3.647.524 1.268 0 2.489-.187 3.646-.524l.967 3.294h5.209l-6.819-19.558Zm-3.003 12.74a8.91 8.91 0 0 1-2.508-.36l2.508-8.54 2.507 8.54a8.903 8.903 0 0 1-2.507.36Z"
        fill="#0052CC"
      />
      <path
        d="M7.056 12.98c-.356-.381-.91-.36-1.15.126L.076 24.764a.698.698 0 0 0 .624 1.01h8.12c.266 0 .5-.134.625-.385 1.746-3.622.686-9.125-2.39-12.41Z"
        fill="url(#a)"
      />
      <path
        d="M11.335 2.375c-3.263 5.166-3.047 10.889-.899 15.182 2.149 4.293 3.798 7.595 3.916 7.828.119.237.36.384.625.384h8.12a.7.7 0 0 0 .625-1.009L12.524 2.364c-.252-.485-.873-.489-1.19.01Z"
        fill="#2684FF"
      />
      <defs>
        <linearGradient
          id="a"
          x1={10.252}
          y1={14.779}
          x2={4.097}
          y2={25.439}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052CC" />
          <stop offset={0.923} stopColor="#2684FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AtlassianAccountLogo;
