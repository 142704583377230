import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-next';
import Select, { OptionProps, components } from '@atlaskit/select';

import * as Styled from '../AccountPreferencesSettings.styled';

import { LoadingPlaceHolder } from '../../Loading';
import { Timezone, timezoneOptions } from './Timezones';
import { useAccountPreferenceSettings } from '../hooks/useAccountPreferences';
import { useAccountPreferencesMutation } from '../hooks/useAccountPreferencesMutation';

const Option = (
  props: React.PropsWithChildren<OptionProps<Timezone, false>>,
) => (
  <components.Option {...props}>
    <Styled.TimezoneOption>
      <Styled.TimezoneName>{props.label}</Styled.TimezoneName>
      <Styled.TimezoneOffset>{props.value.offset}</Styled.TimezoneOffset>
    </Styled.TimezoneOption>
  </components.Option>
);

export type TimezoneProps = {
  inputId?: string;
  userId: string;
  onMutationSuccess?: () => void;
  onMutationError?: (e: Error) => void;
};

export const TimezoneDropdown = ({
  inputId,
  userId,
  onMutationSuccess,
  onMutationError,
}: TimezoneProps) => {
  const { defaultValue, isError, isLoading } = useAccountPreferenceSettings(
    'timezone',
    userId,
  );
  const { mutate: onChange } = useAccountPreferencesMutation({
    userId,
    onMutationSuccess,
    onMutationError,
  });

  const defaultTimezone = useMemo(
    () => timezoneOptions.find(option => option.value.zone === defaultValue),
    [defaultValue],
  );

  return (
    <Styled.DropdownWrapper>
      {isError && (
        <Styled.ErrorField>
          <FormattedMessage
            id="ptc-directory.manage-account.account-preferences.location.timezone.error"
            defaultMessage="Unable to load your time zone."
            description="An error message shows when cannot load timezone in Account Preferences page"
          />
        </Styled.ErrorField>
      )}

      {isLoading && <LoadingPlaceHolder data-testid="TimezoneDropdownLoader" />}

      {!isError && !isLoading && (
        <Select
          inputId={inputId}
          placeholder={
            <FormattedMessage
              id="pf-home.profile-page.timezone.placeholder"
              defaultMessage="Your time zone"
              description="Placeholder text to display in the time zone input when it is empty"
            />
          }
          options={timezoneOptions}
          onChange={option =>
            option && onChange({ timezone: option.value.zone })
          }
          components={{ Option }}
          defaultValue={defaultTimezone}
        />
      )}
    </Styled.DropdownWrapper>
  );
};

export default TimezoneDropdown;
