/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Ukrainian (Ukraine)
export default {
  'focused-task-close-account.cancel': 'Скасувати',
  'focused-task-close-account.deactivate-account': 'Деактивувати обліковий запис',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Буде деактивовано обліковий запис такого користувача:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Буде деактивовано обліковий запис такого користувача:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Деактивація облікового запису',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Деактивація облікового запису',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Обліковий запис можна буде повторно активувати в будь-який час.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Обліковий запис можна буде повторно активувати в будь-який час.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Далі описано наслідки деактивації цього облікового запису.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Далі описано наслідки деактивації цього облікового запису.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Рахунок за них не виставлятиметься.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Рахунок за них не виставлятиметься.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>відразу втратить доступ</b> до всіх служб облікового запису Atlassian. Наразі цей користувач має доступ до таких служб:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>відразу втратить доступ</b> до всіх служб облікового запису Atlassian. Наразі цей користувач не має доступу до жодних служб, окрім Спільноти та Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Інші служби облікового запису Atlassian, як-от Спільнота Atlassian і Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Ви <b>відразу втратите доступ</b> до всіх служб облікового запису Atlassian. Наразі ви маєте доступ до таких служб:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Ви <b>відразу втратите доступ</b> до всіх служб облікового запису Atlassian. Наразі цей ви не маєте доступу до жодних служб, окрім Спільноти та Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Особисті дані користувача, такі як повне ім’я та адреса електронної пошти, а також створений контент зберігатимуться в службах облікових записів Atlassian.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Особисті дані користувача, такі як повне ім’я та адреса електронної пошти, а також створений контент зберігатимуться в службах облікових записів Atlassian.',
  'focused-task-close-account.delete-account': 'Видалити обліковий запис',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Примітка. Відповіді в цьому опитуванні допоможуть нам краще обслуговувати всіх користувачів. Користувач і надалі відображатиметься як «Колишній користувач» після видалення його облікового запису.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Примітка. Відповіді в цьому опитуванні допоможуть нам краще обслуговувати всіх користувачів, зокрема й тих, що видаляють свої облікові записи. Після видалення облікового запису ви й надалі відображатиметеся як «Колишній користувач».',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Колишній користувач',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Як би ви хотіли, щоб відображався видалений користувач?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Як би ви хотіли, щоб відображався ваш видалений обліковий запис?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Який варіант відображення видаленого користувача іншим користувачам ви б вибрали, якби мали таку можливість?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Який варіант відображення вашого облікового запису іншим користувачам ви б вибрали, якби мали таку можливість?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Після видалення облікового запису користувач відображатиметься іншим як «Колишній користувач». Приділіть хвилинку, щоб пройти опитування.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Після видалення облікового запису ви відображатиметесь іншим як «Колишній користувач». Приділіть хвилинку, щоб пройти опитування.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Показати менше',
  'focused-task-close-account.delete-account.drop-down-expand-button': 'Ще {num}',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Буде видалено обліковий запис такого користувача:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Буде видалено ваш обліковий запис:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Видалення облікового запису',
  'focused-task-close-account.delete-account.overview.heading.self': 'Видалення вашого облікового запису',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Це, наприклад, сторінки, проблеми й коментарі, створені користувачем у продуктах.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Це, наприклад, сторінки, проблеми й коментарі, створені вами в продуктах.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Ви або інші користувачі могли встановити додатки, які додають певні функції до продуктів Atlassian. Ці додатки могли зберігати інформацію з профілю користувача.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Ви або інші користувачі могли встановити додатки, які додають певні функції до продуктів Atlassian. Ці додатки могли зберігати інформацію з вашого профілю.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Інформація, пов’язана з покупками, яка нам потрібна для фінансової звітності.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Інформація, пов’язана з покупками, яка нам потрібна для фінансової звітності.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Записи про те, що ми видалили обліковий запис користувача, які нам, можливо, потрібно буде надати контрольним органам.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Записи про те, що ми видалили обліковий запис користувача, які нам, можливо, потрібно буде надати контрольним органам.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Дані, задіяні в поточному судовому процесі, які нам потрібно зберігати відповідно до вимог законодавства.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Дані, задіяні в поточному судовому процесі, які нам потрібно зберігати відповідно до вимог законодавства.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Ми зберігаємо персональні дані протягом обмеженого часу для законних юридичних або бізнес-цілей. До них належать, наприклад, такі:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Ми зберігаємо персональні дані протягом обмеженого часу для законних юридичних або бізнес-цілей. До них належать, наприклад, такі:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Ми не видаляємо персональні дані з вмісту, створеного користувачами, як-от імена або електронні адреси, введені на сторінці або в даних проблеми. Адміністратори продуктів повинні видаляти такі дані вручну.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Ми не видаляємо персональні дані з вмісту, створеного вами або іншими користувачами, як-от імена або електронні адреси, введені на сторінці або в даних проблеми. Адміністратори продуктів повинні видаляти такі дані вручну.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Користувачі мають право подавати скарги до органів наглядового контролю своєї країни.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Ви маєте право подавати скарги до органів наглядового контролю своєї країни.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Далі описано наслідки видалення цього облікового запису.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Далі описано наслідки видалення вашого облікового запису.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Створений користувачем вміст залишиться в службах облікових записів Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Створений вами вміст залишиться в службах облікових записів Atlassian.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Після 14-денного періоду очікування скасувати видалення облікового запису буде неможливо.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Ми надішлемо вам список додатків, де могли зберігатися персональні дані користувача.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Ми надішлемо вам список додатків, де могли зберігатися ваші персональні дані.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>відразу втратить доступ</b> до всіх служб облікових записів Atlassian. Наразі цей користувач має доступ до таких служб:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>відразу втратить доступ</b> до всіх служб облікових записів Atlassian. Наразі цей користувач не має доступу до жодних служб, окрім Спільноти та Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Інші служби облікових записів Atlassian, як-от Спільнота Atlassian і Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Ви <b>відразу втратите доступ</b> до всіх служб облікових записів Atlassian. Наразі ви маєте доступ до таких служб:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Ви <b>відразу втратите доступ</b> до всіх служб облікових записів Atlassian. Наразі цей ви не маєте доступу до жодних служб, окрім Спільноти та Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Ми <b>видалимо персональні дані користувача</b>, як-от його повне ім’я й електронну адресу, зі служб облікових записів Atlassian протягом 30 днів, окрім випадків, коли ці дані потрібні для законних юридичних або бізнес-цілей.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Ми <b>видалимо ваші персональні дані</b>, як-от повне ім’я й електронну адресу, зі служб облікового запису Atlassian протягом 30 днів, окрім випадків, коли ці дані потрібні для законних юридичних або бізнес-цілей.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Після 14-денного періоду очікування скасувати видалення облікового запису буде неможливо. Якщо ви вважаєте, що обліковий запис знадобиться вам пізніше, деактивуйте його.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Після 14-денного періоду очікування скасувати видалення облікового запису буде неможливо.',
  'focused-task-close-account.learnMore': 'Докладніше',
  'focused-task-close-account.next': 'Далі',
  'focused-task-close-account.previous': 'Назад'
};