import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

interface HeightProp {
  height: number;
}

const roundTopCorners = css({
  borderRadius: `${token('border.radius.100', '3px')} ${token(
    'border.radius.100',
    '3px',
  )} 0 0`,
});

export const Header = styled.header`
  height: ${({ height }: HeightProp) => height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

export const LoadingSpinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownTrigger = styled.div`
  display: flex;

  &:focus {
    height: ${token('space.400', '32px')};
    outline: none;
    box-shadow: 0 0 0 2px ${token('color.border.focused', colors.B100)};
    border-radius: ${token('border.radius.100', '3px')};
  }
`;

export const Backdrop = styled.div`
  width: 100%;
  height: ${({ height }: HeightProp) => height}px;
  background: ${token('color.background.accent.gray.subtlest', colors.N20)};
  outline: none;

  position: absolute;
  top: 0;
  left: 0;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(
      to top,
      rgba(9, 30, 66, 0.2) 0px,
      rgba(9, 30, 66, 0.05) 1px,
      rgba(9, 30, 66, 0.02) 2px,
      rgba(0, 0, 0, 0) 3px
    );
  }
`;

interface ProfileHeaderImageProps {
  hasRoundCorners: boolean;
  isUploading: boolean;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Image = styled.img<ProfileHeaderImageProps & HeightProp>`
  width: 100%;
  height: ${({ height }) => height}px;
  object-fit: cover;
  vertical-align: top;

  animation-name: ${fadeIn};
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;

  ${props => props.hasRoundCorners && roundTopCorners};

  ${props =>
    props.isUploading &&
    css`
      filter: opacity(50%);
    `};
`;

interface HoverOverlayProps {
  isVisible: boolean;
  height: number;
  hasRoundCorners: boolean;
}

export const HoverOverlay = styled.div<HoverOverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height}px;
  background: ${token('color.background.accent.gray.bolder', colors.N100A)};
  color: ${token('color.text.inverse', colors.N0)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;

  ${props => props.hasRoundCorners && roundTopCorners}
  ${props =>
    props.isVisible
      ? css`
          opacity: 1;
          pointer-events: unset;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `};
`;
