import { UserDetails } from '../../../../../utils/user';
import { useManageProfileUser } from '../../../hooks/useManageProfileUser';

export const useAccountPreferenceSettings = (
  propPath: keyof Omit<UserDetails, 'localTime'>,
  userId: string,
) => {
  const { isError, isLoading, user } = useManageProfileUser(userId);

  return {
    defaultValue: user && user[propPath],
    isLoading,
    isError,
  };
};
