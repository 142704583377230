import React, { useEffect, useRef } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import * as H from 'history';

import { FeatureFlag, getFeatureFlagValue } from '../../../common';

// User Profile routes
import UserSettingsLayout from '../components/UserSettingsLayout';
// Settings Routes
import EmailSettings from './settings/Email';
import SecuritySettings from './settings/Security';
import TwoStepVerification from './settings/TwoStepVerification';
import ApiTokens from './settings/ApiTokens';
import RecentDevices from './settings/RecentDevices';
import PrivacySettings from './settings/Privacy';
import AccountPreferencesSettings from '../components/AccountPreferencesSettings';
import ConnectedAppSettings from './settings/ConnectedApps';
import LinkPreferencesSettings from './settings/LinkPreferences';
import CloseAccountSettings from './settings/CloseAccount';
import ProfileAndVisibilitySettings from './settings/ProfileAndVisibility';
import ProductsSettings from './settings/Products';

const getLocationHashAndSearch = ({ hash, search }: H.Location) =>
  `${hash}${search}`;

const PATH_ROOT = '/manage-profile';

const RedirectUnknownSettings = () => <Redirect to={PATH_ROOT} />;

const RootSettingsRedirect = ({ location }: RouteComponentProps) => {
  const hashAndSearch = getLocationHashAndSearch(location);

  return (
    <Redirect to={`${PATH_ROOT}/profile-and-visibility${hashAndSearch}`} />
  );
};

type RouteComponent =
  | React.ComponentType<RouteComponentProps>
  | React.ComponentType;

export interface RouteObject {
  exact?: boolean;
  path: string;
  component: RouteComponent | null;
  features?: { [K in FeatureFlag]?: boolean };
}

export const allRoutes: RouteObject[] = [
  {
    exact: true,
    path: PATH_ROOT,
    component: RootSettingsRedirect,
  },
  {
    path: `${PATH_ROOT}/profile-and-visibility`,
    component: ProfileAndVisibilitySettings,
  },
  {
    path: `${PATH_ROOT}/email`,
    component: EmailSettings,
  },
  {
    path: `${PATH_ROOT}/security/two-step-verification`,
    component: TwoStepVerification,
  },
  {
    path: `${PATH_ROOT}/security/api-tokens`,
    component: ApiTokens,
  },
  {
    path: `${PATH_ROOT}/security/recent-devices`,
    component: RecentDevices,
  },
  {
    path: `${PATH_ROOT}/security`,
    component: SecuritySettings,
  },
  {
    path: `${PATH_ROOT}/privacy`,
    component: PrivacySettings,
  },
  {
    path: `${PATH_ROOT}/account-preferences`,
    component: AccountPreferencesSettings,
  },
  {
    path: `${PATH_ROOT}/apps`,
    component: ConnectedAppSettings,
  },
  {
    path: `${PATH_ROOT}/link-preferences`,
    component: LinkPreferencesSettings,
  },
  {
    path: `${PATH_ROOT}/products`,
    component: ProductsSettings,
  },
  {
    path: `${PATH_ROOT}/close-account`,
    component: CloseAccountSettings,
  },
  {
    path: `${PATH_ROOT}/*`,
    component: RedirectUnknownSettings,
  },
];

export const getRoutes = (routes: RouteObject[]) => {
  return routes.filter(route => {
    if (!route.features) {
      return true;
    }

    return Object.keys(route.features).every((featureFlagKey: FeatureFlag) => {
      if (route && route.features) {
        return (
          route.features[featureFlagKey] === getFeatureFlagValue(featureFlagKey)
        );
      }
    });
  });
};

export const ManageProfileRoutes = ({ location }: RouteComponentProps) => {
  const previousPathnameRef = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== previousPathnameRef.current) {
      window.scrollTo(0, 0);
      previousPathnameRef.current = location.pathname;
    }
  }, [location.pathname]);

  return (
    <UserSettingsLayout>
      <Switch>
        {getRoutes(allRoutes).map((route: RouteObject, idx: number) => {
          return (
            <Route
              key={idx}
              exact={route.exact}
              path={route.path}
              component={route.component || undefined}
            />
          );
        })}
      </Switch>
    </UserSettingsLayout>
  );
};

export default ManageProfileRoutes;
