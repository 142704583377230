/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Finnish
export default {
  'focused-task-close-account.cancel': 'Peruuta',
  'focused-task-close-account.deactivate-account': 'Poista tili käytöstä',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Olet poistamassa käytöstä seuraavan henkilön tilin:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Olet poistamassa käytöstä seuraavan henkilön tilin:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Poista tili käytöstä',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Poista tili käytöstä',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Voit ottaa tilin uudelleen käyttöön milloin tahansa.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Voit ottaa tilin uudelleen käyttöön milloin tahansa.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Kun poistat tilin käytöstä tapahtuu seuraavaa:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Kun poistat tilin käytöstä tapahtuu seuraavaa:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Emme enää laskuta sinua hänestä.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Emme enää laskuta sinua hänestä.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} <b>menettää heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä hänellä on pääsy seuraaviin kohteisiin:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>menettää heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä hänellä ei ole pääsyä mihinkään palveluihin, paitsi Yhteisön ja Marketplacen kaltaisiin palveluihin.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Muut Atlassian-tilipalvelut, kuten Atlassian-Yhteisö ja Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Menetät <b>heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä sinulla on pääsy seuraaviin kohteisiin:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Menetät <b>heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä sinulla ei ole pääsyä mihinkään palveluihin, paitsi Yhteisön ja Marketplacen kaltaisiin palveluihin.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Hänen henkilötietonsa, kuten hänen koko nimensä ja sähköpostiosoitteensa, sekä hänen luomansa sisältö pysyvät Atlassian-tilipalveluissa.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Hänen henkilötietonsa, kuten hänen koko nimensä ja sähköpostiosoitteensa, sekä hänen luomansa sisältö pysyvät Atlassian-tilipalveluissa.',
  'focused-task-close-account.delete-account': 'Poista tili',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Huomaa: Vastaamalla tähän kyselyyn autat meitä luomaan paremman kokemuksen kaikille käyttäjille. Käyttäjä näkyy edelleen "Entisenä käyttäjänä" sen jälkeen, kun olet poistanut hänen tilinsä.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Huomaa: Vastaamalla tähän kyselyyn autat meitä luomaan paremman kokemuksen kaikille käyttäjille, myös niille, jotka ovat poistamassa tilinsä. Näyt edelleen "Entisenä käyttäjänä" sen jälkeen, kun olet poistanut tilisi.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Entinen käyttäjä',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Miltä haluat poistetun käyttäjän näyttävän?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Miltä haluat poistetun tilisi näyttävän?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Jos saisit valita, miltä haluaisit poistetun käyttäjän näyttävän muille käyttäjille?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Jos saisit valita, miten haluaisit näkyä muille käyttäjille?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Sen jälkeen, kun käyttäjän tili on poistettu, hän näkyy "Entisenä käyttäjänä" muille käyttäjille. Käytä hetki kyselymme kysymykseen vastaamiseen.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Sen jälkeen, kun tilisi on poistettu, näyt "Entisenä käyttäjänä" muille käyttäjille. Käytä hetki kyselymme kysymykseen vastaamiseen.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Näytä vähemmän',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} lisää',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Olet poistamassa seuraavan henkilön tilin:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Olet poistamassa tilisi:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Poista tili',
  'focused-task-close-account.delete-account.overview.heading.self': 'Poista tilisi',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Esimerkiksi, sivut, asiat ja kommentit, jotka hän on luonut tuotteissa.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Esimerkiksi, sivut, asiat ja kommentit, jotka olet luonut tuotteissa.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Sinä tai muut käyttäjät olette ehkä asentaneet sovelluksia, jotka lisäävät ominaisuuksia Atlassian-tuotteisiin. Nämä sovellukset ovat saattaneet tallentaa käyttäjän profiilitietoja.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Sinä tai muut käyttäjät olette ehkä asentaneet sovelluksia, jotka lisäävät ominaisuuksia Atlassian-tuotteisiin. Nämä sovellukset ovat saattaneet tallentaa sinun profiilitietojasi.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Ostoksiin liittyvät tiedot, jotka meidän tulee säilyttää talousraportteja varten.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Ostoksiin liittyvät tiedot, jotka meidän tulee säilyttää talousraportteja varten.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Kirjanpidossa näkyy, että poistimme jonkun henkilön tilin, ja meidän tulee ehkä todistaa tämä valvojille.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Kirjanpidossa näkyy, että poistimme jonkun henkilön tilin, ja meidän tulee ehkä todistaa tämä valvojille.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Käynnissä olevaan oikeusjuttuun liittyvät tiedot, joita meidän tulee säilyttää lain mukaan.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Käynnissä olevaan oikeusjuttuun liittyvät tiedot, joita meidän tulee säilyttää lain mukaan.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Säilytämme henkilötietoja rajoitetun ajanjakson ajan laillisia liiketoiminnallisia tai oikeudellisia tarkoituksia varten. Esimerkkejä näistä:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Säilytämme henkilötietoja rajoitetun ajanjakson ajan laillisia liiketoiminnallisia tai oikeudellisia tarkoituksia varten. Esimerkkejä näistä:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Emme poista mitään henkilötietoja käyttäjien luomasta sisällöstä, kuten nimiä tai sähköpostiosoitteita, joita he kirjoittivat sivulle tai asiaan. Tuotteen pääkäyttäjien tulee poistaa nämä tiedot manuaalisesti.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Emme poista mitään henkilötietoja sinun tai muiden luomasta sisällöstä, kuten nimiä tai sähköpostiosoitteita, joita he kirjoittivat sivulle tai asiaan. Tuotteen pääkäyttäjiesi tulee poistaa nämä tiedot manuaalisesti.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Käyttäjillä on oikeus jättää valituksia maansa valvontaviranomaisille.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Sinulla on oikeus jättää valituksia maasi valvontaviranomaisille.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Kun poistat tilin tapahtuu seuraavaa:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Kun poistat tilisi tapahtuu seuraavaa:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Hänen luomansa sisältö pysyy Atlassian-tilipalveluissa.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Luomasi sisältö pysyy Atlassian-tilipalveluissa.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': '14 päivän lisäajan jälkeen et voi peruuttaa tilin poistamista.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Lähetämme sinulle sähköpostitse luettelon sovelluksista, jotka ovat saattaneet tallentaa hänen henkilötietojaan.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Lähetämme sinulle sähköpostitse luettelon sovelluksista, jotka ovat saattaneet tallentaa sinun henkilötietojasi.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} <b>menettää heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä hänellä on pääsy seuraaviin kohteisiin:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} <b>menettää heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä hänellä ei ole pääsyä mihinkään palveluihin, paitsi Yhteisön ja Marketplacen kaltaisiin palveluihin.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Muut Atlassian-tilipalvelut, kuten Atlassian-Yhteisö ja Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Menetät <b>heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä sinulla on pääsy seuraaviin kohteisiin:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Menetät <b>heti pääsyn</b> kaikkiin Atlassian-tilipalveluihin. Tällä hetkellä sinulla ei ole pääsyä mihinkään palveluihin, paitsi Yhteisön ja Marketplacen kaltaisiin palveluihin.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Poistamme <b>hänen henkilötietonsa</b>, kuten koko nimen ja sähköpostiosoitteen, Atlassian-tilipalveluista 30 päivän sisällä, paitsi muutamassa tapauksessa, kun niitä tarvitaan laillisiin liiketoiminnallisiin tai oikeudellisiin tarkoituksiin.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Poistamme <b>henkilötietosi</b>, kuten koko nimesi ja sähköpostiosoitteesi, Atlassian-tilipalveluista 30 päivän sisällä, paitsi muutamassa tapauksessa, kun niitä tarvitaan laillisiin liiketoiminnallisiin tai oikeudellisiin tarkoituksiin.',
  'focused-task-close-account.delete-account.overview.warning-section.body': '14 päivän lisäajan jälkeen et voi peruuttaa tilin poistamista. Jos luulet, että tarvitset tiliä myöhemmin, poista se sen sijaan käytöstä.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': '14 päivän lisäajan jälkeen et voi peruuttaa tilin poistamista.',
  'focused-task-close-account.learnMore': 'Lue lisää',
  'focused-task-close-account.next': 'Seuraava',
  'focused-task-close-account.previous': 'Edellinen'
};