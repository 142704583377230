import React, { useContext } from 'react';

import WidthDetector from '@atlaskit/width-detector';

export interface DeviceContext {
  isMobile: boolean | null;
}

export const DeviceContext = React.createContext<DeviceContext>({
  isMobile: null,
});

export const useDeviceContext = (): DeviceContext => useContext(DeviceContext);

export const DeviceContextConsumer: React.ComponentType<{
  children: (deviceContext: DeviceContext) => React.ReactElement<unknown>;
}> = ({ children }) => children(useDeviceContext());

export const DeviceContextProvider: React.ComponentType<{
  children: React.ReactNode;
  mobileWidthThreshold?: number;
}> = ({ children, mobileWidthThreshold = 980 }) => (
  <WidthDetector>
    {width => (
      <DeviceContext.Provider
        value={{
          isMobile: width ? width < mobileWidthThreshold : null,
        }}
      >
        {children}
      </DeviceContext.Provider>
    )}
  </WidthDetector>
);
