import { gql } from 'graphql-request';

export const USER_QUERY = gql`
  query User($userId: ID!) {
    user(accountId: $userId) {
      id
      name
      picture
      accountStatus
      ... on AtlassianAccountUser {
        email
      }
    }
  }
`;

export type UserQueryData = {
  id: string;
  name: string;
  picture: string;
  accountStatus: string;
  email: string;
};

type UserQueryInput = {
  userId: string;
  url: string;
};

type UserResponse = {
  data: {
    user: UserQueryData;
  };
};

const queryUserFromAGG = async ({
  userId,
  url,
}: UserQueryInput): Promise<UserQueryData> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      query: USER_QUERY,
      variables: {
        userId,
      },
    }),
  });

  const {
    data: { user },
  } = (await response.json()) as UserResponse;

  // The AGG User query returns an id in the format "ari:cloud:identity::user/712020:123456-1234-440e-a377-8c7657b123456".
  // Given the potential instability of the prefix format and the requirement to use only the last part of the id,
  // it's safer to directly use `userId` as the `id` to ensure consistency and reliability.
  return { ...user, id: userId };
};

export default queryUserFromAGG;
