/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Swedish
export default {
  'focused-task-close-account.cancel': 'Avbryt',
  'focused-task-close-account.deactivate-account': 'Inaktivera konto',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Du är på väg att inaktivera användarkontot för:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Du är på väg att inaktivera användarkontot för:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Inaktivera konto',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Inaktivera konto',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Du kan när som helst återaktivera kontot.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Du kan när som helst återaktivera kontot.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'När du inaktiverar kontot:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'När du inaktiverar kontot:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Vi kommer inte längre att ta betalt för dem.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Vi kommer inte längre att ta betalt för dem.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. För närvarande ges åtkomst till:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. I nuläget har de inte åtkomst till några, med undantag av tjänster så som Community och Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Andra Atlassian-kontotjänster, så som Atlassian Community och Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Du kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. Du har har för närvarande åtkomst till:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Du kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. I nuläget har du inte åtkomst till några, med undantag av tjänster så som Community och Marketplace.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Deras personuppgifter, till exempel deras fullständiga namn och e-postadress, och innehåll som de har skapat kommer att finnas kvar i Atlassian-kontotjänster.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Deras personuppgifter, till exempel deras fullständiga namn och e-postadress, och innehåll som de har skapat kommer att finnas kvar i Atlassian-kontotjänster.',
  'focused-task-close-account.delete-account': 'Ta bort konto',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Obs: Genom att svara på enkäten hjälper du oss att skapa en bättre upplevelse för alla användare. Användaren kommer fortfarande att visas som "Före detta användare" efter att du har tagit bort deras konto.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Obs: Genom att svara på enkäten hjälper du oss att skapa en bättre upplevelse för alla användare, inklusive dem som tar bort sina konton. Du kommer fortfarande att visas som "Före detta användare" efter att du har tagit bort ditt konto.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Före detta användare',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Hur vill du att den borttagna användaren ska visas?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Hur vill du att det borttagna kontot ska visas?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Om du fick välja, hur skulle du vilja att den borttagna användaren ska visas för andra användare?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Om du fick välja, hur skulle du vilja att den ska visas för andra användare?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'När användarens konto har tagits bort visas det som "Före detta användare" för andra användare. Ta en stund och svara på vår enkät.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'När ditt konto har tagits bort visas det som "Före detta användare" för andra användare. Ta en stund och svara på vår enkät.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Visa mindre',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} mer',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Du är på väg att ta bort kontot för:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Du är på väg att ta bort ditt konto:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Ta bort konto',
  'focused-task-close-account.delete-account.overview.heading.self': 'Ta bort ditt konto',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Till exempel sidor, ärenden och kommentarer som de har skapat i produkter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Till exempel sidor, ärenden och kommentarer som du har skapat i produkter.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Du eller andra användare kan ha installerat appar som lägger till funktioner till Atlassian-produkter. Dessa appar kan ha lagrat användarens profilinformation.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Du eller andra användare kan ha installerat appar som lägger till funktioner till Atlassian-produkter. Dessa appar kan ha lagrat din profilinformation.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Information relaterat till köp som vi måste spara för redovisning och finansiell rapportering.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Information relaterat till köp som vi måste spara för redovisning och finansiell rapportering.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Register som visar att vi har raderat någons konto, vilket tillsynsmyndigheter kan begära ut.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Register som visar att vi har raderat någons konto, vilket tillsynsmyndigheter kan begära ut.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Data som är del i en pågående rättegång, som vi är skyldiga att spara enligt lag.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Data som är del i en pågående rättegång, som vi är skyldiga att spara enligt lag.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'Vi sparar personuppgifter under begränsade tidsperioder när vi har legitima verksamhetsskäl eller juridiska skäl. Några exempel är:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'Vi sparar personuppgifter under begränsade tidsperioder när vi har legitima verksamhetsskäl eller juridiska skäl. Några exempel är:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Vi tar inte bort några personuppgifter från innehåll som har skapats av användare, till exempel namn eller e-postadresser som de skrivit på en sida eller ett ärende. Produktadministratörerna måste ta bort dessa data manuellt.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Vi tar inte bort några personuppgifter från innehåll som har skapats av dig eller andra personer, till exempel namn eller e-postadresser som har skrivits på en sida eller i ett ärende. Produktadministratörerna måste ta bort dessa data manuellt.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Användare har rätt att framföra klagomål till tillsynsmyndigheten i användarens land.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'Du har rätt att framföra klagomål till tillsynsmyndigheten i ditt land.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'När du tar bort kontot:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'När du tar bort ditt konto:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Innehållet som de redan har skapat kommer att finnas kvar hos Atlassian-kontotjänsterna.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Innehållet som du redan har skapat kommer att finnas kvar hos Atlassian-kontotjänsterna.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': 'Efter en 14 dagars respitperiod kan du inte längre avbryta borttagningen av kontot.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Vi kommer att mejla en lista på appar som kan ha lagrat deras personuppgifter.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Vi kommer att mejla en lista på appar som kan ha lagrat deras personuppgifter.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. De har för närvarande åtkomst till:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. I nuläget har de inte åtkomst till några, med undantag av tjänster så som Community och Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Andra Atlassian-kontotjänster, så som Atlassian Community och Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Du kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. Du har har för närvarande åtkomst till:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Du kommer <b>omedelbart att förlora åtkomst</b> till samtliga Atlassian-kontotjänster. I nuläget har du inte åtkomst till några, med undantag av tjänster så som Community och Marketplace.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'Vi kommer att <b>radera deras personuppgifter</b>, så som fullständigt namn och e-postadress från Atlassian-kontotjänsterna inom 30 dagar med några få undantag när så krävs av verksamhetsskäl eller juridiska skäl.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'Vi kommer att <b>radera dina personuppgifter</b>, så som fullständigt namn och e-postadress från Atlassian-kontotjänsterna inom 30 dagar med några få undantag när så krävs av verksamhetsskäl eller juridiska skäl.',
  'focused-task-close-account.delete-account.overview.warning-section.body': 'Efter en 14 dagars respitperiod kan du inte längre avbryta borttagningen av kontot. Om du tror att du kommer att behöva ditt konto senare bör du inaktivera det istället.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': 'Efter en 14 dagars respitperiod kan du inte längre avbryta borttagningen av kontot.',
  'focused-task-close-account.learnMore': 'Läs mer',
  'focused-task-close-account.next': 'Nästa',
  'focused-task-close-account.previous': 'Föregående'
};