import { defineMessages } from 'react-intl-next';

// Error keys from id-management service
export enum ChangeEmailErrorKeys {
  TOO_MANY_EMAIL_CHANGES = 'com.atlassian.id.profile.too.many.email.changes',
  INVALID_EMAIL = 'change-email.form.new.email.error.invalid',
  DEFAULT = 'change-email.failure',
  NO_EMAIL = 'change-email.form.new.email.error.no.email',
  NO_CHANGE = 'change-email.form.new.email.error.no.change',
  RESTRICTED_DOMAIN = 'change-email.form.new.email.error.restricted.domain',
  CURRENT_DOMAIN_RESTRICTED = 'change-email.form.current.email.error.restricted.domain',
}

export default defineMessages({
  [`flagTitle_${ChangeEmailErrorKeys.TOO_MANY_EMAIL_CHANGES}`]: {
    id: 'pf-home.profile-settings.email.flag.error.update.limit.title',
    defaultMessage: 'Daily limit reached',
    description:
      'The error title in the flag component when an daily limit error occured when the user tried to update their email address.',
  },
  [`flagMessage_${ChangeEmailErrorKeys.TOO_MANY_EMAIL_CHANGES}`]: {
    id: 'pf-home.profile-settings.email.flag.error.update.limit.message',
    defaultMessage:
      'We limit email changes to help keep your account safe. You can try again in 24 hours.',
    description:
      'The error message in the flag component when an daily limit error occured when the user tried to update their email address.',
  },

  [`flagTitle_${ChangeEmailErrorKeys.INVALID_EMAIL}`]: {
    id: 'pf-home.profile-settings.email.flag.error.update.invalid.title',
    defaultMessage: 'Email invalid',
    description:
      'The error title in the flag component when the user tried to update their email address to an invalid email address.',
  },
  [`flagMessage_${ChangeEmailErrorKeys.INVALID_EMAIL}`]: {
    id: 'pf-home.profile-settings.email.flag.error.update.invalid.message',
    defaultMessage:
      'The email you used is not a valid email address. Please try again.',
    description:
      'The error message in the flag component when the user tried to update their email address to an invalid email address.',
  },

  [`flagTitle_${ChangeEmailErrorKeys.DEFAULT}`]: {
    id: 'pf-home.profile-settings.email.flag.error.update.default.title',
    defaultMessage: 'Oops, something went wrong',
    description:
      'The error title in the flag component when an error occured while the user tried to update their email address.',
  },
  [`flagMessage_${ChangeEmailErrorKeys.DEFAULT}`]: {
    id: 'pf-home.profile-settings.email.flag.error.update.default.message',
    defaultMessage: 'We couldn’t save your email change. Please try again.',
    description:
      'The error message in the flag component when an error occured while the user tried to update their email address.',
  },

  [`flagTitle_${ChangeEmailErrorKeys.NO_EMAIL}`]: {
    id: 'pf-home.profile-settings.email.flag.error.no.email.title',
    defaultMessage: 'Email address required',
    description:
      'The error title in the flag component when the user tried to update their email address to an empty email address.',
  },
  [`flagMessage_${ChangeEmailErrorKeys.NO_EMAIL}`]: {
    id: 'pf-home.profile-settings.email.flag.error.no.email.message',
    defaultMessage: 'Please enter your email address',
    description:
      'The error title in the flag component when the user tried to update their email address to an empty email address.',
  },

  [`flagTitle_${ChangeEmailErrorKeys.NO_CHANGE}`]: {
    id: 'pf-home.profile-settings.email.flag.error.no.change.title',
    defaultMessage: 'New email address required',
    description:
      'The error title in the flag component when the user tried to update their email address to the same email address.',
  },
  [`flagMessage_${ChangeEmailErrorKeys.NO_CHANGE}`]: {
    id: 'pf-home.profile-settings.email.flag.error.no.change.message',
    defaultMessage:
      'Please enter an email address that is different to your current email address',
    description:
      'The error title in the flag component when the user tried to update their email address to the same email address.',
  },

  [`flagTitle_${ChangeEmailErrorKeys.RESTRICTED_DOMAIN}`]: {
    id: 'pf-home.profile-settings.email.flag.error.restricted.domain.title',
    defaultMessage: 'Email invalid',
    description:
      'The error title in the flag component when the user tried to update their email address to an email address that is not allowed.',
  },
  [`flagMessage_${ChangeEmailErrorKeys.RESTRICTED_DOMAIN}`]: {
    id: 'pf-home.profile-settings.email.flag.error.restricted.domain.message',
    defaultMessage:
      'The provided email address is not allowed, please use a different one',
    description:
      'The error title in the flag component when the user tried to update their email address to an email address that is not allowed.',
  },

  [`flagTitle_${ChangeEmailErrorKeys.CURRENT_DOMAIN_RESTRICTED}`]: {
    id:
      'pf-home.profile-settings.email.flag.error.current.domain.restricted.title',
    defaultMessage: 'Not allowed',
    description:
      'The error title in the flag component when the user tried to update their email address but they are not allowed.',
  },
  [`flagMessage_${ChangeEmailErrorKeys.CURRENT_DOMAIN_RESTRICTED}`]: {
    id:
      'pf-home.profile-settings.email.flag.error.current.domain.restricted.message',
    defaultMessage: 'Changing from your current email address is not allowed.',
    description:
      'The error title in the flag component when the user tried to update their email address, but they are not allowed.',
  },
});
