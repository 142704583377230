import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
/** @jsx jsx */
import { useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { BANNER_HEIGHT, DEFAULT_TOP_NAVIGATION_HEIGHT, LEFT_PANEL_WIDTH, RIGHT_PANEL_WIDTH, TOP_NAVIGATION, TOP_NAVIGATION_HEIGHT, VAR_TOP_NAVIGATION_HEIGHT } from '../../common/constants';
import { getPageLayoutSlotSelector, resolveDimension } from '../../common/utils';
import { publishGridState, useSkipLink } from '../../controllers';
import SlotFocusRing from './internal/slot-focus-ring';
import SlotDimensions from './slot-dimensions';
var topNavigationStyles = css({
  height: TOP_NAVIGATION_HEIGHT,
  gridArea: TOP_NAVIGATION
});
var fixedStyles = css({
  position: 'fixed',
  zIndex: 2,
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  top: BANNER_HEIGHT,
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  right: RIGHT_PANEL_WIDTH,
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  left: LEFT_PANEL_WIDTH
});

/**
 * __Top navigation__
 *
 * Provides a slot for top navigation within the PageLayout.
 *
 * - [Examples](https://atlassian.design/components/page-layout/examples)
 * - [Code](https://atlassian.design/components/page-layout/code)
 */
var TopNavigation = function TopNavigation(props) {
  var children = props.children,
    _props$height = props.height,
    height = _props$height === void 0 ? DEFAULT_TOP_NAVIGATION_HEIGHT : _props$height,
    _props$isFixed = props.isFixed,
    isFixed = _props$isFixed === void 0 ? true : _props$isFixed,
    shouldPersistHeight = props.shouldPersistHeight,
    testId = props.testId,
    id = props.id,
    skipLinkTitle = props.skipLinkTitle;
  var topNavigationHeight = resolveDimension(VAR_TOP_NAVIGATION_HEIGHT, height, shouldPersistHeight);
  useEffect(function () {
    publishGridState(_defineProperty({}, VAR_TOP_NAVIGATION_HEIGHT, topNavigationHeight));
    return function () {
      publishGridState(_defineProperty({}, VAR_TOP_NAVIGATION_HEIGHT, 0));
    };
  }, [topNavigationHeight]);
  useSkipLink(id, skipLinkTitle);
  return jsx(SlotFocusRing, null, function (_ref) {
    var className = _ref.className;
    return jsx("div", _extends({
      css: [topNavigationStyles, isFixed && fixedStyles],
      className: className,
      "data-testid": testId,
      id: id
    }, getPageLayoutSlotSelector('top-navigation')), jsx(SlotDimensions, {
      variableName: VAR_TOP_NAVIGATION_HEIGHT,
      value: topNavigationHeight
    }), children);
  });
};
export default TopNavigation;