import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import WorldIcon from '@atlaskit/icon/glyph/world';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import LockIcon from '@atlaskit/icon/glyph/lock';

import { FieldType } from '../../../../../../common/model/user';
import { PrivacyLevel, PrivacyOptions } from '../types';

import { getLabel, getDescription } from '../messages';

const ALL_PRIVACY_LEVELS = [
  PrivacyLevel.PUBLIC,
  PrivacyLevel.ORGANISATION,
  PrivacyLevel.PRIVATE,
];

export const generatePrivacyOptions = (
  fieldType: FieldType,
  privacyLevels: PrivacyLevel[] = ALL_PRIVACY_LEVELS,
  options: {
    isManaged: boolean;
    orgName?: string | null;
  },
): PrivacyOptions => {
  // Ensure `orgName` is always a string from here on.
  // So we do not have to modify anything beyond this point to handle `null`.
  const orgName = options.orgName || '';
  const isManaged = Boolean(options.isManaged);

  const privacyOptions = {
    [PrivacyLevel.PUBLIC]: {
      icon: <WorldIcon size="medium" label="" />,
      label: <FormattedMessage {...getLabel(fieldType, PrivacyLevel.PUBLIC)} />,
      description: (
        <FormattedMessage
          {...getDescription(fieldType, PrivacyLevel.PUBLIC, {
            orgName,
            isManaged,
          })}
          values={{
            organizationName: orgName,
          }}
        />
      ),
      value: PrivacyLevel.PUBLIC,
    },
  };
  if (privacyLevels.includes(PrivacyLevel.ORGANISATION)) {
    privacyOptions[PrivacyLevel.ORGANISATION] = {
      icon: <OfficeBuildingIcon size="medium" label="" />,
      label: orgName ? (
        orgName
      ) : (
        <FormattedMessage {...getLabel(fieldType, PrivacyLevel.ORGANISATION)} />
      ),
      description: (
        <FormattedMessage
          {...getDescription(fieldType, PrivacyLevel.ORGANISATION, {
            orgName,
            isManaged,
          })}
          values={{
            organizationName: orgName,
          }}
        />
      ),
      value: PrivacyLevel.ORGANISATION,
    };
  }
  if (privacyLevels.includes(PrivacyLevel.PRIVATE)) {
    privacyOptions[PrivacyLevel.PRIVATE] = {
      icon: <LockIcon size="medium" label="" />,
      label: (
        <FormattedMessage {...getLabel(fieldType, PrivacyLevel.PRIVATE)} />
      ),
      description: (
        <FormattedMessage
          {...getDescription(fieldType, PrivacyLevel.PRIVATE)}
        />
      ),
      value: PrivacyLevel.PRIVATE,
    };
  }

  return privacyOptions;
};
