import React, { useState } from 'react';

import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';

import {
  Action,
  ActionsWrapper,
  IconWrapper,
  MessageWrapper,
  SectionMessageContainer,
  CloseButtonWrapper,
} from './DismissibleSectionMessage.styled';

interface OwnProps {
  message: React.ReactNode;
  icon?: React.ReactNode;
  actions?: React.ReactNode[];
  testId?: string;
  onDismiss?(): void;
}

export const DismissibleSectionMessage: React.ComponentType<OwnProps> = ({
  message,
  icon,
  actions,
  testId,
  onDismiss,
}: OwnProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const hide = () => {
    setIsVisible(false);

    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <ExitingPersistence appear>
      {isVisible && (
        <FadeIn entranceDirection="left">
          {props => (
            <SectionMessageContainer data-testid={testId} {...props}>
              {icon && <IconWrapper>{icon}</IconWrapper>}
              <MessageWrapper hasIcon={!!icon}>
                {message}
                {actions && actions.length ? (
                  <ActionsWrapper>
                    {actions.map((action, i) => (
                      <Action key={i}>{action}</Action>
                    ))}
                  </ActionsWrapper>
                ) : null}
              </MessageWrapper>
              <CloseButtonWrapper>
                <Button
                  shouldFitContainer
                  appearance="subtle"
                  spacing="compact"
                  iconAfter={<CrossIcon label="dismiss" size="small" />}
                  type="button"
                  onClick={hide}
                />
              </CloseButtonWrapper>
            </SectionMessageContainer>
          )}
        </FadeIn>
      )}
    </ExitingPersistence>
  );
};
