import { FeatureGateEnvironment } from '@atlaskit/feature-gate-js-client';
import { FeatureGatesInitialization } from '@atlassian/feature-gates-react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { getEnv } from '../../config/env';
import { getConfig } from '../../config/envConfig';
import { userInfoProvider } from '../../clients/user-info-provider';

const { featureGateInitCode } = getConfig();

const environment =
  getEnv() !== 'prod'
    ? FeatureGateEnvironment.Staging
    : FeatureGateEnvironment.Production;

const featureGatesOptions = {
  targetApp: 'manage-profile-ui_web',
  apiKey: featureGateInitCode,
  environment: environment,
};

const StatSigInit = ({ children }: PropsWithChildren<{}>) => {
  const [userId, setUserId] = useState<undefined | null | string>(null);
  useEffect(() => {
    userInfoProvider
      .get()
      .then(({ userId: accountId }) => {
        setUserId(accountId);
      })
      .catch(() => {
        // If we cannot load the user, then continue render the app without Statsig able to target the user.
        setUserId(undefined);
      });
  }, []);

  // We're still loading the user, so we can't render the app yet.
  if (userId === null) {
    return null;
  }
  return (
    <FeatureGatesInitialization
      enabled
      options={featureGatesOptions}
      identifiers={{ atlassianAccountId: userId }}
    >
      {children}
    </FeatureGatesInitialization>
  );
};

export default StatSigInit;
