import get from 'lodash/get';
import { Store } from 'redux';

// TODO: replace with proper typed state when we have one
type StoreWithAnyState = Store<any>; // tslint:disable-line no-any

function getValue(store: StoreWithAnyState, objectPath: string) {
  const state = store.getState();
  return get(state, objectPath);
}

function validValue(value: string, options: { acceptNull?: boolean }) {
  const { acceptNull } = options;
  if (acceptNull) {
    return value !== undefined;
  }

  return value !== undefined && value !== null;
}

/**
 * Subscribes to a store and waits until a property under a certain key has a value.
 *
 * This is useful outside of react components where you want to wait until the store has a certain value
 * but you don't know or care about where the dispatch came from.
 */
export function whenDefined(
  store: StoreWithAnyState,
  objectPath: string,
  { timeout = 10 * 1000, acceptNull = true } = {},
  // tslint:disable-next-line no-any
): Promise<any> {
  let value = getValue(store, objectPath);
  if (validValue(value, { acceptNull })) {
    return Promise.resolve(value);
  }

  return new Promise((resolve, reject) => {
    const unsubscribe = store.subscribe(() => {
      value = getValue(store, objectPath);

      if (validValue(value, { acceptNull })) {
        unsubscribe();
        resolve(value);
      }
    });

    setTimeout(() => {
      unsubscribe();
      reject(`Subscribe timeout reached for "${objectPath}".`);
    }, timeout);
  });
}
