/**
 * If the promise throws then a default value will be returned as well as a false marker.
 *
 * @param promise The promise to be wrapped.
 * @param defaultValue The value to be returned if an error is thrown.
 */
export function promiseWithDefault<T>(
  promise: Promise<T>,
  defaultValue: T,
): Promise<{ value: T; success: boolean }> {
  return promise
    .then(value => ({ value, success: true }))
    .catch(() => ({ value: defaultValue, success: false }));
}
