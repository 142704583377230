import { metrics, PageLoadMetric } from '@atlassian/browser-metrics';

const cachedMetrics = new Map<string, PageLoadMetric>();

export const getPageLoadMetric = (pageId: string) => {
  // Convert to kebab case to comply with naming convention:
  // https://hello.atlassian.net/wiki/spaces/APD/pages/1435310934/RUNBOOK+Get+the+Experience+Event+Key#Naming-conventions

  const page =
    // we have both manage-profile-ui and aid-frontend registered under 'identity' product in performance portal, so to avoid key collisions we have added mp prefix for the events originating from manage-profile-ui
    'mp-' +
    pageId
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase();

  let metric = cachedMetrics.get(page);

  if (!metric) {
    metric = metrics.pageLoad({ key: page });
    cachedMetrics.set(page, metric);
  }

  return metric;
};
