import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const ErrorImage = styled.img`
  width: 263px;
  margin: 0 0 56px 0;
  display: flex;
`;

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 100px auto 0;
  max-width: 600px;
  flex-direction: column;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const ImageOuter = styled.div`
  flex: none;
  flex-basis: 200px;
  justify-content: center;

  @media (max-width: 640px) {
    margin-right: 0;
    flex-basis: auto;

    & img {
      max-width: 100%;
      padding: 0 24px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;

  @media (max-width: 640px) {
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 16px;
`;

export const Title = styled.h1``;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Description = styled.p`
  font-size: 1em;
  line-height: 24px;
  color: ${token('color.text', colors.N800)};

  @media (max-width: 640px) {
    text-align: center;
  }
`;

export const ErrorTextContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  width: 80%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 640px) {
    max-width: 304px;
    width: 100%;
  }
`;
