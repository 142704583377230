/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Turkish (Turkey)
export default {
  'focused-task-close-account.cancel': 'İptal',
  'focused-task-close-account.deactivate-account': 'Hesabı devre dışı bırak',
  'focused-task-close-account.deactivate-account.overview.first.line.admin': 'Şu kullanıcının hesabını devre dışı bırakmak üzeresiniz:',
  'focused-task-close-account.deactivate-account.overview.first.line.self': 'Şu kullanıcının hesabını devre dışı bırakmak üzeresiniz:',
  'focused-task-close-account.deactivate-account.overview.heading.admin': 'Hesabı devre dışı bırak',
  'focused-task-close-account.deactivate-account.overview.heading.self': 'Hesabı devre dışı bırakma',
  'focused-task-close-account.deactivate-account.overview.last.line.admin': 'Hesabı dilediğiniz zaman yeniden etkinleştirebilirsiniz.',
  'focused-task-close-account.deactivate-account.overview.last.line.self': 'Hesabı dilediğiniz zaman yeniden etkinleştirebilirsiniz.',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.admin': 'Hesabı devre dışı bıraktığınızda:',
  'focused-task-close-account.deactivate-account.overview.paragraph.about-to-deactivate.self': 'Hesabı devre dışı bıraktığınızda:',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.admin': 'Bu hesaplar artık size faturalandırılmaz.',
  'focused-task-close-account.deactivate-account.overview.paragraph.billing.self': 'Bu hesaplar artık size faturalandırılmaz.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin': '{fullName} adlı kullanıcı tüm Atlassian hesabı hizmetlerine <b>erişimini hemen kaybeder</b>. Şu anda şu hizmetlere erişebiliyor:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} adlı kullanıcı tüm Atlassian hesabı hizmetlerine <b>erişimini hemen kaybeder</b>. Şu anda Community ve Marketplace gibi hizmetler dışında hiçbir hizmete erişimi yok.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.footnote': 'Atlassian Topluluğu ve Marketplace gibi diğer Atlassian hesabı hizmetleri.',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self': 'Tüm Atlassian hesabı hizmetlerine <b>erişiminizi hemen kaybedersiniz</b>. Şu anda şu hizmetlere erişebiliyorsunuz:',
  'focused-task-close-account.deactivate-account.overview.paragraph.loseAccess.self.noSites': 'Tüm Atlassian hesabı hizmetlerine <b>erişiminizi hemen kaybedeceksiniz</b>. Şu anda Community ve Marketplace gibi hizmetler dışında hiçbir hizmete erişiminiz yok.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.admin': 'Hesapların ad, soyadı ve e-posta adresi gibi kişisel verileri ve oluşturdukları içerikler, Atlassian hesabı hizmetlerinde kalır.',
  'focused-task-close-account.deactivate-account.overview.paragraph.personal-data.self': 'Hesapların ad, soyadı ve e-posta adresi gibi kişisel verileri ve oluşturdukları içerikler, Atlassian hesabı hizmetlerinde kalır.',
  'focused-task-close-account.delete-account': 'Hesabı sil',
  'focused-task-close-account.delete-account.content-preview.footnote.admin': 'Not: Bu anketi yanıtlamanız tüm kullanıcılara daha iyi bir deneyim sunmamıza yardımcı olur. Hesabını sildiğinizde bu kullanıcıyı "Eski kullanıcı" olarak görürsünüz.',
  'focused-task-close-account.delete-account.content-preview.footnote.self': 'Not: Bu anketi yanıtlamanız, hesaplarını silenler de dahil olmak üzere tüm kullanıcılara daha iyi bir deneyim sunmamıza yardımcı olur. Hesabınızı sildiğinizde "Eski kullanıcı" olarak görünürsünüz.',
  'focused-task-close-account.delete-account.content-preview.formerUser': 'Eski kullanıcı',
  'focused-task-close-account.delete-account.content-preview.heading.admin': 'Hesabı silinen kullanıcının nasıl görünmesini tercih edersiniz?',
  'focused-task-close-account.delete-account.content-preview.heading.self': 'Silinen hesabınızın nasıl görünmesini tercih edersiniz?',
  'focused-task-close-account.delete-account.content-preview.line.survey.admin': 'Tercih imkanınız olsa diğer kullanıcıların hesabı silinen kullanıcıyı nasıl görmesini isterdiniz?',
  'focused-task-close-account.delete-account.content-preview.line.survey.self': 'Tercih imkanınız olsa diğer kullanıcıların silinen hesabınızı nasıl görmesini isterdiniz?',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.admin': 'Kullanıcının hesabı silindikten sonra diğer kullanıcılar bu hesabı "Eski kullanıcı" olarak görür. Lütfen biraz vaktinizi ayırıp anket sorumuzu yanıtlayın.',
  'focused-task-close-account.delete-account.content-preview.paragraph.survey.self': 'Hesabınız silindikten sonra diğer kullanıcılar hesabınızı "Eski kullanıcı" olarak görür. Lütfen biraz vaktinizi ayırıp anket sorumuzu yanıtlayın.',
  'focused-task-close-account.delete-account.drop-down-collapse-button': 'Daha az göster',
  'focused-task-close-account.delete-account.drop-down-expand-button': '{num} adet daha',
  'focused-task-close-account.delete-account.overview.first.line.admin': 'Şu kullanıcının hesabını silmek üzeresiniz:',
  'focused-task-close-account.delete-account.overview.first.line.self': 'Hesabınızı silmek üzeresiniz:',
  'focused-task-close-account.delete-account.overview.heading.admin': 'Hesabı silme',
  'focused-task-close-account.delete-account.overview.heading.self': 'Hesabınızı silme',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.admin': 'Örnek olarak kullanıcının ürünlerde oluşturduğu sayfalar, konular ve yorumlar verilebilir.',
  'focused-task-close-account.delete-account.overview.inline-dialog.content-created.self': 'Örnek olarak ürünlerde oluşturduğunuz sayfalar, konular ve yorumlar verilebilir.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.admin': 'Siz veya diğer kullanıcılar, Atlassian ürünlerine özellik ekleyen uygulamalar yüklemiş olabilirsiniz. Bu uygulamalar kullanıcının profil bilgilerini depolamış olabilir.',
  'focused-task-close-account.delete-account.overview.inline-dialog.data-apps.self': 'Siz veya diğer kullanıcılar, Atlassian ürünlerine özellik ekleyen uygulamalar yüklemiş olabilirsiniz. Bu uygulamalar profil bilgilerinizi depolamış olabilir.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.admin': 'Satın alımlarla ilgili, finansal raporlama için saklamamız gereken bilgiler.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li1.self': 'Satın alımlarla ilgili, finansal raporlama için saklamamız gereken bilgiler.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.admin': 'Bir kullanıcının hesabını sildiğimizi gösteren ve düzenleyicilere sağlamak zorunda olabileceğimiz kayıtlar.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li2.self': 'Bir kullanıcının hesabını sildiğimizi gösteren ve düzenleyicilere sağlamak zorunda olabileceğimiz kayıtlar.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.admin': 'Devam eden bir yargılama sürecine ait ve yasalar gereği saklamamız gereken veriler.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.li3.self': 'Devam eden bir yargılama sürecine ait ve yasalar gereği saklamamız gereken veriler.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.admin': 'İşle ilgili veya hukuki meşru amaçlarımız doğrultusunda kişisel verileri sınırlı bir süre için saklarız. Buna örnek olarak şunlar verilebilir:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p1.self': 'İşle ilgili veya hukuki meşru amaçlarımız doğrultusunda kişisel verileri sınırlı bir süre için saklarız. Buna örnek olarak şunlar verilebilir:',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.admin': 'Kullanıcılar tarafından oluşturulan içeriklerde bulunan, bir sayfaya veya konuya girilmiş adlar veya e-posta adresleri gibi kişisel verileri silmeyiz. Ürün yöneticilerinin bu verileri manuel olarak silmesi gerekir.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p2.self': 'Sizin oluşturduğunuz veya diğer kişiler tarafından oluşturulan içeriklerde bulunan, bir sayfaya veya konuya girilmiş adlar ve e-posta adresleri gibi kişisel verileri silmeyiz. Ürün yöneticilerinizin bu verileri manuel olarak silmesi gerekir.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.admin': 'Kullanıcılar dilerlerse bulundukları ülkedeki denetleyici kuruma şikayetlerini gönderebilir.',
  'focused-task-close-account.delete-account.overview.inline-dialog.personal-data-will-be-deleted.p3.self': 'İsterseniz, bulunduğunuz ülkedeki denetleyici kuruma şikayette bulunabilirsiniz.',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.admin': 'Hesabı sildiğinizde:',
  'focused-task-close-account.delete-account.overview.paragraph.about-to-delete.self': 'Hesabınızı sildiğinizde:',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.admin': 'Kullanıcının oluşturduğu içerikler Atlassian hesabı hizmetlerinden silinmez.',
  'focused-task-close-account.delete-account.overview.paragraph.content-created.self': 'Oluşturduğunuz içerikler Atlassian hesabı hizmetlerinden silinmez.',
  'focused-task-close-account.delete-account.overview.paragraph.grace-period.admin': '14 günlük mühlet sona erdikten sonra hesabın silinmesini iptal edemezsiniz.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.admin': 'Kullanıcının kişisel verilerini depolamış olabilecek uygulamaların listesini size e-posta ile göndereceğiz.',
  'focused-task-close-account.delete-account.overview.paragraph.list-of-apps-with-personal-data.self': 'Kişisel verilerinizi depolamış olabilecek uygulamaların listesini size e-posta ile göndeririz.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin': '{fullName} adlı kullanıcı tüm Atlassian hesabı hizmetlerine <b>erişimini hemen kaybeder</b>. Şu anda şu hizmetlere erişebiliyor:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.admin.noSites': '{fullName} adlı kullanıcı tüm Atlassian hesabı hizmetlerine <b>erişimini hemen kaybeder</b>. Şu anda Community ve Marketplace gibi hizmetler dışında hiçbir hizmete erişimi yok.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.footnote': 'Atlassian Topluluğu ve Marketplace gibi diğer Atlassian hesabı hizmetleri.',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self': 'Tüm Atlassian hesabı hizmetlerine <b>erişiminizi hemen kaybedersiniz</b>. Şu anda şu hizmetlere erişebiliyorsunuz:',
  'focused-task-close-account.delete-account.overview.paragraph.loseAccess.self.noSites': 'Tüm Atlassian hesabı hizmetlerine <b>erişiminizi hemen kaybedeceksiniz</b>. Şu anda Community ve Marketplace gibi hizmetler dışında hiçbir hizmete erişiminiz yok.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.admin': 'İşle ilgili veya hukuki meşru amaçlarımız doğrultusunda saklamamız gereken bazı durumlar dışında, kullanıcının adı ve soyadı ile e-posta adresi gibi kişisel verilerini 30 gün içinde Atlassian hesabı hizmetlerinden <b>sileriz</b>.',
  'focused-task-close-account.delete-account.overview.paragraph.personal-data-will-be-deleted.self': 'İşle ilgili veya hukuki meşru amaçlarımız doğrultusunda saklamamız gereken bazı durumlar dışında, adınız veya soyadınız ile e-posta adresiniz gibi kişisel verilerinizi 30 gün içinde Atlassian hesabı hizmetlerinden <b>sileriz</b>.',
  'focused-task-close-account.delete-account.overview.warning-section.body': '14 günlük mühlet sona erdikten sonra hesabın silinmesini iptal edemezsiniz. İleride bu hesabı kullanmanız gerekebileceğini düşünüyorsanız hesabı silmek yerine devre dışı bırakın.',
  'focused-task-close-account.delete-account.overview.warning-section.deactivated.body': '14 günlük mühlet sona erdikten sonra hesabın silinmesini iptal edemezsiniz.',
  'focused-task-close-account.learnMore': 'Daha fazla bilgi edinin',
  'focused-task-close-account.next': 'İleri',
  'focused-task-close-account.previous': 'Geri'
};