import { createProviderWithCustomFetchData } from '../../common/providers/create-data-provider';
import { ExperienceMark } from '../../common/utils/experience-tracker';
export var fetchEmptyData = function fetchEmptyData() {
  return Promise.resolve({
    sites: undefined
  });
};
export var createJoinableSitesProvider = function createJoinableSitesProvider() {
  var fetchData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : fetchEmptyData;
  var retryConfig = arguments.length > 1 ? arguments[1] : undefined;
  return createProviderWithCustomFetchData('joinableSites', ExperienceMark.API_JOINABLE_SITES, fetchData, retryConfig);
};