/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { NavigationAnalyticsContext } from '@atlaskit/analytics-namespaced-context';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { N30 } from '@atlaskit/theme/colors';
import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '../../common/constants';
import { defaultTheme, ThemeProvider } from '../../theme';
import { PrimaryItemsContainer } from '../PrimaryItemsContainer';
import { PrimaryItemsContainer as PrimaryItemsContainerV2 } from '../PrimaryItemsContainerV2';
var containerStyles = css({
  display: 'flex',
  boxSizing: 'border-box',
  height: HORIZONTAL_GLOBAL_NAV_HEIGHT,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderBlockEnd: "1px solid ".concat("var(--ds-border, ".concat(N30, ")")),
  paddingInlineEnd: "var(--ds-space-150, 12px)",
  paddingInlineStart: "var(--ds-space-150, 12px)"
});
var leftStyles = css({
  display: 'flex',
  minWidth: 0,
  height: 'inherit',
  alignItems: 'center',
  flexGrow: 1,
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > *': {
    flexShrink: 0
  }
});
var rightStyles = css({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles
  '& > *': {
    flexShrink: 0,
    marginInlineEnd: "var(--ds-space-050, 4px)"
  }
});
var noRightMarginStyles = css({
  marginInlineEnd: 0
});
var analyticsData = {
  attributes: {
    navigationLayer: 'global'
  },
  componentName: 'atlassianNavigation'
};

/**
 * __Atlassian navigation__
 *
 * The horizontal navigation component for Atlassian products.
 *
 * - [Examples](https://atlassian.design/components/atlassian-navigation/examples)
 * - [Code](https://atlassian.design/components/atlassian-navigation/examples)
 */
export var AtlassianNavigation = function AtlassianNavigation(props) {
  var label = props.label,
    _props$primaryItems = props.primaryItems,
    primaryItems = _props$primaryItems === void 0 ? [] : _props$primaryItems,
    AppSwitcher = props.renderAppSwitcher,
    create = props.renderCreate,
    Help = props.renderHelp,
    Notifications = props.renderNotifications,
    ProductHome = props.renderProductHome,
    Profile = props.renderProfile,
    Search = props.renderSearch,
    SignIn = props.renderSignIn,
    Settings = props.renderSettings,
    _props$moreLabel = props.moreLabel,
    moreLabel = _props$moreLabel === void 0 ? '…' : _props$moreLabel,
    _props$theme = props.theme,
    theme = _props$theme === void 0 ? defaultTheme : _props$theme,
    testId = props.testId;
  return jsx(ThemeProvider, {
    value: theme
  }, jsx(NavigationAnalyticsContext, {
    data: analyticsData
  }, jsx("header", {
    style: theme.mode.navigation,
    css: containerStyles,
    "data-testid": testId && "".concat(testId, "-header"),
    role: "banner"
  }, jsx("nav", {
    css: leftStyles,
    "aria-label": label
  }, AppSwitcher && jsx(AppSwitcher, null), ProductHome && jsx(ProductHome, null), getBooleanFF('platform.design-system-team.navigation-v2-no-jank_5yhbd') ? jsx(PrimaryItemsContainerV2, {
    testId: testId,
    moreLabel: moreLabel,
    items: primaryItems,
    create: create
    // eslint-disable-next-line @repo/internal/react/no-unsafe-overrides
    ,
    theme: theme
  }) : jsx(PrimaryItemsContainer, {
    testId: testId,
    moreLabel: moreLabel,
    items: primaryItems,
    create: create
    // eslint-disable-next-line @repo/internal/react/no-unsafe-overrides
    ,
    theme: theme
  })), jsx("div", {
    css: rightStyles,
    "data-testid": testId && "".concat(testId, "-secondary-actions")
  }, Search && jsx(Search, null), jsx("div", {
    role: "list",
    css: [rightStyles, noRightMarginStyles]
  }, Notifications && jsx(Notifications, null), Help && jsx(Help, null), Settings && jsx(Settings, null), SignIn && jsx(SignIn, null), Profile && jsx(Profile, null))))));
};
export default AtlassianNavigation;