import { atlassianGraphQLGatewayClient } from '../clients/atlassian-graphql-gateway-client';
import {
  OutboundAuthAccountsPayload,
  outboundAuthClient,
} from '../clients/outbound-auth-client';
import {
  GET_CONNECTED_APPS,
  REMOVE_CONNECTED_APP,
  REMOVE_THIRD_PARTY_CONNECTION,
} from './connected-apps-reducer';
import { AccountGrantResponse, ClientResponse } from './connected-apps-types';

export interface ConnectedAppsBaseAction<T, U, V = null> {
  type: T;
  payload: U;
  meta?: V;
}

export type GetConnectedAppsAction = ConnectedAppsBaseAction<
  typeof GET_CONNECTED_APPS,
  [ClientResponse, OutboundAuthAccountsPayload, AccountGrantResponse]
>;

export type RemoveConnectedAppAction = ConnectedAppsBaseAction<
  typeof REMOVE_CONNECTED_APP,
  void,
  { oauthClientId: string }
>;

export type RemoveThirdPartyConnectionAction = ConnectedAppsBaseAction<
  typeof REMOVE_THIRD_PARTY_CONNECTION,
  void,
  { accountId: string; oauthClientId?: string }
>;

export type ConnectedAppsAction =
  | GetConnectedAppsAction
  | RemoveConnectedAppAction
  | RemoveThirdPartyConnectionAction;

export const getConnectedApps = (userId: string) => {
  return (dispatch): void => {
    dispatch({
      type: GET_CONNECTED_APPS,
      payload: Promise.all([
        atlassianGraphQLGatewayClient.queryConnectedApps({ last: 20 }),
        outboundAuthClient.getAccounts(userId),
        atlassianGraphQLGatewayClient.queryAccountGrantConnectedApps(),
      ]),
    })
      // Prevent uncaught exception in console.
      .catch(() => null);
  };
};

export const removeAccessForApp = (userId: string, oauthClientId: string) => {
  return (dispatch): Promise<void> => {
    const removeConnectedAppsPromise = atlassianGraphQLGatewayClient.removeConnectedApps(
      oauthClientId,
    );

    dispatch({
      type: REMOVE_CONNECTED_APP,
      payload: removeConnectedAppsPromise,
      meta: {
        oauthClientId,
      },
    })
      // Prevent uncaught exception in console.
      .catch(() => null);

    return removeConnectedAppsPromise;
  };
};

export const removeAccessToThirdParty = (
  userId: string,
  accountId: string,
  oauthClientId?: string,
) => {
  return (dispatch): Promise<void> => {
    const removeOutboundAuthPromise = outboundAuthClient.revokeAccess(
      userId,
      accountId,
    );

    dispatch({
      type: REMOVE_THIRD_PARTY_CONNECTION,
      payload: removeOutboundAuthPromise,
      meta: {
        accountId,
        oauthClientId,
      },
    }).catch(() => null);

    return removeOutboundAuthPromise;
  };
};
