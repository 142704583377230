import { useEffect } from 'react';
import createFocusTrap from 'focus-trap';
import noop from '@atlaskit/ds-lib/noop';
export var useFocusManager = function useFocusManager(_ref) {
  var initialFocusRef = _ref.initialFocusRef,
    popupRef = _ref.popupRef,
    shouldCloseOnTab = _ref.shouldCloseOnTab;
  useEffect(function () {
    if (!popupRef || shouldCloseOnTab) {
      return noop;
    }
    var trapConfig = {
      clickOutsideDeactivates: true,
      escapeDeactivates: true,
      initialFocus: initialFocusRef || popupRef,
      fallbackFocus: popupRef,
      returnFocusOnDeactivate: true
    };
    var focusTrap = createFocusTrap(popupRef, trapConfig);
    var frameId = null;

    // wait for the popup to reposition itself before we focus
    frameId = requestAnimationFrame(function () {
      frameId = null;
      focusTrap.activate();
    });
    return function () {
      if (frameId != null) {
        cancelAnimationFrame(frameId);
        frameId = null;
      }
      focusTrap.deactivate();
    };
  }, [popupRef, initialFocusRef, shouldCloseOnTab]);
};